import { DataService } from "./data.service";
import { Affiliate } from "./model/affiliate";
import { Injectable } from '@angular/core';
import GroupsJSON from '../assets/groups.json';
import { GlobalErrorHandlerService } from './pages/GlobalErrorHandlerService';
import { UserGroups } from './model/userGroups';
import { ResortSummary } from './model/resortSummary';
import { GlobalConstants } from './pages/global-constants';

@Injectable({
    providedIn: 'root'
})
export class UsergroupsSerivice {
    selectedItems: any[];
    selectedUserType: string;
    userGroups: UserGroups;
    invalidPropMessage: string;
    inactivePropMessage: string;
    isPropInactive: boolean = false;
    isPropInvalid: boolean = false;
    constructor(private dataService: DataService, public globalErrorHandlerService: GlobalErrorHandlerService,public globalConstants:GlobalConstants ) { }

    async addGroups(selectedAffiliate: string, selectedproperties: any[], accessToken: any) {
        this.userGroups = new UserGroups();
        let groups: Set<string> = new Set();
        //add the groups added in UI

        if (this.selectedItems != null)
            for (let i = 0; i < this.selectedItems.length; i++) {
                groups.add(this.selectedItems[i]["item_id"]);
            }

        this.dataService.canAccessMemberwebTab = false;
        //add the groups based on the affilaite or property selected
        if (this.selectedUserType == 'AFFILIATE_STAFF' || this.selectedUserType == 'AFFILIATE_ADMIN' || this.selectedUserType == 'REGIONAL_AFFILIATE_ADMIN') {
            this.dataService.canAccessMemberwebTab = true;
            await this.getGroupsForAffiliateUsers(selectedAffiliate, accessToken).then(data => {
                data.forEach(element => {
                    groups.add(element);
                });
            });
        }else if (this.selectedUserType == 'VENDOR_STAFF' || this.selectedUserType == 'VENDOR_ADMIN'){
            this.dataService.canAccessMemberwebTab = false;
            //add groups based on the selected vendor code
            await this.getGroupsForVendors(this.selectedUserType).then(data => {
                data.forEach(element => {
                    groups.add(element);
                });
            });
        }else if (this.selectedUserType == 'PROPERTY_STAFF' || this.selectedUserType == 'PROPERTY_ADMIN') {
            await this.getGroupsForPropertyUsers(selectedproperties, accessToken).then((data) => {
                data.forEach(element => {
                    groups.add(element);
                });
            });
        }
        this.userGroups.groups = groups;
        return this.userGroups;
    }

    async getGroupsForAffiliateUsers(selectedAffiliate: string, accessToken: any) {
        let groups: Set<string> = new Set();
        let affiliateCodes: string[] = [];
        affiliateCodes.push(selectedAffiliate);

        try {
            //check if the affiliate is regional affiliate if yes then add associated affiliates.
            let data: string = await this.dataService.getAffiliateOffices(selectedAffiliate,accessToken);
            let affilateOfficeDetails: Affiliate = JSON.parse(JSON.stringify(data));
            if (affilateOfficeDetails.regionalAffiliate) {
                for (let i = 0; i < affilateOfficeDetails.affiliates.length; i++) {
                    affiliateCodes.push(affilateOfficeDetails.affiliates[i].code);
                }
            }

            //get the associated groups for affiliate user from JSON file.
            let affiliateGroups: string[] = GroupsJSON['affilaite_user'];
            if (affiliateGroups.length > 0) {
                affiliateGroups.forEach(item => {
                    if (item.indexOf('XXXX') > -1) {
                        affiliateCodes.forEach(affCode => { groups.add(item.replace('XXXX', affCode)) });
                    } else {
                        groups.add(item);
                    }
                });
            }
        } catch(error) {
            this.globalErrorHandlerService.handleError(error);
            this.userGroups.hasError = true;
            return;

        }
        return groups;
    }
    async getGroupsForPropertyUsers(selectedproperties: string[], accessToken:any) {
        let groups: Set<string> = new Set();
        let internationalPropertiesMap: Map<string, string> = new Map();
        let resortSummary: ResortSummary;
        let propertyGroups: string[] = [];
        let invalidProps:string[] = [];
        let inactiveProps:string[] = [];
        this.userGroups.inactivePropMessage="";
        this.userGroups.invalidPropMessage="";
        //check if prop is internation/domestic property.
        //1. If Domestic property add associated group from JSON file
        //2. If International property add the prop and the chainCode to the Map
        try {
            resortSummary = JSON.parse(JSON.stringify(await this.dataService.getPropDetails(selectedproperties, accessToken)));

            if (resortSummary.activeProperties && resortSummary.activeProperties.length > 0) {
                resortSummary.activeProperties.forEach(activeProperties => {
                    if(activeProperties.chainCode!= null && typeof activeProperties.chainCode!= 'undefined'){
                        this.dataService.canAccessMemberwebTab = true;
                        if (activeProperties.chainCode == 'PB') {
                            propertyGroups = GroupsJSON["property_user"][activeProperties.chainCode];
                            if (propertyGroups && propertyGroups.length > 0) {
                                propertyGroups.forEach(item => groups.add(item));
                            }
                        } else {
                            if (activeProperties["international-domestic-indicator"] == 'I') {
                                internationalPropertiesMap.set(activeProperties.resort, activeProperties.chainCode);
                            } else {
                                propertyGroups = GroupsJSON["property_user"][activeProperties.chainCode + "_D"];
                                if (propertyGroups && propertyGroups.length > 0) {
                                    propertyGroups.forEach(item => groups.add(item));
                                }
                            }
                        }
                    }
                });
            }
            if (resortSummary.inactiveProperties.length > 0) {
                this.isPropInactive = true;
                resortSummary.inactiveProperties.forEach((item) => {
                  inactiveProps.push(item);
                });
                this.userGroups.inactivePropMessage = this.globalConstants.inactive_resorts + inactiveProps;
              }
              if (resortSummary.invalidProperties.length > 0) {
                this.isPropInvalid = true;
                resortSummary.invalidProperties.forEach((item) => {
                  invalidProps.push(item);
                });
                this.userGroups.invalidPropMessage = this.globalConstants.invalid_resorts + invalidProps;
              }
        }
        catch(error) {
            this.globalErrorHandlerService.handleError(error);
            this.userGroups.hasError = true;
            return;
        }

        let internationalProperties: string[] = Array.from(internationalPropertiesMap.keys());
        if (internationalProperties.length > 0) {
            await this.setGroupsForInternationalProperties(internationalProperties, internationalPropertiesMap, accessToken).then((data) => {
                data.forEach(group => groups.add(group));
            });
        }
        return groups;
    }

    
    async setGroupsForInternationalProperties(internationalProperties : string[], internationalPropertiesMap : Map<string, string>,
        accessToken : string) {
        let propAffiliateResponse:Affiliate[] = [];
        let propertyGroups: string[] = [];
        let affiliateCode: string = "";
        let key: string = "";
        let groups : Set<string> = new Set<string>();
        try {
            propAffiliateResponse = JSON.parse(JSON.stringify(await this.dataService.getAffiliateForProperty(internationalProperties,accessToken)));
        } catch(error) {
            if(error.status == 404) {
                propAffiliateResponse = [];
            } else {
                this.globalErrorHandlerService.handleError(error);
                this.userGroups.hasError = true;
                return;
            }
        } 
        internationalProperties.forEach(prop => {
            let chainCode = internationalPropertiesMap.get(prop);
            let affiliate: Affiliate[] = propAffiliateResponse.filter(p => p.properties.includes(prop));
            if (affiliate.length == 1) {
                key = chainCode + "_I_AFFILIATE";
                affiliateCode = affiliate[0].code;
            } else if(chainCode == 'WW' || chainCode == 'XW' || chainCode == 'DO') {
                key = internationalPropertiesMap.get(prop) + "_I";
            } else if (affiliate.length != 1 && !((chainCode == 'WW' || chainCode == 'XW' || chainCode == 'DO'))){
                key = "MPRD_AFFIL";
            } else {
                this.globalErrorHandlerService.handleError(new Error("Error processing your request!!"));
                this.userGroups.hasError = true;
                return;
            }
            propertyGroups = GroupsJSON["property_user"][key];
            if (propertyGroups && propertyGroups.length > 0) {
                propertyGroups.forEach(element => {
                    if (element.indexOf("XXXX") > -1) {
                        groups.add(element.replace("XXXX", affiliateCode));
                    } else {
                        groups.add(element);
                    }
                });
            }
        });
        return groups;
    }

    async getGroupsForVendors(userType){
        let groups: Set<string> = new Set();
        let vendorGroups: string[] = GroupsJSON["vendor_user"];
        if (vendorGroups && vendorGroups.length > 0) {
            vendorGroups.forEach(item => groups.add(item));
            if(userType == 'VENDOR_ADMIN') 
            groups.add('OH-IDM-Role User Management - Admin');
        }
        return groups;
    }
}