export class SearchModel {
    searchCriteria: string;
    operator: string;
    inputValue: string;
    userId: string;
    propertyId: string;
    userRole: string;
    affiliateOffice: string;
    customSearchValue:any[];
    isCustomSearch:boolean;
    selectedItems:any[];
    isSuperEmployee:boolean;
    propUnderScope: any[];
    isCorpUser: boolean;
}