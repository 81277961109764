import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkingData, NetworkRequestParam, NetworkResponse } from 'src/app/model/networking-model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetworkDataService {

  constructor(private httpClient: HttpClient) { }

  public searchNetworkIp(reqParam: NetworkRequestParam) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + reqParam.access_token,
        'submitted-by': reqParam.submitted_by
      })
    };
    return this.httpClient.get<NetworkResponse>(`${environment.services.network_ip_base_url}/${reqParam.id}`, httpOptions);
  }

  public saveNetworkIp(body: NetworkingData,reqParam: NetworkRequestParam){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + reqParam.access_token,
        'submitted-by': reqParam.submitted_by
      })
    }
    return this.httpClient.post<NetworkResponse>(`${environment.services.network_ip_base_url}`, body,httpOptions);
  }

  public deleteNetworkIp(reqParam: NetworkRequestParam) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + reqParam.access_token,
        'submitted-by': reqParam.submitted_by
      }),
      params: new HttpParams().set('uid', reqParam.uid)
    };
    return this.httpClient.delete<NetworkResponse>(`${environment.services.network_ip_base_url}/${reqParam.id}`, httpOptions);
  }
}
