export class Profile{
    firstName: string;
    lastName: string;
    mobilePhone: string;
    secondEmail: string;
    login: string;
    email: string;   
    employeeTypeIDM: string;
    uid: string;
    initials: string[];
    userManagement:boolean;
    beginDate:string;
    endDate:string;
    doNotDelete:boolean
    propID:string[];
    groupsAssigned:string[];
    preferredLanguage:string;
    profileType:string;
    affiliateOffice:string;
    votingMember: boolean;
    vendorCode:string;
    idm_role: string;
    frontDeskIncentives: string;

}