<mat-sidenav-container>
    <multi-app-sidenav [multiAppData]="multiAppData" [sideNavSelItem]="sideNavSelItem" (sidenavClickedEvent)="sidenavClickedEvent($event)"></multi-app-sidenav>
    <multi-app-sidenav-content (masterChange)="masterChangeEvent($event)" (listChange)="listChangeEvent($event)"
             [master_checked]="master_checked" [appMenuItems]="appMenuItems" [disableMemberWebMenus]="disableMemberWebMenus" [isMemberWeb]="isMemberWeb" [mwMenuItems]="mwMenuItems"
                 [selectedAppData]="selectedAppData" [incentiveCdFormCtrl]="incentiveCdFormCtrl"></multi-app-sidenav-content> 
</mat-sidenav-container>
<div class="button-group">
    <button *ngIf="parentModule === globalConstants.manage_user" class="button button-medium bg-color-white" mat-raised-button (click)="resetMenus()">Reset</button>
    <button *ngIf="parentModule === globalConstants.create_user" class="button button-medium bg-color-white" mat-raised-button (click)="clearMenus()">Clear</button>
    <button class="button button-medium bg-color-white" (click)="cancel()" mat-raised-button>Cancel</button>
    <button [disabled]="isUserInactive" class="button button-medium" mat-raised-button (click)="updateMenus()">Save</button>
</div>
