import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MultiAppData } from 'src/app/model/multi-app-access.model';

@Component({
  selector: 'multi-app-sidenav',
  templateUrl: './multi-app-sidenav.component.html',
  styleUrls: ['./multi-app-sidenav.component.css']
})
export class MultiAppSidenavComponent implements OnInit{
  
  @Input() multiAppData: MultiAppData[];
  @Input() sideNavSelItem: string;
  @Output() sidenavClickedEvent = new EventEmitter<MultiAppData>();
  selectedAppData: MultiAppData;

  constructor(){

  }

  ngOnInit(): void {
    console.log(this.multiAppData);
  }

  protected onSideNavClick(navEvent: MultiAppData){
    this.selectedAppData = navEvent;
    this.sideNavSelItem =  navEvent.appName;
    this.sidenavClickedEvent.emit(navEvent);
  }

}
