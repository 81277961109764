import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { User } from '../../model/user';
import { Profile } from '../../model/profile';
import { Affiliate } from '../../model/affiliate';
import { VendorCodes } from '../../model/vendorCodes';
import { OktaAuthService } from '@okta/okta-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { UsergroupsSerivice } from '../../usergroups.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { formatDate } from "@angular/common";
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { UserGroups } from 'src/app/model/userGroups';
import { GlobalConstants } from '../global-constants';
import { Subscription } from 'rxjs';
import { SearchModel } from 'src/app/model/searchModel';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { PopupComponent } from '../popup/popup.component';
import { CreateUpdateRequest } from 'src/app/model/createUpdateRequest';
import { Memberweb } from 'src/app/model/memeberweb';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../.././../environments/environment';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { memberwebMenus } from 'src/app/model/memberwebMenu';
import { SubMenuList } from 'src/app/model/subMenuList';
import { int } from 'aws-sdk/clients/datapipeline';
import { TemplateSearchRequest } from 'src/app/model/idm-models';
const moment = _moment;


const MY_FORMATS = {
  parse: {
    dateInput: 'MMM DD yyyy',
  },
  display: {
    dateInput: 'MMM DD yyyy',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Items {
  value: string;
  viewValue: string;
}
export interface TemplateRoleItems {
  value: string;
  viewValue: string;
  tooltip: string;
}
export interface Templates {
  viewValue: string;
  value: TemplateProfile;

}
@Component({
  selector: 'app-create',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CreateUserComponent implements OnInit {
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  locale = 'en-US';
  form: FormGroup;
  user: User = new User();
  userId: string;
  profile: Profile = new Profile();
  selectedproperties: any[] = [];
  isSinglePropUser: boolean = false;
  userName: string = '';
  firstName: string = '';
  firstNamebox: any;
  lastName: string = '';
  initials: string = '';
  email: string = '';
  isEmailExist: boolean = true;
  activeDate = new Date()
  inActiveDate;
  selectedPropTemp: any;
  tabIndex = 0;
  isCreateTemp: boolean = false;
  isManageTemp: boolean = false;
  isUserMgmt: boolean = false;
  isaffiliatePDR: boolean = false;
  selectedAffiliate: string;
  propertyTempControl = new FormControl('');
  userMngmtControl = new FormControl();
  selectedVendor: string;
  firstNameControl = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9][a-zA-Z0-9' _.-]*$")]);
  lastNameControl = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9][a-zA-Z0-9' _.-]*$")]);
  initialsControl = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-z]+([ ][a-zA-Z]+)*$")]);
  propControl = new FormControl('', [Validators.required]);
  propertyControl = new FormControl('', [Validators.required, Validators.pattern("^[0-9]{5}([,][0-9]{5})*$")]);
  userIdcontrol = new FormControl('', [Validators.required]);
  userGroupsControl = new FormControl('');
  templateIDControl = new FormControl('');
  tempIDtxtboxControl = new FormControl('', [Validators.pattern("^[a-zA-Z0-9_]+$")]);
  emailControl;
  emailbox: any;
  properties: string[];
  activeDateControl = new FormControl(new Date(), [Validators.required]);
  inActiveDateControl = new FormControl('', []);
  inActiveDateMin = new Date();
  userControl = new FormControl('', [Validators.required]);
  affiliateOfficeControl = new FormControl('', [Validators.required]);
  vendorcdControl = new FormControl('', [Validators.required]);
  dropdownList = [];
  selectedItems = [];
  selectedUserType: string;
  dropdownSettings: IDropdownSettings;
  dropdownSettingsID: IDropdownSettings;
  dropdownSettingsTemp: IDropdownSettings;
  usertypes: Items[] = [];
  loggedInUser: any;
  sortedDropDownList = [];
  dropDownListTemplate: Templates[] = [];
  dropDownListProperties: any[] = [];
  selectedID: string[] = [];
  affiliateOffices: Items[] = [];
  vendors: Map<string, string> = new Map();
  accessToken;
  searchModelArray: any[] = [];
  ishideCreateMembTemplate: boolean = false;
  todayDate: Date = new Date();
  isPDRuser: boolean;
  affilateOfficeCode: Affiliate;
  affiliateOfficeCodeProp: Affiliate;
  private listener: Subscription;
  message: string = '';
  isAffiliateUser: boolean = false;
  propRegex = "/^[0-9]{5}([,][0-9]{5})*$/";
  selDate: string;
  selDay: string;
  selMonth: string;
  selYear: string;
  affiliatePdrMap: Map<string, boolean> = new Map();
  isPropertyUser: boolean = false;
  isVendorUser: boolean = false;
  invalidPropertyMessage: string;
  invalidPropControl = new FormControl('', [Validators.required]);
  inactivePropControl = new FormControl('', [Validators.required]);
  invalidPropMessage: string;
  inactivePropMessage: string;
  isSuperEmployee: boolean;
  ediObj: string;
  searchResults: any[] = [];
  templateDropDown: Templates[];
  templateRole: string = '';
  loggedInTemplatePermission: string = '';
  showTemplateScreen: boolean = true;
  templateRoleControl = new FormControl('', [Validators.required]);
  templatePermissions: TemplateRoleItems[] = [];
  dispScreen: any;
  countChangeTabs: number = 0;
  isMultipleUsers: boolean = true;
  isSingleUser: boolean = false;
  assignedProperties: any[] = [];
  affiliateUserPropList: string[];
  menuitems: Memberweb = new Memberweb();
  propUserProperties: any;
  isNoTemplate: boolean = false;
  memberwebMenuItems: MemberwebMenuItems[];
  headings = [];
  tempmenuitems: Memberweb = new Memberweb();
  master_checked: boolean[] = new Array();
  isRestrictedUser: boolean = false;
  tempRolesTooltip: string[] = [];
  isButtonClicked: string;
  groupsAssigned: string = '';
  secondEmail: string = '';
  vendorCode: string = '';
  affiliateOffice: string = '';
  beginDate: string = '';
  propID: string = '';
  isDisabled: boolean = false;

  constructor(
    public oktaAuth: OktaAuthService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private usergroups: UsergroupsSerivice,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    public globalConstants: GlobalConstants,
    public dialog: MatDialog) {
    let date = new Date();
    this.inActiveDateMin = new Date(date.setDate(date.getDate() + 1));
    this.isPDRuser = false;
    this.dataService.isPdrUser = false;
    this.dataService.userclaims = JSON.parse(localStorage.getItem("userClaims"));
    this.propUserProperties = JSON.parse(localStorage.getItem("userClaims"));
    this.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';
    this.dataService.getInvokeEvent(this.globalConstants.idm);
    if (this.selectedUserType != "PROPERTY_STAFF") {
      this.emailControl = new FormControl('', [Validators.required]);
    }
    let isBackClicked: string = localStorage.getItem("isButtonClicked")
    if (isBackClicked == null || typeof isBackClicked == 'undefined') {
      this.isButtonClicked = 'N';
    } else {
      this.isButtonClicked = isBackClicked;
    }
    if (localStorage.getItem("createTempUser") == "Y") {
      this.dataService.isSetFromTemplate = true;
    }
    this.form = this.fb.group({
      checkArray: this.fb.array([], [Validators.required]),
      emailAddressControl: ["", Validators.pattern("[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+[.][A-Za-z]{2,3}$")]
    })
    this.loggedInUser = this.dataService.userclaims.userRole;
    this.loggedInTemplatePermission = this.dataService.userclaims.idm_role[0];
    if (this.loggedInUser == 'PROPERTY_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
      this.isMultipleUsers = false;
      this.isSingleUser = true;
      this.selectedUserType = "PROPERTY_STAFF";
      this.isPropertyUser = true;
    }
    else if (this.loggedInUser == 'PROPERTY_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
      this.isMultipleUsers = false;
      this.isSingleUser = true;
      this.selectedUserType = "PROPERTY_STAFF";
      this.isPropertyUser = true;
    }
    else if (this.loggedInUser == 'VENDOR_ADMIN') {
      this.usertypes.push({ value: 'VENDOR_STAFF', viewValue: 'Vendor Staff' });
      this.isMultipleUsers = false;
      this.isSingleUser = true;
      this.selectedUserType = "VENDOR_STAFF";
      this.isVendorUser = true;
    }
    else if (this.loggedInUser == 'AFFILIATE_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUser == 'AFFILIATE_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' });
    }
    else if (this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }
    else if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == true) {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'VENDOR_ADMIN', viewValue: 'Vendor Admin' },
        { value: 'VENDOR_STAFF', viewValue: 'Vendor Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }
    else if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == false) {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    if (typeof this.dataService.userclaims.properties != 'undefined' && this.dataService.userclaims.properties != null) {
      this.sortedDropDownList = this.dataService.userclaims.properties.sort();
      if (this.sortedDropDownList.length == 1) {
        this.selectedproperties = this.sortedDropDownList;
        this.isSinglePropUser = true;
      }
    }
    if (this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'PROPERTY_STAFF') {
      this.dropDownListProperties = this.propUserProperties.properties.sort();
      if (this.dropDownListProperties.length > 1) {
        this.dropDownListProperties.push('Multi_Property');
      } else {
        this.selectedID = [this.dropDownListProperties[0]];
        let searchModel: SearchModel = new SearchModel();
        searchModel.isCustomSearch = true;
        searchModel.userRole = this.loggedInUser;
        searchModel.customSearchValue = this.selectedID;
        searchModel.isCorpUser = false;
        searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
        this.searchModelArray.push(searchModel);
        this.firstSearchTemplate(this.searchModelArray, this.dataService.accessToken);
      }

    }

    if (this.loggedInUser == 'VENDOR_ADMIN') {
      this.selectedVendor = this.dataService.userclaims.vendorCode
    }
    if (this.loggedInUser == "EMPLOYEE" && this.isSuperEmployee == true) {
      this.dataService.getVendorCodes(this.dataService.accessToken).then((data: String) => {
        let vendorList: VendorCodes = JSON.parse(JSON.stringify(data));
        for (var value in vendorList) {
          this.vendors.set(value, vendorList[value])
        }
      },
        (error) => {
          this.globalErrorHandlerService.handleError(error);
        });
    }

    if (this.loggedInUser == "EMPLOYEE") {
      this.dataService.getAffiliateOfficesforEmp(this.dataService.accessToken).then((data: String) => {
        let affilateOfficeCodes: Affiliate[] = JSON.parse(JSON.stringify(data));
        for (let i = 0; i < affilateOfficeCodes.length; i++) {
          this.affiliateOffices.push({ value: affilateOfficeCodes[i].code, viewValue: affilateOfficeCodes[i].code + "--" + affilateOfficeCodes[i].name })
          this.affiliatePdrMap.set(affilateOfficeCodes[i].code, affilateOfficeCodes[i].pdr);
        }
        this.sortAffiliates(this.affiliateOffices);
      },
        (error) => {
          this.globalErrorHandlerService.handleError(error);
        });
    }
    else if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' ||
      this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.dataService.getAffiliateOffices(this.dataService.userclaims.affiliateOffice, this.dataService.accessToken).then((data: string) => {
        this.affilateOfficeCode = JSON.parse(JSON.stringify(data));
        this.affiliateOfficeCodeProp = JSON.parse(JSON.stringify(data));
        if (this.affilateOfficeCode != null) {
          if (this.affilateOfficeCode.regionalAffiliate) {
            for (let i = 0; i < this.affilateOfficeCode.affiliates.length; i++) {
              this.affiliateOffices.push({ value: this.affilateOfficeCode.affiliates[i].code, viewValue: this.affilateOfficeCode.affiliates[i].code + "--" + this.affilateOfficeCode.affiliates[i].name })
            }
            this.sortAffiliates(this.affiliateOffices);
          } else {
            this.affiliateOffices.push({ value: this.affilateOfficeCode.code, viewValue: this.affilateOfficeCode.code + "--" + this.affilateOfficeCode.name })
          }
          if (typeof this.affilateOfficeCode.properties != 'undefined' && this.affilateOfficeCode.properties != null) {
            setTimeout(() => {
              this.sortedDropDownList = this.affiliateOfficeCodeProp.properties.sort();
            })
          }
          this.dropDownListProperties = this.affilateOfficeCode.properties.sort();
          this.affiliateUserPropList = this.affilateOfficeCode.properties.sort();
          if (this.dropDownListProperties.length > 1) {
            let count = 0;
            for (let i = 0; i < this.dropDownListProperties.length; i++) {
              if (this.dropDownListProperties[i] == 'Multi_Property') {
                count = count + 1;
              }
            }
            if (count == 0) {
              this.dropDownListProperties.push('Multi_Property');
            }
          }
          if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
            for (let i = 0; i < this.affiliateOffices.length; i++) {
              this.dropDownListProperties.push(this.affiliateOffices[i].value);
            }
          }

          if (typeof this.affilateOfficeCode != 'undefined' && this.affilateOfficeCode.pdr == true) {
            this.isPDRuser = true;
            this.dataService.isPdrUser = true;
          }
        }
      },
        (error) => {
          this.globalErrorHandlerService.handleError(error);
        });
    }

    if (this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN' || this.loggedInUser == 'EMPLOYEE' || this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Admin') {
      this.templatePermissions.push({ value: environment.user_roles.admin_role, viewValue: 'Admin User', tooltip: 'Can create and edit templates. Also, can create or edit a user with or without a template' },
        { value: environment.user_roles.power_role, viewValue: 'Power User', tooltip: 'Cannot create or edit templates. But can create or edit a user with or without a template' },
        { value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
        { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.' });
    }

    if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - PowerUser') {
      this.templatePermissions.push({ value: environment.user_roles.power_role, viewValue: 'Power User', tooltip: 'Cannot create or edit templates. But can create or edit a user with or without a template' },
        { value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
        { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.' });
    }

    if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
      this.isRestrictedUser = true;
      this.templatePermissions.push({ value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
        { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.' });
    }

    this.dropdownList = [
      { item_id: 'MPRD_GOV', item_text: 'Allow Governors content access' },
      { item_id: 'MPRD_IC', item_text: 'Allow International content access' },
      { item_id: 'MP_SOFT', item_text: 'BW Premier Collection' },
      { item_id: 'MPRD_ADMIN', item_text: 'Member portal Admin ( Developers) Administrator / Help Desk Admin' },
      { item_id: 'MPRD_HOTELPORTAL', item_text: 'Sure Stay Brand Group' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 30,
      allowSearchFilter: true
    };
    this.dropdownSettingsID = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true

    };

    const searchIterateTemplate = this.searchResults;

    if (this.dataService.isSetFromTemplate || (this.dataService.isSetFromTemplate && this.dataService.isCancelclicked)) {
      this.SetFromTemplate();
      this.dataService.isCancelclicked = false;
    }
    if (this.isButtonClicked == 'Y') {
      this.dataService.getInvokeEvent('Create User - ' + this.dataService.createUser.profile.firstName + ' ' + this.dataService.createUser.profile.lastName);
      this.isDisabled = true
      this.disableCreateForm();
      localStorage.removeItem("isButtonClicked");
      console.log(this.dataService.createUser);
      this.selectedUserType = this.dataService.createUser.profile.employeeTypeIDM;
      this.userControl.setValue(this.dataService.createUser.profile.employeeTypeIDM);
      this.firstName = this.dataService.createUser.profile.firstName;
      this.lastName = this.dataService.createUser.profile.lastName;
      this.secondEmail = this.dataService.createUser.profile.secondEmail;
      if (this.dataService.createUser.profile.email.includes('@bwhhotel.com')) {
        this.email = '';
      } else {
        this.email = this.dataService.createUser.profile.email;
      }
      this.vendorcdControl.setValue(this.dataService.createUser.profile.vendorCode);
      this.vendorCode = this.dataService.createUser.profile.vendorCode;
      this.affiliateOfficeControl.setValue(this.dataService.createUser.profile.affiliateOffice);
      this.affiliateOffice = this.dataService.createUser.profile.affiliateOffice;
      this.beginDate = this.dataService.createUser.profile.beginDate;
      this.initials = this.dataService.createUser.profile.initials[0];
      this.selectedproperties = this.dataService.createUser.profile.propID;
      if (null != this.dataService.createUser.profile.groupsAssigned) {
        this.groupsAssigned = "";
        for (let i = 0; i < this.dataService.createUser.profile.groupsAssigned.length; i++) {
          if (i >= 1)
            this.groupsAssigned = this.groupsAssigned + ',' + this.dataService.createUser.profile.groupsAssigned[i];
          else
            this.groupsAssigned = this.groupsAssigned + this.dataService.createUser.profile.groupsAssigned[i];
        }
      }
      if (null != this.dataService.createUser.profile.groupsAssigned) {
        let items: any = [];
        this.selectedItems = []
        for (let i = 0; i < this.dataService.createUser.profile.groupsAssigned.length; i++) {
          if ("MPRD_GOV" == this.dataService.createUser.profile.groupsAssigned[i]) {
            items.push({ item_id: 'MPRD_GOV', item_text: 'Allow Governors content access' });
          } if ("MPRD_IC" == this.dataService.createUser.profile.groupsAssigned[i]) {
            items.push({ item_id: 'MPRD_IC', item_text: 'Allow International content access' });
          } if ("MPRD_HOTELPORTAL" == this.dataService.createUser.profile.groupsAssigned[i]) {
            items.push({ item_id: 'MPRD_HOTELPORTAL', item_text: 'Sure Stay Brand Group' });
          } if ("MPRD_ADMIN" == this.dataService.createUser.profile.groupsAssigned[i]) {
            items.push({ item_id: 'MPRD_ADMIN', item_text: 'Member portal Admin ( Developers) Administrator / Help Desk Admin' });
          }
          if ("MP_SOFT" == this.dataService.createUser.profile.groupsAssigned[i]) {
            items.push({ item_id: 'MP_SOFT', item_text: 'BW Premier Collection' });
          }
        }
        this.selectedItems = items;
      }
      if (this.dataService.createUser.profile.endDate) {
        this.inActiveDateControl.setValue(this.dataService.createUser.profile.endDate.split('T')[0]);
      }
      this.templateRoleControl.setValue(this.dataService.createUser.groupIds[0]);
    }
  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    console.log(this.dataService.userclaims);
    if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
      this.disableFields();
    }
    if (this.dataService.isSetFromTemplate) {
      if (this.isAffiliateUser) {
        document.getElementById('emailbox').focus();
      }
      if (this.isPropertyUser) {
        let myTextBox = document.getElementById('firstNamebox');
        myTextBox.focus();
      }
    }
  }

  emailValidator(email: string) {
    if (email && email.indexOf("@") != -1) {
      let [, domain] = email.split("@");
      if ((this.isAffiliateUser == true && !this.isPDRuser && !this.isaffiliatePDR) || this.isVendorUser) {
        if (domain.toLowerCase() == 'bestwestern.com' || domain.toLowerCase() == 'autoclerk.com' || domain.toLowerCase() == 'worldhotels.com' || domain.toLowerCase() == 'bwhhotelgroup.com') {
          this.form.get('emailAddressControl').setErrors({ invalid: true });
        }
      }

    }
  }

  onPropChange() {
    this.inactivePropControl.setErrors({ inactiveProp: false });
    this.invalidPropControl.setErrors({ invalidProp: false });
  }

  sortAffiliates(affiliateOffices) {
    affiliateOffices.sort((left, right): number => {
      if (left.value < right.value) return -1;
      if (left.value > right.value) return 1;
      return 0;
    });
  }

  clearStartDate() {
    this.activeDate = new Date();
  }

  clearEndDate() {
    this.inActiveDate = null;
    this.inActiveDateControl.reset();
  }

  onCategoryChange(event) {

    this.isPropertyUser = false;
    this.isAffiliateUser = false;
    this.isVendorUser = false;
    this.form.get('emailAddressControl').reset();

    if (this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === 'REGIONAL_AFFILIATE_ADMIN') {
      this.isAffiliateUser = true;
    }
    if (this.selectedUserType === "PROPERTY_STAFF" || this.selectedUserType === "PROPERTY_ADMIN") {
      this.isPropertyUser = true;
    }
    if (this.selectedUserType === "VENDOR_STAFF" || this.selectedUserType === "VENDOR_ADMIN") {
      this.isVendorUser = true;
    }
    if (this.propertyControl.errors) {
      this.propertyControl.reset();
    }
    if (this.propControl.errors) {
      this.propControl.reset();
    }
    if (this.affiliateOfficeControl.errors) {
      this.affiliateOfficeControl.reset();
    }
    if (this.firstNameControl.errors) {
      this.firstNameControl.reset();
    }
    if (this.lastNameControl.errors) {
      this.lastNameControl.reset();
    }
    if (this.initialsControl.errors) {
      this.initialsControl.reset();
    }

    if (this.vendorcdControl.errors) {
      this.vendorcdControl.reset();
    }

    if (this.activeDateControl.errors) {
      this.activeDateControl.reset();
    }
    if (this.inActiveDateControl.errors) {
      this.inActiveDateControl.reset();
    }

    if (this.userGroupsControl.errors) {
      this.userGroupsControl.reset();
    }
  }

  getAffiliatePropList(affiliateOffice: any) {
    if (affiliateOffice == '') {
      return [88900];
    }
  }

  async searchTemplate(request: any[], accessToken: any) {
    let templateSearchRequest = new TemplateSearchRequest();
    templateSearchRequest.submittedBy = this.dataService.userclaims.userId;
    templateSearchRequest.searchRequestData = request;
    this.dataService.getTemplate(templateSearchRequest, accessToken).subscribe((data: any) => {
      const rslt = JSON.parse(JSON.stringify(data));
      const srchrslt = rslt.body;
      if (srchrslt.length === 0) {
        this.dropDownListTemplate.push({ viewValue: "No Template found with given input", value: null });
      }
      let dataSource = [];
      if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == false) {
        for (let i = 0; i < srchrslt.length; i++) {
          if (srchrslt[i].userType != 'AFFILIATE_STAFF' && srchrslt[i].userType != 'AFFILIATE_ADMIN') {
            dataSource.push(srchrslt[i]);
          }
        }
      } else {
        dataSource = srchrslt;
      }
      if (this.dropDownListTemplate.length == 0) {
        for (let i = 0; i < dataSource.length; i++) {
          this.searchResults.push(dataSource[i]);
          if (dataSource[i].ID == 'Multi_Property') {
            this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName + ' - ' + dataSource[i].propertyID, value: dataSource[i] });
          } else {
            this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName, value: dataSource[i] });
          }
        }
      } else {
        for (let i = 0; i < dataSource.length; i++) {
          this.searchResults.push(dataSource[i]);
          if (this.dropDownListTemplate[0].value.templateid != dataSource[i].templateid) {
            if (dataSource[i].ID == 'Multi_Property') {
              this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName + ' - ' + dataSource[i].propertyID, value: dataSource[i] });
            } else {
              this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName, value: dataSource[i] });
            }
          }
        }
      }

    }, (error) => {
      console.log("*** error" + error); //Error callback
      this.globalErrorHandlerService.handleError(error);
    })
  }

  async firstSearchTemplate(request: any[], accessToken: any) {
    let templateSearchRequest = new TemplateSearchRequest();
    templateSearchRequest.submittedBy = this.dataService.userclaims.userId;
    templateSearchRequest.searchRequestData = request;
    this.dataService.getTemplate(templateSearchRequest, accessToken).subscribe((data: any) => {
      const rslt = JSON.parse(JSON.stringify(data));
      const srchrslt = rslt.body;
      if (srchrslt.length === 0) {
        this.dropDownListTemplate.push({ viewValue: "No Template found with given input", value: null });
      }
      if (this.dropDownListTemplate.length == 0) {
        for (let i = 0; i < srchrslt.length; i++) {
          this.searchResults.push(srchrslt[i]);
          if (srchrslt[i].ID == 'Multi_Property') {
            this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName + ' - ' + srchrslt[i].propertyID, value: srchrslt[i] });
          } else {
            this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName, value: srchrslt[i] });
          }
        }
      } else {
        for (let i = 0; i < srchrslt.length; i++) {
          this.searchResults.push(srchrslt[i]);
          if (this.dropDownListTemplate[0].value.templateid != srchrslt[i].templateid) {
            if (srchrslt[i].ID == 'Multi_Property') {
              this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName + ' - ' + srchrslt[i].propertyID, value: srchrslt[i] });
            } else {
              this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName, value: srchrslt[i] });
            }
          }
        }
      }

    }, (error) => {
      console.log("*** error" + error); //Error callback
      this.globalErrorHandlerService.handleError(error);
    })
  }

  disableFields() {
    this.userControl.disable();
    this.affiliateOfficeControl.disable();
    this.userGroupsControl.disable();
    this.propControl.disable();
    this.propertyControl.disable();
    return;
  }

  disableCreateForm() {
    this.templateIDControl.disable();
    this.tempIDtxtboxControl.disable();
    this.propertyTempControl.disable();
    this.userControl.disable();
    this.vendorcdControl.disable();
    this.userMngmtControl.disable();
    this.affiliateOfficeControl.disable();
    this.templateRoleControl.disable();
    this.firstNameControl.disable();
    this.lastNameControl.disable();
    this.initialsControl.disable();
    this.activeDateControl.disable();
    this.inActiveDateControl.disable();
    this.form.get('emailAddressControl').disable();
    this.userGroupsControl.disable();
    this.propControl.disable();
    this.propertyControl.disable();
    this.isDisabled = true;
    return;
  }

  enableFields() {
    this.userControl.enable();
    this.affiliateOfficeControl.enable();
    this.userGroupsControl.enable();
    this.propControl.enable();
    this.propertyControl.enable();
    return;
  }

  async onIDChange(event) {
    console.log('event', event);
    this.templateClear();
    let searchModel: SearchModel = new SearchModel();
    searchModel.isCustomSearch = true;
    searchModel.userRole = this.loggedInUser;
    const slctID = [];
    slctID.push(event);
    if (this.loggedInUser == 'EMPLOYEE') {
      if (this.tempIDtxtboxControl.value.toString() == 'Multi_Property') {
        searchModel.customSearchValue = [this.tempIDtxtboxControl.value.toString()]
      } else {
        searchModel.customSearchValue = [this.tempIDtxtboxControl.value.toUpperCase().toString()];
      }
    } else {
      searchModel.customSearchValue = slctID;
    }
    this.dataService.tempSearchValue = searchModel.customSearchValue[0];
    if (this.loggedInUser == 'EMPLOYEE') {
      searchModel.isCorpUser = true;
      searchModel.propUnderScope = [];
    } else {
      searchModel.isCorpUser = false;
    }
    if (this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'PROPERTY_STAFF') {
      searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
    }
    if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' || this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      searchModel.propUnderScope = this.affiliateUserPropList;
    }
    this.searchModelArray = [];
    this.dropDownListTemplate = [];
    this.searchModelArray.push(searchModel);
    this.searchTemplate(this.searchModelArray, this.dataService.accessToken);
  }

  onIDUnselect() {
    this.templateClear();
    this.selectedPropTemp = '';
    this.isAffiliateUser = false;
    this.isPropertyUser = false;
  }

  onTemplateChange(event) {
    if (event.value != null) {
      this.dataService.tempName = event.value.templateName;
      this.templateClear();
      this.dataService.isSetFromTemplate = true;
      const editObject = event.value;
      localStorage.removeItem("editTempObject");
      localStorage.setItem("editTempObject", JSON.stringify(editObject));
      if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
        this.enableFields();
      }
      this.userControl.setValue(editObject.userType);
      this.selectedUserType = editObject.userType;
      if (this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === 'REGIONAL_AFFILIATE_ADMIN') {
        this.isAffiliateUser = true;
      } else {
        this.isAffiliateUser = false;
      }
      if (this.selectedUserType === "PROPERTY_STAFF" || this.selectedUserType === "PROPERTY_ADMIN") {
        this.isPropertyUser = true;
      } else {
        this.isPropertyUser = false;
      }
      this.affiliateOfficeControl.setValue(editObject.affiliateOffice);
      this.selectedAffiliate = editObject.affiliateOffice;
      this.propertyControl.setValue(editObject.propertyID);
      this.propControl.setValue(editObject.propertyID);
      this.selectedproperties = [];
      console.log(this.selectedproperties);
      this.selectedproperties = editObject.propertyID;
      this.selectedItems = editObject.selectedItems;
      for (let i = 0; i < this.templatePermissions.length; i++) {
        if (this.templatePermissions[i].value == editObject.templateRole) {
          this.templateRole = editObject.templateRole;
          this.templateRoleControl.setValue(editObject.templateRole);
        }
      }
      if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
        setTimeout(() => {
          this.disableFields();
        })
      }
      setTimeout(() => {
        this.getFocus();
      })
    }
  }

  getFocus() {
    if (this.isAffiliateUser) {
      document.getElementById('emailbox').focus();
    }
    if (this.isPropertyUser) {
      let myTextBox = document.getElementById('firstNamebox');
      myTextBox.focus();
    }
  }

  SetFromTemplate() {
    this.ediObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.ediObj);
    this.selectedID[0] = editObject.ID;
    this.dropDownListTemplate = [];
    this.dropDownListTemplate.push({ value: editObject, viewValue: editObject.templateName });
    this.propertyTempControl.setValue(editObject);
    this.userControl.setValue(editObject.userType);
    this.selectedUserType = editObject.userType;
    if (this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === 'REGIONAL_AFFILIATE_ADMIN') {
      this.isAffiliateUser = true;
    } else {
      this.isAffiliateUser = false;
    }
    if (this.selectedUserType === "PROPERTY_STAFF" || this.selectedUserType === "PROPERTY_ADMIN") {
      this.isPropertyUser = true;
    } else {
      this.isPropertyUser = false;
    }
    this.affiliateOfficeControl.setValue(editObject.affiliateOffice);
    this.selectedAffiliate = editObject.affiliateOffice;
    this.propertyControl.setValue(editObject.propertyID);
    this.propControl.setValue(editObject.propertyID);
    this.selectedproperties = [];
    this.selectedproperties = editObject.propertyID;
    this.selectedItems = editObject.selectedItems;
    this.templateRole = editObject.templateRole;
    this.templateRoleControl.setValue(editObject.templateRole);
  }


  Clear() {
    this.userId = ""
    if (this.isMultipleUsers) {
      this.selectedUserType = ""
      this.userControl.reset();
    }
    this.firstName = ''
    this.lastName = ''
    this.initials = ''
    this.email = ''
    this.activeDate = null
    this.inActiveDate = null
    if (!this.isSinglePropUser) {
      this.propControl.reset();
    }
    this.propertyControl.reset();
    this.firstNameControl.reset();
    this.lastNameControl.reset();
    this.initialsControl.reset();
    this.activeDateControl.setValue(new Date())
    this.activeDate = new Date();
    this.inActiveDateControl.reset();
    this.form.get('emailAddressControl').reset();
    this.affiliateOfficeControl.reset();
    this.userGroupsControl.reset();
    this.inactivePropControl.setErrors({ inactiveProp: false })
    this.invalidPropControl.setErrors({ invalidProp: false })
    this.dataService.isSetFromTemplate = false;
    this.vendorcdControl.reset();
    this.tempIDtxtboxControl.reset();
    this.templateRoleControl.reset();
    if (!this.isSinglePropUser) {
      this.templateIDControl.reset();
    }
    this.propertyTempControl.reset();
  }

  templateClear() {
    this.userId = ""
    if (this.isMultipleUsers) {
      this.selectedUserType = ""
      this.userControl.reset();
    }
    this.firstName = ''
    this.lastName = ''
    this.initials = ''
    this.email = ''
    this.activeDate = null
    this.inActiveDate = null
    this.propControl.reset();
    this.propertyControl.reset();
    this.firstNameControl.reset();
    this.lastNameControl.reset();
    this.initialsControl.reset();
    this.activeDateControl.setValue(new Date())
    this.activeDate = new Date();
    this.inActiveDateControl.reset();
    this.form.get('emailAddressControl').reset();
    this.affiliateOfficeControl.reset();
    this.userGroupsControl.reset();
    this.inactivePropControl.setErrors({ inactiveProp: false })
    this.invalidPropControl.setErrors({ invalidProp: false })
    this.dataService.isSetFromTemplate = false;
    this.vendorcdControl.reset();
    this.templateRoleControl.reset();
  }

  addEvent(type, event: MatDatepickerInputEvent<Date>) {
    type = moment(event.value);
    this.selDate = type.format('DD');
    this.selDay = type.format('dddd');
    this.selMonth = type.format('MMMM');
    this.selYear = type.format('YYYY');
  }

  async validateMandetoryFields() {
    let hasErrors = false;

    if (this.userControl.errors) {
      this.userControl.markAsTouched();
      hasErrors = true;
    }
    if (this.selectedUserType == 'PROPERTY_STAFF' || this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'VENDOR_STAFF' || this.selectedUserType == 'VENDOR_ADMIN' || this.selectedUserType == 'AFFILIATE_STAFF' || this.selectedUserType == 'AFFILIATE_ADMIN' || this.selectedUserType == 'REGIONAL_AFFILIATE_ADMIN') {
      if (this.form.get('emailAddressControl').errors) {
        this.form.get('emailAddressControl').markAsTouched();
        hasErrors = true;
      }
    }

    if (this.loggedInUser != 'EMPLOYEE' && this.isPropertyUser === true) {
      if (this.propControl.errors) {
        this.propControl.markAsTouched();
        hasErrors = true;
      }
    }

    if (this.loggedInUser == 'EMPLOYEE' && this.isPropertyUser === true) {
      if (this.propertyControl.errors) {
        this.propertyControl.markAsTouched();
        hasErrors = true;
      }
    }

    if (this.isAffiliateUser) {
      if (this.affiliateOfficeControl.errors) {
        this.affiliateOfficeControl.markAsTouched();
        hasErrors = true;
      }
    }
    if (this.firstNameControl.errors) {
      this.firstNameControl.markAsTouched();
      hasErrors = true;
    }
    if (this.lastNameControl.errors) {
      this.lastNameControl.markAsTouched();
      hasErrors = true;
    }
    if (this.initialsControl.errors) {
      this.initialsControl.markAsTouched();
      hasErrors = true;
    }

    if (this.activeDateControl.errors) {
      this.activeDateControl.markAsTouched();
      hasErrors = true;
    }

    if (this.inActiveDateControl.errors) {
      this.inActiveDateControl.markAsTouched();
      hasErrors = true;
    }

    if (this.selectedUserType == 'VENDOR_ADMIN' || (this.selectedUserType == 'VENDOR_STAFF' && !this.isVendorUser)) {
      if (this.vendorcdControl.errors) {
        this.vendorcdControl.markAsTouched();
        hasErrors = true;
      }
    }

    if (this.userGroupsControl.errors) {
      this.userGroupsControl.markAsTouched();
      hasErrors = true;
    }

    if ((this.selectedPropTemp == null || this.selectedPropTemp == 'undefined') && this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
      this.propertyTempControl.markAsTouched();
      hasErrors = true;
    }

    if (this.selectedUserType == 'PROPERTY_STAFF' || this.selectedUserType == 'AFFILIATE_STAFF') {
      if (this.templateRoleControl.errors) {
        this.templateRoleControl.markAsTouched();
        hasErrors = true;
      }
    }

    if (hasErrors == false && this.email != "" && (this.selectedUserType == 'VENDOR_STAFF' || this.selectedUserType == 'VENDOR_ADMIN' || this.selectedUserType == 'AFFILIATE_ADMIN' || this.selectedUserType == 'AFFILIATE_STAFF' || this.selectedUserType == 'REGIONAL_AFFILIATE_ADMIN') && !this.isPDRuser && !this.isaffiliatePDR) {
      let isExist: boolean = await this.isEmailAlreadyExist(this.email, this.accessToken);
      if (isExist) {
        this.form.get('emailAddressControl').setErrors({ duplicate: true });
        this.form.get('emailAddressControl').markAsTouched();
        hasErrors = true;
      }
    }

    this.invalidPropControl.reset();
    this.inactivePropControl.reset();

    return hasErrors;
  }

  async submit() {
    if (this.isButtonClicked == "N") {
      localStorage.removeItem("isButtonClicked");
      if (await this.validateMandetoryFields()) {
        return;
      }
      this.user.profile = this.profile;
      let createUser: User = new User();
      let profile: Profile = new Profile();
      createUser.profile = profile;
      profile.employeeTypeIDM = this.selectedUserType;
      this.dataService.createUserType = this.selectedUserType;
      profile.userManagement = true;
      profile.firstName = this.firstName;
      profile.lastName = this.lastName;
      if (this.initials != null)
        profile.initials = this.initials.split(",");
      profile.beginDate = formatDate(this.activeDate, this.dateTimeFormat, this.locale);
      if (this.inActiveDateControl.value) {
        profile.endDate = formatDate(this.inActiveDateControl.value, this.dateTimeFormat, this.locale);
      }
      profile.profileType = "HOTEL";
      profile.votingMember = false;
      profile.uid = this.generateUserId(6);
      if (this.isPropertyUser || this.isVendorUser) {
        if (this.selectedproperties && this.loggedInUser == 'EMPLOYEE') {
          profile.propID = this.selectedproperties.toString().split(',');
        } else {
          profile.propID = this.selectedproperties;
        }
      }

      this.usergroups.selectedItems = this.selectedItems;
      this.usergroups.selectedUserType = this.selectedUserType;
      let userGroupdata: UserGroups = await this.usergroups.addGroups(this.selectedAffiliate, profile.propID, this.accessToken);
      if (userGroupdata.hasError)
        return;
      if (userGroupdata.invalidPropMessage || userGroupdata.inactivePropMessage) {
        if (userGroupdata.invalidPropMessage) {
          this.invalidPropMessage = userGroupdata.invalidPropMessage;
          this.invalidPropControl.setErrors({ invalidProp: true })
          this.invalidPropControl.markAsTouched();
        }
        if (userGroupdata.inactivePropMessage) {
          this.inactivePropMessage = userGroupdata.inactivePropMessage;
          this.inactivePropControl.setErrors({ inactiveProp: true })
          this.inactivePropControl.markAsTouched();
        }
        return;
      }

      profile.groupsAssigned = Array.from(userGroupdata.groups);
      if (this.isAffiliateUser) {
        // Enter when user type is any Affiliate user i.e, employee and Affiliate users can enter
        let isaffPDR: boolean = true;
        if (this.loggedInUser == 'EMPLOYEE') {
          // If login user is employee get PDR indicator from affiliatePdrMap
          isaffPDR = this.affiliatePdrMap.get(this.selectedAffiliate);
        }
        else {
          // If login user is other than employee check if PDR user or not
          isaffPDR = this.isPDRuser ? true : false;
        }
        // If PDR user then login will be userid+@bwhhotel.com otherwise email
        profile.login = isaffPDR ? profile.uid + "@bwhhotel.com" : this.email;
        profile.affiliateOffice = this.selectedAffiliate;
      }
      else if (this.isPropertyUser) {
        profile.login = profile.uid + "@bwhhotel.com";
      }
      else if (this.isVendorUser) {
        profile.login = this.email;
        profile.vendorCode = this.selectedVendor;
      }

      if (this.isPropertyUser && !this.email) {
        profile.email = profile.uid + "@bwhhotel.com";
      } else {
        profile.email = this.email;
      }
      profile.doNotDelete = false;
      createUser.groupIds = [];
      if (!this.isVendorUser) {
        if (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'AFFILIATE_ADMIN') {
          createUser.groupIds.push(environment.user_roles.admin_role);
          this.templateRole = environment.user_roles.admin_role;
        } else {
          createUser.groupIds.push(this.templateRole);
        }
      } else {
        if (this.selectedUserType == 'VENDOR_ADMIN') {
          this.templateRole = environment.user_roles.admin_role;
        }
        else {
          this.templateRole = environment.user_roles.no_user_mgnt
        }
        createUser.groupIds.push(this.templateRole);
      }
      for (var i = 0; i < createUser.groupIds.length; i++) {
        if (this.templateRole == environment.user_roles.admin_role) {
          profile.idm_role = 'Admin';
        } else if (this.templateRole == environment.user_roles.power_role) {
          profile.idm_role = 'PowerUser';
        } else if (this.templateRole == environment.user_roles.restricted_role) {
          profile.idm_role = 'Restricted';
        } else if (this.templateRole == environment.user_roles.no_user_mgnt) {
          profile.idm_role = 'None';
        }
      }
      this.dataService.createUser = createUser;
      if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
        this.memberweb();
        this.updateMemberwebPrivilages();
      } else {
        let message = await this.createUser();
        if (message == 'success') {
          if (this.dataService.createUser.profile.email == null || this.dataService.createUser.profile.email == '' || this.dataService.createUser.profile.email.includes('@bwhhotel.com')) {
            setTimeout(() => this.temporaryPassword(), 5000);
          }
          else {
            let successMessage1 = "User account successfully created.";
            let appendValue = this.dataService.createUser.profile.login.includes('@bwhhotel.com') ? this.dataService.createUser.profile.login.replace('@bwhhotel.com', '') : this.dataService.createUser.profile.login;
            let successMessage2 = 'UserId: ' + appendValue + "\n" + " You will receive a confirmation via email with your password reset link";
            if (this.isVendorUser) {
              localStorage.setItem("isRequestFromEditScreen", "N");
            } else if (!this.isVendorUser) {
              localStorage.setItem("isRequestFromCreateScreen", "Y");
            }
            this.openDialog(successMessage2, successMessage1)
          }
        }
      }
    } else {
      this.router.navigate(['pages/app-access']);
    }

  }

  public generateUserId(length) {
    var userId = '';
    var characters = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ987654321';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      userId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return userId;
  }

  validateInActiveDate(event: any) {
    this.inActiveDateMin = event.target.value;
  }

  async isEmailAlreadyExist(loginId: any, accessToken: string) {
    let submitted_by = this.dataService.userclaims.userId
    await this.dataService.getCurrentUserDeatils(loginId, accessToken, submitted_by).then(data => {
      this.isEmailExist = true;
    }, (error) => {
      this.isEmailExist = false;
    });
    return this.isEmailExist;
  }

  onAffSelect() {
    this.isaffiliatePDR = this.affiliatePdrMap.get(this.selectedAffiliate);
  }

  changeToCreateTemplate() {
    this.isCreateTemp = true;
  }

  changeToManageTemplate() {
    this.isManageTemp = true;
  }

  changeTabs(event: number) {
    if (this.tabIndex == 1) {
      let isReturnFromMembTemp = localStorage.getItem('isReturnFromCreateMembTemp');
      let isSwitchTabs = localStorage.getItem('isSwitchTabs');
      if (this.countChangeTabs > 0) {
        setTimeout(() => {
          this.dataService.getInvokeEvent(this.globalConstants.idm);
        })
      } if (isReturnFromMembTemp == 'Y' && this.countChangeTabs == 0) {
        setTimeout(() => {
          this.dataService.getInvokeEvent(this.dataService.user.templateProfile.templateName);
        })
      }
    }
    if (this.tabIndex == 0) {
      if (this.dispScreen != null || this.dispScreen != undefined) {
        setTimeout(() => {
          this.dataService.getInvokeEvent(this.dispScreen);
        })

      }
    }

    if (this.dataService.isSetFromTemplate == true) {
      this.Clear();
      this.isAffiliateUser = false;
      this.isPropertyUser = false;
      this.tabIndex = event;
      this.SetFromTemplate();
    }
    this.countChangeTabs = this.countChangeTabs + 1;

  }

  async createUser() {
    let msg = '';
    let createUpdateRequest: CreateUpdateRequest = new CreateUpdateRequest();
    createUpdateRequest.user = this.dataService.createUser;
    createUpdateRequest.submitted_by = this.dataService.userclaims.userId;
    createUpdateRequest.bearer_token = this.dataService.accessToken;
    const body = { "Detail": JSON.stringify(createUpdateRequest), "Source": "createUserEvent" }
    await this.dataService.postUserDetails(body, this.accessToken).then((data: String) => {
      if (this.isVendorUser) {
        this.updateMemberprivilagesForVendor(this.dataService.createUser.profile.uid);
      }
      msg = 'success';
    }, (error) => {
      let failMeassage = ' failed to process request. Please try again later !';
      localStorage.setItem("isRequestFromEditScreen", "N");
      this.openDialog(failMeassage, "Warning")
      msg = 'failure';
    });
    return msg;
  }

  updateMemberprivilagesForVendor(userId) {
    let vendorId: string = environment.vendor_menus;
    setTimeout(() => this.dataService.getMemberOnePageUser(vendorId, this.dataService.accessToken).subscribe(
      (response) => {
        this.menuitems = response;
        this.menuitems["updated-by"] = this.dataService.userclaims.userId;
        this.menuitems["bearer_token"] = this.accessToken;
        this.menuitems.menus = JSON.parse(JSON.stringify(this.menuitems.menus).replace(/null,/g, '').replace(/,null/g, ''));
        setTimeout(() => this.dataService.postMemberMenusAccess(userId, JSON.stringify(this.menuitems), this.dataService.accessToken).subscribe(
          (response) => {
          },
          (error) => {
            let failMeassage = 'Menu Access Failed!';
            localStorage.setItem("isRequestFromEditScreen", "N");
            this.openDialog(failMeassage, "Warning")
            console.log(error)
          }
        ), 1000);
      },
      (error) => {
        console.log(error);
        this.globalErrorHandlerService.handleError(error)
      }
    ), 1000);
  }

  openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  onPropertyChange(event) {
    console.log('selectedproperties', this.selectedproperties);
    console.log('propControl', this.propControl);
  }

  memberweb() {
    this.dataService.getMemberMenus(this.dataService.accessToken).subscribe(
      (response) => {
        console.log(response);
        this.headings = response
        this.memberwebMenuItems = JSON.parse(JSON.stringify(response));
        this.getUserSpecificMenus();
      },
      (error) => {
        console.log(error);
      }
    )
  }

  getUserSpecificMenus() {
    this.ediObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.ediObj);
    console.log('editobject', editObject);
    this.tempmenuitems.menus = editObject.menuitems.menus;
    for (let i = 0; i < this.memberwebMenuItems.length; i++) {
      for (let m = 0; m < this.tempmenuitems.menus.length; m++) {
        if (this.tempmenuitems.menus[m] !== null && this.tempmenuitems.menus[m]['menu-id'] == this.memberwebMenuItems[i]["menu-id"]) {

          let k = 0;
          for (let j = 0; j < this.memberwebMenuItems[i]["sub-menu-list"].length; j++) {
            if (this.tempmenuitems.menus[m]['sub-menu-id-list'].includes(this.memberwebMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
              this.memberwebMenuItems[i]["sub-menu-list"][j].checked = true;
              k++;
              if (k == this.memberwebMenuItems[i]["sub-menu-list"].length) {
                this.subMenuAllchecked(i);

              }
            }
          }
        }
      }
      this.list_change(this.memberwebMenuItems[i].name, i);
    }
  }

  subMenuAllunChecked(val: int) {
    this.master_checked[val] = false;
  }

  subMenuAllchecked(val: int) {
    this.master_checked[val] = true;
  }

  list_change(menu: any, order: any) {
    let value: SubMenuList;
    let user_id = this.dataService.userclaims.userId;
    let allChange: boolean = true;
    let allNotChange: boolean = true;
    for (value of this.memberwebMenuItems[order]['sub-menu-list']) {
      if (value != null && value.checked) {
        allNotChange = false;
        if (this.menuitems.menus[order] == null) {
          this.menuitems.menus[order] = new memberwebMenus();
          this.menuitems.menus[order]['menu-id'] = this.memberwebMenuItems[order]['menu-id'];
          this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
        }
        else {
          if (!this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
          }
        }
        this.menuitems['updated-by'] = user_id;
      }
      else if (!value.checked) {
        allChange = false;
        if (this.menuitems.menus[order] != null) {
          if (this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            const index: number = this.menuitems.menus[order]['sub-menu-id-list'].indexOf(value['sub-menu-id']);
            this.menuitems.menus[order]['sub-menu-id-list'].splice(index, 1);
          }
        }
      }
    }
    if (allChange) {
      this.subMenuAllchecked(order);
    }
    else {
      this.subMenuAllunChecked(order);
    }
    if (allNotChange) {
      this.menuitems.menus[order] = null;
    }
  }

  async updateMemberwebPrivilages() {
    let message = await this.createUserRestricted();
    if (message == 'success') {
      this.dataService.isSetFromTemplate = false;
      localStorage.removeItem("editTempObject");
      if (this.dataService.canAccessMemberwebTab) {
        this.menuitems["updated-by"] = this.dataService.userclaims.userId;
        this.menuitems["bearer_token"] = this.accessToken;
        this.menuitems.menus = JSON.parse(JSON.stringify(this.menuitems.menus).replace(/null,/g, '').replace(/,null/g, ''));
        setTimeout(() => this.dataService.postMemberMenusAccess(this.dataService.createUser.profile.uid, JSON.stringify(this.menuitems), this.accessToken).subscribe(
          (response) => {

          },
          (error) => {
            let failMeassage = 'Menu Access Failed!';
            localStorage.setItem("isRequestFromEditScreen", "N");
            this.openDialog(failMeassage, "Warning")
            console.log(error)
          }
        ), 1000);
      }
      if (this.dataService.createUser.profile.email || this.dataService.createUser.profile.email.includes('@bwhhotel.com')) {
        setTimeout(() => this.temporaryPassword(), 5000);
      }
      else {
        let successMessage1 = "User account successfully created.";
        let appendValue = this.dataService.createUser.profile.login.includes('@bwhhotel.com') ? this.dataService.createUser.profile.login.replace('@bwhhotel.com', '') : this.dataService.createUser.profile.login;
        let successMessage2 = 'UserId: ' + appendValue + "\n" + " You will receive a confirmation via email with your password reset link";
        if (this.isVendorUser) {
          localStorage.setItem("isRequestFromEditScreen", "N");
          localStorage.setItem("isRequestFromCreateScreen", "N");
        } else {
          localStorage.setItem("isRequestFromCreateScreen", "Y");
        }
        this.openDialog(successMessage2, successMessage1)
      }

    }
  }

  temporaryPassword() {
    const payload = {
      "userId": this.dataService.createUser.profile.login,
      "isEmail": false,
      "submitted_by": this.dataService.userclaims.userId,
      "bearer_token": this.accessToken
    }
    this.dataService.postResetUserPasswordRequest(payload, this.accessToken).subscribe(
      (response) => {
        let successMessage = 'The User Id is: ' + this.dataService.createUser.profile.uid + "\n" + 'and Temporary Password is : ' + response.description.tempPassword;
        localStorage.setItem("isRequestFromCreateScreen", "Y");
        this.openDialog(successMessage, "User account has been created.")
      },
      (error) => {
        console.log(error);
        let failureMessage = 'The User Id is: ' + this.dataService.createUser.profile.uid + "\n" + 'Temporary passoword creation is failed.';
        localStorage.setItem("isRequestFromCreateScreen", "Y");
        this.openDialog(failureMessage, "Warning")
      }
    )
  }

  async createUserRestricted() {
    let msg = '';
    let createUpdateRequest: CreateUpdateRequest = new CreateUpdateRequest();
    createUpdateRequest.user = this.dataService.createUser;
    createUpdateRequest.submitted_by = this.dataService.userclaims.userId;
    createUpdateRequest.bearer_token = this.accessToken;
    const body = { "Detail": JSON.stringify(createUpdateRequest), "Source": "createUserEvent" }
    await this.dataService.postUserDetails(body, this.accessToken).then((data: String) => {
      msg = 'success';
    }, (error) => {
      let failMeassage = ' failed to process request. Please try again later !';
      localStorage.setItem("isRequestFromEditScreen", "N");
      this.openDialog(failMeassage, "Warning")
      console.log("*** error" + error);
      msg = 'failure';
    });
    return msg;
  }

}