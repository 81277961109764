<mat-tab-group #matTabGroup (selectedTabChange)="tabClick($event)" selectedIndex="1">
    <mat-tab disabled>
        <ng-template mat-tab-label>
            <button mat-raised-button class="save-button" (click)="backToSearch()"><i
                    class="back-button material-icons">arrow_back</i>Back</button>
        </ng-template>
    </mat-tab>
    <mat-tab label="Update User">
        <div class="content">
            <div class="left-fields">
                <div *ngIf="(!isVendorUser && (loggedInUserType != 'EMPLOYEE'))">
                    <h1 class="top-italic">{{globalConstants.search_frm_template}}</h1>
                    <p>Target Template Group</p>
                    <div class="user-groups">
                        <ng-multiselect-dropdown [placeholder]="'click here to select template ID'"
                            [settings]="dropdownSettingsID" [(data)]="dropDownListProperties"
                            [(ngModel)]="selectedID" (onSelect)="onIDChange($event)" (onDeSelect)="onIDUnselect()"
                            [formControl]="templateIDControl">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
                <div *ngIf="(!isVendorUser && (loggedInUserType == 'EMPLOYEE'))">
                    <h1 class="top-italic">{{globalConstants.search_frm_template}}</h1>
                    <p>Target Template Group</p>
                    <div class="target-grp">
                        <textarea [(ngModel)]="selectedID" class="txtareaTemplate"
                            [formControl]="tempIDtxtboxControl"></textarea>
                        <button class="save-button fetch-more" (click)="onIDChange($event)">Fetch Templates</button>
                        <p *ngIf="tempIDtxtboxControl.hasError('pattern')"
                            class="prop-temp-error">
                            {{globalConstants.temp_err1}}</p>
                    </div>
                    <div class="tempHint">
                        <mat-hint>{{globalConstants.temp_hint1}}</mat-hint>
                    </div>
                </div>
                <br>
                <mat-form-field class="mat-field-col-2" *ngIf="!isVendorUser">
                    <mat-select placeholder="Select a template to choose from" [formControl]="propertyTempControl"
                        (selectionChange)="onTemplateChange($event)" [(value)]="selectedPropTemp">
                        <mat-option *ngFor="let template of dropDownListTemplate" [value]="template.value">
                            {{template.viewValue}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <h1 class="top-italic">{{globalConstants.user_info}}</h1>
                <mat-form-field class="mat-field-col-2">
                    <mat-label>{{globalConstants.userType}}</mat-label>
                    <mat-select [formControl]="userControl" disableOptionCentering [(value)]="selectedUserType"
                        (selectionChange)="onCategoryChange($event)" placeholder="Select">
                        <mat-option *ngFor="let usertype of usertypes" [value]="usertype.value">
                            {{ usertype.viewValue }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="userControl.hasError('required')">{{globalConstants.userType_err}}
                    </mat-error>
                </mat-form-field>
                <br>
                <mat-form-field class="mat-field-col-2 disabled" *ngIf="selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'PROPERTY_ADMIN'">
                    <input matInput [value]="userIdcontrol" placeholder="User Id (read only)">
                </mat-form-field>
                <br />
                <div
                    *ngIf="(loggedInUserType == 'EMPLOYEE' && (selectedUserType == 'VENDOR_ADMIN' || selectedUserType == 'VENDOR_STAFF'))">
                    <mat-form-field class="mat-field-col-2">
                        <mat-label>{{globalConstants.vendor_cd}}</mat-label>
                        <mat-select [formControl]="vendorControl" disableOptionCentering [(ngModel)]="selectedVendor"
                        placeholder="Select">
                            <mat-option *ngFor="let vendor of vendors | keyvalue"
                                [value]="vendor.key">
                                {{vendor.key}} - {{vendor.value}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="vendorControl.hasError('required')">
                            {{globalConstants.vendor_err}}</mat-error>
                    </mat-form-field>
                </div>
                <mat-form-field class="mat-field-col-2" *ngIf="selectedUserType == 'PROPERTY_STAFF'">
                    <input matInput [formControl]="email" placeholder="Email"
                        [style.visibility]="propStaffFlag ? 'hidden' : 'visible'">
                    <mat-error *ngIf="email.hasError('pattern')">{{globalConstants.email_err6}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mat-field-col-2"
                    *ngIf="selectedUserType == 'PROPERTY_ADMIN' || selectedUserType == 'VENDOR_STAFF' || selectedUserType == 'VENDOR_ADMIN' || selectedUserType == 'AFFILIATE_ADMIN' || selectedUserType == 'AFFILIATE_STAFF' || selectedUserType == 'REGIONAL_AFFILIATE_ADMIN'">
                    <input required matInput [formControl]="email" placeholder="Email"
                        (blur)="emailValidator(email.value)">
                    <mat-error *ngIf="email.hasError('required')">{{globalConstants.email_err1}}
                    </mat-error>
                    <mat-error *ngIf="email.hasError('pattern')">{{globalConstants.email_err6}}
                    </mat-error>
                    <mat-error *ngIf="(isAffiliateUser || isVendorUser) && email.hasError('invalid')">
                        <div class="error-tooltip">
                            Email address is not valid.<mat-icon matTooltip="{{globalConstants.email_err3}}" matTooltipPosition="above" matTooltipClass="tooltip-error-info">info</mat-icon>
                        </div>
                    </mat-error>
                    <mat-error
                        *ngIf="(isAffiliateUser || isVendorUser) && !isPDRuser && email.hasError('duplicate')">
                        {{globalConstants.email_err4}}
                    </mat-error>
                    <mat-hint class="text-hint" *ngIf="showHint">
                        {{globalConstants.email_err5}}
                    </mat-hint>
                </mat-form-field>
                <br>
                <br />
                <div
                    *ngIf="selectedUserType == 'AFFILIATE_ADMIN' || selectedUserType == 'AFFILIATE_STAFF' || selectedUserType == 'REGIONAL_AFFILIATE_ADMIN'">
                    <mat-form-field class="mat-field-col-2">
                        <mat-label>{{globalConstants.affiliate_ofc}}</mat-label>
                        <mat-select [formControl]="affiliateOfficeControl" disableOptionCentering
                            [(ngModel)]="selectedAffiliate" placeholder="Select">
                            <mat-option *ngFor="let affiliateOffice of affiliateOffices"
                                [value]="affiliateOffice.value">
                                {{ affiliateOffice.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="affiliateOfficeControl.hasError('required')">
                            {{globalConstants.affiliate_ofc_err1}}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="(selectedUserType =='PROPERTY_STAFF' || selectedUserType =='AFFILIATE_STAFF')">
                <mat-form-field class="mat-field-col-2">        
                    <mat-label>User Role</mat-label>
                    <mat-select [formControl]="templateRoleControl" name="permission" disableOptionCentering [(ngModel)]="templateRole" (selectionChange)="onTemplatePermissionChange($event)" placeholder="Select a template permission to assign to the user">
                        <mat-option *ngFor="let templatePermission of templatePermissions" [value]="templatePermission.value" matTooltip={{templatePermission.tooltip}} matTooltipPosition="right">{{templatePermission.viewValue}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="templateRoleControl.hasError('required')">
                        Please choose a User Role</mat-error>
                </mat-form-field>
                </div>
                <div *ngIf="selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'AFFILIATE_STAFF'"><br>
                </div>
                <mat-form-field class="mat-field-col-2 disabled" *ngIf="showUserId">
                    <input matInput [value]="userIdcontrol" placeholder="User Id (read only)">
                </mat-form-field>
                <mat-form-field class="mat-field-col-2">
                    <input matInput required [(ngModel)]="firstname" placeholder="First Name"
                        [formControl]="firstNameControl" maxlength="50">
                    <mat-error *ngIf="firstNameControl.hasError('required')">{{globalConstants.first_name_err1}}
                    </mat-error>
                    <mat-error
                        *ngIf="(!firstNameControl.hasError('required')) && firstNameControl.hasError('pattern')">
                        {{globalConstants.first_last_name_err}}
                    </mat-error>
                </mat-form-field>
                <br />
                <div *ngIf="(!firstNameControl.hasError('required')) && firstNameControl.hasError('pattern')"
                    class="pad-bottom2"></div>
                <mat-form-field class="mat-field-col-2">
                    <input matInput required [(ngModel)]="lastname" placeholder="Last Name"
                        [formControl]="lastNameControl" maxlength="50">
                    <mat-error *ngIf="lastNameControl.hasError('required')">{{globalConstants.last_name_err1}}
                    </mat-error>
                    <mat-error
                        *ngIf="(!lastNameControl.hasError('required')) && lastNameControl.hasError('pattern')">
                        {{globalConstants.first_last_name_err}}
                    </mat-error>
                </mat-form-field>
                <br />
                <div *ngIf="(!lastNameControl.hasError('required')) && lastNameControl.hasError('pattern')"
                    class="pad-bottom2"></div>
                <mat-form-field class="mat-field-col-2">
                    <input required matInput maxlength="3" [formControl]="initials" placeholder="Initials">
                    <mat-error *ngIf="initials.hasError('required')">{{globalConstants.initials_err1}}</mat-error>
                    <mat-error *ngIf="initials.hasError('pattern')">{{globalConstants.initials_err2}}</mat-error>
                </mat-form-field>
                <br />
                <br />
                <div *ngIf="(selectedUserType == 'PROPERTY_STAFF'|| selectedUserType == 'PROPERTY_ADMIN') 
                    && (loggedInUserType != 'EMPLOYEE')" class="padding-div">
                    <p class="o-form-label">{{globalConstants.props}} *</p>
                    <mat-form-field class="mat-field-col-2">
                        <mat-select [formControl]="propControl" disableOptionCentering required
                            [(ngModel)]="selectedproperties" (selectionChange)="onPropertyChange($event)" multiple>
                            <mat-option *ngFor="let property of sortedDropDownList" [value]="property">
                                {{ property}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userControl.hasError('required')">{{globalConstants.userType_err}}
                        </mat-error>
                    </mat-form-field>
                    <p *ngIf="propControl.hasError('required') && propControl.touched" class="error-message">
                        {{globalConstants.props_err1}}
                    </p>
                    <p [innerText]="invalidPropertyMessage"
                        *ngIf="propControl.hasError('invalid') && propControl.touched" class="error-message">
                    </p>
                    <p *ngIf="invalidPropControl.hasError('invalidProp') && invalidPropControl.touched"
                        class="error-message">{{invalidPropMessage}}
                    </p>
                    <p *ngIf="inactivePropControl.hasError('inactiveProp') && inactivePropControl.touched"
                        class="error-message">{{inactivePropMessage}}
                    </p>
                </div>
                <div *ngIf="(loggedInUserType == 'EMPLOYEE') 
                    && (selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'PROPERTY_ADMIN')">
                    <span class="o-form-label properties-padding-label">{{globalConstants.props}} *</span>
                    <div class="textarea-padding">
                        <textarea [(ngModel)]="selectedproperties" [formControl]="propertyControl" class="txtarea"
                            (keyup)="onPropChange()"></textarea>
                    </div>
                    <p *ngIf="propertyControl.hasError('required') && propertyControl.touched"
                        class="error-message">{{globalConstants.props_err1}}
                    </p>
                    <p *ngIf="!propertyControl.hasError('required') && propertyControl.hasError('pattern') && propertyControl.touched"
                        class="error-message"> {{globalConstants.props_err2}}
                    </p>
                    <p [innerText]="invalidPropMessage" *ngIf="isPropInvalid" class="error-message">
                    </p>
                    <p [innerText]="inactivePropMessage" *ngIf="isPropInactive" class="error-message">
                    </p>
                    <p *ngIf="invalidPropControl.hasError('invalidProp') && invalidPropControl.touched"
                        class="error-message">{{invalidPropMessage}}
                    </p>
                    <p *ngIf="inactivePropControl.hasError('inactiveProp') && inactivePropControl.touched"
                        class="error-message">{{inactivePropMessage}}
                    </p>
                </div>
                <br />
            </div>

            <div class="right-fields">
                <h1 class="top-italic">{{globalConstants.user_status}} <span class="userStatus">: {{(userStatus ==
                        'PROVISIONED') ? ("ACTIVE (PENDING)") : (userStatus == 'SUSPENDED') ? ("DEACTIVATED - This
                        user's data cannot be updated!") : ((userStatus == 'DEPROVISIONED') ? ("DEPROVISIONED - This
                        user's data cannot be updated!") : (userStatus))}}</span></h1>
                <div>
                    <button *ngIf="isUserActive" mat-raised-button class="save-button"
                        (click)="deactivateUser()">Deactivate User</button>
                    <button [disabled]="isUserDeprovisioned" *ngIf="isUserInactive" mat-raised-button
                        class="save-button" (click)="reactivateUser()">Reactivate User</button>
                    <button *ngIf="isUserProvisioned" class="save-button" mat-raised-button
                        (click)="isRecovery ? recoveryPasswordReset() : resetPassword()">Reset Password</button>
                    <button *ngIf="isUserLocked" mat-raised-button class="save-button" (click)="unLockUser()">Unlock
                        User</button>
                    <button *ngIf="isUserActivePending" mat-raised-button class="save-button"
                        (click)="sendAccountActivationEmail()">Activate Account</button>
                </div>
                <div>
                    <p *ngIf="errorTF" class="reset-error">{{errorMessage}} </p>
                    <p *ngIf="errorTF" class="error">{{errorMsgUnlockUser}} </p>
                </div>
                <br>
                <mat-form-field class="mat-field-col-2" appearance="fill">
                    <mat-label>{{globalConstants.active_date}}</mat-label>
                    <input matInput [matDatepicker]="picker" [min]="todayDate"
                        (dateInput)="addEvent(activeDate, $event)" (dateChange)="addEvent(activeDate, $event)"
                        [(ngModel)]="activeDate" (dateChange)="validateInActiveDate($event)" (click)="picker.open()"
                        readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <br />
                <mat-form-field class="mat-field-col-2" appearance="fill">
                    <mat-label>{{globalConstants.inactive_date}}</mat-label>
                    <input matInput [matDatepicker]="picker1" (dateInput)="addEvent(inActiveDate, $event)"
                        (dateChange)="addEvent(inActiveDate, $event)" [formControl]="inActiveDate"
                        [min]="inActiveDateMin" (click)="picker1.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <mat-datepicker-toggle matSuffix *ngIf="inActiveDate.value" (click)="clearEndDate()">
                        <mat-icon matDatepickerToggleIcon>{{globalConstants.clear}}</mat-icon>
                    </mat-datepicker-toggle>
                    <mat-error>{{globalConstants.inactive_date_err1}}</mat-error>
                </mat-form-field>
                <br><br>
                <!-- Do not delete checkbox -->
                <mat-checkbox *ngIf="!isVendorUser" [(ngModel)]="isDelete" class="donotdelete-checkbox"
                    matTooltip={{globalConstants.status_donotdelete}} matTooltipPosition="below">
                    {{globalConstants.doNotDelete}}</mat-checkbox>

                <!-- Network Restriction checkbox -->
                <mat-checkbox  *ngIf="globalConstants.property_users.includes(selectedUserType)" [(ngModel)]="isNetworkRestricted" class="network-retsriction-checkbox"
                    matTooltip={{globalConstants.network_restriction_checkbox_tooltip}} matTooltipPosition="below">
                    {{globalConstants.network_restriction_checkbox_label}}</mat-checkbox>

                <div *ngIf="!isVendorUser">
                    <h1 class="top-italic">{{globalConstants.groups_info}}</h1>
                    <span class="o-form-label">{{globalConstants.assigned_groups}}</span>
                    <div class="textarea-padding">
                        <textarea [(ngModel)]="currentUserGroups" class="txtarea userGroups-textarea"
                            readonly></textarea>
                    </div>
                </div>
                <div *ngIf="loggedInUserType == 'EMPLOYEE' && !isVendorUser">
                    <div class="o-form-label">
                        <label for="UserType">{{globalConstants.user_groups}} &nbsp;</label>
                    </div>
                    <div class="userGroups-div">
                        <ng-multiselect-dropdown [placeholder]="'click here to select user groups'"
                            [settings]="dropdownSettings" [data]="dropdownList" [formControl]="userGroupsControl" [(ngModel)]="selectedItems">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="pad-top25">
                <div [ngClass]="{'two_col_right': loggedInUserType == 'EMPLOYEE','two_col_right_sr':loggedInUserType != 'EMPLOYEE'}">
                    <button class="save-button reset-button" mat-raised-button (click)="Reset()">Reset</button>
                    <button [disabled]="isUserInactive" class="save-button" mat-raised-button (click)="updateUserInfo()">Save</button>
                </div>
            </div>
        </div>
    </mat-tab>            
    <mat-tab *ngIf="canShowAppaccessTab" label="Application Access">
        <ng-container *ngIf="appAccessTabEnabled">
            <multi-app-menu-access [matTabGroup]="matTabGroup" [currentUser]="currentUser" [parentModule]="globalConstants.manage_user" [isUserInactive]="isUserInactive"></multi-app-menu-access>
        </ng-container>
    </mat-tab>
</mat-tab-group>