import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { AppMenuItem, MemberWebUserMenu, MultiAppAccessReqParams, MultiAppData, SelectedMenuData, UserAccessGroup } from 'src/app/model/multi-app-access.model';
import { GlobalConstants } from '../../global-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { switchMap, catchError } from 'rxjs/operators';
import { User } from 'src/app/model/user';

@Injectable({
  providedIn: 'root'
})
export class AppAccessDataService {

  selectedAppDataSubject$ = new Subject<MultiAppData>();
  appMenusDataSubject$ = new Subject<AppMenuItem[]>();
  menusSelectionSubject$ = new BehaviorSubject<SelectedMenuData[]>([]);
  clearDataSubject$ = new Subject<boolean>();

  selectedAppDataObs$ = this.selectedAppDataSubject$.asObservable();
  appMenusDataObs$ = this.appMenusDataSubject$.asObservable();
  clearDataObs$ = this.clearDataSubject$.asObservable();

  constructor(
    private globalConstants: GlobalConstants,
    private httpClient: HttpClient) {

  }

  public getMultiAppMenus(_reqParams: MultiAppAccessReqParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + _reqParams.token,
        'Submitted-By': _reqParams.submittedBy,
        'User-Type': _reqParams.userType,
        'User-Id': _reqParams.userId
      })
    };
    let reqUrl = `${environment.services.multi_app_url}applications/${_reqParams.appCode}`
    return this.httpClient.get<any>(reqUrl, httpOptions);
  }

  public getMultiappDetails(_reqParams: MultiAppAccessReqParams) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + _reqParams.token,
        'Submitted-By': _reqParams.submittedBy,
        'User-Type': _reqParams.userType,
        'User-Id': _reqParams.userId
      })
    };
    return this.httpClient.get<any>(environment.services.multi_app_url + 'applications', httpOptions);
  }

  clearAllSelectedMenuData(appMenuItems: AppMenuItem[]) {
    appMenuItems.forEach(menu => {
      if (this.globalConstants.input_menu_types.includes(menu.menuType)) {
        menu.selectedOption = menu.menuType === this.globalConstants.menu_type_radio ? this.globalConstants.empty_str : false;
      }
      if (menu.children && menu.children.length > 0) {
        this.clearAllSelectedMenuData(menu.children);
      }
    })
    this.defaultNoAccessOption(appMenuItems);
  }

  defaultNoAccessOption(menuItems: AppMenuItem[]) {
    menuItems.forEach(menu => {
      if (this.globalConstants.input_menu_types.includes(menu.menuType) && menu.name === this.globalConstants.no_access) {
        menu.selectedOption = menu.menuType === this.globalConstants.menu_type_radio ? menu.name : true;
      }
      if (menu.children && menu.children.length > 0) {
        this.defaultNoAccessOption(menu.children);
      }
    })
  }

  public getUserGroups(userId, accessToken, group_prefix) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.get<UserAccessGroup[]>(environment.services.userURL + '/' + userId + '?group_prefix=' + group_prefix, httpOptions);
  }

  public getMemberWebUserMenus(userId, accessToken) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      })
    };
    return this.httpClient.get<any>(environment.services.memberweb_menu_url + 'user/' + userId, httpOptions);
  }

  getUserAccessGroups(reqParams: MultiAppAccessReqParams) {
    if (reqParams.appName === this.globalConstants.app_member_web) {
      return this.getMemberWebUserMenus(reqParams.userId, reqParams.token).pipe(
        switchMap(memberWebResponse => {
          if (memberWebResponse) {
            const resultResponse: UserAccessGroup[] = this.convertToUserGroupsFormat(new MemberWebUserMenu(memberWebResponse));
            return of(resultResponse);
          }
          return of(null);
        }),
        catchError(error => {
          console.error('Error occurred:', error);
          return of(null);
        })
      );
    } else {
      return this.getUserGroups(reqParams.loginId, reqParams.token, reqParams.groupPrefix).pipe(
        switchMap(userGroupResponse => {
          if (userGroupResponse) {
            return of(userGroupResponse);
          }
          return of(null);
        }),
        catchError(error => {
          console.error('Error occurred:', error);
          return of(null);
        })
      );
    }
  }

  convertToUserGroupsFormat(data: MemberWebUserMenu): UserAccessGroup[] {
    const result: UserAccessGroup[] = [];
    data.menus.forEach(menu => {
        const menuId = menu.menuId;
        menu.subMenuId.forEach(subMenuId => {
            result.push({
                id: subMenuId,
                name: menuId
            });
        });
    });
    return result;
  }

  loginIdByUserType(user: User, isPdrUser: boolean) {
    if (this.globalConstants.non_property_users.includes(user.profile.employeeTypeIDM) && !isPdrUser) {
      return user.profile.email;
    } else {
      return user.profile.login;
    }
  }
}


