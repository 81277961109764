import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { IpAddressData, NetworkingData, NetworkRequestParam } from 'src/app/model/networking-model';
import { NetworkDataService } from './network-data.service';
import { OktaAuthService } from '@okta/okta-angular';
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { ToastrWrapperService } from 'src/app/toastr-wrapper.service';
import { GlobalConstants } from '../global-constants';
import { UserClaims } from 'src/app/model/user';
import { ipCidrValidator } from './custom-ip-address-validator';

@Component({
  selector: 'app-networking',
  templateUrl: './networking.component.html',
  styleUrls: ['./networking.component.css']
})
export class NetworkingComponent {
  loggedInUser: UserClaims;
  accessToken: string;
  displayedColumns: string[] = ['ip', 'comment', 'action'];
  ipAddressData: IpAddressData[] = []
  propNumber = new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(5), Validators.pattern('^[0-9]+$')]);
  ipAddress = new FormControl('', [Validators.required, ipCidrValidator()]);
  comment = new FormControl('');
  isContentEnabled: boolean = false;
  userPropIds: string[];

  constructor(
    private dataService: DataService,
    private networkService: NetworkDataService,
    private oktaAuth: OktaAuthService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private toastrService: ToastrWrapperService,
    public globalConstants: GlobalConstants) {
    
  }

  async ngOnInit() {
    this.dataService.getInvokeEvent("Manage Networking");
    this.loggedInUser = this.dataService.userclaims ? this.dataService.userclaims : JSON.parse(localStorage.getItem('userClaims'));
    console.log(this.loggedInUser)
    this.accessToken = await this.oktaAuth.getAccessToken();
  }

  searchIpAddress() {
    if (this.propNumber.invalid) {
      this.toastrService.showToast("Please enter property number to search.", "Error");
      return;
    }
    let reqParam: NetworkRequestParam = {
      "id": this.propNumber.value,
      "access_token": this.accessToken,
      "submitted_by": this.loggedInUser.userId
    }
    this.resetIpAddressForm();
    this.networkService.searchNetworkIp(reqParam).subscribe((response) => {
      if (response.httpStatus === 200) {
        let data = response.body;
        this.ipAddressData = data[0].ip_address;
        this.propNumber.setValue(data[0].id);
        this.isContentEnabled = true;
      } else if (response.httpStatus === 404) {
        this.ipAddressData = [];
        this.toastrService.showToast("No record(s) found with the given search input.", "Warning")
        this.isContentEnabled = true;
      } else if (response.httpStatus === 400) {
        this.toastrService.showToast(response.errorDesc, "Error")
      } else {
        this.toastrService.showToast("Failed to retrieve ip address. Please try again or contact Coporate Help Desk.", "Error");
      }
    },
      (error) => {
        console.error(error);
        this.globalErrorHandlerService.handleError(error);
      }
    );
  }

  addIpAddress() {
    if (this.ipAddress.invalid) {
      this.toastrService.showToast("Ip address is invalid.", "Error")
      return;
    }
    this.createNetworkIpData();
  }

  deleteIpAddress(rowData: IpAddressData) {
    let reqParam: NetworkRequestParam = {
      "id": this.propNumber.value,
      "access_token": this.accessToken,
      "submitted_by": this.loggedInUser.userId,
      "uid": rowData.uid
    }
    console.log("Delete request parameter : ",reqParam)
    this.networkService.deleteNetworkIp(reqParam).subscribe(
      (response) => {
        console.log("Response : ", response)
        if(response.httpStatus === 200) {
          this.searchIpAddress();
          this.toastrService.showToast("Ip address deleted successfully.", "Success")
        }
      },
      (error) => {
        console.error(error);
        this.toastrService.showToast("Failed to delete ip address. Please try again or contact Coporate Help Desk.", "Error")
      }
    );
  }

  createNetworkIpData() {
    const newIpAddressData: IpAddressData = {
      uid: this.dataService.generateUserId(6),
      ip: this.formatIpCidr(this.ipAddress.value),
      comment: this.comment.value
    };

    const networkingData: NetworkingData = {
      id: this.propNumber.value,
      ip_address: [newIpAddressData]
    };

    const reqParam: NetworkRequestParam = {
      "id": this.propNumber.value,
      "access_token": this.accessToken,
      "submitted_by": this.dataService.userclaims.userId,
    }

    this.networkService.saveNetworkIp(networkingData, reqParam).subscribe(
      (response) => {
        if (response.httpStatus === 200) {
          this.searchIpAddress();
          this.toastrService.showToast("Ip address saved successfully.", "Success")
        } else if (response.httpStatus === 400) {
          this.toastrService.showToast(response.errorDesc, "Error")
        } else {
          this.toastrService.showToast("Failed to save ip address. Please try again or contact Coporate Help Desk.", "Error");
        }
      },
      (error) => {
        console.error(error);
        this.toastrService.showToast("Failed to save ip address. Please try again or contact Coporate Help Desk.", "Error")
      }
    );
  }

  private formatIpCidr(value: string): string {
    if(value.includes("-")) {
      let ips = value.split("-");
      return `${ips[0].trim()}-${ips[1].trim()}`
    }
    if(!value.includes("/")){
      return `${value}/32`
    }
    return value
  }

  private resetIpAddressForm() {
    this.ipAddress.setValue('');
    this.ipAddress.markAsUntouched();
    this.comment.setValue('');
    this.comment.markAsUntouched();
  }

}
