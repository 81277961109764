import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { User } from 'src/app/model/user';
import { UserAuthService } from 'src/app/user-auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  isAuthenticated: boolean = false;
  user: User;
  userName: string;
  url:string;
  constructor(private myservice: UserAuthService, public dataService: DataService) { }
  ngOnInit() {
    this.isAuthenticated = this.myservice.isAuthenticated;
  }
  displayTutorial() {
    if ((this.dataService.userclaims.userRole == 'AFFILIATE_STAFF' || this.dataService.userclaims.userRole == 'PROPERTY_STAFF' || this.dataService.userclaims.userRole == 'VENDOR_STAFF') && !this.dataService.userclaims.userManagement) {
      window.open("https://rise.articulate.com/share/GYyu7_hGAeM4Lf5C2vaHAEwIY__k1g14#/");
    }
    else {
      window.open("https://rise.articulate.com/share/t0vED_sz4ek6rljKXY4-hLT-ktqXil8a#/");
    }
  }

  displayWhatsChanged(){
    window.open("https://vimeo.com/877975728/0febda0812?share=copy");
  }
}