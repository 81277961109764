import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/model/user';
import { DataService } from 'src/app/data.service';
import * as bcrypt from 'bcryptjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { access } from 'fs';
import { OktaAuthService } from '@okta/okta-angular';
import { type } from 'os';
import { PopupComponent } from '../popup/popup.component';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { empty } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from '../global-constants';
import { isNull } from 'util';

@Component({
  selector: 'app-question-answers',
  templateUrl: './question-answers.component.html',
  styleUrls: ['./question-answers.component.css']
})
export class QuestionAnswersComponent implements OnInit {
  options: FormGroup;
  hideRequiredControl = new FormControl(false);
  floatLabelControl = new FormControl('auto');
  userInfo;
  question1: string;
  question2: string;
  question3: string;
  answer1: string;
  answer2: string;
  answer3: string;
  errorTF: boolean;
  flags: Boolean[];
  tick1 = false;
  tick2 = false;
  tick3 = false;
  error;
  CAForm: FormGroup;
  accessToken: string;
  successMessage
  isreqFromEdit: boolean;
  counter=0;
  isWarning: boolean = false;
  isResendEmail: boolean;
  isTempPassword: boolean;


  constructor(public oktaAuth: OktaAuthService, private fb: FormBuilder, private dataService: DataService, private router: Router, private httpClient: HttpClient,
     public dialog: MatDialog, private toastr: ToastrService,public globalConstants:GlobalConstants) {
    this.options = fb.group({
      hideRequired: this.hideRequiredControl,
      floatLabel: this.floatLabelControl,
    });
    this.CAForm = this.fb.group({
      userId: '',
      userChallengeAnswer1: [''],
      userChallengeAnswer2: [''],
      userChallengeAnswer3: ['']
    });
    this.isResendEmail = this.router.getCurrentNavigation().extras.state.resendEmail;
  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    let editObj = JSON.parse(localStorage.getItem("editObject"));
    //let editObj = this.dataService.editServiceData;
    this.isreqFromEdit = localStorage.getItem("isRequestFromEditScreen") == 'Y';
    if(!this.isreqFromEdit){
      this.router.navigate(['pages/home']);
    }
    this.userInfo = editObj;
    if(!editObj.profile.challengeQuestion1) {
      this.toastr.warning("There are no challenging questions for this user","Warning",{
        timeOut: 5000,
        extendedTimeOut: 5000
      })
    }
    this.question1 = editObj.profile.challengeQuestion1;
    this.question2 = editObj.profile.challengeQuestion2;
    this.question3 = editObj.profile.challengeQuestion3;
    this.answer1 = editObj.profile.challengeAnswer1 ? editObj.profile.challengeAnswer1 : null;
    this.answer2 = editObj.profile.challengeAnswer2 ? editObj.profile.challengeAnswer2 : null;
    this.answer3 = editObj.profile.challengeAnswer3 ? editObj.profile.challengeAnswer3 : null;
    
    this.CAForm.controls.userChallengeAnswer1.valueChanges.subscribe(data => {
      this.singleCompare(data, this.answer1, 1)
    })
    this.CAForm.controls.userChallengeAnswer2.valueChanges.subscribe(data => {
      this.singleCompare(data, this.answer2, 2)
    })
    this.CAForm.controls.userChallengeAnswer3.valueChanges.subscribe(data => {
      this.singleCompare(data, this.answer3, 3)
    })

  }

  generatePassword() {
    this.errorTF = false;
    if (!this.errorTF) {
      const payload = {
        "userId": this.userInfo.profile.login,
        "isEmail": false,
        "submitted_by" : this.dataService.userclaims.userId,
        "bearer_token" : this.accessToken
      }
      if(this.isResendEmail) {
        if (this.userInfo.profile.email != null && this.userInfo.profile.email != '' && !this.userInfo.profile.email.includes('@bwhhotel.com')) {
          payload.isEmail = true;
        }
      }
      this.dataService.postResetUserPasswordRequest(payload, this.accessToken).subscribe(
        (response) => {
          if (Object.keys(response.description).length === 0) {
            this.successMessage = 'User Password Reset Successfully.'
          } else {
            this.successMessage = 'User Password Reset Successfully. Temporary Password is : ' + response.description.tempPassword;
          }
          localStorage.setItem("isRequestFromEditScreen", "Y");
          this.openDialog(this.successMessage)
        },
        (error) => {
          this.errorTF = true;
          //this.error = JSON.parse(error.error.errorMessage.replaceAll("'", "\"")).response.errorSummary;
          this.error = 'Password reset failed.';
        }
      )
    }
  }

  validateAnswer() {
    this.counter = 0;
    Promise.all([this.passwordCompare(this.answer1,this.CAForm.get('userChallengeAnswer1').value), this.passwordCompare(this.answer2,this.CAForm.get('userChallengeAnswer2').value), this.passwordCompare(this.answer3,this.CAForm.get('userChallengeAnswer3').value)])
    .then((res) => {
        if (this.counter < 2) {
          this.isWarning = true;
          this.openConfirmationPopup("You are resetting an account without verifying the users identity via their security answers. By doing so you are confirming you have validated the User by another approved means.","Warning");
          this.dataService.getInvokeEvent(this.globalConstants.idm);
        }
        else{
          this.isWarning = false;
          this.openConfirmationPopup("Are you sure you want to do this?","Alert!");
          this.dataService.getInvokeEvent(this.globalConstants.idm);
        }
    });
  }

  compare(userEnteredAnswer: string, oktaStoredAnswer: string, callback: (error: string | null, match: boolean | null) => void) {
    bcrypt.compare(userEnteredAnswer.replace(/\s/g, "").toLowerCase(), oktaStoredAnswer, (err: Error, match: boolean) => {
      if (match) {
        callback(null, true);
      } else {
        callback('Invalid password match', null);
      }
    });
  }

  openDialog(description): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openConfirmationPopup(description,title): void {
     let dialogRef;
     if(this.isWarning) {
     dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '250px',
      panelClass:'custom-warning-container',
      data: { description: description, title: title },
      disableClose: true
    });
  } else{
    dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '200px',
      data: { description: description, title: title },
      disableClose: true
    });
  }

    dialogRef.afterClosed().subscribe(result => {
      if(result == true){
        this.generatePassword();
      }
    });
  }

  singleCompare(userEnteredAnswer, oktaStoredAnswer, index) {
    this.compare(userEnteredAnswer, oktaStoredAnswer, (error: string | null, match: boolean | null) => {
      if (!error) {
        switch (index) {
          case 1:
            this.tick1 = true;
            break;
          case 2:
            this.tick2 = true;
            break;
          case 3:
            this.tick3 = true;
            break;
        }
      } else {
        switch (index) {
          case 1:
            this.tick1 = false;
            break;
          case 2:
            this.tick2 = false;
            break;
          case 3:
            this.tick3 = false;
            break;
        }
      }
    });
  }

  Cancel() {
    this.router.navigate(['pages/edit-user']);
  }
  passwordCompare(oktaStoredAnswer: string, userEnteredAnswer: string): Promise<boolean>{
    return new Promise((resolve) => {
      if (this.counter < 2) {
      this.compare(userEnteredAnswer, oktaStoredAnswer,(error: string | null, match: boolean | null) => {
        if (!error) {
           this.counter = this.counter + 1;
           resolve(true);
        } else {
          resolve(false);
        }
      });
    }
    });    
}
}
