<div class="header" *ngIf="isAuthenticated">
    
    <nav>
        <ul>
            <div class="main">
                <img class="logo" src="assets/bwh-hotel-group.png">
                <h4 class="flex-child1">{{titleDisplay}}</h4>
            </div>
            <li class="spacer">

            </li>
            
            <li *ngIf="!this.dataService.firstLogin" (click)="redirectTohome()">
                <span class="material-symbols-rounded">home</span>
                <a class="font15">{{globalConstants.home}}</a>
            </li>
            <li *ngIf="!this.dataService.firstLogin && isUserMgmtAllowed && showTemplateScreen && !isVendorUser" (click)="redirectToManageTemplate()">
                <span class="material-symbols-rounded">bookmark_manager</span>
                <a class="font15">{{globalConstants.manage_template}}</a>
            </li>
            <li *ngIf="!this.dataService.firstLogin && isUserMgmtAllowed" (click)="redirectToEdit()">
                <span class="material-symbols-rounded">manage_accounts</span>
                <a class="font15" >{{globalConstants.manage_user}}</a>
            </li>
            <li *ngIf="!this.dataService.firstLogin && isUserMgmtAllowed" (click)="redirectTousers()">
                <span class="material-symbols-rounded">person_add</span>
                <a class="font15">{{globalConstants.create_user}}</a>
            </li>
            <li>
                <span [style.visibility]="isDataAvailable ? 'visible' : 'hidden'">
                    <span>
                        <a [style.visibility]="isDataAvailable ? 'hidden' : 'visible'" id="loading"></a>
                    </span>
                    <span class="material-symbols-rounded">account_circle</span>
                    <a class="font15 pad" [matMenuTriggerFor]="menu"> {{userName}}</a>
                    <span class="material-symbols-rounded" [matMenuTriggerFor]="menu">arrow_drop_down</span>
                    <mat-menu #menu="matMenu">
                        <button *ngIf="userClaims?.userRole != 'EMPLOYEE'" mat-menu-item (click)="redirectToProfile()">{{globalConstants.profile}}</button>
                        <button mat-menu-item (click)="logout()">{{globalConstants.sign_out}}</button>
                    </mat-menu>
                </span>
            </li>
        </ul>
    </nav>
</div>