import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/data.service';
import { GlobalConstants } from '../../../global-constants';
import { GlobalErrorHandlerService } from '../../../GlobalErrorHandlerService';
import { User } from 'src/app/model/user';
import { AppMenuItem, MultiAppData, ReqParamsMultiApp, SelectedMenu } from 'src/app/model/multi-app-access.model';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { Memberweb } from 'src/app/model/memeberweb';
import { SubMenuList } from 'src/app/model/subMenuList';
import { memberwebMenus } from 'src/app/model/memberwebMenu';
import { OktaAuthService } from '@okta/okta-angular';
import { PopupComponent } from '../../../popup/popup.component';
import { FormControl, Validators } from '@angular/forms';
import { Profile } from 'src/app/model/profile';
import { CreateUpdateRequest } from 'src/app/model/createUpdateRequest';
import { MultiAppAccessDataService } from '../multi-app-access-data.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'multi-app-menu-access',
  templateUrl: './multi-app-menu-access.component.html',
  styleUrls: ['./multi-app-menu-access.component.css']
})
export class MultiAppMenuAccessComponent implements OnInit {

  @Input() currentUser: User;
  @Input() isUserInactive: boolean;
  @Input() parentModule: string;
  @Input() matTabGroup: MatTabGroup;

  loggedInUser: any;
  sideNavSelItem: string;
  accessToken: string;
  multiAppData: MultiAppData[] = [];
  appMenuItems: AppMenuItem[];
  disableMemberWebMenus: boolean = false;
  selectedAppData: MultiAppData = new MultiAppData();
  isMemberWeb: boolean = true;
  incentiveCdFormCtrl = new FormControl('',[Validators.pattern("^[0-9]{3}")]);

  mwMenuItems: MemberwebMenuItems[];
  mwUserMenus: any;
  master_checked: boolean[] = new Array();
  mwSelectedMenuItems: Memberweb = new Memberweb();
  userOktaId: string;
  selectedGroupId: SelectedMenu[] = [];
  selectedPrimaryKeyData: any[] = [];
  mwTemplateSelection: string;
  subscriptions: Subscription[] = [];
  
  constructor(private oktaAuth: OktaAuthService,
    private dataService: DataService,
    private dialog: MatDialog,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private menuDataService: MultiAppAccessDataService,
    private router: Router,
    public globalConstants: GlobalConstants) {
      this.subscriptions.push(
        this.menuDataService.radioSelectionChange.subscribe(value => {
          console.log(`Subscriber: ${value}`);
          this.markRadioSelected(value);
        })
      );
  }

  async ngOnInit() {
    this.loggedInUser = this.dataService.userclaims;
    this.accessToken = await this.oktaAuth.getAccessToken();
    localStorage.setItem("mwDefaulted", "N");
    this.getCurrentUser();
    this.loadMultiAppData();
  }

  sidenavClickedEvent(event: MultiAppData) {
    this.selectedAppData = event;
    this.clearMenuData();
    this.loadSelectedAppData();
    this.resetIncentiveCode();
  }

  private resetIncentiveCode(){
    this.getCurrentUser();
  }

  private getCurrentUser() {
    this.dataService.getCurrentUserDeatils(this.currentUser.profile.login, 
      this.accessToken, this.loggedInUser.userId).then(response => {
        let data: User = JSON.parse(response);
        this.userOktaId = data.id;
        this.incentiveCdFormCtrl.setValue(data.profile.frontDeskIncentives);
    });
  }

  private loadMultiAppData() {
    let reqParams : ReqParamsMultiApp = {
      "token": this.accessToken,
      "submittedBy": this.loggedInUser.userId,
      "userType": this.currentUser.profile.employeeTypeIDM,
      "userId": this.currentUser.profile.uid
    }

    this.dataService.getMultiappDetails(reqParams).subscribe(
      (response) => {
        let data = JSON.parse(JSON.stringify(response.body));
        let multiAppDataArr: MultiAppData[] = [];
        for (let i = 0; i < response.body.length; i++){
          multiAppDataArr.push({appCode: data[i].sk, appName: data[i].name, menuType: data[i].menuType, appUrl: data[i].endpoint,
             description: data[i].description, groupPrefix: data[i].groupPrefix}); 
        }
        this.multiAppData = multiAppDataArr;
        let defautlSideNav: MultiAppData = this.multiAppData.find(app => app.appName == this.globalConstants.default_side_nav_app_name);
        this.highlightSideNav(defautlSideNav.appName);
        this.selectedAppData = defautlSideNav;
        this.loadSelectedAppData();
     },
      (error) => {
        console.log(error);
        this.globalErrorHandlerService.handleError(error);
      }
    );
  }

  private loadSelectedAppData() {
    if (this.selectedAppData.appCode == this.globalConstants.member_web_app_code) {
      this.isMemberWeb = true;
      this.loadMultiAppMemberWebMenu();
    } else {
      this.isMemberWeb = false;
      this.loadMultiAppMenu();
    }
  }

  private loadMultiAppMenu() {
    let reqParams: ReqParamsMultiApp = {
      "appCode": this.selectedAppData.appCode,
      "token": this.accessToken,
      "submittedBy": this.loggedInUser.userId,
      "userType": this.currentUser.profile.employeeTypeIDM,
      "userId": this.currentUser.profile.uid
    }

    this.dataService.getMultiappMenus(reqParams).subscribe((response) => {
      if(response.statusCode === 200) {
        this.appMenuItems = JSON.parse(JSON.stringify(response.body));
        this.defaultOption(this.appMenuItems);
        this.dataService.getUserGroupDetails(this.currentUser.profile.login, this.accessToken,
          this.selectedAppData.groupPrefix).subscribe(result => {
            let userGroups = JSON.parse(JSON.stringify(result));
            userGroups.forEach(userGroup => {
              this.assignSelectedMenu(this.appMenuItems, userGroup);
            });
          })
      } else {
        console.error('Error in get multi app menu API calls:');
        this.globalErrorHandlerService.handleError('Error in API calls:');
      }
    });
  }

  private defaultOption(menuItems: AppMenuItem[]) {
    menuItems.forEach(menu => {
      if(menu.menuType !== this.globalConstants.menu_type_div && menu.menuType !== this.globalConstants.menu_type_title) {
        if(menu.menuType === this.globalConstants.menu_type_radio && menu.name === this.globalConstants.no_access) {
          menu.selectedOption = menu.name;
        }
        if(menu.menuType === this.globalConstants.menu_type_checkbox && menu.name === this.globalConstants.no_access) {
          menu.selectedOption = true;
        }
      }
      if (menu.children && menu.children.length > 0) {
        this.defaultOption(menu.children);
      }
    })
  }

  private assignSelectedMenu(menuItems: AppMenuItem[], userGroup: any) {
    menuItems.forEach(menu => {
      if(menu.menuType !== this.globalConstants.menu_type_div && menu.menuType !== this.globalConstants.menu_type_title && menu.groupId === userGroup.id) {
        if(menu.menuType === this.globalConstants.menu_type_radio) {
          menu.selectedOption = menu.name;
        } else {
          menu.selectedOption = true;
        }
      }
      if (menu.children && menu.children.length > 0) {
        this.assignSelectedMenu(menu.children, userGroup);
      }
    })
  }

  private highlightSideNav(selectedSideNav: string){
    this.sideNavSelItem = selectedSideNav;
  }

  markRadioSelected(selectedItem: AppMenuItem) {
    if(selectedItem) {
      let searchResult = this.searchSelectedNode(this.appMenuItems, selectedItem);
      if(searchResult) {
        searchResult.matchedNode.selectedOption = selectedItem.name;
        searchResult.otherSiblings.forEach(item => item.selectedOption = '');
      }
    }
  }

  searchSelectedNode(data: AppMenuItem[], search: AppMenuItem) {
    for (const node of data) {
      if (node.children) {
        const result = this.searchSelectedNode(node.children, search);
        if (result) {
          return result;
        }
      }
  
      const parent = data.find(parentNode => parentNode.children && parentNode.children.some(child => (child.name === search.name && child.parent === search.parent)));
  
      if (parent) {
        const matchedNode = parent.children.find(child => child.name === search.name);
        const otherSiblings = parent.children.filter(child => child.name !== search.name);
        return { matchedNode, otherSiblings };
      }
    }
    return null;
  }

  updateMenus() {
    if(this.selectedAppData.appName == this.globalConstants.app_member_web) {
      this.updateMemberwebPrivilages();
    } else {
      this.updateMenuAccess();
    }
  }

  updateMemberwebPrivilages() {
    localStorage.setItem("isButtonClicked", "Y");
    const selectedMwMenus: Memberweb = new Memberweb();
    selectedMwMenus["updated-by"] = this.loggedInUser.userId;
    selectedMwMenus["bearer_token"] = this.accessToken;
    selectedMwMenus.menus = this.mwSelectedMenuItems.menus.filter(Boolean);
    this.dataService.postMemberMenusAccess(this.currentUser.profile.uid, JSON.stringify(selectedMwMenus), this.accessToken).subscribe(
      (response) => {
        this.openDialog("Menu access has been updated.", "Success")
      },
      (error) => {
        this.openDialog('Failed to update menus. Please contact Adminstrator.', "Warning")
      }
    )
  }

  updateMenuAccess() {
    if(this.selectedAppData.appName == this.globalConstants.app_autoclerk && this.incentiveCdFormCtrl.touched) {
      if(this.incentiveCdFormCtrl.errors) {
        this.openDialog(this.globalConstants.invalid_fdic, "Warning!");
        return;
      } else {
        this.updateIncentiveCode();
      }
    }
    this.selectedPrimaryKeyData = [];
    this.selectedGroupId = [];   
    this.getSelectedMenus(this.appMenuItems);
    const policyCheckReqData = {
      "body": {
        "submitted_by" :this.loggedInUser.userId,
        "bearer_token" : this.accessToken,
        "sel_menus" : this.selectedPrimaryKeyData
      },
      "user_uid": this.currentUser.profile.uid
    }
    this.dataService.checkMenuRestrictionPolicy(policyCheckReqData).subscribe((response) => {
      let data = JSON.parse(JSON.stringify(response.body));
      if (data.decision == 'Allow') {
        this.updateGroupsToOkta(this.selectedGroupId, this.selectedAppData.groupPrefix);
      } else {
        this.openDialog("You are not permitted to grant one or more of these roles/permissions.", "Warning!")
      }
    },
    (error) => {
        console.log(error);
    });
  }

  getSelectedMenus(menuItems: AppMenuItem[]) {    
    menuItems.forEach(menu => {
      if (menu.menuType !== this.globalConstants.menu_type_div && menu.menuType !== this.globalConstants.menu_type_title) {
        if (menu.name !== this.globalConstants.no_access && menu.selectedOption) {
          this.selectedGroupId.push({ value: menu.groupId, viewValue: menu.group });
          this.selectedPrimaryKeyData.push({
            "SK": menu.sk,
            "PK": menu.pk
          });
        }
      }
      if (menu.children && menu.children.length > 0) {
        this.getSelectedMenus(menu.children);
      }
    })
  }

  updateGroupsToOkta(selectedGroupId, groupPrefix){
    const selReqBody = {
      "userId":this.userOktaId,
      "loginId": this.currentUser.profile.login,
      "groupId" : selectedGroupId,
      "groupPrefix" :groupPrefix,
      "submitted_by" :this.loggedInUser.userId,
      "bearer_token" : this.accessToken
    }
    console.log(this.currentUser)
    this.dataService.changeGroupAccess(selReqBody, this.accessToken).subscribe(
      (response) => {
        console.log("Access group has been added to okta", response);
        let successMessage = "Menu access provided successfully.";
        this.openDialog(successMessage,"Success");
      },
      (error) => {
        let failMeassage = 'Menu Access Failed!';
        this.openDialog(failMeassage,"Warning")
        console.log(error)
      });
  }

  updateIncentiveCode() {
    let editUser: User = new User();
    let profile: Profile = new Profile();
    editUser.profile = profile;
    profile.employeeTypeIDM = this.currentUser.profile.employeeTypeIDM;
    profile.beginDate = formatDate(this.currentUser.profile.beginDate.split('T')[0], this.globalConstants.date_time_format, this.globalConstants.locale);
    profile.frontDeskIncentives = this.incentiveCdFormCtrl.value;
    profile.propID = this.currentUser.profile.propID;
    profile.uid = this.currentUser.profile.uid;
    let reqBody: CreateUpdateRequest = new CreateUpdateRequest();
    reqBody.user = editUser;
    reqBody.submitted_by = this.loggedInUser.userId;
    reqBody.bearer_token = this.accessToken;
    if (this.globalConstants.non_property_users.includes(this.currentUser.profile.employeeTypeIDM) && !this.dataService.isPdrUser) {
      reqBody.searchUser = this.currentUser.profile.email;
    } else {
      reqBody.searchUser = this.currentUser.profile.login;
    }
    const body = {
      "Detail": JSON.stringify(reqBody),
      "Source": "updateUserEvent"
    }
    this.dataService.postUserDetails(body, this.accessToken).then((data: String) => {
    },
      (error) => {
        console.log(error);
        let failMeassage = 'Unable to update Frontdesk incentive Id.';
        this.openDialog(failMeassage, "Warning")
      });
  }

  loadMultiAppMemberWebMenu() {
    if (this.loggedInUser.idm_role[0] === this.globalConstants.idm_role_res){
      this.disableMemberWebMenus = true;
    }
    
    this.dataService.getMemberMenus(this.accessToken).subscribe(
      (response) => {
        this.mwMenuItems = JSON.parse(JSON.stringify(response));
        this.getUserSpecificMenus();
      },
      (error) => {
        console.log(error);
        this.globalErrorHandlerService.handleError(error);
      }
    );
  }

  clearMenuData() {
    this.appMenuItems = [];
    this.mwMenuItems = [];
    this.selectedGroupId = [];
    this.selectedPrimaryKeyData = [];
  }

  subMenuAllchecked(val: number) {
    this.master_checked[val] = true;
  }

  subMenuAllunChecked(val: number) {
    this.master_checked[val] = false;
  }

  list_change(menu: any, order: any) {
    let value: SubMenuList;
    let user_id = this.loggedInUser.userId;
    let allChange: boolean = true;
    let allNotChange: boolean = true;
    for (value of this.mwMenuItems[order]['sub-menu-list']) {
      if (value != null && value.checked) {
        allNotChange = false;
        if (this.mwSelectedMenuItems.menus[order] == null) {
          this.mwSelectedMenuItems.menus[order] = new memberwebMenus();
          this.mwSelectedMenuItems.menus[order]['menu-id'] = this.mwMenuItems[order]['menu-id'];
          this.mwSelectedMenuItems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
        }
        else {
          if (!this.mwSelectedMenuItems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            this.mwSelectedMenuItems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
          }
        }
        this.mwSelectedMenuItems['updated-by'] = user_id;
      }
      else if (!value.checked) {
        allChange = false;
        if (this.mwSelectedMenuItems.menus[order] != null) {
          if (this.mwSelectedMenuItems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            const index: number = this.mwSelectedMenuItems.menus[order]['sub-menu-id-list'].indexOf(value['sub-menu-id']);
            this.mwSelectedMenuItems.menus[order]['sub-menu-id-list'].splice(index, 1);
          }
        }
      }
    }
    if (allChange) {
      this.subMenuAllchecked(order);
    }
    else {
      this.subMenuAllunChecked(order);
    }
    if (allNotChange) {
      this.mwSelectedMenuItems.menus[order] = null;
    }
    JSON.stringify(this.mwSelectedMenuItems);
  }

  master_change(str: string, val: number) {
    for (let value of this.mwMenuItems[val]['sub-menu-list']) {
      value.checked = this.master_checked[val];
    }
    this.list_change(str, val);
  }

  getUserSpecificMenus() {
    this.dataService.getMemberOnePageUser(this.currentUser.profile.uid, this.accessToken).subscribe(
      (response) => {
        console.log("user specific", response)
        this.mwUserMenus = response;
        this.setMenu();
        localStorage.setItem("mwDefaulted", "Y");
      },
      (error) => {
        console.error(error);
        if(error.status === 404) {
          this.userSpecificMwSelection();
        }
      }
    );
  }

  private userSpecificMwSelection() {
    if(this.dataService.isSetFromTemplate) {
      this.setMenuFromTemplate();
    } else {
      if(localStorage.getItem("mwDefaulted") === "Y") {
        this.memberWebClearAllSelection();
      } else {
        this.defaultAllMemberWeb();
        localStorage.setItem("mwDefaulted", "Y");
      }
    }
  }

  private defaultAllMemberWeb() {
    if (this.parentModule === this.globalConstants.create_user && this.dataService.canAccessMemberwebTab && this.dataService.createUserType != 'PROPERTY_STAFF') {
      this.mwMenuItems.forEach((element, i) => {
        const subMenuList = element['sub-menu-list'];
        subMenuList.forEach(subElement => {
          subElement.checked = true;
        });
        this.master_checked[i] = true;
        this.list_change(element.name, i);
      });
    }
  }

  private setMenuFromTemplate() {
    this.memberWebClearAllSelection();
    console.log("template specific", localStorage.getItem("editTempObject"))
    this.mwTemplateSelection = localStorage.getItem("editTempObject");
    const mwTemplateSelectionJson = JSON.parse(this.mwTemplateSelection).menuitems;
    for (let i = 0; i < this.mwMenuItems.length; i++) {
      for (let m = 0; m < mwTemplateSelectionJson.menus.length; m++) {
        if (mwTemplateSelectionJson.menus[m] !== null && mwTemplateSelectionJson.menus[m]['menu-id'] == this.mwMenuItems[i]["menu-id"]) {
          let k = 0;
          for (let j = 0; j < this.mwMenuItems[i]["sub-menu-list"].length; j++) {
            if (mwTemplateSelectionJson.menus[m]['sub-menu-id-list'].includes(this.mwMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
              this.mwMenuItems[i]["sub-menu-list"][j].checked = true;
              k++;
              if (k == this.mwMenuItems[i]["sub-menu-list"].length) {
                this.subMenuAllchecked(i);
              }
            }
          }
        }
      }
      this.list_change(this.mwMenuItems[i].name, i);
    }
  }

  private setMenu() {
    this.memberWebClearAllSelection();
    this.mwUserMenus.menus.forEach(element => {
      if (this.mwMenuItems) {
        for (let i = 0; i < Object.keys(this.mwMenuItems).length; i++) {
          if (element['menu-id'] == this.mwMenuItems[i]["menu-id"] && element.hasOwnProperty('sub-menu-id-list')) {
            this.mwSelectedMenuItems.menus[i] = element;
            let k = 0;
            for (let j = 0; j < this.mwMenuItems[i]["sub-menu-list"].length; j++) {
              if (element['sub-menu-id-list'].includes(this.mwMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
                this.mwMenuItems[i]["sub-menu-list"][j].checked = true;
                k++;
                if (k == this.mwMenuItems[i]["sub-menu-list"].length) {
                  this.subMenuAllchecked(i);
                }
              }
            }
          }
        }
      }
    });
  }

  private openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  resetMenus() {
    if(this.selectedAppData.appName === this.globalConstants.app_member_web) {
      this.memberWebClearAllSelection();
    } else {
      this.clearMenuData();
    }
    if(this.selectedAppData.appName === this.globalConstants.app_autoclerk) {
      this.resetIncentiveCode();
    }
    this.loadSelectedAppData();
  }

  cancel() {
    if(this.parentModule === this.globalConstants.manage_user) {
      this.matTabGroup.selectedIndex = 1;
    } else {
      this.router.navigate(['pages/create-user']);
    }
  }

  clearMenus() {
    if(this.selectedAppData.appName === this.globalConstants.app_member_web) {
      this.memberWebClearAllSelection();
    } else {
      this.clearAllSelectedMenuData(this.appMenuItems);
    }
    if(this.selectedAppData.appName === this.globalConstants.app_autoclerk) {
      this.incentiveCdFormCtrl.setValue("");
    }
  }

  private clearAllSelectedMenuData(appMenuItems: AppMenuItem[]) {
    appMenuItems.forEach(menu => {
      if(menu.menuType !== this.globalConstants.menu_type_div && menu.menuType !== this.globalConstants.menu_type_title) {
        if(menu.name === this.globalConstants.no_access) {
          if(menu.menuType === this.globalConstants.menu_type_radio) {
            menu.selectedOption = menu.name;
          } else if (menu.menuType === this.globalConstants.menu_type_checkbox) {
            menu.selectedOption = true;
          } 
        } else {
          if(menu.menuType === this.globalConstants.menu_type_radio) {
            menu.selectedOption = this.globalConstants.empty_str;
          } else if (menu.menuType === this.globalConstants.menu_type_checkbox) {
            menu.selectedOption = false;
          }
        }
      }
      if (menu.children && menu.children.length > 0) {
        this.clearAllSelectedMenuData(menu.children);
      }
    })
  }

  masterChangeEvent(event:{menuName: string, index: number}){
    this.master_change(event.menuName, event.index);
  }

  listChangeEvent(event:{menuName: string, index: number}){
    this.list_change(event.menuName, event.index);
  }

  private memberWebClearAllSelection() {
    this.mwMenuItems.forEach((element, i) => {
      this.subMenuAllunChecked(i)
      this.master_change(element.name, i)
    });
  }

  ngOnDestroy() {
    this.clearMenuData();
    this.menuDataService.radioSelectionChange.next(null);
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
    localStorage.removeItem("mwDefaulted");
  }

}
