import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ToastrWrapperService {

    constructor(private toastr: ToastrService) { }

    // General method to show toastr based on type
    showToast(message: string, title: string, timer: number = this.getDefaultTimeOut(title.toLowerCase()), options?: any) {
        this.toastr.clear();
        let type: string = title.toLowerCase();
        // Default options
        const defaultOptions = {
            progressBar: true,
            timeOut: timer,
            extendedTimeOut: timer,
            closeButton: true,
            ...options, // Merge with user-provided options if any
        };

        switch (type) {
            case 'error':
                this.toastr.error(message, title, defaultOptions);
                break;
            case 'info':
                this.toastr.info(message, title, defaultOptions);
                break;
            case 'warning':
                this.toastr.warning(message, title, defaultOptions);
                break;
            case 'success':
            default:
                this.toastr.success(message, title, defaultOptions);
                break;
        }
    }

    private getDefaultTimeOut(type: string): number {
        return type === 'error' ? 7000 : 5000;
    }
}
