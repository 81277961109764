<body>
    <div class="content">
        <h1 class="search-lable"><i>{{globalConstants.search_template}}</i></h1>

            <div class="search-pad" >
                <div *ngIf="isformArrayError" class="search-error">{{globalConstants.search_err1}}</div>
                <form [formGroup]="searchForm" autocomplete="off">
                    <mat-form-field *ngIf="(!isDisplayAddFilter || (loggedInUser == 'EMPLOYEE'))" class="search-input"
                        [ngStyle]="setMyStyles()">
                        <input matInput placeholder="Search Value" formControlName="searchControl" 
                            [(ngModel)]="searchValue"  (keydown.enter)="getSearchUsers()">
                            <mat-error *ngIf="searchForm.get('searchControl').hasError('required')">
                                {{globalConstants.search_value_err1}}
                            </mat-error>
                            <mat-error *ngIf="!searchForm.get('searchControl').hasError('minlength') && searchForm.get('searchControl').hasError('pattern')">
                                {{globalConstants.property_number_err}}
                            </mat-error>
                        <mat-hint class="mat-hint-text">{{globalConstants.search_hint3}}</mat-hint>
                    </mat-form-field>
                </form>
            </div>
                    <div *ngIf="(loggedInUser != 'EMPLOYEE')"  class="user-groups">
                        <p class="temp_class">Template ID</p>
                        <ng-multiselect-dropdown [placeholder]="'click here to select template ID'"
                            [settings]="dropdownSettingsID" [(data)]="dropDownListProperties" [(ngModel)]="selectedItems" (onSelect)="onIDChange($event)" (onDeSelect)="onIDUnselect()"
                            [formControl]="templateIDControl">
                        </ng-multiselect-dropdown>
                    </div> <br>
                <div  class="border search-pad" [style.visibility]="isDisplayBorder ? 'visible' : 'hidden'">
                <form [formGroup]="searchFormArray" autocomplete="off">
                    <div *ngFor="let searchArray of searchFormArray.controls; let idCount=index;">
                        <div [formGroup]="searchArray">
                            <p class="search-txt">where
                                <mat-form-field class="search-item">
                                    <mat-select formControlName="selectedItem" placeholder="Field" required (selectionChange)="onCategoryChange(idCount)">
                                        <mat-option *ngFor="let searchItem of searchItems" [value]="searchItem.value" >
                                            {{ searchItem.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="searchArray.get('selectedItem').hasError('required')">
                                        {{globalConstants.search_err3}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="search-op">
                                    <mat-select formControlName="selectedOperator" placeholder="Operator" required>
                                        <mat-option *ngFor="let operator of operatorList" [value]="operator.value"
                                            [style.display]="mathOperatorforNumbers(searchArray.get('selectedItem').value, operator.value )?'none':'block'">
                                            {{ operator.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="searchArray.get('selectedOperator').hasError('required')">
                                        {{globalConstants.search_err4}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="search-val" *ngIf="!(searchArray.get('selectedItem').value == 'beginDate' || searchArray.get('selectedItem').value == 'endDate' 
                                    || searchArray.get('selectedItem').value == 'created')">
                                    <input formControlName="searchValues" matInput placeholder="Value" required (keydown.enter)="getSearchUsers()">
                                    <mat-error *ngIf="searchArray.get('searchValues').hasError('required')">
                                        {{globalConstants.search_err5}}
                                    </mat-error>
                                    <mat-error *ngIf="validateSearchValue(searchArray.get('selectedItem').value,idCount) && searchArray.get('searchValues').hasError('minChars')">
                                        {{globalConstants.search_value_err2}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="search-val"
                                    *ngIf="(searchArray.get('selectedItem').value == 'beginDate' || 
                                    searchArray.get('selectedItem').value == 'endDate' || searchArray.get('selectedItem').value == 'created')">
                                    <input matInput [matDatepicker]="picker" formControlName="searchValues"
                                        (click)="picker.open()" placeholder="Choose a date" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <i class="material-icons font18 pad-left" (click)="removeCondition(idCount)">clear
                                    icon</i>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        <h1>
            <div *ngIf="(isDisplayAddFilter && (loggedInUser != 'PROPERTY_ADMIN') && (loggedInUser != 'PROPERTY_STAFF'))" class="left-pad10">
                <button class="save-button addnew" mat-raised-button (click)="addCount()">+ADD NEW CONDITION</button>
            </div>
        </h1>
        <form>
            <div class="pad-top25">
                <div class="search-button">
                    <button class="save-button" mat-raised-button (click) ="createNewTemplate()">Create a new template</button>
                    <button class="save-button bg-color" mat-raised-button (click)="clearConditions()">Clear</button>
                    <button class="save-button" mat-raised-button (click) ="getSearchUsers()">Search</button>
                </div>
            </div>
        </form>
        <div *ngIf="isDisplyResults">
            <h1 class="pad-btm20 font18"><i>{{globalConstants.search_results}}</i>
                
            </h1>
            <mat-card-content>
                <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8">

                    <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->
              
                    <ng-container matColumnDef="edit">
                      <th mat-header-cell class="edit-col" *matHeaderCellDef mat-sort-header="edit">
                        </th>
                      <td mat-cell *matCellDef="let element" data-label="edit">
                        <button class="edit-btn" (click)="redirectToEditTemplate(element)">Edit</button>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="create-a-user">
                        <th mat-header-cell class="edit-col" *matHeaderCellDef mat-sort-header="create-a-user">
                          </th>
                        <td mat-cell *matCellDef="let element" data-label="create-a-user">
                          <button class="edit-btn" (click)="redirectToCreateUser(element)">Create a User</button>
                        </td>
                      </ng-container>

                      <ng-container matColumnDef="delete">
                        <th mat-header-cell class="edit-col" *matHeaderCellDef mat-sort-header="delete">
                          </th>
                        <td mat-cell *matCellDef="let element" data-label="delete">
                          <button class="edit-btn" (click)="deleteConfirmation(element)">Delete</button>
                        </td>
                      </ng-container>
              
                    <ng-container matColumnDef="templateName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="templateName"> Template Name </th>
                      <td mat-cell *matCellDef="let element" data-label="templateName"> {{element.templateName}} </td>
                    </ng-container>
              
                    <ng-container matColumnDef="userType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="userType"> User Type </th>
                      <td mat-cell *matCellDef="let element" data-label="userType"> {{element.userType}} </td>
                    </ng-container>
              
                    <ng-container matColumnDef="createdDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">Created Date</th>
                      <td mat-cell *matCellDef="let element" data-label="createdDate"> {{element.createdDate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="createdBy">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy">Created By</th>
                        <td mat-cell *matCellDef="let element" data-label="createdBy"> {{element.createdBy}} </td>
                      </ng-container>

                      <ng-container matColumnDef="affiliateOffice">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="affiliateOffice">Affiliate Office</th>
                        <td mat-cell *matCellDef="let element" data-label="affiliateOffice"> {{element.affiliateOffice}} </td>
                      </ng-container>
              
                    <ng-container matColumnDef="ID">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="ID">ID</th>
                      <td mat-cell *matCellDef="let element" data-label="ID"> {{element.ID}} </td>
                    </ng-container>
              
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  </table>
            </mat-card-content>
        </div>
