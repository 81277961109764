import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-okta-error-handler',
  templateUrl: './okta-error-handler.component.html',
  styleUrls: ['./okta-error-handler.component.css']
})
export class OktaErrorHandlerComponent {
  errorMessage: string | null = null;
  errorDescription: string | null = null;

  constructor(private route: ActivatedRoute, private router: Router, private oktaAuth: OktaAuthService) { }

  ngOnInit() {
    this.errorMessage = this.route.snapshot.queryParamMap.get('error');
    this.errorDescription = this.route.snapshot.queryParamMap.get('error_description');
  }

  reLogin() {
    this.oktaAuth.logout();
    this.router.navigateByUrl(environment.navigateSignOut);
  }
}
