import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OktaAuthService } from '@okta/okta-angular';
import { DataService } from 'src/app/data.service';
import { SearchUserComponent } from '../search-user/search-user.component';
import { take } from 'rxjs/operators';
import { DeleteTemplate } from 'src/app/model/deleteTemplate';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.component.html',
  styleUrls: ['./delete-popup.component.css']
})
export class DeletePopupComponent implements OnInit {
  deleteTemplate = new DeleteTemplate();

  @Input() isConfirmed : boolean;

  constructor(public oktaAuth: OktaAuthService, public dataService: DataService, public dialog: MatDialog) {}

 ngOnInit() {
  
  }

  exit() {
    this.dialog.closeAll();
  }

  apply() {
    let id = this.generateRandomNumber(8);
    this.dataService.invokeDeleteConfirmation.emit(id);
    this.dialog.closeAll();
  }

  public generateRandomNumber(length) {
    var userId = '';
    var characters = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ987654321';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      userId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return userId;
  }

}
