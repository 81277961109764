<mat-sidenav-content style="margin-left: 185px; clear: both;" *ngIf="appMenuItems && appMenuItems.length">
    <div class="two_col_right_save_cancel">
        <div class="warning-box">
            {{selectedAppData.description}}
        </div>
    </div>
    <div class="title-text">{{selectedAppData.appName}}</div><br>
    <div *ngIf="selectedAppData.appName === globalConstants.app_autoclerk" class="flex-container">
        <mat-form-field class="flex-card-col-3">
            <mat-label style="font-weight: 700;">Front Desk Incentive Code</mat-label>
            <input matInput  maxlength="3" [formControl]="incentiveCdFormCtrl" placeholder="Please enter 3 digit Front Desk Incentive ID">
            <mat-error *ngIf="incentiveCdFormCtrl.hasError('pattern')">{{globalConstants.invalid_memberId}}</mat-error>
        </mat-form-field>
    </div>
    <div [ngClass]="appMenuItems && appMenuItems.length > 3 ? 'masonry' : 'flex-container'">
        <mat-card [ngClass]="appMenuItems.length > 3 ? 'item' : 'flex-card'" *ngFor="let menuItem of appMenuItems; let i = index">
            <multi-app-menu (radioSelectionChange)="onRadioSelectionChange($event)" [menuItem]="menuItem" 
                (checkboxSelectionChange)="onCheckboxSelectionChange($event)"></multi-app-menu>
        </mat-card>
    </div>
</mat-sidenav-content>