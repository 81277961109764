import { Component, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { memberwebMenus } from 'src/app/model/memberwebMenu';
import { Memberweb } from 'src/app/model/memeberweb';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { OktaAuthService } from '@okta/okta-angular';
import { DataService } from 'src/app/data.service';
import { Router } from '@angular/router';
import { SubMenuList } from 'src/app/model/subMenuList';
import { MatDialog } from '@angular/material/dialog';
import { PopUpTemplateComponent } from '../pop-up-template/pop-up-template.component';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { TemplateServiceService } from 'src/app/template-service.service';
import { GlobalConstants } from '../global-constants';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-create-memberweb-template',
  templateUrl: './create-memberweb-template.component.html',
  styleUrls: ['./create-memberweb-template.component.css']
})
export class CreateMemberwebTemplateComponent implements OnInit {

  @Output() changeTabs: EventEmitter<any> = new EventEmitter();
  master_checked: boolean[] = new Array();
  disableCheckboxes: boolean = false;
  memberwebMenuItems: MemberwebMenuItems[] = [];
  menuitems: Memberweb = new Memberweb();
  accessToken: any;
  headings: MemberwebMenuItems[] = [];
  profile: TemplateProfile = new TemplateProfile();

  constructor(public oktaAuth: OktaAuthService, public globalConstants: GlobalConstants, public templateService: TemplateServiceService, public apiDataService: DataService, public router: Router, public dialog: MatDialog, private toastr: ToastrService) { }

  async ngOnInit() {
    if (localStorage.getItem("isReturnFromCreateMembTemp") == 'Y') {
      this.accessToken = await this.oktaAuth.getAccessToken();
      this.apiDataService.getMemberMenus(this.apiDataService.accessToken).subscribe(
        (response) => {
          this.headings = response;
          this.memberwebMenuItems = JSON.parse(JSON.stringify(response));
          this.getUserSpecificMenus()
        },
        (error) => {
          console.log(error);

        }
      );
    } else {
      this.apiDataService.getMemberMenus(this.apiDataService.accessToken).subscribe(
        (response) => {
          console.log(response);
          this.headings = response
          this.memberwebMenuItems = JSON.parse(JSON.stringify(response));

          if (this.apiDataService.user.templateProfile.userType != 'PROPERTY_STAFF') {
            for (var i = 0; i < Object.keys(this.memberwebMenuItems).length; i++) {
              for (let value of this.memberwebMenuItems[i]['sub-menu-list']) {
                value.checked = true;
                this.master_checked[i] = true;
              }
              this.list_change(this.memberwebMenuItems[i].name, i);
            }
          }
          console.log(this.apiDataService.isWorldHotel);
        },
        (error) => {
          console.log(error);

        }
      );
    }
    localStorage.setItem('isReturnFromCreateMembTemp', 'Y');
    this.templateService.saveCreateMembTemplate.subscribe((data) => {
      if (data == true) {
        this.apiDataService.menuitems = new Memberweb();
        this.apiDataService.menuitems.menus = new Array();
        setTimeout(() => {
          this.apiDataService.menuitems.menus = this.menuitems.menus;
        });

      }
    })
  }

  getUserSpecificMenus() {
    this.menuitems.menus = this.apiDataService.menuitems.menus;
    for (let i = 0; i < this.memberwebMenuItems.length; i++) {
      for (let m = 0; m < this.menuitems.menus.length; m++) {
        if (this.menuitems.menus[m] !== null && this.menuitems.menus[m]['menu-id'] == this.memberwebMenuItems[i]["menu-id"]) {

          let k = 0;
          for (let j = 0; j < this.memberwebMenuItems[i]["sub-menu-list"].length; j++) {
            if (this.menuitems.menus[m]['sub-menu-id-list'].includes(this.memberwebMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
              this.memberwebMenuItems[i]["sub-menu-list"][j].checked = true;
              k++;
              if (k == this.memberwebMenuItems[i]["sub-menu-list"].length) {
                this.subMenuAllchecked(i);

              }
            }
          }
        }
      }
    }
  }

  Clear() {
    for (var i = 0; i < Object.keys(this.memberwebMenuItems).length; i++) {
      this.subMenuAllunChecked(i)
      this.master_change(this.headings[i].name, i)
    }
  }

  subMenuAllchecked(val: number) {
    this.master_checked[val] = true;
  }

  subMenuAllunChecked(val: number) {
    this.master_checked[val] = false;
  }

  list_change(menu: any, order: any) {
    let value: SubMenuList;
    let allChange: boolean = true;
    let allNotChange: boolean = true;
    for (value of this.memberwebMenuItems[order]['sub-menu-list']) {
      if (value != null && value.checked) {
        allNotChange = false;
        if (this.menuitems.menus[order] == null) {
          this.menuitems.menus[order] = new memberwebMenus();
          this.menuitems.menus[order]['menu-id'] = this.memberwebMenuItems[order]['menu-id'];
          this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
        }
        else {
          if (!this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
          }
        }

      }
      else if (!value.checked) {
        allChange = false;
        if (this.menuitems.menus[order] != null) {
          if (this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            const index: number = this.menuitems.menus[order]['sub-menu-id-list'].indexOf(value['sub-menu-id']);
            this.menuitems.menus[order]['sub-menu-id-list'].splice(index, 1);
          }
        }
      }
    }
    if (allChange) {
      this.subMenuAllchecked(order);
    }
    else {
      this.subMenuAllunChecked(order);
    }
    if (allNotChange) {
      //this.menuitems.menus[order] = null;
    }
  }

  master_change(str: string, val: number) {
    for (let value of this.memberwebMenuItems[val]['sub-menu-list']) {
      value.checked = this.master_checked[val];
    }
    this.list_change(str, val);
  }

  cancel() {
    this.router.navigate(['pages/manageTemplate/createTemplate']);
    this.templateService.getValueOfTabIndex(0);
    if (this.apiDataService.user.templateProfile.templateName != null || this.apiDataService.user.templateProfile.templateName != undefined) {
      this.apiDataService.getInvokeEvent(this.apiDataService.user.templateProfile.templateName);
    }
    if (this.apiDataService.user.templateProfile.templateName == null || this.apiDataService.user.templateProfile.templateName == undefined) {
      this.apiDataService.getInvokeEvent(this.globalConstants.idm);
    }
  }

  openDialog(description: any, title: any): void {
    const dialogRef = this.dialog.open(PopUpTemplateComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  updateMemberwebPrivileges() {
    if (this.apiDataService.user.templateProfile.templateid == null) {
      this.toastr.error("One or more required fields in Create Template page has not been entered correctly", "Error", {
        timeOut: 10000,
        extendedTimeOut: 5000
      })
    } else {
      this.apiDataService.user.templateProfile.menuitems = this.menuitems;
      this.apiDataService.user.templateProfile.submittedBy = this.apiDataService.userclaims.userId
      const body = JSON.stringify(this.apiDataService.user);
      this.apiDataService.postTemplate(body, this.apiDataService.accessToken).then((data: string) => {
        localStorage.setItem("isRequestFromEditScreen", "N");
        this.openDialog("Template successfully created", "Success")
        localStorage.removeItem('isReturnFromCreateMembTemp');
        this.apiDataService.getInvokeEvent(this.globalConstants.idm);
      }, (error) => {
        console.log(error);
      })
    }
  }

}
