<mat-sidenav-container>
    <multi-app-sidenav [currentUser]="currentUser" (resetIncentiveCd)="onResetIncentiveCd()"></multi-app-sidenav>
    <multi-app-sidenav-content [currentUser]="currentUser" [parentModule]="parentModule" [incentiveCdFormCtrl]="incentiveCdFormCtrl"></multi-app-sidenav-content> 
</mat-sidenav-container>
<div class="button-group" *ngIf="appMenuItems && appMenuItems.length > 0">
    <button *ngIf="parentModule === globalConstants.manage_user" class="button button-medium bg-color-white" mat-raised-button (click)="resetMenus()">Reset</button>
    <button *ngIf="parentModule === globalConstants.create_user" class="button button-medium bg-color-white" mat-raised-button (click)="clearMenus()">Clear</button>
    <button class="button button-medium bg-color-white" (click)="cancel()" mat-raised-button>Cancel</button>
    <button [disabled]="isUserInactive" class="button button-medium" mat-raised-button (click)="updateMenus()">Save</button>
</div>
