import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SearchUserComponent } from './search-user/search-user.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { PagesModule } from './pages.module';
import { PagesComponent } from './pages.component';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MemberwebPrivilagesComponent } from './memberweb-privilages/memberweb-privilages.component';
import { QuestionAnswersComponent } from './question-answers/question-answers.component';
import { GlobalErrorComponent } from './global-error-component/global-error-component.component';
import { OktaAuthGuard } from '@okta/okta-angular';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { CreateMemberwebTemplateComponent } from './create-memberweb-template/create-memberweb-template.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { SearchTemplateComponent } from './search-template/search-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { EditMemberwebTemplateComponent } from './edit-memberweb-template/edit-memberweb-template.component';
import { ManageTemplateComponent } from './manage-template/manage-template.component';
import { ManageTemplateHeaderComponent } from './manage-template-header/manage-template-header.component';
import { ManageEditTemplateHeaderComponent } from './manage-edit-template-header/manage-edit-template-header.component';
import { CreateUserAppAccessComponent } from './create-user-app-access/create-user-app-access.component';


const routes: Routes = [
  {
    path: 'pages', 
    component: PagesComponent, 
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full'},
      { path: 'home', component: HomeComponent, canActivate: [OktaAuthGuard]},
      { path: 'search-user', component:  SearchUserComponent, canActivate: [OktaAuthGuard]},
      { path: 'update-profile', component:  UpdateProfileComponent, canActivate: [OktaAuthGuard]},
      { path: 'create-user', component:  CreateUserComponent, canActivate: [OktaAuthGuard]},
      { path: 'edit-user', component:  EditUserComponent, canActivate: [OktaAuthGuard]},
      { path: 'update-profile/reset-password',component: ResetPasswordComponent, canActivate: [OktaAuthGuard]},
      { path: 'memberweb-privilages', component:  MemberwebPrivilagesComponent, canActivate: [OktaAuthGuard]},
      { path: 'question-answer', component:  QuestionAnswersComponent, canActivate: [OktaAuthGuard]},
      { path: 'error', component:  GlobalErrorComponent, canActivate: [OktaAuthGuard]},
      { path: 'confirmation-popup', component:  ConfirmationPopupComponent, canActivate: [OktaAuthGuard]},
      { path: 'searchTemplate', component: SearchTemplateComponent, canActivate: [OktaAuthGuard]},
      { path: 'manageTemplate', component: ManageTemplateComponent, canActivate: [OktaAuthGuard], children: [
      { path: 'createMemberwebTemplate', component: CreateMemberwebTemplateComponent, canActivate: [OktaAuthGuard]},
      { path: 'createTemplate', component: CreateTemplateComponent, canActivate: [OktaAuthGuard]},
      { path: 'mangeTemplateHeader', component: ManageTemplateHeaderComponent, canActivate: [OktaAuthGuard]}, 
      { path: 'manageEditTemplateHeader', component: ManageEditTemplateHeaderComponent, canActivate: [OktaAuthGuard]},
      { path: 'editTemplate', component: EditTemplateComponent, canActivate: [OktaAuthGuard]},
      { path: 'editMemberwebTemplate', component: EditMemberwebTemplateComponent, canActivate: [OktaAuthGuard]}]},
      { path: 'app-access', component: CreateUserAppAccessComponent, canActivate: [OktaAuthGuard]}
  
    ]
  }
];


export const routing: ModuleWithProviders<PagesModule> = RouterModule.forRoot(routes);