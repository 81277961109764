import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateServiceService } from 'src/app/template-service.service';

@Component({
  selector: 'app-manage-template-header',
  templateUrl: './manage-template-header.component.html',
  styleUrls: ['./manage-template-header.component.css']
})
export class ManageTemplateHeaderComponent implements OnInit {
  isDispCreateTemp: boolean = false;
  isDispEditTemp: boolean = false;
  tabIndex: number;
  isNextButtonClicked: boolean = false;

  constructor(public templateService: TemplateServiceService, public router: Router) {
  }


  ngOnInit(): void {
    this.templateService.valueOfTabIndex.subscribe((value) => {
      this.tabIndex = value;
    })
    this.templateService.isNextButtonClicked.subscribe((value) => {
      this.isNextButtonClicked = value;
    })
  }

  onTabChange(event) {
    console.log(this.tabIndex);
    if (this.tabIndex == 0) {
      this.templateService.getSaveCreateTemplate(false);
      this.templateService.getSaveCreateMembTemplate(true);
      this.router.navigate(['pages/manageTemplate/createTemplate']);
    }
    if (this.tabIndex == 1) {
      if (this.isNextButtonClicked == false) {
        this.templateService.getSaveCreateTemplate(true);
        this.templateService.getSaveCreateMembTemplate(false);
      }
      this.router.navigate(['pages/manageTemplate/createMemberwebTemplate']);
    }
  }

}
