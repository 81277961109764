import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import IpRegex from 'ip-regex';


const blacklist_ips = Object.freeze(["0.0.0.0"])

/**
 * Custom validator for IP addresses with CIDR notation
 * @returns ValidatorFn
 */
export function ipCidrValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value.trim();

        // Validate CIDR (e.g., 192.168.1.0/24)
        if (value.includes('/')) {
            try {
                const parts: string[] = value.split("/");
                if(parts.length !== 2) {
                    return { invalidInput: true };
                }

                // Block specific IP (e.g., 0.0.0.0)
                if (isBlacklistIp(parts[0])) {
                    return { blockedIp: true };
                }

                // Ensure ip part is valid
                if (!isValidIp(parts[0])) {
                    return { invalidInput: true };
                }

                // Validate CIDR range /24 to /32
                const prefixLength = parseInt(parts[1])
                if (prefixLength < 24 || prefixLength > 32) {
                    return { invalidInput: true };
                }

                return null; // Valid CIDR
            } catch {
                return { invalidInput: true };
            }
        } else if (value.includes('-')) {// Validate IP range (e.g., 192.168.1.1-192.168.1.50)
            const [startIp, endIp] = value.split('-').map((ip) => ip.trim());

            // Ensure both start and end IPs are valid
            if (!isValidIp(startIp) || !isValidIp(endIp)) {
                return { invalidIp: true };
            }

            // Block specific IP (e.g., 0.0.0.0)
            if (isBlacklistIp(startIp) || isBlacklistIp(endIp)) {
                return { blockedIp: true };
            }

            const startValue = ipToNumber(startIp);
            const endValue = ipToNumber(endIp);

            // Ensure start IP is less than end IP and range <= 300
            if (startValue >= endValue) {
                return { invalidInput: true };
            }

            // Ensure start IP is less than end IP and range <= 300
            if (endValue - startValue + 1 > 300) {
                return { tooManyIps: true };
            }

            return null; // Valid range
        } else {// Validate single IP address
            if (value) {
                if(!isValidIp(value)) return { invalidInput: true } // Invalid IP
                if(isBlacklistIp(value)) return { blockedIp: true } // black list IP
            }
        }

        return null; // Valid input
    };
}

/**
   * Check if an IP address is valid using ip-regex
   */
function isValidIp(ip: string): boolean {
    return IpRegex({ exact: true }).test(ip);
}

/**
   * Check if an IP address is valid using ip-regex
   */
function isBlacklistIp(ip: string): boolean {
    return blacklist_ips.includes(ip);
}

/**
 * Convert an IP address to a numerical value for range validation
 */
function ipToNumber(ip: string): number {
    return ip
        .split('.')
        .map((octet, index) => parseInt(octet) * Math.pow(256, 3 - index))
        .reduce((sum, val) => sum + val, 0);
}

