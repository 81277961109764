<header class="header-container" *ngIf="isAuthenticated">
    <div class="header-logo">
        <img src="assets/bwh-hotel-group.png" height="40px">
        <h4 class="header-title">{{titleDisplay}}</h4>
    </div>
    <div class="hamburger" (click)="toggleMenu()">
        <span class="material-symbols-rounded">menu</span>
    </div>
    <nav class="header-menu" [class.menu-open]="isMenuOpen">
        <ul>
            <li *ngIf="!this.dataService.firstLogin" (click)="redirectTohome()" [class.active-menu]="router.url.includes('/pages/home')">
                <span class="material-symbols-rounded">home</span>
                <a class="menu-title">{{globalConstants.home}}</a>
            </li>
            <li *ngIf="!this.dataService.firstLogin && isUserMgmtAllowed && showTemplateScreen && !isVendorUser"
                (click)="redirectToNetworking()" [class.active-menu]="router.url.includes('/pages/networking')">
                <span class="material-symbols-rounded">lan</span>
                <a class="menu-title">{{globalConstants.header_network}}</a>
            </li>
            <li *ngIf="!this.dataService.firstLogin && isUserMgmtAllowed && showTemplateScreen && !isVendorUser"
                (click)="redirectToManageTemplate()" [class.active-menu]="router.url.includes('/pages/searchTemplate')">
                <span class="material-symbols-rounded">bookmark_manager</span>
                <a class="menu-title">{{globalConstants.manage_template}}</a>
            </li>
            <li *ngIf="!this.dataService.firstLogin && isUserMgmtAllowed" (click)="redirectToEdit()" [class.active-menu]="router.url.includes('/pages/search-user') || router.url.includes('/pages/edit-user')">
                <span class="material-symbols-rounded">manage_accounts</span>
                <a class="menu-title">{{globalConstants.manage_user}}</a>
            </li>
            <li *ngIf="!this.dataService.firstLogin && isUserMgmtAllowed" (click)="redirectTousers()" [class.active-menu]="router.url.includes('/pages/create-user') || router.url.includes('/pages/app-access')">
                <span class="material-symbols-rounded">person_add</span>
                <a class="menu-title">{{globalConstants.create_user}}</a>
            </li>
            <li [class.active-menu]="router.url.includes('pages/update-profile')">
                <span [style.visibility]="isDataAvailable ? 'visible' : 'hidden'">
                    <span>
                        <a [style.visibility]="isDataAvailable ? 'hidden' : 'visible'" id="loading"></a>
                    </span>
                    <span class="material-symbols-rounded">account_circle</span>
                    <a class="menu-title pad" [matMenuTriggerFor]="menu"> {{userName}}</a>
                    <span class="material-symbols-rounded" [matMenuTriggerFor]="menu">arrow_drop_down</span>
                    <mat-menu #menu="matMenu">
                        <button class="sub-menu-title" *ngIf="userClaims?.userRole != 'EMPLOYEE'" mat-menu-item (click)="redirectToProfile()" [class.active-menu]="router.url.includes('pages/update-profile')">
                            {{globalConstants.profile}}
                        </button>
                        <button class="sub-menu-title" mat-menu-item (click)="logout()">
                            {{globalConstants.sign_out}}
                        </button>
                    </mat-menu>
                </span>
            </li>
        </ul>
    </nav>
</header>