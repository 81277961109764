import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class OktaErrorGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const error = route.queryParams['error'];
    const errorDescription = route.queryParams['error_description'];

    if (error) {
      // Redirect to error page with query parameters
      this.router.navigate(['/auth-error'], {
        queryParams: { error, error_description: errorDescription },
      });
      return false;
    }
    return true;
  }
}
