import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { User } from 'src/app/model/user';
import { GlobalConstants } from '../global-constants';
import { Router } from '@angular/router';

@Component({
  selector: 'create-user-app-access',
  templateUrl: './create-user-app-access.component.html',
  styleUrls: ['./create-user-app-access.component.css']
})
export class CreateUserAppAccessComponent implements OnInit {

  currentUser: User;
  isUserInactive: boolean;

  constructor(
    private dataService: DataService,
    private router: Router,
    public globalConstants: GlobalConstants) {

  }

  ngOnInit(): void {
    this.currentUser = this.dataService.createUser;
    this.dataService.getInvokeEvent(this.currentUser.profile.firstName + ' ' + this.currentUser.profile.lastName);
  }

  backToCreateUser(){
    this.dataService.getInvokeEvent(this.globalConstants.create_user_title);
    localStorage.setItem("isButtonClicked", "Y");
    this.router.navigate(['pages/create-user']);
  }
}
