export class MultiAppMenus {
    OWNER: string;
    INSERTDATE: Date;
    ENDPOINT: string;
    SK: string;
    PK: string;
    GROUP: string;
    GROUPID: string;
    UPDATEDATE: Date;
    TYPE: string;
    NAME: string;
    PARENT: string;
    selected:boolean;
}

export class MultiAppAccessReqParams {
    token: string;
    userId: string;
    submittedBy?: string;
    userType?: string;
    groupPrefix?:string;
    appCode?: string;
    appName?: string;
    menuType?: string;
    loginId?:string;
}

export class MultiAppData {
    appCode: string;
    appName: string;
    menuType: string;
    appUrl: string
    description: string;
    groupPrefix: string;
}

export class AppMenuItem {
    name: string;
    parent: string;
    owner: string;
    pk: string;
    sk: string;
    menuType: string;
    group: string;
    groupId: string;
    selectedOption: string | boolean;
    disabled: boolean;
    children: AppMenuItem[]
}

export class SelectedMenuData {
    appDetails: MultiAppData;
    groupSelectedData: GroupSelectedData[];
}

export class GroupSelectedData {
    groupName: string;
    groupId: string
    selectedValues: string[];
}

export interface SelectedMenu {
    value: string;
    viewValue: string;
}

export class UserAccessGroup {
    id: string;
    name: string;
    description?: string;
}

export class MemberWebUserMenu {
    menus: UserMenuData[];
    constructor(data: any) {
        this.menus = data.menus ? data.menus.map((menuData: any) => new UserMenuData(menuData)) : [];
    }
}

export class UserMenuData {
    menuId: string;
    subMenuId: string []
    constructor(data: any){
        this.menuId = data["menu-id"];
        this.subMenuId = data["sub-menu-id-list"];
    }
}