import { Component, ViewChild, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OktaAuthService } from '@okta/okta-angular';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/data.service';
import { SearchModel } from 'src/app/model/searchModel';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from "@angular/common";
import * as _moment from 'moment';
import { SearchRequest } from 'src/app/model/searchRequest';
import { GlobalConstants } from '../global-constants';
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { integer } from 'aws-sdk/clients/cloudfront';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { TemplateServiceService } from 'src/app/template-service.service';
import { Affiliate } from 'src/app/model/affiliate';
import { DeleteTemplate } from 'src/app/model/deleteTemplate';
import { take } from 'rxjs/operators';
import { DeletePopupComponent } from '../delete-popup/delete-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { PopUpTemplateComponent } from '../pop-up-template/pop-up-template.component';
import { TemplateSearchRequest } from 'src/app/model/idm-models';
export interface Items {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-search-template',
  templateUrl: './search-template.component.html',
  styleUrls: ['./search-template.component.css']
})
export class SearchTemplateComponent implements OnInit {
  @Output() changeTabs: EventEmitter<any> = new EventEmitter();
  addCounttrack: integer = 0;
  searchRequest: SearchRequest;
  searchFormArray = new FormArray([]);
  affiliateOfficeCode: Affiliate;
  dataSource: any = new MatTableDataSource<TemplateProfile>();
  dataSource1: any;
  isDisplayAddFilter: boolean = false;
  isDisplayCompoundSearch: boolean = false;
  isActive: boolean = true;
  isInactive: boolean = true;
  isDisplayBorder: boolean = false;
  isDisplyResults: boolean = false;
  isDisplayProps: boolean = false;
  activeAndInactive: String
  searchValue
  CustomOrCompound: string = 'custom';
  searchCustomorCompound: string = '';
  isRequestFromEditScreen: string = "N";
  accessToken: string;
  dropdownList = [];
  selectedItems = [];
  sortedDropDownList = [];
  templateIDControl = new FormControl('');
  selectedID: string = '';
  affilateOfficeCode: Affiliate;
  affiliateOffices: Items[] = [];
  dropDownListProperties = [];
  dropdownSettingsID: IDropdownSettings;
  dropdownSettings: IDropdownSettings;
  searchResults: TemplateProfile[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userClaims: any;
  searchModelArray = [];
  isformArrayError: boolean = false;
  filterMap: Map<String, String[]> = new Map();
  dateFormat = 'yyyy-MM-dd';
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  locale = 'en-US';
  displayedColumns: string[] = ['templateName', 'ID', 'userType', 'create-a-user', 'edit', 'delete'];
  searchValuesTemp = new FormControl();
  selDate: string;
  selDay: string;
  selMonth: string;
  selYear: string;
  searchForm = this.fb.group({
    searchControl: ['', [Validators.required]],
    selectedProperties: ['', []]
  });

  searchItems: Items[] = [
    { value: 'templateName', viewValue: 'Template Name' },
    { value: 'userType', viewValue: 'User Type' }
  ];
  operatorList: Items[] = [
    { value: 'eq', viewValue: '=' },
    { value: 'gt', viewValue: '>' },
    { value: 'ge', viewValue: '>=' },
    { value: 'lt', viewValue: '<' },
    { value: 'le', viewValue: '<=' },
    { value: 'sw', viewValue: 'starts with' }
  ];
  minChars: boolean = false;
  loggedInUser: string;
  isSuperEmployee: boolean;
  placeholderValue: any = '';
  deleteModel = new DeleteTemplate();

  constructor(public oktaAuth: OktaAuthService, public templateService: TemplateServiceService, private router: Router, private dataService: DataService, private fb: FormBuilder, private tostr: ToastrService, public globalConstants: GlobalConstants, public dialog:MatDialog, private globalErrorHandlerService: GlobalErrorHandlerService) {
    const userClaims = JSON.parse(localStorage.getItem("userClaims"));
    this.userClaims = userClaims;
    this.loggedInUser = this.userClaims.userRole;
    if (this.userClaims.userRole == 'EMPLOYEE') {
      this.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';
    }
    if (this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'PROPERTY_STAFF') {
      this.dropDownListProperties = this.dataService.userclaims.properties.sort();
      if (this.dropDownListProperties.length == 1) {
        this.selectedItems = this.dropDownListProperties;
      }
      if (this.dropDownListProperties.length > 1) {
        let count = 0;
        for (let i = 0; i < this.dropDownListProperties.length; i++) {
          if (this.dropDownListProperties[i] == 'Multi_Property') {
            count = count + 1;
          }
        }
        if (count == 0) {
          this.dropDownListProperties.push('Multi_Property');
        }
      }
    }
    // else if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == false) {
    //   this.dropDownListProperties = this.dataService.userclaims.properties.sort();
    //   if (this.dropDownListProperties.length == 1) {
    //     this.selectedItems = this.dropDownListProperties;
    //   }
    //   if (this.dropDownListProperties.length > 1) {
    //     let count = 0;
    //     for (let i = 0; i < this.dropDownListProperties.length; i++) {
    //       if (this.dropDownListProperties[i] == 'Multi_Property') {
    //         count = count + 1;
    //       }
    //     }
    //     if (count == 0) {
    //       this.dropDownListProperties.push('Multi_Property');
    //     }
    //   }
    // }

    else if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' ||
      this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.dataService.getAffiliateOffices(this.dataService.userclaims.affiliateOffice, this.dataService.accessToken).then((data: string) => {
        this.affilateOfficeCode = JSON.parse(JSON.stringify(data));
        if (this.affilateOfficeCode != null) {
          if (this.affilateOfficeCode.regionalAffiliate) {
            for (let i = 0; i < this.affilateOfficeCode.affiliates.length; i++) {
              this.affiliateOffices.push({ value: this.affilateOfficeCode.affiliates[i].code, viewValue: this.affilateOfficeCode.affiliates[i].code + "--" + this.affilateOfficeCode.affiliates[i].name })
            }
            this.sortAffiliates(this.affiliateOffices);
          } else {
            this.affiliateOffices.push({ value: this.affilateOfficeCode.code, viewValue: this.affilateOfficeCode.code + "--" + this.affilateOfficeCode.name })
          }
          this.dropDownListProperties = this.affilateOfficeCode.properties.sort();
          if (this.dropDownListProperties.length == 1) {
            this.selectedItems = this.dropDownListProperties;
          }
          if (this.dropDownListProperties.length > 1) {
            let count = 0;
            for (let i = 0; i < this.dropDownListProperties.length; i++) {
              if (this.dropDownListProperties[i] == 'Multi_Property') {
                count = count + 1;
              }
            }
            if (count == 0) {
              this.dropDownListProperties.push('Multi_Property');
            }
          }
          if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
            for (let i = 0; i < this.affiliateOffices.length; i++) {
              this.dropDownListProperties.push(this.affiliateOffices[i].value);
            }
          }
          // if (this.loggedInUser == 'AFFILIATE_STAFF' || this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
          //   for (let i = 0; i < this.affiliateOffices.length; i++) {
          //     this.dropDownListProperties.push(this.affiliateOffices[i].value + '_Property');
          //   }
          // }
        }
      })
    }
  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.isDisplayAddFilter = true;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 30,
      allowSearchFilter: true
    };
    this.dropdownSettingsID = {
      singleSelection: false,
      allowSearchFilter: true,
      closeDropDownOnSelection: true
    };
    this.listenDeleteEvent();
    if (this.userClaims.userRole == 'PROPERTY_ADMIN') {
      this.isDisplayProps = true;
      this.sortedDropDownList = this.userClaims.properties;
      if (this.sortedDropDownList.length == 1) {
        this.selectedItems = this.sortedDropDownList[0];
      }
      if (this.sortedDropDownList.length > 1) {
        this.sortedDropDownList.push('Multi_Property');
      }
    }
    if (this.userClaims.userRole == 'PROPERTY_ADMIN' || this.userClaims.userRole == 'PROPERTY_STAFF') {
      this.isDisplyResults = true;
      let searchModel = new SearchModel();
      searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
      searchModel.userRole = this.userClaims.userRole;
      searchModel.isCorpUser = false;
      searchModel.isCustomSearch = true;
      searchModel.customSearchValue = this.dataService.userclaims.properties.sort();
      this.searchModelArray = [];
      this.searchModelArray.push(searchModel);
      this.sendUserRequest(this.searchModelArray, this.accessToken);
      this.searchModelArray = [];
    }
    else if (localStorage.getItem('isRequestFromEditScreen') == 'Y') {
      localStorage.removeItem('isRequestFromEditScreen');
      this.isDisplyResults = true;
      const searchModelArr = JSON.parse(localStorage.getItem('searchModelArray'));
      this.sendUserRequest(searchModelArr, this.accessToken);
    }
    else if ((localStorage.getItem('isRequestFromEditScreen') == 'N')&& (localStorage.getItem('isReqFromCreateTemp') == 'Y') ) {
      localStorage.removeItem('isRequestFromEditScreen');
      this.isDisplyResults = true;
      const searchModelArr = JSON.parse(localStorage.getItem('searchModelArray'));
      this.sendUserRequest(searchModelArr, this.accessToken);
    }
    
  }

  onIDChange(event) {
  }

  onIDUnselect() {
  }

  listenDeleteEvent(){
    this.dataService.invokeDeleteConfirmation.pipe(take(1)).subscribe((data: any) => {
      if (data != this.placeholderValue) {
      this.deleteTemplate(data);
      }
    }, (error: any) => {
      console.log(error);
    })
  }

  sortAffiliates(affiliateOffices) {
    affiliateOffices.sort((left, right): number => {
      if (left.value < right.value) return -1;
      if (left.value > right.value) return 1;
      return 0;
    });
  }

  addCount() {
    if (this.searchFormArray.valid) {
      this.isformArrayError = false;
      this.searchFormArray.push(this.fb.group({ selectedItem: new FormControl("", [Validators.required]), selectedOperator: new FormControl("", [Validators.required]), searchValues: new FormControl("", [Validators.required]) }, {
        validator: this.minChars
      }));
      this.isDisplayCompoundSearch = true;
    }
    else {
      this.isformArrayError = true;
    }
    if (this.searchFormArray.length == 0) {
      this.isDisplayBorder = false;
    }
    if (this.searchFormArray.length > 0) {
      this.isDisplayBorder = true;
    }
  }

  setMyStyles() {
    let styles = {
      'padding-top': this.isDisplayAddFilter ? '2%' : ''
      , 'padding-left': this.isDisplayAddFilter ? '30%' : 'unset'
    };
    return styles;
  }

  validateSearchRequest() {
    if (!this.searchFormArray.valid) {
      this.isformArrayError = true;
    }
    else {
      this.isformArrayError = false;
    }
  }

  validateMandetoryFields() {
    if ("Y" == localStorage.getItem("isButtonClicked")) {
      localStorage.removeItem("isButtonClicked");
      return true;
    }
    else if (this.searchForm.valid) {
      return true;
    } else {
      return true;
    }
  }

  radioChange() {
    this.isDisplayCompoundSearch = true;
    if (this.searchCustomorCompound != '' && this.CustomOrCompound != this.searchCustomorCompound) {
      this.isDisplyResults = true;
    }
    else {
      this.isDisplyResults = false;
    }
    if ("Y" == this.isRequestFromEditScreen) {
      this.isDisplyResults = true;
    }
  }

  clearConditions() {
    if (this.searchFormArray) {
      this.searchFormArray.reset();
      this.isformArrayError = false;
      this.selectedItems = [];
    }
    if (this.searchForm) {
      this.searchForm.reset();
      this.searchValue = '';
    }

  }

  removeCondition(idCount) {
    this.searchFormArray.removeAt(idCount);
    if (this.searchFormArray.length == 0) {
      this.isDisplayCompoundSearch = false;
      this.isDisplayBorder = false;
    }
  }

  mathOperatorforNumbers(str: any, str1: any): boolean {
    if (str == 'endDate' || str == 'beginDate') {
      return false;
    } else if (str == 'created') {
      return str1 == 'sw' ? true : false;
    } else {
      return (str1 == 'gt' || str1 == 'ge' || str1 == 'lt' || str1 == 'le') ? true : false;
    }
  }

  onCategoryChange(index: any) {
    let searchcontrol = this.searchFormArray.controls[index] as FormArray;
    searchcontrol.controls['searchValues'].reset();
    searchcontrol.controls['selectedOperator'].reset();
  }

  validateSearchValue(selectedItem: any, index: any): boolean {
    let hasMinCharError = false;
    let searchcontrol = this.searchFormArray.controls[index] as FormArray;
    if (searchcontrol.controls['searchValues'].value != null && searchcontrol.controls['searchValues'].value != '') {
      if ((selectedItem == 'uid' || selectedItem == 'lastName' || selectedItem == 'firstName' || selectedItem == 'email')) {
        if (searchcontrol.controls['searchValues'].value.length < 3) {
          searchcontrol.controls['searchValues'].setErrors({ 'minChars': true });
          hasMinCharError = true;
        }
      }
      searchcontrol.controls['searchValues'].markAsTouched();
    }
    return hasMinCharError;
  }

  async getSearchUsers() {
    this.validateSearchRequest();
    if (!this.searchFormArray.valid) {
      return;
    }
    if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee) {
      if (!this.searchForm.valid) {
        return;
      }
    }  
    this.isDisplyResults = true;
    let searchModel = this.searchRequestBuilder(true);
    // if (typeof this.searchValue != 'undefined')
    //   searchModel.customSearchValue = this.searchValue.split(',');
    this.searchModelArray.push(searchModel);
    for (let control of this.searchFormArray.controls) {
      if (control.value['selectedItem'] == 'created' && control.value['selectedOperator'] == 'eq') {
        this.searchModelArray.push(this.compundSearchRequestBuilder('le', control.value['selectedItem'], control.value['searchValues']));
        this.searchModelArray.push(this.compundSearchRequestBuilder('ge', control.value['selectedItem'], control.value['searchValues']));
      } else {
        this.searchModelArray.push(this.compundSearchRequestBuilder(control.value['selectedOperator'], control.value['selectedItem'],
          control.value['searchValues']));
      }
    }
    localStorage.removeItem('searchModelArray');
    localStorage.setItem('searchModelArray', JSON.stringify(this.searchModelArray));
    localStorage.setItem('isReqFromCreateTemp', "Y");
    this.sendUserRequest(this.searchModelArray, this.accessToken);
    this.searchModelArray = [];

  }

  searchRequestBuilder(isCustomSearch: boolean) {
    let searchModel: SearchModel = new SearchModel();
    if (this.dataService.userclaims.userRole == 'EMPLOYEE') {
      searchModel.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';
    }
    searchModel.isCustomSearch = isCustomSearch;
    searchModel.userId = this.userClaims.login_ID;
    if (this.loggedInUser == 'EMPLOYEE') {
      let srchvalues: any[] = [];
      srchvalues = this.searchValue.split(',');
      if (srchvalues.length == 0) {
        searchModel.customSearchValue = [];
      } else {
        searchModel.customSearchValue = [];
      for (let i=0; i<srchvalues.length; i++) {
        if (srchvalues[i] == 'Multi_Property') {
          searchModel.customSearchValue.push(srchvalues[i]);
        } else {
          searchModel.customSearchValue.push(srchvalues[i].toUpperCase());
        }
      }}
    } else {
      searchModel.customSearchValue = this.selectedItems;
    }
    if (this.loggedInUser == 'AFFILIATE_STAFF' || this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      searchModel.propUnderScope = this.affilateOfficeCode.properties.sort();
    }
    else if (this.loggedInUser == 'PROPERTY_STAFF' || this.loggedInUser == 'PROPERTY_ADMIN') {
      searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
    }
    searchModel.userRole = this.userClaims.userRole;
    searchModel.affiliateOffice = this.userClaims.affiliateOffice;
    if (searchModel.userRole == 'EMPLOYEE') {
      searchModel.isCorpUser = true;
      searchModel.propUnderScope = [];
    } else {
      searchModel.isCorpUser = false;
    }
    if (searchModel.userRole == 'PROPERTY_ADMIN' || searchModel.userRole == 'PROPERTY_STAFF') {
      searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
    }
    if (searchModel.userRole == 'AFFILIATE_ADMIN' || searchModel.userRole == 'AFFILIATE_STAFF' || searchModel.userRole == 'REGIONAL_AFFILIATE_ADMIN') {
      this.dataService.getAffiliateOffices(this.dataService.userclaims.affiliateOffice, this.dataService.accessToken).then((data: string) => {
        this.affiliateOfficeCode = JSON.parse(JSON.stringify(data));
        searchModel.propUnderScope = this.affiliateOfficeCode.properties.sort();
      })
    }
    return searchModel;
  }

  compundSearchRequestBuilder(selectedOperator: string, selectedItem: string, searchValues: string) {
    let searchModel = this.searchRequestBuilder(false);
    let searchString = searchValues;
    searchModel.operator = selectedOperator;
    searchModel.searchCriteria = selectedItem;

    /* For begin/End Date change if operator is eq change to sw and convert string to Date format
        For Created Date convert string to required date/time format. 
    */
    if (searchString == undefined || searchString == '' || searchString == ' ') {
      searchString = "*";
    } else {
      if (searchModel.searchCriteria == 'createdDate' || searchModel.searchCriteria == 'lastModifDate') {
        if (searchModel.operator == 'eq') {
          searchModel.operator = 'sw';
        }
        searchString = formatDate(searchValues, this.dateFormat, this.locale);
      } else if (searchModel.searchCriteria == 'created') {
        if (searchModel.operator == 'gt' || searchModel.operator == 'le') {
          let endDate = new Date(searchValues).setHours(23, 59, 59, 999);
          searchString = formatDate(endDate, this.dateTimeFormat, this.locale);
        } else if (searchModel.operator == 'ge' || searchModel.operator == 'lt') {
          searchString = formatDate(searchValues, this.dateTimeFormat, this.locale);
        }
      } else {
        searchString = searchValues;
      }
    }
    searchModel.inputValue = searchString;
    return searchModel;
  }

  sendUserRequest(request: any[], accessToken) {
    this.searchResults = [];
    let templateSearchRequest = new TemplateSearchRequest();
    templateSearchRequest.submittedBy = this.dataService.userclaims.userId;
    templateSearchRequest.searchRequestData = request;
    this.dataService.getTemplate(templateSearchRequest, accessToken).subscribe((data: any) => {
      const rslt = data;
      const srchrslt = rslt.body;
      for (let i = 0; i < srchrslt.length; i++) {
        this.searchResults.push(srchrslt[i]);

      }
      this.filterDeprovisioned(this.searchResults);
    }, (error) => {
      console.log("*** error" + error);  //Error callback
      this.globalErrorHandlerService.handleError(error);
    });
  }

  filterDeprovisioned(searchResult) {
    if (searchResult.length === 0) {
      this.tostr.error("No records found with given search input", "Error", {
        timeOut: 5000,
        extendedTimeOut: 5000
      })
    }
    this.getSortFilterPagination(searchResult);
  }

  getSortFilterPagination(data: any) {
    let dataSource = [];
    if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == false) {
      for (let i=0; i<data.length; i++) {
        if (data[i].userType != 'AFFILIATE_STAFF' && data[i].userType != 'AFFILIATE_ADMIN') {
          dataSource.push(data[i]);
        }
      }
    } else {
      dataSource = data;
    }
    setTimeout(() => {
      this.dataSource.data = dataSource;




      /*  this.dataSource.filterPredicate = (data: User, filter:Map<String, String[]>) => {
         let statusFilter = filter.get("STATUS")[0];
         let propertyFilter = filter.get("PROPERTY");
         let propAvailable = false;
         if(propertyFilter && propertyFilter.length > 0) {
           propAvailable = data.profile.propID.length === filter.get("PROPERTY").length && 
           data.profile.propID.every(item => filter.get("PROPERTY").includes(item));
         } else {
           propAvailable = true;
         }
         if(statusFilter == "none") {
           return false;
         } else if(statusFilter == "all") {
           return propAvailable;
         } else if(statusFilter == "active") {
           return propAvailable && !(data.status.toLowerCase() == "deprovisioned" || data.status.toLowerCase() == "suspended");
         } else if (statusFilter == "inactive") {
           return propAvailable && (data.status.toLowerCase() == "deprovisioned" || data.status.toLowerCase() == "suspended");
         }
       }*/
    });

    setTimeout(() => this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'templateName': return item.templateName.toLowerCase();
        case 'createdBy': return item.createdBy.toLowerCase();
        case 'propertyID': return item.propertyID.toLowerCase();
        case 'userType': return item.userType.toLowerCase();
        case 'createdDate': return item.createdDate;
        default: return item[property];
      }
    });
    setTimeout(() => this.dataSource.sort = this.sort);
    const sortState: Sort = { active: 'lastName', direction: 'asc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  redirectToEditTemplate(element: any) {

    localStorage.setItem("editTempObject", JSON.stringify(element));
    /* this.templateService.getIsDispEditTemp(true);
    this.templateService.getIsDispCreateTemp(false);
    this.templateService.isDispCreateTemplate = false;
    this.templateService.isDispEditTemplate = true; */
    localStorage.removeItem("isDispCreateTemplate");
    localStorage.removeItem("isDispEditTemplate");
    localStorage.setItem("isDispCreateTemplate", 'N');
    localStorage.setItem("isDispEditTemplate", 'Y');
    localStorage.setItem('editTempScreen', 'Y');
    this.router.navigate(['pages/manageTemplate/editTemplate']);

  }

  createNewTemplate() {
    /* this.templateService.getIsDispCreateTemp(true);
    this.templateService.getIsDispEditTemp(false);
    this.templateService.isDispCreateTemplate = true;
    this.templateService.isDispEditTemplate = false; */
    localStorage.removeItem("isDispCreateTemplate");
    localStorage.removeItem("isDispEditTemplate");
    localStorage.setItem("isDispCreateTemplate", 'Y');
    localStorage.setItem("isDispEditTemplate", 'N');
    this.router.navigate(['pages/manageTemplate/createTemplate']);
  }

  redirectToCreateUser(element: any) {
    if (this.dataService.isSetFromTemplate == true) {
      localStorage.setItem("editTempObject", '');
      localStorage.setItem("editTempObject", JSON.stringify(element));
      this.dataService.isSetFromTemplate = true;
      this.router.navigate(['pages/create-user']);
    }
    if (this.dataService.isSetFromTemplate == false) {
      localStorage.setItem("editTempObject", JSON.stringify(element));
      localStorage.setItem("createTempUser","Y");
      this.dataService.isSetFromTemplate = true;
      this.router.navigate(['pages/create-user']);
    }
  }

  deleteTemplate(id) {
    this.placeholderValue = id;
    let deleteTemplate: DeleteTemplate = new DeleteTemplate();
    deleteTemplate.ID = this.deleteModel.ID;
    deleteTemplate.templateid = this.deleteModel.templateid;
    deleteTemplate.submittedBy = this.dataService.userclaims.userId;
    localStorage.removeItem('isDelete');
    localStorage.setItem('isDelete', 'Y');
    const body = {
      "Detail": JSON.stringify(deleteTemplate),
      "Source": "deleteTemplateEvent"
    }
    this.listenDeleteEvent();
    this.dataService.deleteTemplate(body, this.accessToken).then((data)=>{
      this.confirmDialog('The template has been successfully deleted', 'Success')
    }, (error)=>{
      console.log(error);
      this.openDialog('Template delete process failed. Please try again later', 'Error')
    })
  }

  openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopUpTemplateComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
  }

  confirmDialog(description, title): void {
    const dialogRef = this.dialog.open(PopUpTemplateComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDisplyResults = true;
      const searchModelArr = JSON.parse(localStorage.getItem('searchModelArray'));
      this.sendUserRequest(searchModelArr, this.accessToken);
    });
  }

  deleteConfirmation(event) {
    this.deleteModel.ID = event.ID;
    this.deleteModel.templateid = event.templateid;
    this.dialog.open(DeletePopupComponent, {
      width: '500px',
      height: '250px',
      disableClose: true
    });
  }

}


