import { environment } from "src/environments/environment"

export class GlobalConstants {
    public readonly server_error: string = "Internal Server error"
    public readonly global_error: string = "An error occurred, if this persists, please contact the Help Desk"
    public readonly submit: string = "Submit"
    public readonly cancel: string = "Cancel"
    /**************** IDM user roles ****************/
    public readonly role_emp: string = "EMPLOYEE"
    public readonly role_aff_admin: string = "AFFILIATE_ADMIN"
    /**************** header ****************/
    public readonly idm: string = "User Management"
    public readonly home: string = "Home"
    public readonly manage_user: string = "Manage User"
    public readonly create_user: string = "Create User"
    public readonly profile: string = "Profile"
    public readonly sign_out: string = "Sign out"
    public readonly header_network: string = "Networking"
    /**************** IDM user groups ****************/
    public readonly devAllIdmGroup: string = 'OH-IDM-Scope User Types - All'
    public readonly qaAllIdmGroup: string = 'OH-IDM-Scope User Types - All'
    public readonly prodAllIdmGroup: string = 'OH-IDM-Scope User Types - All'
    public readonly devIdmGroup: string = 'OH-IDM-Scope User Types - All Property'
    public readonly qaIdmGroup: string = 'OH-IDM-Scope User Types - All Property'
    public readonly prodIdmGroup: string = 'OH-IDM-Scope User Types - All Property'
    /**************** create and edit user ****************/
    public readonly personalInfo: string = "Personal Information"
    public readonly userType: string = "User Type"
    public readonly userType_err: string = "Please choose a User Type"
    public readonly email_err1: string = "Email is required"
    public readonly email_err2: string = "Please include email address"
    public readonly email_err3: string = "It can not contain bestwestern.com, autoclerk.com, worldhotels.com, bwhhotelgroup.com, bwh.com"
    public readonly email_err4: string = "Email already exists."
    public readonly email_err5: string = "(Note: Email ID will be login ID)"
    public readonly email_err6: string = "Please enter valid email"
    public readonly affiliate_ofc: string = "Affiliate Office"
    public readonly vendor_cd: string = "Vendor Code"
    public readonly vendor_err: string = "Please choose a Vendor Code"
    public readonly affiliate_ofc_err1: string = "Please choose a Affiliate Office"
    public readonly user_mgmt: string = "Allow User Management"
    public readonly first_name_err1: string = "First Name is required"
    public readonly first_last_name_err: string = "Allowed special characters are spaces, dots, apostrophes, underscore and dashes.Can't start with special character."
    public readonly property_number_err: string = "Allowed characters are only numbers and comma.Can't start with comma."
    public readonly last_name_err1: string = "Last Name is required"
    public readonly initials_err1: string = "Initials is required"
    public readonly initials_err2: string = "Initials is invalid"
    public readonly user_status: string = "User Status"
    public readonly active_date: string = "Active Date"
    public readonly active_date_err: string = "Active Date is required"
    public readonly inactive_date: string = "Inactive Date"
    public readonly inactive_date_err: string = "Inactive Date cannot be before Active Date"
    public readonly inactive_date_err1: string = "Inactive date cannot be past date"
    public readonly clear: string = "clear"
    public readonly user_info: string = "User Information"
    public readonly props: string = "Properties"
    public readonly props_err1: string = "Please choose atleast one property"
    public readonly props_err2: string = "Note: Property IDs must be a five digit numbers separated by commas."
    public readonly user_groups: string = "User Groups"
    public readonly doNotDelete: string = "Do not delete"
    public readonly groups_info: string = "Groups Information"
    public readonly assigned_groups: string = "Assigned User Groups"
    public readonly select_user_groups: string = "click here to select user groups"
    public readonly inactive_resorts: string = "Inactive Resort(s) : "
    public readonly invalid_resorts: string = "Invalid Resort(s) : "
    public readonly invalid_memberId: string = "Invalid Front Desk Incentive ID"
    public readonly invalid_role: string = "User is not allowed to grant this role."
    /**************** search ****************/
    public readonly search_user: string = "Search User"
    public readonly quick_search: string = "Quick Search"
    public readonly advanced_search: string = "Advanced Search"
    public readonly search_hint1: string = "Please enter firstname/ lastname/ userID/ email"
    public readonly search_hint2: string = "Enter * or leave blank to display all the results"
    public readonly search_hint3: string = "Please enter one or more property IDs, affiliate office code or 'Multi_Property' separated by comma to fetch templates"
    public readonly search_err1: string = "Please fill mandatory fields"
    public readonly search_err2: string = "Enter * or leave blank to display all the results"
    public readonly search_err3: string = "Please select the field"
    public readonly search_err4: string = "Please select the operator"
    public readonly search_err5: string = "Please enter the value"
    public readonly search_results: string = "Search Results"
    public readonly active: string = "Active"
    public readonly inActive: string = "Deactivated"
    public readonly edit: string = "Edit"
    public readonly user_id: string = "User Id"
    public readonly first_name: string = "First Name"
    public readonly last_name: string = "Last Name"
    public readonly search_user_status: string = "Status"
    public readonly search_active_date: string = "Active Date"
    public readonly search_inactive_date: string = "Inactive Date"
    public readonly search_value_err1: string = "Search Value is required"
    public readonly search_value_err2: string = "Search Value must be at least 3 characters"
    public readonly search_value_err3: string = "Search Value must be at least 5 characters"
    public readonly status_tooltip_active: string = "User is activated, and user is able to login to access applications."
    public readonly status_tooltip_active_pending: string = "User is in an active state, but the user has not completed the activation process."
    public readonly status_tooltip_recovery: string = "User requests a password reset or an Admin initiates one on their behalf."
    public readonly status_tooltip_password_expired: string = "The password has expired and the account requires an update to the password before a user is granted access to applications."
    public readonly status_tooltip_locked_out: string = "User exceeds the number of login attempts or ‘Forgot Password’ attempts defined by policy."
    public readonly status_tooltip_suspended: string = "User cannot login to access applications, but application assignments are unaffected. Password status also remains unchanged."
    public readonly status_tooltip_deprovisioned: string = "All application assignments are removed, and the password is permanently deleted."
    public readonly status_donotdelete: string = "\"Do Not Delete\" accounts will have an extended period of time before account is removed due to non-use."
    /**************** Question & Answers ****************/
    public readonly welcomeMessage: string = 'Please answer the below security questions'
    public readonly warningMessage: string = 'Please answer at least two challenge questions correctly to change password'
    public readonly req_field: string = "This Field is required"
    public readonly challenge_ans1: string = "Challenge Answer1"
    public readonly challenge_ans2: string = "Challenge Answer2"
    public readonly challenge_ans3: string = "Challenge Answer3"
    /**************** Reset Password ****************/
    public readonly old_psw: string = "Old Password"
    public readonly old_psw_err: string = "Please enter your old password"
    public readonly new_psw: string = "New Password"
    public readonly new_psw_err: string = "Please enter your new password"
    public readonly new_psw_err2: string = "Password should contain atleast 8 characters, one upper and lower case letter, number and special character"
    public readonly cnfrm_psw: string = "Confirm Password"
    public readonly cnfrm_psw_err: string = "Passwords do not match"
    public readonly change_psw: string = "Change Password"
    /**************** Update Profile ****************/
    public readonly update_profile: string = "Update Profile"
    public readonly uid_error: string = "User Id is required"
    public readonly user_type_error: string = "User Type is required"
    public readonly update_challengeQA: string = "Update Challenge Q/A"
    public readonly update_challengeQA_note: string = "All challenge answers must be unique"
    public readonly challenge_anw_err1: string = "Answer must contain at least 3 characters."
    public readonly challenge_anw_err2: string = "Challenge Answers must be unique"
    /**************** Template ****************/
    public readonly usr_info: string = "User Information"
    public readonly search_template: string = "Search Template"
    public readonly user_grp: string = "User Groups"
    public readonly template_info: string = "Template Information"
    public readonly search_frm_template: string = "Use Template"
    public readonly manage_template: string = "Manage Template"
    public readonly temp_err1: string = "The only characters allowed are letters, numbers or '_' in the value 'Multi_Property'"
    public readonly temp_hint1: string = "Please enter only one value - either propertyID, affiliate code or 'Multi_Property'"
    public readonly templatesAccessGroups: string[] = ['OH-IDM-Role User Management - Admin', environment.corp_groups.corp_admin, environment.corp_groups.corp_prop_admin]
    public readonly corpPropUsers: string[] = [environment.corp_groups.corp_prop_admin, environment.corp_groups.corp_prop_power_user]
    /**app access page constants */
    public readonly default_side_nav_app_name: string = 'Member Web'
    public readonly member_web_app_code: string = 'MemberWeb'
    public readonly app_autoclerk: string = "Autoclerk Cloud"
    public readonly invalid_fdic: string = "Invalid Front Desk Incentive Code"
    public readonly date_time_format: string = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"
    public readonly locale = 'en-US'
    public readonly app_member_web: string = "Member Web"
    public readonly idm_role_res: string = 'OH-IDM-Role User Management - Restricted'
    public readonly create_user_title = 'User Management'
    public readonly non_property_users: string[] = ['AFFILIATE_ADMIN', 'AFFILIATE_STAFF', 'REGIONAL_AFFILIATE_ADMIN', 'VENDOR_ADMIN', 'VENDOR_STAFF']
    public readonly user_type_pa: string = "PROPERTY_ADMIN"
    public readonly user_type_ps: string = "PROPERTY_STAFF"
    public readonly menu_type_div: string = "div"
    public readonly menu_type_title: string = "title"
    public readonly menu_type_radio: string = "radio"
    public readonly menu_type_checkbox: string = "checkbox"
    public readonly no_access: string = "No Access"
    public readonly empty_str: string = ""
    public readonly front_desk_cd: string = "frontDeskIncentiveCd"
    public readonly input_menu_types: string[] = ["radio", "checkbox"]
    public readonly property_users : string[]= ['PROPERTY_ADMIN','PROPERTY_STAFF']

    /** Networking constants*/
    public readonly network_prop_req_err: string = "Property Number is required"
    public readonly network_prop_len_err: string = "Property Number must be at least exactly 5 characters long"
    public readonly ip_req_err : string = "IP Address is required"
    public readonly ip_invalid_err: string = "Invalid IP address detected"
    public readonly ip_invalid_input_err: string = "Enter a valid IP, CIDR (/24 to /32), or IP range"
    public readonly ip_too_many_err: string = "IP range exceeds the maximum allowed (300 IPs)"
    public readonly ip_blocked_err: string = "IP 0.0.0.0 is not allowed"
    public readonly network_restriction_checkbox_label : string = "Network Restriction"
    public readonly network_restriction_checkbox_tooltip : string = "\"Network Restriction\" Users will have restricted access based on IP addresses"
    public readonly idm_network_restriction_group: string = 'OH-OKTA-Network Restricted'
    public readonly network_prop_num_err : string = "Property Number should be numeric"
    public readonly network_prop_invalid_err: string = "Property entered is invalid"
    public readonly network_prop_inactive_err: string = "Property entered is inactive"
    public readonly network_prop_noaccess_err: string = "You don't have access to the entered property"
    public readonly network_custom_err = {invalidProp: "invalidProp", inactiveProp: "inactiveProp", noaccessProp: "noaccessProp"}
    public readonly network_admins: string[] = ['AFFILIATE_ADMIN', 'REGIONAL_AFFILIATE_ADMIN', 'PROPERTY_ADMIN']
    public readonly restricted_email_domains: string [] = ['bestwestern.com', 'autoclerk.com', 'worldhotels.com', 'bwhhotelgroup.com', 'bwh.com']
    public readonly email_pattern: RegExp = /^[A-Za-z0-9._%+-]{1,64}@(?=.{1,256}$)([A-Za-z0-9-]{1,63}\.)+[A-Za-z]{2,63}$/
}