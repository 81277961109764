import { Component, OnInit } from '@angular/core';
import { TemplateServiceService } from 'src/app/template-service.service';

@Component({
  selector: 'app-manage-template',
  templateUrl: './manage-template.component.html',
  styleUrls: ['./manage-template.component.css']
})
export class ManageTemplateComponent implements OnInit {
  isDispCreateTemp: boolean = true;
  isDispEditTemp: boolean = true;

  constructor(public templateService: TemplateServiceService) { 
    
  }

  ngOnInit(): void {
   /*  this.templateService.isDispCreateTemp.subscribe((state: boolean)=>{
      this.isDispCreateTemp = state;
    })
    this.templateService.isDispEditTemp.subscribe((state: boolean)=>{
      this.isDispEditTemp = state;
    }) */
    
    if (localStorage.getItem("isDispCreateTemplate") == 'N'){
      this.isDispCreateTemp = false;
    }
    if (localStorage.getItem("isDispEditTemplate") == 'N'){
      this.isDispEditTemp = false;
    }
  } 

}
