import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OktaAuthService } from '@okta/okta-angular';
import { DataService } from 'src/app/data.service';
import { MultiAppData, MultiAppAccessReqParams } from 'src/app/model/multi-app-access.model';
import { User } from 'src/app/model/user';
import { GlobalConstants } from 'src/app/pages/global-constants';
import { GlobalErrorHandlerService } from 'src/app/pages/GlobalErrorHandlerService';
import { AppAccessDataService } from '../app-access-data.service';

@Component({
  selector: 'multi-app-sidenav',
  templateUrl: './multi-app-sidenav.component.html',
  styleUrls: ['./multi-app-sidenav.component.css']
})
export class MultiAppSidenavComponent implements OnInit{

  @Input() currentUser: User;
  @Output() resetIncentiveCd: EventEmitter<void> = new EventEmitter<void>();

  accessToken: string;
  multiAppData: MultiAppData[];
  selectedAppData: MultiAppData;
  loggedInUser: any;
  sideNavSelItem: string;

  constructor(
    private dataService: DataService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    public globalConstants: GlobalConstants,
    private appAccessDataService: AppAccessDataService,
    private oktaAuth: OktaAuthService) {

  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.loggedInUser = this.dataService.userclaims;
    this.loadMultiAppData();
  }

  private loadMultiAppData() {
    let reqParams : MultiAppAccessReqParams = {
      "token": this.accessToken,
      "submittedBy": this.loggedInUser.userId,
      "userType": this.currentUser.profile.employeeTypeIDM,
      "userId": this.appAccessDataService.loginIdByUserType(this.currentUser, this.dataService.isPdrUser)
    }

    this.appAccessDataService.getMultiappDetails(reqParams).subscribe(
      (response) => {
        let data = JSON.parse(JSON.stringify(response.body));
        let multiAppDataArr: MultiAppData[] = [];
        for (let i = 0; i < response.body.length; i++){
          multiAppDataArr.push({appCode: data[i].sk, appName: data[i].name, menuType: data[i].menuType, appUrl: data[i].endpoint,
             description: data[i].description, groupPrefix: data[i].groupPrefix}); 
        }
        this.multiAppData = multiAppDataArr;
        let defautlSideNav: MultiAppData = this.multiAppData.find(app => app.appName == this.globalConstants.default_side_nav_app_name);
        this.highlightSideNav(defautlSideNav.appName);
        this.selectedAppData = defautlSideNav;
        this.appAccessDataService.selectedAppDataSubject$.next(this.selectedAppData);
     },
      (error) => {
        console.error(error);
        this.globalErrorHandlerService.handleError(error);
      }
    );
  }

  protected onSideNavClick(navEvent: MultiAppData){
    this.selectedAppData = navEvent;
    this.sideNavSelItem =  navEvent.appName;
    this.clearMenuData();
    this.appAccessDataService.selectedAppDataSubject$.next(this.selectedAppData);
    this.resetIncentiveCode();
  }

  private highlightSideNav(appName) {
    this.sideNavSelItem =  appName;
  }

  private clearMenuData() {
    this.appAccessDataService.clearDataSubject$.next(true);
  }

  private resetIncentiveCode() {
    this.resetIncentiveCd.emit();
  }
}
