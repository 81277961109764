import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { EditTemplateBody } from 'src/app/model/editTemplateBody';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Affiliate } from 'src/app/model/affiliate';
import { OktaAuthService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { HotelPortalGroups } from 'src/app/model/Hotel-portal';
import { formatDate } from '@angular/common';
import { TemplateServiceService } from 'src/app/template-service.service';
import { GlobalConstants } from '../global-constants';
import { ResortSummary } from 'src/app/model/resortSummary';
import { environment } from 'src/environments/environment';

export interface Items {
  value: string;
  viewValue: string;
}

export interface Dropdownlist {
  item_id: String;
  item_text: String;
}

export interface TemplateRoleItems {
  value: string;
  viewValue: string;
  tooltip: string;
}

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.css']
})
export class EditTemplateComponent implements OnInit {
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  locale = 'en-US';
  userType = new FormControl('', [Validators.required]);
  /* usertypes: Items[] = [
    {value:'PROPERTY_STAFF', viewValue:'Property Staff' },
    { value:'PROPERTY_ADMIN', viewValue:'Property Admin' },
    {value:'AFFILIATE_STAFF', viewValue:'Affiliate Staff'},
    {value:'AFFILIATE_ADMIN', viewValue:'Affiliate Admin'},
    {value:'VENDOR_STAFF',  viewValue:'Vendor Staff'},
    {value:'VENDOR_ADMIN', viewValue:'Vendor Admin'}
  ]; */
  usertypes: Items[] = [];
  selectedUserType: any;
  userSelected: string = '';
  isUserMgmt: boolean = false;
  votingMemb: boolean = false;
  propertyID = new FormControl('', [Validators.required, Validators.pattern("^[0-9]{5}([,][0-9]{5})*$")]);
  propDropDown = new FormControl('', [Validators.required]);
  templateID = new FormControl('', [Validators.required]);
  templateName = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9@$_^&, ]{5,30}$")]);
  tempCreatedBy: string;
  tempCreatedDate: string;
  displayCreatedDate: string;
  tempLastModifBy: string = '';
  tempLastModifDate: string = '';
  isPropertyUser: boolean = false;
  isAffiliateUser: boolean = false;
  userMgmt = new FormControl('', [Validators.required])
  votingMember = new FormControl('', [Validators.required]);
  propID: string = '';
  tempID: string = '';
  tempid: string = '';
  profile: TemplateProfile = new TemplateProfile();
  ediObj: any;
  affiliateOfficeControl = new FormControl('', [Validators.required]);
  selectedAffiliate: string = '';
  affiliateOffices: Items[] = [];
  isaffiliatePDR: boolean = false;
  affiliatePdrMap: Map<string, boolean> = new Map();
  userGroupsControl = new FormControl('');
  selectedItems!: any[];
  dropdownList: Dropdownlist[] = [];
  dropdownSettings!: IDropdownSettings;
  isPDRuser: boolean;
  loggedInUser: any;
  isSuperEmployee: boolean;
  sortedDropDownList: String[] = [];
  affilateOfficeCode!: Affiliate;
  propertyid: any[];
  propertyids: any;
  ID: any;
  worldhotelProps: any[];
  inactivePropMessage: string;
  invalidPropMessage: string;
  accessToken: string;
  templateRoleControl = new FormControl('', [Validators.required]);
  selectedTemplateRole: string;
  templateRoles: TemplateRoleItems[] = [];


  constructor(public oktaAuth: OktaAuthService, public apiDataService: DataService, public globalConstants: GlobalConstants, public templateService: TemplateServiceService, public datePipe: DatePipe, public router: Router) {
    let date = new Date();
    this.isPDRuser = false;
    this.apiDataService.userclaims = JSON.parse(localStorage.getItem("userClaims")!);
    this.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';

    this.loggedInUser = this.apiDataService.userclaims.userRole;
    if (this.loggedInUser == 'PROPERTY_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUser == 'PROPERTY_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUser == 'AFFILIATE_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUser == 'AFFILIATE_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' });
    }
    else if (this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }
    else if (this.loggedInUser == 'EMPLOYEE') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }


    if (typeof this.apiDataService.userclaims.properties != 'undefined' && this.apiDataService.userclaims.properties != null)
      this.sortedDropDownList = this.apiDataService.userclaims.properties.sort();

    if (this.loggedInUser == "EMPLOYEE") {
      this.apiDataService.getAffiliateOfficesforEmp(this.apiDataService.accessToken).then((data: String) => {
        let affilateOfficeCodes: Affiliate[] = JSON.parse(JSON.stringify(data));
        for (let i = 0; i < affilateOfficeCodes.length; i++) {
          this.affiliateOffices.push({ value: affilateOfficeCodes[i].code, viewValue: affilateOfficeCodes[i].code + "--" + affilateOfficeCodes[i].name })
          this.affiliatePdrMap.set(affilateOfficeCodes[i].code, affilateOfficeCodes[i].pdr);
        }
        this.sortAffiliates(this.affiliateOffices);
      },
        (error: any) => {
          console.log(error);
        });
    }
    else if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' ||
      this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.apiDataService.getAffiliateOffices(this.apiDataService.userclaims.affiliateOffice, this.apiDataService.accessToken).then((data: string) => {
        this.affilateOfficeCode = JSON.parse(JSON.stringify(data));
        if (this.affilateOfficeCode != null) {
          if (this.affilateOfficeCode.regionalAffiliate) {
            for (let i = 0; i < this.affilateOfficeCode.affiliates.length; i++) {
              this.affiliateOffices.push({ value: this.affilateOfficeCode.affiliates[i].code, viewValue: this.affilateOfficeCode.affiliates[i].code + "--" + this.affilateOfficeCode.affiliates[i].name })
            }
            this.sortAffiliates(this.affiliateOffices);
          } else {
            this.affiliateOffices.push({ value: this.affilateOfficeCode.code, viewValue: this.affilateOfficeCode.code + "--" + this.affilateOfficeCode.name })
          }
          if (typeof this.affilateOfficeCode.properties != 'undefined' && this.affilateOfficeCode.properties != null)
            this.sortedDropDownList = this.affilateOfficeCode.properties.sort();
          if (typeof this.affilateOfficeCode != 'undefined' && this.affilateOfficeCode.pdr == true) {
            this.isPDRuser = true;
          }
        }
      },
        (error: any) => {
          console.log(error);
        });
    }
    this.dropdownList = [
      { item_id: 'MPRD_GOV', item_text: 'Allow Governors content access' },
      { item_id: 'MPRD_IC', item_text: 'Allow International content access' },
      { item_id: 'MP_SOFT', item_text: 'BW Premier Collection' },
      { item_id: 'MPRD_ADMIN', item_text: 'Member portal Admin ( Developers) Administrator / Help Desk Admin' },
      { item_id: 'MPRD_HOTELPORTAL', item_text: 'Sure Stay Brand Group' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 30,
      allowSearchFilter: true
    };
    this.templateRoles.push({ value: environment.user_roles.admin_role, viewValue: 'Admin User', tooltip: 'Can create and edit templates. Also, can create or edit a user with or without a template' },
        { value: environment.user_roles.power_role, viewValue: 'Power User', tooltip: 'Cannot create or edit templates. But can create or edit a user with or without a template' },
        { value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
        { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.'});

  }

  sortAffiliates(affiliateOffices: any[]) {
    affiliateOffices.sort((left, right): number => {
      if (left.value < right.value) return -1;
      if (left.value > right.value) return 1;
      return 0;
    });
  }

  setFromApiDataService() {
    const editObject = this.apiDataService.editTempBody;
    const editObj = JSON.parse(localStorage.getItem('editTempObject'));
    this.userType.setValue(editObject.userType);
    this.selectedUserType = this.userType.value;
    this.selectedTemplateRole = editObject.templateRole;
    this.templateRoleControl.setValue(editObject.templateRole);
    // this.isUserMgmt = editObject.userManagement;
    if (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'PROPERTY_STAFF') {
      this.propertyID.setValue(editObject.propertyID);
      this.propDropDown.setValue(editObject.propertyID);
      this.propertyid = editObject.propertyID;
      const prop = editObject.propertyID;
      for (let i = 0; i < prop.length; i++) {
        if (i == 0) {
          this.propertyids = prop[i];
        } else {
          this.propertyids = this.propertyids + ',' + prop[i];
        }
      }
    }
    this.templateID.setValue(editObj.templateid);
    this.templateName.setValue(editObject.templateName);
    this.tempCreatedBy = editObj.createdBy;
    this.tempCreatedDate = editObj.createdDate;
    this.tempLastModifBy = editObject.lastModifBy;
    this.tempLastModifDate = editObject.lastModifDate;
    this.tempid = editObj.templateid;
    this.ID = editObj.ID;
    this.selectedItems = editObject.selectedItems;
    this.userGroupsControl.setValue(editObject.selectedItems);
    this.selectedAffiliate = editObject.affiliateOffice;
    this.affiliateOfficeControl.setValue(editObject.affiliateOffice);
    this.apiDataService.getInvokeEvent(editObject.templateName);

    if (this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "REGIONAL_AFFILIATE_ADMIN") {
      this.isAffiliateUser = true;
      this.isPropertyUser = false;
    }
    if (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'PROPERTY_STAFF') {
      this.isAffiliateUser = false;
      this.isPropertyUser = true;
    }
  }

  setTemplateFields() {
    this.ediObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.ediObj);
    this.userType.setValue(editObject.userType);
    this.selectedUserType = this.userType.value;
    // if (editObject.userManagement == 'Yes') {
    //   this.isUserMgmt = true;
    // }
    // if (editObject.userManagement == 'No') {
    //   this.isUserMgmt = false;
    // }
    // this.isUserMgmt = editObject.userManagement;
    this.selectedTemplateRole = editObject.templateRole;
    this.templateRoleControl.setValue(editObject.templateRole);
    if (this.selectedUserType == 'PROPERTY_STAFF' || this.selectedUserType == 'PROPERTY_ADMIN') {
      this.propertyID.setValue(editObject.propertyID);
      this.propDropDown.setValue(editObject.propertyID);
      this.propertyid = editObject.propertyID;
      const prop = editObject.propertyID;
      for (let i = 0; i < prop.length; i++) {
        if (i == 0) {
          this.propertyids = prop[i];
        } else {
          this.propertyids = this.propertyids + ',' + prop[i];
        }
      }
    }
    this.templateID.setValue(editObject.templateid);
    this.templateName.setValue(editObject.templateName);
    this.tempCreatedBy = editObject.createdBy;
    this.tempCreatedDate = editObject.createdDate;
    let displayDateTime = new Date(this.tempCreatedDate).toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    let timezone = new Date().toLocaleDateString('en-US', {
      day: '2-digit',
      timeZoneName: 'short',
    }).slice(4);
    this.displayCreatedDate = displayDateTime + " " + timezone;
    this.tempLastModifBy = editObject.lastModifBy;
    let displayModifDate = new Date(editObject.lastModifDate).toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    if(displayModifDate === "Invalid Date") {
      this.tempLastModifDate = "";
    } else{
      this.tempLastModifDate = displayModifDate + " " + timezone;
    }
    this.tempid = editObject.templateid;
    this.ID = editObject.ID;
    this.selectedItems = editObject.selectedItems;
    this.userGroupsControl.setValue(editObject.selectedItems);
    this.selectedAffiliate = editObject.affiliateOffice;
    this.affiliateOfficeControl.setValue(editObject.affiliateOffice);
    this.apiDataService.getInvokeEvent(editObject.templateName);

    if (this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "REGIONAL_AFFILIATE_ADMIN") {
      this.isAffiliateUser = true;
      this.isPropertyUser = false;
    }
    if (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'PROPERTY_STAFF') {
      this.isAffiliateUser = false;
      this.isPropertyUser = true;
    }
  }

  onCategoryChange(event: any) {
    // if (event.value == 'PROPERTY_ADMIN' || event.value == 'AFFILIATE_ADMIN' || event.value == 'REGIONAL_AFFILIATE_ADMIN') {
    //   this.isUserMgmt = true;

    // }
    // else {
    //   this.isUserMgmt = false;
    // }
    if (this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === 'REGIONAL_AFFILIATE_ADMIN') {
      this.isAffiliateUser = true;
      this.isPropertyUser = false;
    }
    if (this.selectedUserType === "PROPERTY_STAFF" || this.selectedUserType === "PROPERTY_ADMIN") {
      this.isPropertyUser = true;
      this.isAffiliateUser = false;
    }
  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken(); 
    localStorage.setItem('editTempScreen', 'Y');
    if (this.apiDataService.isWorkInProgress == false) {
      this.setTemplateFields();
    }
    if (this.apiDataService.isWorkInProgress == true) {
      this.setFromApiDataService();
    }

  }

  async ValidateMandatoryFields() {
    let hasErrors = false;
    if (this.userType.errors) {
      this.userType.markAsTouched();
      hasErrors = true;
    }
    if ((this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'PROPERTY_STAFF' || this.loggedInUser == 'AFFILIATE_STAFF' || this.loggedInUser == 'AFFILIATE_ADMIN') && this.isPropertyUser) {
      if (this.propDropDown.errors) {
        this.propDropDown.markAsTouched();
        hasErrors = true;
      }
    }
    if (this.loggedInUser == 'EMPLOYEE' && this.isPropertyUser) {
      if (this.propertyID.errors) {
        this.propertyID.markAsTouched();
        hasErrors = true;
      }
    }
    if (this.templateName.errors) {
      this.templateName.markAsTouched();
      hasErrors = true;
    }

    if (this.isAffiliateUser) {
      if (this.affiliateOfficeControl.errors) {
        this.affiliateOfficeControl.markAsTouched();
        hasErrors = true;
      }
    }
    return hasErrors;
  }

  async submit() {
    if (await this.ValidateMandatoryFields()) {
      return;
    }
    this.apiDataService.editTempBody.userType = this.userType.value;
    // if (this.userType.value == 'PROPERTY_ADMIN' || this.userType.value == 'AFFILIATE_ADMIN') {
    //   this.apiDataService.editTempBody.userManagement = true;
    // } else {
    //   this.apiDataService.editTempBody.userManagement = this.userMgmt.value;
    // } 
    this.apiDataService.editTempBody.templateRole = this.selectedTemplateRole;
    if (this.loggedInUser == 'EMPLOYEE' && this.isPropertyUser) {
      console.log(this.propertyids);
      const prpID = this.propertyids.split(',');
      await this.validateProperties(prpID, this.accessToken);
      if (this.invalidPropMessage != '' || this.inactivePropMessage != '') {
        return;
      }
      console.log(this.worldhotelProps);
      if (prpID.length == this.worldhotelProps.length) {
        this.apiDataService.isWorldHotel = true;
      } else {
        this.apiDataService.isWorldHotel = false;
      }
      this.apiDataService.editTempBody.propertyID = prpID;
      const editObjProp = (JSON.parse(localStorage.getItem('editTempObject'))).propertyID;
      if (prpID.length == 1 && editObjProp.length == 1 && prpID[0] == editObjProp[0]) {
        this.apiDataService.editTempBody.isEdit = true;
      } else if (prpID.length > 1 && editObjProp.length > 1) {
        this.apiDataService.editTempBody.isEdit = true;
      } else {
        this.apiDataService.editTempBody.isEdit = false;
      }
    }
    if (this.loggedInUser != 'EMPLOYEE' && this.isPropertyUser) {
      this.apiDataService.editTempBody.propertyID = this.propertyid;
      await this.validateProperties(this.propertyid, this.accessToken);
      if (this.propertyid.length == this.worldhotelProps.length && this.propertyid.length != 0) {
        this.apiDataService.isWorldHotel = true;
      } else {
        this.apiDataService.isWorldHotel = false;
      }
      const editObjProp = (JSON.parse(localStorage.getItem('editTempObject'))).propertyID;
      if (this.propertyid.length == 1 && editObjProp.length == 1 && this.propertyid[0] == editObjProp[0]) {
        this.apiDataService.editTempBody.isEdit = true;
      } else if (this.propertyid.length > 1 && editObjProp.length > 1) {
        this.apiDataService.editTempBody.isEdit = true;
      } else if (this.propertyid.length == 0 && editObjProp.length == 0 && this.isPropertyUser) {
        this.apiDataService.editTempBody.isEdit = true;
      }
       else {
        this.apiDataService.editTempBody.isEdit = false;
      }
    }
    if (this.isAffiliateUser) {
      if (this.affiliateOfficeControl.value == JSON.parse(localStorage.getItem('editTempObject')).affiliateOffice) {
        this.apiDataService.editTempBody.isEdit = true;
      } else {
        this.apiDataService.editTempBody.isEdit = false;
      }
      this.apiDataService.editTempBody.propertyID = null;
    }
    console.log(this.apiDataService.editTempBody.isEdit);
    this.apiDataService.editTempBody.templateName = (this.templateName.value).toUpperCase();
    this.apiDataService.editTempBody.lastModifBy = this.apiDataService.userEmail;
    let date = new Date().toLocaleString('en-US', {
      timeZone: "Africa/Accra" //GMT
    });
    let tempModifiedDate = formatDate(date, this.dateTimeFormat, this.locale);
    this.apiDataService.editTempBody.createdBy = this.tempCreatedBy;
    this.apiDataService.editTempBody.createdDate = this.tempCreatedDate;
    this.apiDataService.editTempBody.lastModifDate = tempModifiedDate;
    this.apiDataService.editTempBody.affiliateOffice = this.affiliateOfficeControl.value;
    this.apiDataService.editTempBody.selectedItems = this.selectedItems;
    this.apiDataService.tempid = this.tempid;
    this.apiDataService.editTempBody.tempID = this.tempid;
    this.apiDataService.editTempBody.ID = this.ID;
    this.router.navigate(['pages/manageTemplate/editMemberwebTemplate']);
    this.templateService.getValueOfTabIndex(1);
    this.apiDataService.isWorkInProgress = true;
    localStorage.removeItem('editTempScreen');
    localStorage.setItem('editTempScreen', 'N');

  }

  resetControls() {
    /* this.userType.reset();
    this.templateID.reset();
    this.userMgmt.reset();
    this.propertyID.reset();
    this.tempCreatedBy.reset();
    this.tempCreatedDate.reset();
    this.tempLastModifBy.reset();
    this.tempLastModifDate.reset(); */
    this.setTemplateFields();

  }

  onAffSelect() {
    this.isaffiliatePDR = this.affiliatePdrMap.get(this.selectedAffiliate)!;
  }

  displayTemplate(event) {
    this.apiDataService.getInvokeEvent(event.target.value);
  }

  goSearchTemplate(){
    localStorage.setItem("isRequestFromEditScreen", "Y");
    this.apiDataService.isWorkInProgress = false;
    localStorage.removeItem('isDispEditTemplate');
    this.apiDataService.getInvokeEvent(this.globalConstants.idm); 
    this.router.navigate(['pages/searchTemplate']);
  }

  async validateProperties(selectedproperties: string[], accessToken:any) {
    let resortSummary: ResortSummary;
    let invalidProps:string[] = [];
    let inactiveProps:string[] = [];
    this.worldhotelProps = [];
    this.inactivePropMessage="";
    this.invalidPropMessage="";
    try {
        resortSummary = JSON.parse(JSON.stringify(await this.apiDataService.getPropDetails(selectedproperties, accessToken)));
        console.log('resortsummary', resortSummary);
        if (resortSummary.inactiveProperties.length > 0) {
          this.propertyID.setErrors({ inactiveProp: true });
          this.propertyID.markAsTouched();
          resortSummary.inactiveProperties.forEach((item) => {
            inactiveProps.push(item);
          });
          this.inactivePropMessage = this.globalConstants.inactive_resorts + inactiveProps;
        }
        if (resortSummary.invalidProperties.length > 0) {
          this.propertyID.setErrors({ invalidProp: true });
          this.propertyID.markAsTouched();
          resortSummary.invalidProperties.forEach((item) => {
            invalidProps.push(item);
          });
          this.invalidPropMessage = this.globalConstants.invalid_resorts + invalidProps;
        }
        if (resortSummary.activeProperties.length > 0) {
          for (let i=0; i<resortSummary.activeProperties.length; i++ ) {
            if (resortSummary.activeProperties[i].chainCode! && 
              (resortSummary.activeProperties[i].chainCode == 'WW' || resortSummary.activeProperties[i].chainCode == 'DO')) {
              this.worldhotelProps.push(resortSummary.activeProperties[i]);
            }
          }
        }
    }
    catch(error) {
        // this.globalErrorHandlerService.handleError(error);
        return;
    }
}

onTemplateRoleChange(event) {
  this.selectedTemplateRole = event.value;
  this.templateRoleControl.setValue(event.value);
}

}



