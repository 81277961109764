import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from 'src/app/data.service';
import { GlobalConstants } from '../../../global-constants';
import { User } from 'src/app/model/user';
import { AppMenuItem, MultiAppData, SelectedMenu } from 'src/app/model/multi-app-access.model';
import { Memberweb } from 'src/app/model/memeberweb';
import { OktaAuthService } from '@okta/okta-angular';
import { PopupComponent } from '../../../popup/popup.component';
import { FormControl, Validators } from '@angular/forms';
import { Profile } from 'src/app/model/profile';
import { CreateUpdateRequest } from 'src/app/model/createUpdateRequest';
import { AppAccessDataService } from '../app-access-data.service';
import { Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'multi-app-menu-access',
  templateUrl: './multi-app-menu-access.component.html',
  styleUrls: ['./multi-app-menu-access.component.css']
})
export class MultiAppMenuAccessComponent implements OnInit {

  @Input() currentUser: User;
  @Input() isUserInactive: boolean;
  @Input() parentModule: string;
  @Input() matTabGroup: MatTabGroup;

  loggedInUser: any;
  accessToken: string;
  incentiveCdFormCtrl = new FormControl('',[Validators.pattern("^[0-9]{3}")]);

  userOktaId: string;
  selectedGroupId: SelectedMenu[] = [];
  selectedAppGroupsData: any[] = [];
  selectedAppData: MultiAppData;
  appMenuItems: AppMenuItem[];
  subscription: Subscription = new Subscription();
  
  constructor(private oktaAuth: OktaAuthService,
    private dataService: DataService,
    private dialog: MatDialog,
    private appAccessDataService: AppAccessDataService,
    private router: Router,
    public globalConstants: GlobalConstants) {
      
  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.subscription.add(this.appAccessDataService.selectedAppDataObs$.subscribe(appData => {
      this.selectedAppData = appData;
    }));
    this.subscription.add(this.appAccessDataService.appMenusDataObs$.subscribe(appMenuData => {
      this.appMenuItems = appMenuData;
    }));
    this.subscription.add(this.appAccessDataService.clearDataSubject$.subscribe(status => {
      if(status) {
        this.clearMenuData();
      }
    }));
    this.loggedInUser = this.dataService.userclaims;
    await this.getCurrentUser();
  }

  private async resetIncentiveCode() {
    await this.getCurrentUser();
  }

  private async getCurrentUser() {
    const userData = JSON.parse(await this.dataService.getCurrentUserDeatils(this.currentUser.profile.login, 
      this.accessToken, this.loggedInUser.userId));
    this.userOktaId = userData.id;
    this.incentiveCdFormCtrl.setValue(userData.profile.frontDeskIncentives);
  }

  private openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  updateMenus() {
    if(this.selectedAppData.appName == this.globalConstants.app_member_web) {
      this.updateMemberwebPrivilages();
    } else {
      this.updateMenuAccess();
    }
  }

  updateMemberwebPrivilages() {
    localStorage.setItem("isButtonClicked", "Y");
    const selectedMwMenus: Memberweb = new Memberweb();
    selectedMwMenus["updated-by"] = this.loggedInUser.userId;
    selectedMwMenus["bearer_token"] = this.accessToken;
    this.selectedAppGroupsData = [];
    this.selectedGroupId = [];   
    this.getSelectedMenus(this.appMenuItems);
    selectedMwMenus['menus'] = this.convertUserGroupsToMemberWeb(this.selectedGroupId)
    this.dataService.postMemberMenusAccess(this.currentUser.profile.uid, JSON.stringify(selectedMwMenus), this.accessToken).subscribe(
      (response) => {
        this.openDialog("Menu access has been updated.", "Success")
      },
      (error) => {
        this.openDialog('Failed to update menus. Please contact Adminstrator.', "Warning")
      }
    )
  }

  private convertUserGroupsToMemberWeb(selectedGroupIds: SelectedMenu[]) {
    const resultMap: { [key: string]: string[] } = {};
    selectedGroupIds.forEach(item => {
      const viewValue = item.viewValue;
      const value = item.value;
      if (!value) return;
      const menuId = viewValue.split('-').pop();
      if (!resultMap[menuId]) {
        resultMap[menuId] = [];
      }
      resultMap[menuId].push(value);
    });
    const formattedMwMenus = Object.entries(resultMap).map(([menuId, subMenuIdList]) => ({
      "menu-id": menuId,
      "sub-menu-id-list": subMenuIdList
    }));
    return formattedMwMenus;
  }

  updateMenuAccess() {
    if(this.selectedAppData.appName == this.globalConstants.app_autoclerk && this.incentiveCdFormCtrl.touched) {
      if(this.incentiveCdFormCtrl.errors) {
        this.openDialog(this.globalConstants.invalid_fdic, "Warning!");
        return;
      } else {
        this.updateIncentiveCode();
      }
    }
    this.selectedAppGroupsData = [];
    this.selectedGroupId = [];   
    this.getSelectedMenus(this.appMenuItems);
    const policyCheckReqData = {
      "body": {
        "submitted_by" :this.loggedInUser.userId,
        "bearer_token" : this.accessToken,
        "sel_menus" : this.selectedAppGroupsData,
        "group_prefix": this.selectedAppData.groupPrefix
      },
      "user_uid": this.appAccessDataService.loginIdByUserType(this.currentUser, this.dataService.isPdrUser)
    }
    this.dataService.checkMenuRestrictionPolicy(policyCheckReqData).subscribe((response) => {
      let data = JSON.parse(JSON.stringify(response.body));
      if (data.decision == 'Allow') {
        this.updateGroupsToOkta(this.selectedGroupId, this.selectedAppData.groupPrefix);
      } else {
        this.openDialog("You are not permitted to grant one or more of these roles/permissions.", "Warning!")
      }
    },
    (error) => {
        console.log(error);
    });
  }

  getSelectedMenus(menuItems: AppMenuItem[]) {    
    menuItems.forEach(menu => {
      if (menu.menuType !== this.globalConstants.menu_type_div && menu.menuType !== this.globalConstants.menu_type_title) {
        if (menu.name !== this.globalConstants.no_access && menu.selectedOption) {
          this.selectedGroupId.push({ value: menu.groupId, viewValue: menu.group });
          this.selectedAppGroupsData.push({
            "sk": menu.sk,
            "pk": menu.pk,
            "group_id": menu.groupId
          });
        }
      }
      if (menu.children && menu.children.length > 0) {
        this.getSelectedMenus(menu.children);
      }
    })
  }

  updateGroupsToOkta(selectedGroupId, groupPrefix){
    const selReqBody = {
      "userId":this.userOktaId,
      "loginId": this.currentUser.profile.login,
      "groupId" : selectedGroupId,
      "groupPrefix" :groupPrefix,
      "submitted_by" :this.loggedInUser.userId,
      "bearer_token" : this.accessToken
    }
    this.dataService.changeGroupAccess(selReqBody, this.accessToken).subscribe(
      (response) => {
        console.log("Access group has been added to okta", response);
        let successMessage = "Menu access provided successfully.";
        this.openDialog(successMessage,"Success");
      },
      (error) => {
        let failMeassage = 'Menu Access Failed!';
        this.openDialog(failMeassage,"Warning")
        console.log(error)
      });
  }

  updateIncentiveCode() {
    let editUser: User = new User();
    let profile: Profile = new Profile();
    editUser.profile = profile;
    profile.employeeTypeIDM = this.currentUser.profile.employeeTypeIDM;
    profile.beginDate = formatDate(this.currentUser.profile.beginDate.split('T')[0], this.globalConstants.date_time_format, this.globalConstants.locale);
    profile.frontDeskIncentives = this.incentiveCdFormCtrl.value;
    profile.propID = this.currentUser.profile.propID;
    profile.uid = this.currentUser.profile.uid;
    let reqBody: CreateUpdateRequest = new CreateUpdateRequest();
    reqBody.user = editUser;
    reqBody.submitted_by = this.loggedInUser.userId;
    reqBody.bearer_token = this.accessToken;
    reqBody.searchUser = this.appAccessDataService.loginIdByUserType(this.currentUser, this.dataService.isPdrUser);
    reqBody.updateGroups = false;
    const body = {
      "Detail": JSON.stringify(reqBody),
      "Source": "updateUserEvent"
    }
    this.dataService.postUserDetails(body, this.accessToken).then((data: String) => {
    },
      (error) => {
        console.log(error);
        let failMeassage = 'Unable to update Frontdesk incentive Id.';
        this.openDialog(failMeassage, "Warning")
      });
  }

  onResetIncentiveCd() {
    this.getCurrentUser();
  }

  resetMenus() {
    this.clearMenuData();
    if(this.selectedAppData.appName === this.globalConstants.app_autoclerk) {
      this.resetIncentiveCode();
    }
    this.appAccessDataService.selectedAppDataSubject$.next(this.selectedAppData);
  }

  cancel() {
    if(this.parentModule === this.globalConstants.manage_user) {
      this.matTabGroup.selectedIndex = 1;
    } else {
      this.router.navigate(['pages/create-user']);
    }
  }

  clearMenus() {
    this.appAccessDataService.clearAllSelectedMenuData(this.appMenuItems);
    if(this.selectedAppData.appName === this.globalConstants.app_autoclerk) {
      this.incentiveCdFormCtrl.setValue("");
    }
  }

  clearMenuData() {
    this.selectedGroupId = [];
    this.selectedAppGroupsData = [];
    this.appMenuItems = [];
  }

  ngOnDestroy() {
    this.clearMenuData();
    if(this.subscription){
      this.subscription.unsubscribe();
    }
  }

}
