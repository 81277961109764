import { Component } from '@angular/core';


@Component({
  selector: 'app-pages',
  template: `<div >
  <app-header></app-header>
  <div style="margin-top:30px;"></div>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>`
})
export class PagesComponent {
  title = 'custom-idm';
  
   constructor() {}
   ngOnInit() {
   }
}
