import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TemplateServiceService } from 'src/app/template-service.service';

@Component({
  selector: 'app-manage-edit-template-header',
  templateUrl: './manage-edit-template-header.component.html',
  styleUrls: ['./manage-edit-template-header.component.css']
})
export class ManageEditTemplateHeaderComponent implements OnInit {
  tabIndex: number;

  constructor(public router: Router, public templateService: TemplateServiceService) {}

  ngOnInit(): void {
    if (localStorage.getItem('editTempScreen') == 'Y'){
      this.tabIndex = 0;
    }
    if (localStorage.getItem('editTempScreen') == 'N'){
      this.tabIndex = 1;
    }
    this.templateService.valueOfTabIndex.subscribe((value)=>{
      this.tabIndex = value;
    })
  }

  onTabChange(event){
    if (this.tabIndex == 0){
      this.router.navigate(['pages/manageTemplate/editTemplate']);
    }
    if (this.tabIndex ==1){
      this.router.navigate(['pages/manageTemplate/editMemberwebTemplate']);
    }
  }

}
