import { Injectable } from '@angular/core';
import {User} from './model/user';
import { DataService } from './data.service';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { environment } from './../environments/environment';
@Injectable()
export class UserAuthService {
  isAuthenticated: boolean = false;
  user: User;
  userName: string;
  accessToken: string;
    constructor(public oktaAuth: OktaAuthService,private dataService: DataService, private router: Router) { }
    ngOnit(){
        
    }
    async userInfo() {
      this.dataService.accessToken = await this.oktaAuth.getAccessToken();
      this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean)  => this.isAuthenticated = isAuthenticated
    );
     
    if(!this.isAuthenticated){
      this.dataService.firstLogin = true;
      this.oktaAuth.loginRedirect();
    } else {
      try {
        const userClaims = await this.oktaAuth.getUser();
        this.dataService.firstLogin = false;
      localStorage.setItem("userClaims",JSON.stringify(userClaims));
      if ( !(userClaims.userRole === "EMPLOYEE") && !(userClaims.challengeQuestion1 && userClaims.challengeQuestion2 && userClaims.challengeQuestion3) ) {
        this.dataService.firstLogin = true;
        this.router.navigate(['pages/update-profile']);
      }
      this.userName = userClaims.userId;
      this.dataService.userEmail = this.userName;
      return userClaims;
      } catch (err) {
        this.oktaAuth.logout();
        this.router.navigateByUrl(environment.navigateSignOut);
      }
    }
   }
}