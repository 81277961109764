import { Component, OnInit } from '@angular/core';
import { Location } from 'aws-sdk/clients/ec2';
import { Router } from '@angular/router';
import { GlobalConstants } from '../global-constants';


@Component({
  selector: 'app-global-error-component',
  templateUrl: './global-error-component.component.html',
  styleUrls: ['./global-error-component.component.css']
})
export class GlobalErrorComponent implements OnInit {

  constructor(private router: Router,public globalConstants:GlobalConstants) {
  }
  ngOnInit(): void {
  }
  goBack() {
    this.router.navigate(['pages/home']);
  }

}
