<mat-sidenav-content style="margin-left: 185px; clear: both;">
    <div class="two_col_right_save_cancel">
        <div class="warning-box">
            {{selectedAppData.description}}
        </div>
    </div>
    <div class="title-text">{{selectedAppData.appName}}</div><br>
    <div *ngIf="selectedAppData.appName === globalConstants.app_autoclerk" class="flex-container">
        <mat-form-field class="flex-card-col-3">
            <mat-label style="font-weight: 700;">Front Desk Incentive Code</mat-label>
            <input matInput  maxlength="3" [formControl]="incentiveCdFormCtrl" placeholder="Please enter 3 digit Front Desk Incentive ID">
            <mat-error *ngIf="incentiveCdFormCtrl.hasError('pattern')">{{globalConstants.invalid_memberId}}</mat-error>
        </mat-form-field>
    </div>
    <div [ngClass]="appMenuItems && appMenuItems.length > 3 ? 'masonry' : 'flex-container'" *ngIf="!isMemberWeb">
        <mat-card [ngClass]="appMenuItems.length > 3 ? 'item' : 'flex-card'" *ngFor="let menuItem of appMenuItems; let i = index">
            <multi-app-menu [menuItem]="menuItem"></multi-app-menu>
        </mat-card>
    </div>
    <div [ngClass]="mwMenuItems && mwMenuItems.length > 3 ? 'masonry' : 'flex-container'" *ngIf="isMemberWeb">
        <mat-card [ngClass]="mwMenuItems.length > 3 ? 'item' : 'flex-card'" *ngFor="let menuItem of mwMenuItems; let i = index">
            <mat-card-title><b>{{menuItem.name}}</b></mat-card-title>
            <mat-card-content>
                <mat-list>
                    <mat-list-item>
                        <mat-checkbox [(ngModel)]="master_checked[i]"
                            (change)="onMasterChange(menuItem.name, i)" [disabled]="disableMemberWebMenus">
                            Assign All {{menuItem.name}}
                        </mat-checkbox>
                    </mat-list-item>
                    <mat-list-item class="pad" *ngFor="let subItem of menuItem['sub-menu-list']">
                        <mat-checkbox [(ngModel)]="subItem.checked" [disabled]="disableMemberWebMenus"
                            [labelPosition]="subItem.labelPosition" (change)="onListChange(menuItem.name, i)">
                            {{ subItem.name }}</mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
        </mat-card>
    </div>
</mat-sidenav-content>