import { Profile } from "./profile";

export class User{
    id: string;
    status: string;
    created: string;
    lastLogin: string;
    passwordChanged: string;
    lastUpdated: string;   
    statusChanged: string;
    profile: Profile;
    groupIds: string[];
    templateRole: string;
}