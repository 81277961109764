import { Profile } from "./profile";

export class User {
    id: string;
    status: string;
    created: string;
    lastLogin: string;
    passwordChanged: string;
    lastUpdated: string;   
    statusChanged: string;
    profile: Profile;
    groupIds: string[];
    templateRole: string;
}

export class UserClaims {
    sub: string;
    name: string;
    locale: string;
    preferred_username: string;
    given_name: string;
    family_name: string;
    zoneinfo: string;
    updated_at: 1712860249;
    userManagement: boolean;
    lastName: string;
    login_ID: string;
    firstName: string;
    challengeQuestion3: string;
    challengeQuestion2: string;
    challengeQuestion1: string;
    idm_role: string[];
    idm_scope: string[];
    userRole: string;
    userId: string;
    properties: string[];
    affilaiteOffice: string;
}