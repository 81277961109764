<mat-card-content>
    <mat-list class="menu-list">
        <mat-list-item>
            <mat-list class="pad">
                <mat-list-item *ngIf="menuItem.menuType === globalConstants.menu_type_title"><mat-card-title><b>{{ menuItem.name }}</b></mat-card-title></mat-list-item>
                <mat-list-item *ngIf="menuItem.menuType === globalConstants.menu_type_div"><b>{{ menuItem.name }}</b></mat-list-item>
                <mat-list-item *ngIf="menuItem.menuType === globalConstants.menu_type_radio">
                    <mat-radio-button name="{{menuItem.parent}}" (change)="onSelectionChange(menuItem)" [checked]="menuItem.selectedOption === menuItem.name" [value]="menuItem.name">
                        {{menuItem.name}}
                    </mat-radio-button>
                </mat-list-item>
                <mat-list-item *ngIf="menuItem.menuType === globalConstants.menu_type_checkbox">
                    <mat-checkbox [(ngModel)]="menuItem.selectedOption">
                        {{ menuItem.name }}
                    </mat-checkbox>
                </mat-list-item>
                <mat-list-item *ngIf="menuItem.children?.length">
                    <multi-app-menu *ngFor="let child of menuItem.children" [menuItem]="child"></multi-app-menu>
                </mat-list-item>
            </mat-list>
        </mat-list-item>
    </mat-list>
</mat-card-content>