<div class="content">
  <form class="example-form" [formGroup]="passwordForm" *ngIf="passwordForm">
    <mat-form-field class="example-full-width">
      <mat-label>{{globalConstants.old_psw}}</mat-label>
      <input [type]="hide1 ? 'password' : 'text'" matInput formControlName="oldPassword">
      <mat-icon matSuffix (click)="hide1 = !hide1">{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error class="error-text" *ngIf="passwordForm.get('oldPassword').hasError('required')">
        {{globalConstants.old_psw_err}}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>{{globalConstants.new_psw}}</mat-label>
      <input [type]="hide2 ? 'password' : 'text'" matInput formControlName="newPassword">
      <mat-icon matSuffix (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error class="error-text" *ngIf="passwordForm.hasError('required', 'newPassword')">
        {{globalConstants.new_psw_err}}
      </mat-error>
      <mat-error class="error-text" *ngIf="passwordForm.get('newPassword').hasError('pattern')">
        {{globalConstants.new_psw_err2}}
      </mat-error>
      <!-- <mat-error *ngIf="showError">
            Password should not contain firstname or lastname or username
          </mat-error> -->
    </mat-form-field>
    <div class="error-padding" *ngIf="!passwordForm.hasError('required', 'newPassword') && passwordForm.get('newPassword').hasError('pattern')"></div>

    <mat-form-field class="example-full-width">
      <mat-label>{{globalConstants.cnfrm_psw}}</mat-label>
      <input [type]="hide3 ? 'password' : 'text'" matInput formControlName="confirmPassword"
        [errorStateMatcher]="errorMatcher">
      <mat-icon matSuffix (click)="hide3 = !hide3">{{hide3 ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error class="error-text" *ngIf="passwordForm.hasError('passwordsDoNotMatch')">
        {{globalConstants.cnfrm_psw_err}}
      </mat-error>
    </mat-form-field>
    <div>
      <button class="save-button" mat-raised-button [disabled]="passwordForm.invalid"
        (click)="confirm()">{{globalConstants.change_psw}}</button>
      <button class="save-button cancel-btn" mat-raised-button (click)="Cancel()">{{globalConstants.cancel}}</button>
    </div>
  </form>
</div>