import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppMenuItem } from 'src/app/model/multi-app-access.model';
import { GlobalConstants } from 'src/app/pages/global-constants';

@Component({
  selector: 'multi-app-menu',
  templateUrl: './multi-app-menu.component.html',
  styleUrls: ['./multi-app-menu.component.css']
})
export class MultiAppMenuComponent implements OnInit {

  @Input() menuItem: AppMenuItem;
  @Output() radioSelectionChange = new EventEmitter<AppMenuItem>();
  @Output() checkboxSelectionChange = new EventEmitter<AppMenuItem>();

  constructor(
    public globalConstants: GlobalConstants){

  }

  ngOnInit(): void {
  }

  onRadioSelectionChange(selectedItem: AppMenuItem) {
    this.radioSelectionChange.emit(selectedItem);
  }

  onCheckboxSelectionChange(selectedItem: AppMenuItem) {
    this.checkboxSelectionChange.emit(selectedItem);
  }
  
}
