<body>
      <div class="content">
        <div class="left-fields">

          <h1 class="top-italic">{{globalConstants.template_info}}</h1>
  
          <mat-form-field class="example-full-width" >
            <mat-label color="grey">Template Name</mat-label>
            <textarea style="height: 20px;" [formControl]="templateName" matInput (keyup)="displayTemplate($event)" ></textarea>
            <mat-hint>Please enter at least 5 characters but not more than 30 characters</mat-hint>
            <mat-error *ngIf="templateName.hasError('required')">Please enter a template name
            </mat-error>
            <mat-error *ngIf="templateName.hasError('pattern')">Allowed characters are a-z, A-Z, 0-9, '@', '$', '^', '&', '_', comma and space. Please enter at least 5 characters but not more than 30 characters.
            </mat-error>
          </mat-form-field>
          <br><br>
          <h1 class="top-italic usertype">{{globalConstants.usr_info}}</h1>
  
  
          <mat-form-field *ngIf="isMultipleUsers" class="example-full-width">
            <mat-label>User Type</mat-label>
            <mat-select [formControl]="userType" [(value)]="selectedUserType"
              (selectionChange)="onCategoryChange($event)">
              <mat-option *ngFor="let usertype of usertypes" [value]="usertype.value">
                {{usertype.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="userType.hasError('required')">Please select a user type
            </mat-error>
          </mat-form-field> 
          
          <mat-form-field *ngIf="isSingleUser" class="example-full-width">
            <mat-label>User Type</mat-label>
            <input matInput required [formControl]="userType" [(ngModel)]="selectedUserType" readonly>
            <mat-error *ngIf="userType.hasError('required')">Please select a user type
            </mat-error>
          </mat-form-field> <br> <br>
  
          <div *ngIf=isAffiliateUser>
            <mat-form-field class="affilOff, example-full-width">
                <mat-label>Affiliate Office</mat-label>
                <mat-select [formControl]="affiliateOfficeControl" disableOptionCentering [(value)]="selectedAffiliate"
                (selectionChange)="onAffSelect()"
                    placeholder="Select" required>
                    <mat-option *ngFor="let affiliateOffice of affiliateOffices"
                        [value]="affiliateOffice.value">
                        {{ affiliateOffice.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="affiliateOfficeControl.hasError('required')">
                    Please choose an Affiliate Office.</mat-error>
            </mat-form-field><br><br>
        </div>
  
          <!-- <div class="example-full-width" appearance="fill" *ngIf="selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'AFFILIATE_STAFF'">
            <mat-checkbox [(ngModel)]="isUserMgmt" [formControl]="userMgmt">Allow User Management
            </mat-checkbox>
            <br>
          </div> -->

          <mat-form-field *ngIf="selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'AFFILIATE_STAFF'" class="example-full-width">
            <mat-label>User Role</mat-label>
            <mat-select [formControl]="templateRoleControl" [(value)]="selectedTemplateRole"
              (selectionChange)="onTemplateRoleChange($event)">
              <mat-option *ngFor="let templateRole of templateRoles" [value]="templateRole.value" matTooltip={{templateRole.tooltip}} matTooltipPosition="right" >
                {{templateRole.viewValue}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="templateRoleControl.hasError('required')">Please select a User Role
            </mat-error>
          </mat-form-field>
          <br><br><br>
          
          <table class="example-full-width" >
            <tr>
              <th>Created By</th>
              <td>{{tempCreatedBy}}</td>
              
            </tr>
            <tr>
              <th>Creation Date</th>
              <td>{{displayCreatedDate}}</td>
              
            </tr>
            <tr> 
              <th>Last Modified By</th>
              <td></td>
              
            </tr>
            <tr>
              <th>Last Modified Date</th>
              <td></td>
              
            </tr>
          </table>
         
         

  
        </div>
  
        <div class="right-fields">
          <div *ngIf="(loggedInUser != 'EMPLOYEE') && isPropertyUser" class="user-groups">
            <p>{{globalConstants.props}} *</p>
            <ng-multiselect-dropdown class="font-15" [placeholder]="'Click here to select Properties'"
                [settings]="dropdownSettings" [(data)]="sortedDropDownList" [formControl]="propControl"
                [(ngModel)]="selectedproperties" required>
            </ng-multiselect-dropdown>
            <p *ngIf="propControl.hasError('required') && propControl.touched" class="prop-error">
                {{globalConstants.props_err1}}
            </p>
            <p *ngIf="invalidPropControl.hasError('invalidProp') && invalidPropControl.touched"
            class="prop-error">{{invalidPropMessage}}
            </p>
            <p *ngIf="inactivePropControl.hasError('inactiveProp') && inactivePropControl.touched"
            class="prop-error">{{inactivePropMessage}}
            </p>  
        </div>
          <div *ngIf="(isPropertyUser && loggedInUser == 'EMPLOYEE')">
          <!-- <h1 style="margin-left: 3%;" class="top-italic">Property *</h1> -->

          <div style="padding-left:5%" class="example-full-width">
            <p>Property ID</p>
            <textarea class="txtarea" [(ngModel)]="propertyselected"  [formControl]="propertyID" matInput placeholder="00000"></textarea>
            <p *ngIf="propertyID.hasError('required') && propertyID.touched" class="prop-error" >Please enter a property ID
            </p>
            <p *ngIf="propertyID.hasError('pattern') && propertyID.touched" class="prop-error" >Allowed characters are only numbers and comma. Please enter 5-digit numerical property IDs separated by comma
            </p>
            <p *ngIf="propertyID.hasError('invalidProp') && propertyID.touched"
            class="prop-error">{{invalidPropMessage}}
            </p>
            <p *ngIf="propertyID.hasError('inactiveProp') && propertyID.touched"
            class="prop-error">{{inactivePropMessage}}
            </p>
          </div> 
          </div>
          <div *ngIf="isAffiliateUser">
            <h1 style="margin-left: 3%;" class="top-italic">Affiliate Code</h1>

            <mat-form-field style="padding-left:5%" class="example-full-width">
              <mat-label>Affiliate Code</mat-label>
              <textarea style="height: 20px;" [(ngModel)]="affiliatecodeselected"  [formControl]="affiliateCodeControl" matInput placeholder="XXXXX" readonly></textarea> 
            </mat-form-field> 
          </div>
          
  
          
          <div class="user-groups" *ngIf="(selectedUserType != 'PROPERTY_STAFF' && loggedInUser == 'EMPLOYEE')">
            <h1 style="margin-left: -2%;" class="top-italic">{{globalConstants.user_grp}}</h1>
            <ng-multiselect-dropdown [placeholder]="'click here to select user groups'" [settings]="dropdownSettings"
              [data]="dropdownList" [(ngModel)]="selectedItems" [formControl]="userGroupsControl">
            </ng-multiselect-dropdown>
    
          </div>
          
            <br>
            <br>
            
            <div class="pad-top">
          
      
              <button class="save-button bg-color-white" mat-raised-button color="primary" (click)="resetControls()">Clear</button>
              <button class="save-button" mat-raised-button color="primary" (click)="submit()">Next</button>
              
            </div>
            
        </div>
      </div>
</body>
    
  
  
 
