import { Component, OnInit, Inject } from '@angular/core';
import { QuestionAnswersComponent } from '../question-answers/question-answers.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';

export interface DialogData {
  description: String,
  title: String
}

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<QuestionAnswersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public dialog: MatDialog, private route: ActivatedRoute,  private router: Router, private dataService: DataService) {}

  ngOnInit(): void {
  }

  onClick() {
    this.dialogRef.close();
    if (localStorage.getItem("isRequestFromEditScreen") === "Y") {
      this.router.navigate(['pages/search-user'])
    } else if (localStorage.getItem("isRequestFromEditScreen") === "N") {
      this.router.navigate(['pages/create-user']) 
    } else if (localStorage.getItem("isRequestFromCreateScreen") === "Y") {
      this.router.navigate(['pages/app-access']);
    }
  }
}
