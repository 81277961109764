import { Injectable, EventEmitter } from '@angular/core';
import { subject } from 'aws-sdk/clients/sns';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateServiceService {
  isDispCreateTemp: Subject<boolean> = new Subject();
  isDispEditTemp: Subject<boolean> = new Subject();
  isDispCreateTemplate: boolean = true;
  isDispEditTemplate: boolean = true;
  valueOfTabIndex: EventEmitter<any> = new EventEmitter();
  invokeEvent: EventEmitter<any> = new EventEmitter();
  saveCreateTemplate: EventEmitter<boolean> = new EventEmitter();
  saveCreateMembTemplate: EventEmitter<boolean> = new EventEmitter();
  isNextButtonClicked: EventEmitter<boolean> = new EventEmitter();
  saveEditTemplate: EventEmitter<boolean> = new EventEmitter();
  saveEditMembTemplate: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  getIsDispEditTemp(state: boolean){
    this.isDispEditTemp.next(state);
  }

  getIsDispCreateTemp(state: boolean){
    this.isDispCreateTemp.next(state);
  }

  getValueOfTabIndex(value){
    this.valueOfTabIndex.emit(value);
  }

  public getInvokeEvent(value: any){
    this.invokeEvent.emit(value);
  }

  public getSaveCreateTemplate(value: any){
    this.saveCreateTemplate.emit(value);
  }

  public getSaveCreateMembTemplate(value: any){
    this.saveCreateMembTemplate.emit(value);
  }

  public getIsNextButtonClicked(value: any){
    this.isNextButtonClicked.emit(value);
  }

  public getSaveEditTemplate(value: any){
    this.saveEditTemplate.emit(value);
  }

  public getSaveEditMembTemplate(value: any){
    this.saveEditMembTemplate.emit(value);
  }
}
