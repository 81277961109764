<mat-tab-group *ngIf="memberwebMenuItems">
    <mat-tab label="Memberweb">
        <div class="masonry">
            <div class="item" *ngFor="let menuItem of memberwebMenuItems; let i = index">
                <mat-card>
                    <mat-card-title><b>{{menuItem.name}}</b></mat-card-title>
                    <mat-card-content>
                        <mat-list>
                            <mat-list-item>
                                <mat-checkbox [(ngModel)]="master_checked[i]"
                                    (change)="master_change(menuItem.name, i)" [disabled]="disableCheckboxes">
                                    Assign All {{menuItem.name}}
                                </mat-checkbox>
                            </mat-list-item>
                            <mat-list-item class="pad" *ngFor="let subItem of menuItem['sub-menu-list']">
                                <mat-checkbox [(ngModel)]="subItem.checked" [disabled]="disableCheckboxes"
                                    [labelPosition]="subItem.labelPosition" (change)="list_change(menuItem.name, i)">
                                    {{ subItem.name }}</mat-checkbox>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="two_col_right_save_cancel">
            <button class="save-button bg-color-white" mat-raised-button (click)="Clear()">Clear</button>
            <button class="save-button bg-color-white" mat-raised-button
                (click)="cancel()">Cancel</button>
            <button class="save-button" mat-raised-button
                (click)="updateMemberwebPrivilages()">Save</button>
        </div>
    </mat-tab>
</mat-tab-group>