import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { User } from 'src/app/model/user';
import { Profile } from 'src/app/model/profile';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Affiliate } from 'src/app/model/affiliate';
import { VendorCodes } from '../../model/vendorCodes';
import { int } from 'aws-sdk/clients/datapipeline';
import { UsergroupsSerivice } from 'src/app/usergroups.service';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { SubMenuList } from 'src/app/model/subMenuList';
import { memberwebMenus } from 'src/app/model/memberwebMenu';
import { Memberweb } from 'src/app/model/memeberweb';
import * as cloneDeep from 'lodash/cloneDeep';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { formatDate } from '@angular/common';
import { UserGroups } from 'src/app/model/userGroups';
import { GlobalConstants } from '../global-constants';
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { CreateUpdateRequest } from 'src/app/model/createUpdateRequest';
import { ResortSummary } from 'src/app/model/resortSummary';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { ThreeButtonPopupComponent } from '../three-button-popup/three-button-popup.component';
import { SearchModel } from 'src/app/model/searchModel';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { GroupsTemplate } from 'src/app/model/groupsTemplate';
import { CreateTemplateGroupRequest } from 'src/app/model/createTemplateGroupRequest';
import { MultiAppMenus } from 'src/app/model/multi-app-access.model';
import { HomeAppMenus } from 'src/app/model/homeAppMenus';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { TemplateSearchRequest } from 'src/app/model/idm-models';
import { AppAccessDataService } from '../shared/application-menu-access/app-access-data.service';
const moment = _moment;

const MY_FORMATS = {
  parse: {
    dateInput: 'MMM DD yyyy',
  },
  display: {
    dateInput: 'MMM DD yyyy',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface Items {
  value: string;
  viewValue: string;
}
export interface TemplateRoleItems {
  value: string;
  viewValue: string;
  tooltip: string;
}

export interface subItem {
  id: number;
  name: string;
  checked: boolean;
}

export interface Templates {
  viewValue: string;
  value: TemplateProfile;
}
export interface appDetails {
  value: string;
  viewValue: string;
  menuType: string;
  appUrl: string;
  description: string;
  group_prefix: string;
}
export interface groups {
  id: string;
  name: string;
  desc: string;
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class EditUserComponent implements OnInit {
  canShowAppaccessTab: boolean = false;
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  locale = 'en-US';
  todayDate: Date = new Date();
  inactiveDateMin: Date = new Date();
  selectedproperties = [];
  displayAtc: boolean = false;
  displayHomePmx: boolean;
  tempEditObject;
  properties: string[];
  dropdownList = [];
  groups: groups[] = [];
  showButtons = true;
  showHint: boolean = false;
  showUserId: boolean = false;
  //userInfo;
  isReactivated: boolean = false;
  inActiveDateMin = new Date();
  selectedItems = [];
  selectedUserType: string;
  dropdownSettings: IDropdownSettings;
  userIdcontrol: string = "";
  originalEmail: string;
  isEmailExist: boolean = true;
  login: string;
  employeecontrol: string;
  firstname = "";
  lastname = "";
  disableCheckboxes: boolean = false;
  istempselected: boolean = false;
  propertyTempControl = new FormControl('');
  firstNameControl = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9][a-zA-Z0-9' _.-]*$")]);
  lastNameControl = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9][a-zA-Z0-9' _.-]*$")]);
  initials = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-z]+([ ][a-zA-Z]+)*$")]);
  menus;
  email = new FormControl('', [Validators.pattern(this.globalConstants.email_pattern)]);
  userControl = new FormControl('', [Validators.required]);
  selectFormControl = new FormControl('', Validators.required);
  currentUserGroups: string = '';
  propertyId: string = '';
  isUserMgmt: boolean = false;
  isDelete: boolean = false;
  selectedAffiliate: string;
  selectedVendor: string;
  error = "Login Name is not valid";
  errorMessage;
  errorMsgUnlockUser;
  errorTF: boolean;
  revenue_mgmt_checkbox_list = [];
  usertypes: Items[] = [];
  affiliateOfficeControl = new FormControl('', [Validators.required]);
  vendorControl = new FormControl('', [Validators.required]);
  propControl = new FormControl('', [Validators.required]);
  propertyControl = new FormControl('', [Validators.required, Validators.pattern("^[0-9]{5}([,][0-9]{5})*$")]);
  affiliateOffices: Items[] = [];
  vendors: Map<string, string> = new Map();
  activeDate: string;
  inActiveDate = new FormControl('');
  loggedInUserType: any;
  accessToken;
  successMessage;
  isUserLocked: boolean = false;
  lockStatus: string = '';
  master_checked: boolean[] = new Array();
  editObj;
  sortedDropDownList = [];
  dropdownSettingsID: IDropdownSettings;
  selectedPropTemp: any;
  affiliateUserPropList: string[];
  templateIDControl = new FormControl('');
  tempIDtxtboxControl = new FormControl('', [Validators.pattern("^[a-zA-Z0-9_]+$")]);
  dropDownListProperties: any[] = [];
  selectedID: string[] = [];
  searchModelArray: any[] = [];
  searchResults: any[] = [];
  dropDownListTemplate: Templates[] = [];
  propStaffFlag: boolean = false;
  userMngmtControl = new FormControl();
  invalidPropControl = new FormControl('', [Validators.required]);
  inactivePropControl = new FormControl('', [Validators.required]);
  isUserActive: boolean = true;
  isUserInactive: boolean = false;
  userStatus: string = '';
  isUserDeprovisioned: boolean = false;
  isUserActivePending: boolean = false;
  isUserProvisioned: boolean = false;

  memberwebMenuItems: MemberwebMenuItems[];
  menuitems: Memberweb = new Memberweb();
  tempmenuitems: Memberweb = new Memberweb();
  tempMenu;
  isPDRuser: boolean = false;
  message: string = '';
  isAffiliateUser: boolean = false;
  isVendorUser: boolean = false;
  isPropertyUser: boolean = false;
  activeDateTemp;
  selDate: string;
  selDay: string;
  selMonth: string;
  selYear: string;
  invalidPropertyMessage: string;
  invalidPropMessage: string;
  inactivePropMessage: string;
  isPropInactive: boolean = false;
  isPropInvalid: boolean = false;
  isSuperEmployee: boolean;
  isRecovery: boolean = false;
  templateRoleControl = new FormControl('', [Validators.required]);
  templatePermissions: TemplateRoleItems[] = [];
  templateRole: string;
  initialRole: string;
  loggedInTemplatePermission: string = '';
  userGroupsControl = new FormControl('');
  deleteGroupID: string;
  isFirstCallSuccessfull: boolean;
  istemplatePermissionSelected: boolean = false;
  appDetails: appDetails[] = [];
  appMenuItems: MultiAppMenus[] = [];
  homemenuItems: HomeAppMenus[] = [];
  appName: string;
  DisplayName: string;
  Discription: string;
  group_prefix: string;
  menuType: string;
  qkMenuItem: string;
  atcRole: string;
  sysDate: string;
  isRadio: boolean = false;
  isNestedCheckbox: boolean = false;
  isSubCheckbox: boolean = false;
  selectedAll: any;
  appMenuItem: boolean = false;
  appLabel: string;
  highlightedButton: string;
  navName: string;
  permissionList: MultiAppMenus[] = [];
  rolesList: MultiAppMenus[] = [];
  isRadioCheckbox: boolean = false;
  currentUser: User;
  appAccessTabEnabled: boolean = false;
  isNetworkRestricted: boolean = false;

  constructor(
    public dialog: MatDialog, 
    public oktaAuth: OktaAuthService, 
    private dataService: DataService, 
    private router: Router, 
    private usergroups: UsergroupsSerivice, 
    public globalConstants: GlobalConstants, 
    private globalErrorHandlerService: GlobalErrorHandlerService, 
    private datePipe: DatePipe,
    private appAccessDataService: AppAccessDataService) {
      let date = new Date();
      this.inActiveDateMin = new Date(date.setDate(date.getDate() + 1));
      this.setAffiliateGroup(true);
  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    console.log(this.currentUser)
    var date = new Date();
    this.dataService.isSetFromTemplate = false;
    this.sysDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    if (this.dataService.userclaims.idm_role[0] == 'OH-IDM-Role User Management - Restricted') {
      this.disableFields();
    }
    this.dropdownList = [
      { item_id: 'MPRD_GOV', item_text: 'Allow Governors content access' },
      { item_id: 'MPRD_IC', item_text: 'Allow International content access' },
      { item_id: 'MP_SOFT', item_text: 'BW Premier Collection' },
      { item_id: 'MPRD_ADMIN', item_text: 'Member portal Admin ( Developers) Administrator / Help Desk Admin' },
      { item_id: 'MPRD_HOTELPORTAL', item_text: 'Sure Stay Brand Group' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 30,
      allowSearchFilter: true
    };
    this.dropdownSettingsID = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true

    };
  }

  async setAffiliateGroup(flag) {
    this.dataService.isPdrUser = false;
    let invalidProps: string[] = [];
    let inactiveProps: string[] = [];
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.dataService.userclaims = JSON.parse(localStorage.getItem("userClaims"))
    this.editObj = JSON.parse(localStorage.getItem("editObject"));
    this.currentUser = this.editObj;
    this.isPropertyUser = this.editObj.profile.employeeTypeIDM.includes("PROPERTY");
    this.dataService.groupsTemplate = new GroupsTemplate();
    this.dataService.groupsTemplate.user = this.editObj.id;
    this.dataService.groupsTemplate.group = null;
    this.dataService.groupsTemplate.delGroup = null;
    let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
    createTemplateGroupRequest.group = this.dataService.groupsTemplate;
    createTemplateGroupRequest.submitted_by = this.dataService.userclaims.userId;
    createTemplateGroupRequest.bearer_token = this.accessToken;
    this.usertypes = [];
    this.templatePermissions = [];
    this.affiliateOffices = [];

    const body = {
      "Detail": JSON.stringify(createTemplateGroupRequest),
      "Source": "updateUserEvent"
    }
    await this.dataService.templatesRoles(body, this.accessToken).then((data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].id == environment.user_roles.admin_role) {
          this.templateRole = environment.user_roles.admin_role;
          this.deleteGroupID = environment.user_roles.admin_role;
          this.templateRoleControl.setValue(environment.user_roles.admin_role);
        }
        if (data[i].id == environment.user_roles.power_role) {
          this.templateRole = environment.user_roles.power_role;
          this.deleteGroupID = environment.user_roles.power_role;
          this.templateRoleControl.setValue(environment.user_roles.power_role);
        }
        if (data[i].id == environment.user_roles.restricted_role) {
          this.templateRole = environment.user_roles.restricted_role;
          this.deleteGroupID = environment.user_roles.restricted_role;
          this.templateRoleControl.setValue(environment.user_roles.restricted_role);
        }
        if (data[i].id == environment.user_roles.no_user_mgnt) {
          this.templateRole = environment.user_roles.no_user_mgnt;
          this.deleteGroupID = environment.user_roles.no_user_mgnt;
          this.templateRoleControl.setValue(environment.user_roles.no_user_mgnt);
        }
        if (data[i].id == environment.network_restriction_group){
          this.isNetworkRestricted = true;
        }
      }
    }, (error) => {
      console.log(error);
    })
    console.log(this.templateRole);
    this.initialRole = this.templateRole;
    this.userStatus = this.editObj.status;
    if (this.editObj.status === "DEPROVISIONED") {
      this.isUserDeprovisioned = true;
    }
    if (this.editObj.status === "ACTIVE" || this.editObj.status === "PROVISIONED" || this.editObj.status === "PASSWORD_EXPIRED" || this.editObj.status === "LOCKED_OUT" || this.editObj.status === "RECOVERY") {
      this.isUserActive = true;
      this.isUserInactive = false;
    } else {
      this.isUserActive = false;
      this.isUserInactive = true;
    }
    if (this.editObj.status === "PROVISIONED") {
      this.isUserActivePending = true;
      this.isUserInactive = false;
    }
    if (this.editObj.status === "ACTIVE" || this.editObj.status === "PASSWORD_EXPIRED" || this.editObj.status === "LOCKED_OUT" || this.editObj.status === "RECOVERY") {
      this.isUserProvisioned = true;
    } else {
      this.isUserProvisioned = false;
    }
    if ((this.editObj.profile.employeeTypeIDM == 'PROPERTY_ADMIN' || this.editObj.profile.employeeTypeIDM == 'PROPERTY_STAFF')
      && this.editObj.profile.propID) {
      try {
        let resortSummary: ResortSummary = JSON.parse(JSON.stringify(await this.dataService.getPropDetails(this.editObj.profile.propID, this.accessToken)));
        if (resortSummary) {
          if (resortSummary.activeProperties && resortSummary.activeProperties.length > 0) {
            resortSummary.activeProperties.forEach((item) => {
              if ((item.chainCode != null || typeof item.chainCode != 'undefined') && (item.chainCode.toUpperCase() == 'WW' || item.chainCode.toUpperCase() == 'DO')) {
                this.disableCheckboxes = false;
              }
            });
          }
          if (resortSummary.inactiveProperties.length > 0) {
            this.isPropInactive = true;
            resortSummary.inactiveProperties.forEach((item) => {
              inactiveProps.push(item);
            });
            this.inactivePropMessage = this.globalConstants.inactive_resorts + inactiveProps;
          }
          if (resortSummary.invalidProperties.length > 0) {
            this.isPropInvalid = true;
            resortSummary.invalidProperties.forEach((item) => {
              invalidProps.push(item);
            });
            this.invalidPropMessage = this.globalConstants.invalid_resorts + invalidProps;
          }
        }
      } catch (error) {
        console.log(error);
        this.globalErrorHandlerService.handleError(error);
      }
    }
    if (this.editObj.profile.employeeTypeIDM == 'VENDOR_ADMIN' || this.editObj.profile.employeeTypeIDM == 'VENDOR_STAFF') {
      this.canShowAppaccessTab = false;
      this.isVendorUser = true;
    } else {
      this.canShowAppaccessTab = true;
      this.isVendorUser = false;
    }
    this.tempEditObject = this.editObj;
    this.dataService.editServiceData = this.editObj;
    this.loggedInUserType = this.dataService.userclaims.userRole;
    this.loggedInTemplatePermission = this.dataService.userclaims.idm_role[0];
    if (this.loggedInTemplatePermission != 'OH-IDM-Role User Management - None') {
      this.isUserMgmt = true;
    }
    if (this.loggedInUserType == 'PROPERTY_ADMIN' || this.loggedInUserType == 'AFFILIATE_ADMIN' || this.loggedInUserType == 'REGIONAL_AFFILIATE_ADMIN' || this.loggedInUserType == 'EMPLOYEE' || this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Admin') {
      this.templatePermissions.push({ value: environment.user_roles.admin_role, viewValue: 'Admin User', tooltip: 'Can create and edit templates. Also, can create or edit a user with or without a template' },
        { value: environment.user_roles.power_role, viewValue: 'Power User', tooltip: 'Cannot create or edit templates. But can create or edit a user with or without a template' },
        { value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
        { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.' });
    }

    if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - PowerUser') {
      this.templatePermissions.push({ value: environment.user_roles.power_role, viewValue: 'Power User', tooltip: 'Cannot create or edit templates. But can create or edit a user with or without a template' },
        { value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
        { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.' });
    }

    if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
      this.templatePermissions.push({ value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
        { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.' });
    }

    if (this.editObj.status === "RECOVERY") {
      this.isRecovery = true;
    }
    if (this.selectedUserType == 'VENDOR_ADMIN' || this.selectedUserType == 'VENDOR_STAFF')
      this.selectedVendor = this.editObj.profile.vendorCode;

    if ((this.loggedInUserType == "EMPLOYEE" && flag && this.isVendorUser) || this.loggedInUserType == "VENDOR_ADMIN") {
      this.populateVendorCodesAndAssign();
    }
    if (this.editObj.status === "RECOVERY") {
      this.isRecovery = true;
    }
    if (this.loggedInUserType == "EMPLOYEE" && flag) {
      await this.dataService.getAffiliateOfficesforEmp(this.accessToken).then((data: String) => {
        let affilateOfficeCodes: Affiliate[] = JSON.parse(JSON.stringify(data));
        for (let i = 0; i < affilateOfficeCodes.length; i++) {
          this.affiliateOffices.push({ value: affilateOfficeCodes[i].code, viewValue: affilateOfficeCodes[i].code + "--" + affilateOfficeCodes[i].name })
        }
        this.sortAffiliates(this.affiliateOffices);
        this.setUserDetails(true);
      },
        (error) => {
          console.log(error);
          this.globalErrorHandlerService.handleError(error);
        });
    }
    else if (flag && this.loggedInUserType == 'AFFILIATE_ADMIN' || this.loggedInUserType == 'AFFILIATE_STAFF' || this.loggedInUserType == 'REGIONAL_AFFILIATE_ADMIN') {

      await this.dataService.getAffiliateOffices(this.dataService.userclaims.affiliateOffice, this.accessToken).then((data: string) => {
        let affilateOfficeCode: Affiliate = JSON.parse(JSON.stringify(data));
        if (affilateOfficeCode != null) {
          if (affilateOfficeCode.regionalAffiliate) {
            for (let i = 0; i < affilateOfficeCode.affiliates.length; i++) {
              this.affiliateOffices.push({ value: affilateOfficeCode.affiliates[i].code, viewValue: affilateOfficeCode.affiliates[i].code + "--" + affilateOfficeCode.affiliates[i].name })
            }
            this.sortAffiliates(this.affiliateOffices);
          } else {
            this.affiliateOffices.push({ value: affilateOfficeCode.code, viewValue: affilateOfficeCode.code + "--" + affilateOfficeCode.name })
          }
          if (typeof affilateOfficeCode.properties != 'undefined' && affilateOfficeCode.properties != null) {
            setTimeout(() => {
              for (let i = 0; i < affilateOfficeCode.properties.length; i++) {
                if (affilateOfficeCode.properties[i] != 'Multi_Property') {
                  this.sortedDropDownList.push(affilateOfficeCode.properties[i]);
                }
              }
            })
          }
          this.dropDownListProperties = affilateOfficeCode.properties.sort();
          this.affiliateUserPropList = affilateOfficeCode.properties.sort();
          if (this.dropDownListProperties.length > 1) {
            let count = 0;
            for (let i = 0; i < this.dropDownListProperties.length; i++) {
              if (this.dropDownListProperties[i] == 'Multi_Property') {
                count = count + 1;
              }
            }
            if (count == 0) {
              this.dropDownListProperties.push('Multi_Property');
            }
          }
          if (this.loggedInUserType == 'AFFILIATE_ADMIN' || this.loggedInUserType == 'REGIONAL_AFFILIATE_ADMIN') {
            for (let i = 0; i < this.affiliateOffices.length; i++) {
              this.dropDownListProperties.push(this.affiliateOffices[i].value);
            }
          }
          if (typeof affilateOfficeCode != 'undefined' && affilateOfficeCode.pdr == true) {
            this.isPDRuser = true;
            this.dataService.isPdrUser = true;
          }
        }
        this.setUserDetails(true);
      },
        (error) => {
          console.log(error);
          this.globalErrorHandlerService.handleError(error);
        });
    } else {
      this.setUserDetails(true);
    }
    if (this.editObj.profile.affiliateOffice) {
      await this.dataService.getAffiliateOffices(this.editObj.profile.affiliateOffice, this.accessToken).then((data: string) => {
        let affiliates: Affiliate = JSON.parse(JSON.stringify(data));
        if (typeof affiliates != 'undefined' && affiliates.pdr == true) {
          this.isPDRuser = true;
          this.dataService.isPdrUser = true;
        }
        this.selectedAffiliate = this.editObj.profile.affiliateOffice;
      },
        (error) => {
          console.log(error);
          this.globalErrorHandlerService.handleError(error);
        });
    }
    if (this.loggedInUserType == 'EMPLOYEE' && (this.isPDRuser || this.isAffiliateUser)) {
      this.showUserId = true;
    }
    if ((!this.isPDRuser && this.isAffiliateUser) || (this.isVendorUser)) {
      this.showHint = true;
    }
    if (typeof this.dataService.userclaims.properties != 'undefined' || this.dataService.userclaims.properties != null)
      for (let i = 0; i < this.dataService.userclaims.properties.length; i++) {
        if (this.dataService.userclaims.properties[i] != 'Multi_Property') {
          this.sortedDropDownList.push(this.dataService.userclaims.properties[i]);
        }
      }
    if (this.sortedDropDownList.length == 1) {
      this.selectedproperties = this.sortedDropDownList;
    }
  }

  emailValidator(email: string) {
    if (email && email.indexOf("@") != -1) {
      let [_, domain] = email.split("@");
      if ((!this.isPDRuser && this.isAffiliateUser) || this.isVendorUser) {
        if (this.globalConstants.restricted_email_domains.includes(domain.toLowerCase())) {
          this.email.setErrors({ invalid: true });
        }
      }

    }
  }

  onPropChange() {
    this.inactivePropControl.setErrors({ inactiveProp: false });
    this.invalidPropControl.setErrors({ invalidProp: false });
    this.isPropInactive = false;
    this.isPropInvalid = false;
  }

  addEvent(type, event: MatDatepickerInputEvent<Date>) {
    type = moment(event.value);
    this.selDate = type.format('DD');
    this.selDay = type.format('dddd');
    this.selMonth = type.format('MMMM');
    this.selYear = type.format('YYYY');
  }

  clearEndDate() {
    this.inActiveDate.setValue(null);
  }

  setUserDetails(flag) {
    this.editObj = JSON.parse(localStorage.getItem("editObject"));
    if (this.editObj.profile.employeeTypeIDM == 'PROPERTY_STAFF') {
      this.email.setValidators([Validators.pattern(this.globalConstants.email_pattern)]);
    } else {
      this.email.setValidators([Validators.required, Validators.pattern(this.globalConstants.email_pattern)]);
    }

    this.dataService.userclaims = JSON.parse(localStorage.getItem("userClaims"))
    this.userIdcontrol = this.editObj.profile.uid;
    this.originalEmail = this.editObj.profile.email;
    this.login = this.editObj.profile.login;
    this.employeecontrol = this.editObj.profile.employeeTypeIDM;
    this.userControl.setValue(this.editObj.profile.employeeTypeIDM);
    this.selectedUserType = this.editObj.profile.employeeTypeIDM;
    if (this.selectedUserType == 'AFFILIATE_STAFF' || this.selectedUserType == 'AFFILIATE_ADMIN' || this.selectedUserType == 'REGIONAL_AFFILIATE_ADMIN') {
      this.selectedAffiliate = this.editObj.profile.affiliateOffice;
    }
    this.lastname = this.editObj.profile.lastName;
    this.firstname = this.editObj.profile.firstName;
    this.selectedVendor = this.editObj.profile.vendorCode;
    this.dataService.getInvokeEvent(this.editObj.profile.firstName + ' ' + this.editObj.profile.lastName);
    this.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';
    if (null != this.editObj.profile.initials && this.editObj.profile.initials.length > 0)
      this.initials.setValue(this.editObj.profile.initials[0]);
    if (null != this.editObj.profile.groupsAssigned && flag) {
      this.currentUserGroups = "";
      for (let i = 0; i < this.editObj.profile.groupsAssigned.length; i++) {
        if (i >= 1)
          this.currentUserGroups = this.currentUserGroups + ',' + this.editObj.profile.groupsAssigned[i];
        else
          this.currentUserGroups = this.currentUserGroups + this.editObj.profile.groupsAssigned[i];
      }
    }

    if (null != this.editObj.profile.propID) {
      if (!flag)
        this.selectedproperties = []
      for (let i = 0; i < this.editObj.profile.propID.length; i++) {
        this.selectedproperties.push(this.editObj.profile.propID[i]);
      }
      this.selectedproperties = [...new Set(this.selectedproperties)];
    }

    if (null != this.editObj.profile.groupsAssigned) {
      let items: any = [];
      if (!flag)
        this.selectedItems = []
      for (let i = 0; i < this.editObj.profile.groupsAssigned.length; i++) {
        if ("MPRD_GOV" == this.editObj.profile.groupsAssigned[i]) {
          items.push({ item_id: 'MPRD_GOV', item_text: 'Allow Governors content access' });
        } if ("MPRD_IC" == this.editObj.profile.groupsAssigned[i]) {
          items.push({ item_id: 'MPRD_IC', item_text: 'Allow International content access' });
        } if ("MPRD_HOTELPORTAL" == this.editObj.profile.groupsAssigned[i]) {
          items.push({ item_id: 'MPRD_HOTELPORTAL', item_text: 'Sure Stay Brand Group' });
        } if ("MPRD_ADMIN" == this.editObj.profile.groupsAssigned[i]) {
          items.push({ item_id: 'MPRD_ADMIN', item_text: 'Member portal Admin ( Developers) Administrator / Help Desk Admin' });
        }
        if ("MP_SOFT" == this.editObj.profile.groupsAssigned[i]) {
          items.push({ item_id: 'MP_SOFT', item_text: 'BW Premier Collection' });
        }
      }
      this.selectedItems = items;
    }

    if (this.editObj.profile.email.includes('@bwhhotel.com')) {
      this.email.setValue('');
    }
    else { this.email.setValue(this.editObj.profile.email); }
    if (this.editObj.profile.beginDate) {
      this.activeDate = this.editObj.profile.beginDate.split('T')[0];
    }
    if (this.editObj.profile.endDate) {
      this.inActiveDate.setValue(this.editObj.profile.endDate.split('T')[0]);
    }
    if (this.isReactivated == true && typeof this.editObj.profile.endDate === 'undefined') {
      this.inActiveDate.setValue(null);
    }
    this.inactiveDateMin = new Date(this.activeDate) > this.todayDate ? new Date(this.activeDate) : this.todayDate;
    this.templateRole = this.initialRole;
    this.templateRoleControl.setValue(this.initialRole);
    this.loggedInUserType = this.dataService.userclaims.userRole;
    if (typeof this.editObj.profile.doNotDelete != 'undefined')
      this.isDelete = this.editObj.profile.doNotDelete;
    if (this.editObj.status == 'LOCKED_OUT') {
      this.isUserLocked = true;
      this.lockStatus = "LOCKED";
    }
    if (!flag) {
      return
    }
    if (this.loggedInUserType == 'PROPERTY_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUserType == 'PROPERTY_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUserType == 'VENDOR_ADMIN') {
      this.usertypes.push({ value: 'VENDOR_STAFF', viewValue: 'Vendor Staff' });
    }
    else if (this.loggedInUserType == 'AFFILIATE_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUserType == 'AFFILIATE_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' });
    }
    else if (this.loggedInUserType == 'REGIONAL_AFFILIATE_ADMIN' || this.loggedInTemplatePermission == environment.corp_groups.corp_power_user) {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }
    else if (this.loggedInUserType == 'EMPLOYEE' && (this.isSuperEmployee == true || this.loggedInTemplatePermission == environment.corp_groups.corp_admin)) {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'VENDOR_ADMIN', viewValue: 'Vendor Admin' },
        { value: 'VENDOR_STAFF', viewValue: 'Vendor Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }
    else if (this.loggedInUserType == 'EMPLOYEE' && (this.isSuperEmployee == false || this.globalConstants.corpPropUsers.includes(this.loggedInTemplatePermission))) {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    
    if (this.userControl.value == 'AFFILIATE_STAFF' || this.userControl.value == 'AFFILIATE_ADMIN' || this.userControl.value == 'REGIONAL_AFFILIATE_ADMIN') {
      this.isAffiliateUser = true;
    }
    if (this.userControl.value == 'VENDOR_STAFF' || this.userControl.value == 'VENDOR_STAFF') {
      this.isVendorUser = true;
    }
    if (this.loggedInUserType == 'PROPERTY_ADMIN' || this.loggedInUserType == 'PROPERTY_STAFF') {
      this.isPropertyUser = true;
      this.dropDownListProperties = this.dataService.userclaims.properties.sort();
      if (this.dropDownListProperties.length > 1) {
        this.dropDownListProperties.push('Multi_Property');
      } else {
        this.selectedID = [this.dropDownListProperties[0]];
        let searchModel: SearchModel = new SearchModel();
        searchModel.isCustomSearch = true;
        searchModel.userRole = this.loggedInUserType;
        searchModel.customSearchValue = this.selectedID;
        searchModel.isCorpUser = false;
        searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
        this.searchModelArray.push(searchModel);
        this.firstSearchTemplate(this.searchModelArray, this.dataService.accessToken);
      }
    }
  }

  sortAffiliates(affiliateOffices) {
    affiliateOffices.sort((left, right): number => {
      if (left.value < right.value) return -1;
      if (left.value > right.value) return 1;
      return 0;
    });
  }

  disableFields() {
    this.userControl.disable();
    // this.userMngmtControl.disable();
    this.affiliateOfficeControl.disable();
    this.userGroupsControl.disable();
    this.propControl.disable();
    this.propertyControl.disable();
    return;
  }

  enableFields() {
    this.userControl.enable();
    // this.userMngmtControl.enable();
    this.affiliateOfficeControl.enable();
    this.userGroupsControl.enable();
    this.propControl.enable();
    this.propertyControl.enable();
    return;
  }

  templateReset() {
    this.setUserDetails(false);
    this.inactivePropControl.setErrors({ inactiveProp: false });
    this.invalidPropControl.setErrors({ invalidProp: false });
    this.isPropInvalid = false;
    this.isPropInactive = false;
    this.istempselected = false;
    this.dataService.isSetFromTemplate = false;
  }

  Reset() {
    this.setUserDetails(false);
    this.inactivePropControl.setErrors({ inactiveProp: false });
    this.invalidPropControl.setErrors({ invalidProp: false });
    this.isPropInvalid = false;
    this.isPropInactive = false;
    this.istempselected = false;
    this.dataService.isSetFromTemplate = false;
    this.tempIDtxtboxControl.reset();
    this.propertyTempControl.reset();
    this.selectedPropTemp = '';
    if (this.loggedInUserType != 'EMPLOYEE') {
      if (this.dropDownListProperties.length > 1) {
        this.templateIDControl.reset();
        this.dropDownListTemplate = [];
      }
    } else {
      this.dropDownListTemplate = [];
    }
  }

  async tabClick(event: MatTabChangeEvent) {
    this.isSubCheckbox = false;
    this.showButtons = false;
    this.appLabel = event.tab.textLabel;
    if (event.tab.textLabel === "Application Access") {
      this.showButtons = true;
      this.appAccessTabEnabled = true;
    } else {
      this.showButtons = true;
      this.appAccessTabEnabled = false;
    }
    if (this.dataService.userclaims.idm_role[0] == 'OH-IDM-Role User Management - Restricted') {
      this.disableCheckboxes = true;
    }
  }

  getUserSpecificMenus() {
    if (!this.menus && !this.istempselected) {
      this.appAccessDataService.getMemberWebUserMenus(this.dataService.editServiceData.profile.uid, this.accessToken).subscribe(
        (response) => {
          this.menus = response
          this.tempMenu = cloneDeep(this.menus)
          this.setMenu(false)

        },
        (error) => {
          console.log(error);
        }
      )
    } else if (this.istempselected) {
      this.setMenuFromTemplate();
    }
  }

  setMenuFromTemplate() {
    for (var i = 0; i < Object.keys(this.memberwebMenuItems).length; i++) {
      this.subMenuAllunChecked(i)
      this.master_change(this.memberwebMenuItems[i].name, i)
    }

    this.editObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.editObj);
    this.tempmenuitems.menus = editObject.menuitems.menus;
    this.tempmenuitems.menus = editObject.menuitems.menus;
    for (let i = 0; i < this.memberwebMenuItems.length; i++) {
      for (let m = 0; m < this.tempmenuitems.menus.length; m++) {
        if (this.tempmenuitems.menus[m] !== null && this.tempmenuitems.menus[m]['menu-id'] == this.memberwebMenuItems[i]["menu-id"]) {

          let k = 0;
          for (let j = 0; j < this.memberwebMenuItems[i]["sub-menu-list"].length; j++) {
            if (this.tempmenuitems.menus[m]['sub-menu-id-list'].includes(this.memberwebMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
              this.memberwebMenuItems[i]["sub-menu-list"][j].checked = true;
              k++;
              if (k == this.memberwebMenuItems[i]["sub-menu-list"].length) {
                this.subMenuAllchecked(i);

              }
            }
          }
        }
      }
      this.list_change(this.memberwebMenuItems[i].name, i);
    }
  }

  subMenuAllchecked(val: int) {
    this.master_checked[val] = true;
  }

  subMenuAllunChecked(val: int) {
    this.master_checked[val] = false;
  }

  master_change(str: string, val: int) {
    for (let value of this.memberwebMenuItems[val]['sub-menu-list']) {
      value.checked = this.master_checked[val];
    }
    this.list_change(str, val);
  }

  setMenu(flag) {

    if (flag) {
      this.menus = cloneDeep(this.tempMenu)
    }
    for (var i = 0; i < Object.keys(this.memberwebMenuItems).length; i++) {
      this.subMenuAllunChecked(i)
      this.master_change(this.memberwebMenuItems[i].name, i)
    }
    this.menus.menus.forEach(element => {
      if (this.memberwebMenuItems) {
        for (let i = 0; i < this.memberwebMenuItems.length; i++) {
          if (element['menu-id'] == this.memberwebMenuItems[i]["menu-id"] && element.hasOwnProperty('sub-menu-id-list')) {
            this.menuitems.menus[i] = element;
            let k = 0;
            for (let j = 0; j < this.memberwebMenuItems[i]["sub-menu-list"].length; j++) {
              if (element['sub-menu-id-list'].includes(this.memberwebMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
                this.memberwebMenuItems[i]["sub-menu-list"][j].checked = true;
                k++;
                if (k == this.memberwebMenuItems[i]["sub-menu-list"].length) {
                  this.subMenuAllchecked(i);
                }
              }
            }

          }
        }
      }
    });
  }

  updateMemberwebPrivilagesFromEditUser() {
    console.log(this.tempmenuitems);
    localStorage.setItem("isButtonClicked", "Y");
    this.menuitems["updated-by"] = this.dataService.userclaims.userId;
    this.menuitems["bearer_token"] = this.accessToken;
    this.menuitems.menus = JSON.parse(JSON.stringify(this.menuitems.menus).replace(/null,/g, '').replace(/,null/g, ''));
    this.dataService.postMemberMenusAccess(this.dataService.editServiceData.profile.uid, JSON.stringify(this.menuitems), this.accessToken).subscribe(
      (response) => {
        this.successMessage = "Menu access has been updated."
        localStorage.setItem("isRequestFromEditScreen", "Y");
        this.openDialog(this.successMessage, "Success")
      },
      (error) => {
        this.successMessage = 'Menus Update unsuccessfull! please contact Adminstrator.'
        localStorage.setItem("isRequestFromEditScreen", "Y");
        this.openDialog(this.successMessage, "Warning")
      }
    )
  }

  populateVendorCodesAndAssign() {
    this.dataService.getVendorCodes(this.dataService.accessToken).then((data: String) => {
      let vendorList: VendorCodes = JSON.parse(JSON.stringify(data));
      for (var value in vendorList) {
        this.vendors.set(value, vendorList[value])
      }
    },
      (error) => {
        this.globalErrorHandlerService.handleError(error);
      });

  }

  onCategoryChange(event) {
    this.isAffiliateUser = false;
    this.isVendorUser = false;
    if (event.value == 'PROPERTY_STAFF') {
      this.email.setValidators([Validators.pattern(this.globalConstants.email_pattern)]);
    } else {
      this.email.setValidators([Validators.required, Validators.pattern(this.globalConstants.email_pattern)]);
    }

    if (this.editObj.profile.email.includes('@bwhhotel.com')) {
      this.email.setValue('');
    }
    else { this.email.setValue(this.editObj.profile.email); }
    if (this.selectedUserType == 'AFFILIATE_STAFF' || this.selectedUserType == 'AFFILIATE_ADMIN' || this.selectedUserType == 'REGIONAL_AFFILIATE_ADMIN') {
      this.isAffiliateUser = true;
    }
    if (this.selectedUserType == 'VENDOR_STAFF' || this.selectedUserType == 'VENDOR_ADMIN') {
      this.isVendorUser = true;
      this.populateVendorCodesAndAssign();
    }
  }

  list_change(menu: any, order: any) {
    let value: SubMenuList;
    let user_id = this.dataService.userclaims.userId;
    let allChange: boolean = true;
    let allNotChange: boolean = true;
    for (value of this.memberwebMenuItems[order]['sub-menu-list']) {
      if (value != null && value.checked) {
        allNotChange = false;
        if (this.menuitems.menus[order] == null) {
          this.menuitems.menus[order] = new memberwebMenus();
          this.menuitems.menus[order]['menu-id'] = this.memberwebMenuItems[order]['menu-id'];
          this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
        }
        else {
          if (!this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
          }
        }
        this.menuitems['updated-by'] = user_id;
      }
      else if (!value.checked) {
        allChange = false;
        if (this.menuitems.menus[order] != null) {
          if (this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            const index: number = this.menuitems.menus[order]['sub-menu-id-list'].indexOf(value['sub-menu-id']);
            this.menuitems.menus[order]['sub-menu-id-list'].splice(index, 1);
          }
        }
      }
    }
    if (allChange) {
      this.subMenuAllchecked(order);
    }
    else {
      this.subMenuAllunChecked(order);
    }
    if (allNotChange) {
      this.menuitems.menus[order] = null;
    }

    JSON.stringify(this.menuitems);
  }

  onTemplatePermissionChange(event) {
    this.istemplatePermissionSelected = true;
  }

  async validateMandatoryFields() {
    if ((this.selectedUserType == 'PROPERTY_ADMIN' || this.globalConstants.non_property_users.includes(this.selectedUserType)) && !this.isPDRuser) {
      if (this.email.errors) {
        this.email.markAsTouched();
        return true;
      }
    }
    if (this.email.value != "" && this.email.value != this.originalEmail && (this.globalConstants.non_property_users.includes(this.selectedUserType))) {
      let isExist: boolean = await this.isEmailAlreadyExist(this.email.value, this.accessToken);
      if (isExist) {
        this.email.setErrors({ duplicate: true });
        return true;
      }
    }

    if (this.firstNameControl.errors) {
      this.firstNameControl.markAsTouched();
      return true;
    }
    if (this.lastNameControl.errors) {
      this.lastNameControl.markAsTouched();
      return true;
    }

    if (this.initials.errors) {
      this.initials.markAsTouched();
      return true;
    }

    if (this.selectedUserType == 'AFFILIATE_ADMIN' || this.selectedUserType == 'AFFILIATE_STAFF') {
      if (this.affiliateOfficeControl.errors) {
        this.affiliateOfficeControl.markAsTouched();
        return true;
      }
    }

    if (this.loggedInUserType != 'EMPLOYEE' &&
      (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'PROPERTY_STAFF')) {
      if (this.propControl.errors) {
        this.propControl.markAsTouched();
        return true;
      }
    }

    if (this.loggedInUserType == 'EMPLOYEE' &&
      (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'PROPERTY_STAFF')) {
      if (this.propertyControl.errors) {
        this.propertyControl.markAsTouched();
        return true;
      }
    }

    if (this.selectedUserType == 'PROPERTY_STAFF' || this.selectedUserType == 'AFFILIATE_STAFF') {
      if (this.templateRoleControl.errors) {
        this.templateRoleControl.markAsTouched();
        return true;
      }
    }

    if (this.inActiveDate.errors && !this.isReactivated) {
      this.inActiveDate.markAsTouched();
      return true;
    }

    this.invalidPropControl.reset();
    this.inactivePropControl.reset();

    return false;
  }

  async updateUserInfo() {
    if (await this.validateMandatoryFields()) {
      return;
    }

    let editUser: User = new User();
    let profile: Profile = new Profile();
    editUser.profile = profile;
    profile.firstName = this.firstname;
    profile.lastName = this.lastname;
    profile.employeeTypeIDM = this.selectedUserType;
    profile.uid = this.userIdcontrol;
    profile.idm_role = this.setIdm_role();
    profile.doNotDelete = this.isDelete;
    profile.beginDate = formatDate(this.activeDate, this.dateTimeFormat, this.locale);
    profile.vendorCode = this.selectedVendor;
    profile.endDate = this.inActiveDate.value ? formatDate(this.inActiveDate.value, this.dateTimeFormat, this.locale) : null;
    if (this.selectedUserType == 'AFFILIATE_ADMIN' || this.selectedUserType == 'AFFILIATE_STAFF' || this.selectedUserType == 'REGIONAL_AFFILIATE_ADMIN')
      profile.affiliateOffice = this.selectedAffiliate;
    if (this.initials != null)
      profile.initials = this.initials.value.split(",");
    if (this.selectedUserType == 'PROPERTY_STAFF' || this.selectedUserType == 'PROPERTY_ADMIN') {
      if (this.loggedInUserType == 'EMPLOYEE') {
        profile.propID = this.selectedproperties.toString().split(',');
      } else {
        profile.propID = this.selectedproperties;
      }
    }

    this.usergroups.selectedItems = this.selectedItems;
    this.usergroups.selectedUserType = this.selectedUserType;
    let userGroupdata: UserGroups = await this.usergroups.addGroups(profile.affiliateOffice, profile.propID, this.accessToken);
    if (userGroupdata.hasError)
      return;
    if (userGroupdata.invalidPropMessage || userGroupdata.inactivePropMessage) {

      if (userGroupdata.invalidPropMessage) {
        this.invalidPropMessage = userGroupdata.invalidPropMessage;
        this.invalidPropControl.setErrors({ invalidProp: true })
        this.invalidPropControl.markAsTouched();
      }
      if (userGroupdata.inactivePropMessage) {
        this.inactivePropMessage = userGroupdata.inactivePropMessage;
        this.inactivePropControl.setErrors({ inactiveProp: true })
        this.inactivePropControl.markAsTouched();
      }

      return;
    }
    profile.groupsAssigned = Array.from(userGroupdata.groups);
    this.dataService.groupsTemplate = new GroupsTemplate();
    if (!this.isVendorUser) {

      if (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'AFFILIATE_ADMIN') {
        this.dataService.groupsTemplate.group = environment.user_roles.admin_role;
        this.templateRole = environment.user_roles.admin_role;
      } else {
        this.dataService.groupsTemplate.group = this.templateRole;
      }
      this.dataService.groupsTemplate.user = this.editObj.id;
      this.dataService.groupsTemplate.delGroup = this.deleteGroupID;

    } else {
      if (this.selectedUserType == 'VENDOR_ADMIN') {
        this.templateRole = environment.user_roles.admin_role;
      }
      else {
        this.templateRole = environment.user_roles.no_user_mgnt
      }
      this.dataService.groupsTemplate.group = this.templateRole;

    }
    profile.idm_role = this.setIdm_role();

    if (this.selectedUserType == 'PROPERTY_STAFF') {
      if (this.email.value == null || this.email.value == '') {
        this.email.setValue(profile.uid + "@bwhhotel.com");
        this.propStaffFlag = true;
      }
      profile.email = this.email.value;
    } else {
      profile.email = this.email.value;
    }

    if(this.globalConstants.non_property_users.includes(this.selectedUserType)){
      this.isNetworkRestricted = false;
    }

    let createUpdateRequest: CreateUpdateRequest = new CreateUpdateRequest();
    createUpdateRequest.user = editUser;
    createUpdateRequest.isPdrUser = this.isPDRuser;
    createUpdateRequest.submitted_by = this.dataService.userclaims.userId;
    createUpdateRequest.bearer_token = this.accessToken;
    createUpdateRequest.networkRestricted = this.isNetworkRestricted;
    createUpdateRequest.updateGroups = true;
    if (this.globalConstants.non_property_users.includes(this.selectedUserType) && !this.isPDRuser) {
      createUpdateRequest.searchUser = this.dataService.editServiceData.profile.email;
    } else {
      createUpdateRequest.searchUser = this.dataService.editServiceData.profile.login;
    }
    const body = {
      "Detail": JSON.stringify(createUpdateRequest),
      "Source": "updateUserEvent"
    }
    if (await this.istempselected) {
      this.dataService.getMemberMenus(this.accessToken).subscribe(
        (response) => {
          this.memberwebMenuItems = JSON.parse(JSON.stringify(response));
          this.setMenuFromTemplate();
        },
        (error) => {
          console.log(error);
          this.globalErrorHandlerService.handleError(error);
        }
      );

    }
    let isChangeTempRole = await this.checkTemplateRoleChange();
    this.dataService.postUserDetails(body, this.accessToken).then((data: String) => {
      localStorage.setItem("isRequestFromEditScreen", "Y");
      localStorage.setItem("isButtonClicked", "Y");
      if (!this.dataService.canAccessMemberwebTab && !this.isVendorUser) {
        this.menuitems["updated-by"] = this.dataService.userclaims.userId;
        this.menuitems["bearer_token"] = this.accessToken;
        this.menuitems.menus = [];
        setTimeout(() => this.dataService.postMemberMenusAccess(editUser.profile.uid, JSON.stringify(this.menuitems), this.accessToken).subscribe(
          (response) => {
            localStorage.setItem("isRequestFromEditScreen", "Y");
            if (!isChangeTempRole) {
              this.openDialog('User account has been updated.', 'Success')
            }
          },
          (error) => {
            let failMeassage = 'Failed to process the request. Please try again later!';
            localStorage.setItem("isRequestFromEditScreen", "Y");
            this.openDialog(failMeassage, "Warning")
          }
        ), 1000);
      } else {
        localStorage.setItem("isRequestFromEditScreen", "Y");
        if (!isChangeTempRole) {
          this.openDialog('User account has been updated.', 'Success')
        }
      }
    }, (error) => {
      let failMeassage = ' Failed to process the request. Please try again later!';
      localStorage.setItem("isRequestFromEditScreen", "Y");
      this.openDialog(failMeassage, 'Warning')
    });

    if (isChangeTempRole) {
      let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
      createTemplateGroupRequest.group = this.dataService.groupsTemplate;
      createTemplateGroupRequest.submitted_by = this.dataService.userclaims.userId;
      createTemplateGroupRequest.bearer_token = this.accessToken;
      const body = {
        "Detail": JSON.stringify(createTemplateGroupRequest),
        "Source": "updateUserEvent" + "/" + this.dataService.editServiceData.profile.login
      }
      this.dataService.templatesRoles(body, this.accessToken).then((data: String) => {
        localStorage.setItem("isRequestFromEditScreen", "Y");
        this.openDialog('User account has been updated.', 'Success')
      }, (error) => {
        let failMeassage = 'User account has been updated but failed to change the template permission'
        localStorage.setItem("isRequestFromEditScreen", "Y");
        this.openDialog(failMeassage, 'Warning')
      })
    }

  }

  async isEmailAlreadyExist(loginId: any, accessToken: string) {
    let submitted_by = this.dataService.userclaims.userId
    await this.dataService.getCurrentUserDeatils(loginId, accessToken, submitted_by).then(data => {
      this.isEmailExist = true;
    }, (error) => {
      this.isEmailExist = false;
    });
    return this.isEmailExist;
  }

  async checkTemplateRoleChange() {
    let flag: boolean = false;
    if (this.deleteGroupID == this.dataService.groupsTemplate.group) {
      flag = false;
    } else {
      flag = true;
    }
    return flag;
  }

  setIdm_role() {
    if (this.templateRole == environment.user_roles.admin_role) {
      return 'Admin';
    }
    if (this.templateRole == environment.user_roles.power_role) {
      return 'PowerUser';
    }
    if (this.templateRole == environment.user_roles.restricted_role) {
      return 'Restricted';
    }
    if (this.templateRole == environment.user_roles.no_user_mgnt) {
      return 'None';
    }
  }

  unLockUser() {
    let userLogin;
    this.errorTF = false;
    if (this.isAffiliateUser || this.isVendorUser) {
      userLogin = this.email.value;
    } else {
      userLogin = this.dataService.editServiceData.profile.login;
    }
    if (this.lockStatus == 'LOCKED' && this.isUserLocked) {
      let data = { "userId": userLogin, "submitted_by": this.dataService.userclaims.userId, "bearer_token": this.accessToken }
      this.dataService.unlockUser(JSON.stringify(data), this.accessToken).subscribe(
        (response) => {
          this.successMessage = "User Unlocked Successfully"
          localStorage.setItem("isRequestFromEditScreen", "Y");
          this.openDialog(this.successMessage, "Success")
        },
        (error) => {
          this.errorTF = true;
          this.errorMsgUnlockUser = 'Failed to unlock the user';
        }
      )
    }
  }

  sendAccountActivationEmail() {
    let userLogin;
    this.errorTF = false;
    if (this.isAffiliateUser && !this.isPDRuser) {
      userLogin = this.email.value;
    } else {
      userLogin = this.dataService.editServiceData.profile.login;
    }
    let data = { "userId": userLogin, "submitted_by": this.dataService.userclaims.userId, "bearer_token": this.accessToken }
    let description = "This account is pending activation. Would you like to resend activation email or Generate a temporary password to manually activate the account?"
    let title = "Account Activation"
    let note = "Note: Manual activation should only be used as a last resort when email is not functioning."
    this.accountActivationPopup(description, title, data, note)
  }

  accountActivationPopup(description, title, data, note): void {
    let dialogRef;
    dialogRef = this.dialog.open(ThreeButtonPopupComponent, {
      width: '600px',
      height: '300px',
      panelClass: 'custom-warning-container',
      data: {
        description: description, title: title, note: note, buttons: [
          { 'name': "Resend Email" },
          { 'name': "Generate Password" },
          { 'name': "Cancel" }
        ]
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 0) {
        this.dataService.activateUser(JSON.stringify(data), this.accessToken).subscribe(
          (response) => {
            this.successMessage = "Account activation email sent to registered email id."
            localStorage.setItem("isRequestFromEditScreen", "Y");
            localStorage.setItem("isButtonClicked", "Y");
            this.openDialog(this.successMessage, "Success")
          },
          (error) => {
            this.errorTF = true;
            this.errorMsgUnlockUser = 'Failed to send the account activation email.';
          }
        )
      } else if (result == 1) {
        data['isEmail'] = false
        this.dataService.postResetUserPasswordRequest(JSON.stringify(data), this.accessToken).subscribe(
          (response) => {
            let userAppendValue = data.userId.includes('@bwhhotel.com') ? data.userId.replace('@bwhhotel.com', '') : data.userId;
            let successMessage = 'The User Id is: ' + userAppendValue + "\n" + 'and Temporary Password is : ' + response.description.tempPassword;
            localStorage.setItem("isRequestFromEditScreen", "Y");
            localStorage.setItem("isButtonClicked", "Y");
            this.openDialog(successMessage, "User account has been activated.")
          },
          (error) => {
            console.log(error);
            localStorage.setItem("isRequestFromEditScreen", "Y");
            localStorage.setItem("isButtonClicked", "Y");
            this.openDialog("Temporary passoword creation failed.", "Warning")
          }
        )
      }
    });
  }

  deactivateUser() {
    let userLogin;
    this.errorTF = false;
    if ((this.isAffiliateUser || this.isVendorUser) && !this.isPDRuser) {
      userLogin = this.email.value;
    } else {
      userLogin = this.dataService.editServiceData.profile.login;
    }
    let data = { "userId": userLogin, "submitted_by": this.dataService.userclaims.userId, "bearer_token": this.accessToken }
    this.confirmDeactivationPopup("This account will be Deactivated and will be blocked from all access. Click OK to Deactivate.", "WARNING", JSON.stringify(data), this.accessToken);
  }

  confirmDeactivationPopup(description, title, data, accessToken): void {
    let dialogRef;
    dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '250px',
      panelClass: 'custom-warning-container',
      data: { description: description, title: title },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.dataService.deactivateUser(data, accessToken).subscribe(
          (response) => {
            this.successMessage = "User Deactivated Successfully"
            localStorage.setItem("isRequestFromEditScreen", "Y");
            localStorage.setItem("isButtonClicked", "Y");
            this.openDialog(this.successMessage, "Success")
          },
          (error) => {
            this.errorTF = true;
            this.errorMessage = 'Failed to Deactivate the user';
          }
        )
      }
    });
  }

  reactivateUser() {
    let userLogin;
    this.errorTF = false;
    if ((this.isAffiliateUser || this.isVendorUser) && !this.isPDRuser) {
      userLogin = this.email.value;
    } else {
      userLogin = this.dataService.editServiceData.profile.login;
    }
    let sysDate = formatDate(this.todayDate, this.dateTimeFormat, this.locale);
    let isPastdate;
    let newEndDate;
    if (this.editObj.profile.endDate <= sysDate) {
      isPastdate = true;
      newEndDate = null;
    } else {
      isPastdate = false;
      newEndDate = this.editObj.profile.endDate;
    }
    let data = { "userId": userLogin, "submitted_by": this.dataService.userclaims.userId, "bearer_token": this.accessToken, "isPastDate": isPastdate, "updateBody": { "profile": { "endDate": newEndDate } } }
    this.confirmReactivationPopup("This account will be Reactivated and will be able to access IDM features. Click OK to Reactivate.", "WARNING", JSON.stringify(data), this.accessToken);
  }

  confirmReactivationPopup(description, title, data, accessToken): void {
    let dialogRef;
    dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '500px',
      height: '250px',
      panelClass: 'custom-reactivate-popup',
      data: { description: description, title: title },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.dataService.reactivateUser(data, accessToken).subscribe(
          (response) => {
            let user_id = JSON.parse(data).userId;
            this.isReactivated = true;
            let submitted_by = this.dataService.userclaims.userId
            this.dataService.getCurrentUserDeatils(user_id, accessToken, submitted_by).then(data => {
              localStorage.setItem("editObject", data);
              this.setAffiliateGroup(true);
            });
            this.successMessage = "User Reactivated Successfully"
            localStorage.setItem("isRequestFromEditScreen", "No");
            this.openDialog(this.successMessage, "Success")
          },
          (error) => {
            this.errorTF = true;
            this.errorMessage = 'Failed to Reactivate the user';
          }
        )
      }
    });
  }

  resetPassword() {
    this.errorTF = false;
    localStorage.setItem("isButtonClicked", "Y");
    if (!((this.loggedInUserType === 'PROPERTY_ADMIN' || this.loggedInUserType === 'PROPERTY_STAFF' ||
      this.loggedInUserType === 'VENDOR_ADMIN' || this.loggedInUserType === 'VENDOR_STAFF') && this.dataService.userclaims.userManagement)) {
      localStorage.setItem("isRequestFromEditScreen", "Y");
      this.router.navigate(['pages/question-answer'], { state: { resendEmail: true } });
      return;
    }
    const payload = {
      "userId": this.login,
      "isEmail": false,
      "submitted_by": this.dataService.userclaims.userId,
      "bearer_token": this.accessToken
    }
    if (this.email.value != null && this.email.value != '' && !this.email.value.includes('@bwhhotel.com')) {
      payload.isEmail = true;
    }
    this.dataService.postResetUserPasswordRequest(payload, this.accessToken).subscribe(
      (response) => {
        if (Object.keys(response.description).length === 0) {
          this.successMessage = 'User Password Reset Successfully.'
        } else {
          this.successMessage = 'User Password Reset Successfully. Temporary Password is : ' + response.description.tempPassword;
        }
        localStorage.setItem("isRequestFromEditScreen", "Y");
        this.openDialog(this.successMessage, "Success")
      },
      (error) => {
        this.errorTF = true;
        this.errorMessage = 'Password reset failed.';
      }
    )
  }

  openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    if (this.appLabel != 'Application Access') {
      this.dataService.getInvokeEvent(this.globalConstants.idm);
    }
  }

  recoveryPasswordReset() {
    let userLogin;
    this.errorTF = false;
    if (this.isAffiliateUser && !this.isPDRuser) {
      userLogin = this.email.value;
    } else {
      userLogin = this.dataService.editServiceData.profile.login;
    }
    let data = { "userId": userLogin, "submitted_by": this.dataService.userclaims.userId, "bearer_token": this.accessToken }
    let description = "This account is in recovery status. Would you like to resend password reset email or Generate a temporary password to manually change the password?"
    let title = "Reset Password"
    let note = "Note: Manual password reset should only be used as a last resort when email is not functioning."
    this.resetPasswordPopup(description, title, data, note);
  }

  resetPasswordPopup(description, title, data, note): void {
    let dialogRef;
    dialogRef = this.dialog.open(ThreeButtonPopupComponent, {
      width: '600px',
      height: '300px',
      panelClass: 'custom-warning-container',
      data: {
        description: description, title: title, note: note, buttons: [
          { 'name': "Resend Email" },
          { 'name': "Generate Password" },
          { 'name': "Cancel" }
        ]
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 0) {
        this.errorTF = false;
        localStorage.setItem("isButtonClicked", "Y");
        if (!((this.loggedInUserType === 'PROPERTY_ADMIN' || this.loggedInUserType === 'PROPERTY_STAFF') && this.dataService.userclaims.userManagement)) {
          localStorage.setItem("isRequestFromEditScreen", "Y");
          this.router.navigate(['pages/question-answer'], { state: { resendEmail: true } });
          return;
        }
        data['isEmail'] = true
        this.dataService.postResetUserPasswordRequest(JSON.stringify(data), this.accessToken).subscribe(
          (response) => {
            this.successMessage = "Password reset email sent to registered email id."
            localStorage.setItem("isRequestFromEditScreen", "Y");
            localStorage.setItem("isButtonClicked", "Y");
            this.openDialog(this.successMessage, "Success")
          },
          (error) => {
            this.errorTF = true;
            this.errorMsgUnlockUser = 'Failed to send the password reset email.';
          }
        )
      } else if (result == 1) {
        localStorage.setItem("isButtonClicked", "Y");
        if (!((this.loggedInUserType === 'PROPERTY_ADMIN' || this.loggedInUserType === 'PROPERTY_STAFF') && this.dataService.userclaims.userManagement)) {
          localStorage.setItem("isRequestFromEditScreen", "Y");
          this.router.navigate(['pages/question-answer'], { state: { resendEmail: false } });
          return;
        }
        data['isEmail'] = false
        this.dataService.postResetUserPasswordRequest(JSON.stringify(data), this.accessToken).subscribe(
          (response) => {
            let userAppendValue = data.userId.includes('@bwhhotel.com') ? data.userId.replace('@bwhhotel.com', '') : data.userId;
            let successMessage = 'The User Id is: ' + userAppendValue + "\n" + 'and Temporary Password is : ' + response.description.tempPassword;
            localStorage.setItem("isRequestFromEditScreen", "Y");
            localStorage.setItem("isButtonClicked", "Y");
            this.openDialog(successMessage, "Password reset successful.")
          },
          (error) => {
            console.log(error);
            localStorage.setItem("isRequestFromEditScreen", "Y");
            localStorage.setItem("isButtonClicked", "Y");
            this.openDialog("Temporary passoword creation failed.", "Warning")
          }
        )
      }
    });
  }

  validateInActiveDate(event: any) {
    this.inActiveDateMin = event.target.value;
  }

  backToSearch() {
    this.dataService.getInvokeEvent(this.globalConstants.idm);
    localStorage.setItem("isRequestFromEditScreen", "Y");
    localStorage.setItem("isButtonClicked", "Y");
    this.router.navigate(['pages/search-user']);
  }

  onIDChange(event) {
    this.istempselected = true;
    this.dataService.isSetFromTemplate = true;
    this.templateReset();
    let searchModel: SearchModel = new SearchModel();
    searchModel.isCustomSearch = true;
    searchModel.userRole = this.loggedInUserType;
    const slctID = [];
    slctID.push(event);
    if (this.loggedInUserType == 'EMPLOYEE') {
      if (this.tempIDtxtboxControl.value.toString() == 'Multi_Property') {
        searchModel.customSearchValue = [this.tempIDtxtboxControl.value.toString()]
      } else {
        searchModel.customSearchValue = [this.tempIDtxtboxControl.value.toUpperCase().toString()];
      }
    } else {
      searchModel.customSearchValue = slctID;
    }
    if (this.loggedInUserType == 'EMPLOYEE') {
      searchModel.isCorpUser = true;
      searchModel.propUnderScope = [];
    } else {
      searchModel.isCorpUser = false;
    }
    if (this.loggedInUserType == 'PROPERTY_ADMIN' || this.loggedInUserType == 'PROPERTY_STAFF') {
      searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
    }
    if (this.loggedInUserType == 'AFFILIATE_ADMIN' || this.loggedInUserType == 'AFFILIATE_STAFF' || this.loggedInUserType == 'REGIONAL_AFFILIATE_ADMIN') {
      searchModel.propUnderScope = this.affiliateUserPropList;
    }
    this.searchModelArray = [];
    this.dropDownListTemplate = [];
    this.searchModelArray.push(searchModel);
    this.searchTemplate(this.searchModelArray, this.dataService.accessToken);
  }

  onIDUnselect() {
    this.templateReset();
    this.selectedPropTemp = '';
    this.isAffiliateUser = false;
    this.isPropertyUser = false;
    this.propertyTempControl.reset();
  }

  onTemplateChange(event) {
    if (event.value != null) {
      this.templateReset();
      this.istempselected = true;
      this.dataService.isSetFromTemplate = true;
      const editObject = event.value;
      localStorage.removeItem("editTempObject");
      localStorage.setItem("editTempObject", JSON.stringify(editObject));
      if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
        this.enableFields();
      }
      this.userControl.setValue(editObject.userType);
      this.selectedUserType = editObject.userType;
      for (let i = 0; i < this.templatePermissions.length; i++) {
        if (this.templatePermissions[i].value == editObject.templateRole) {
          this.templateRole = editObject.templateRole;
          this.templateRoleControl.setValue(editObject.templateRole);
        }
      }
      if (this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === 'REGIONAL_AFFILIATE_ADMIN') {
        this.isAffiliateUser = true;
      } else {
        this.isAffiliateUser = false;
      }
      if (this.selectedUserType === "PROPERTY_STAFF" || this.selectedUserType === "PROPERTY_ADMIN") {
        this.isPropertyUser = true;
      } else {
        this.isPropertyUser = false;
      }
      this.affiliateOfficeControl.setValue(editObject.affiliateOffice);
      this.selectedAffiliate = editObject.affiliateOffice;
      this.propertyControl.setValue(editObject.propertyID);
      this.propControl.setValue(editObject.propertyID);
      // this.selectedproperties = [];
      this.selectedproperties = editObject.propertyID;
      // this.isUserMgmt = editObject.userManagement;
      this.selectedItems = editObject.selectedItems;
      if (this.loggedInTemplatePermission == 'OH-IDM-Role User Management - Restricted') {
        setTimeout(() => {
          this.disableFields();
        })
      }
      setTimeout(() => {
        this.getFocus();
      })
    }


  }

  getFocus() {
    if (this.isAffiliateUser) {
      document.getElementById('emailbox').focus();
    }
    if (this.isPropertyUser) {
      let myTextBox = document.getElementById('firstNamebox');
      myTextBox.focus();
    }

  }

  SetFromTemplate() {
    this.editObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.editObj);
    this.selectedID[0] = editObject.ID;
    this.dropDownListTemplate = [];
    this.dropDownListTemplate.push({ value: editObject, viewValue: editObject.templateName });
    this.propertyTempControl.setValue(editObject);
    this.userControl.setValue(editObject.userType);
    this.selectedUserType = editObject.userType;
    if (this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === 'REGIONAL_AFFILIATE_ADMIN') {
      this.isAffiliateUser = true;
    } else {
      this.isAffiliateUser = false;
    }
    if (this.selectedUserType === "PROPERTY_STAFF" || this.selectedUserType === "PROPERTY_ADMIN") {
      this.isPropertyUser = true;
    } else {
      this.isPropertyUser = false;
    }
    this.affiliateOfficeControl.setValue(editObject.affiliateOffice);
    this.selectedAffiliate = editObject.affiliateOffice;
    this.propertyControl.setValue(editObject.propertyID);
    this.propControl.setValue(editObject.propertyID);
    this.selectedproperties = [];
    this.selectedproperties = editObject.propertyID;

    this.selectedItems = editObject.selectedItems;
  }

  searchTemplate(request: any[], accessToken: any) {
    let templateSearchRequest = new TemplateSearchRequest();
    templateSearchRequest.submittedBy = this.dataService.userclaims.userId;
    templateSearchRequest.searchRequestData = request;
    this.dataService.getTemplate(templateSearchRequest, accessToken).subscribe((data: any) => {
      const rslt = JSON.parse(JSON.stringify(data));
      const srchrslt = rslt.body;
      if (srchrslt.length === 0) {
        this.dropDownListTemplate.push({ viewValue: "No Template found with given input", value: null });
      }
      let dataSource = [];
      if (this.loggedInUserType == 'EMPLOYEE' && (this.isSuperEmployee == false || this.globalConstants.corpPropUsers.includes(this.loggedInTemplatePermission))) {
        for (let i = 0; i < srchrslt.length; i++) {
          if (srchrslt[i].userType != 'AFFILIATE_STAFF' && srchrslt[i].userType != 'AFFILIATE_ADMIN') {
            dataSource.push(srchrslt[i]);
          }
        }
      } else {
        dataSource = srchrslt;
      }
      if (this.dropDownListTemplate.length == 0) {
        for (let i = 0; i < dataSource.length; i++) {
          this.searchResults.push(dataSource[i]);
          if (dataSource[i].ID == 'Multi_Property') {
            this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName + ' - ' + dataSource[i].propertyID, value: dataSource[i] });
          } else {
            this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName, value: dataSource[i] });
          }
        }
      } else {
        for (let i = 0; i < dataSource.length; i++) {
          this.searchResults.push(dataSource[i]);
          if (this.dropDownListTemplate[0].value.templateid != dataSource[i].templateid) {
            if (dataSource[i].ID == 'Multi_Property') {
              this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName + ' - ' + dataSource[i].propertyID, value: dataSource[i] });
            } else {
              this.dropDownListTemplate.push({ viewValue: dataSource[i].templateName, value: dataSource[i] });
            }
          }
        }
      }

    }, (error) => {
      console.log("*** error" + error); //Error callback
      this.globalErrorHandlerService.handleError(error);
    })
  }

  firstSearchTemplate(request: any[], accessToken: any) {
    let templateSearchRequest = new TemplateSearchRequest();
    templateSearchRequest.submittedBy = this.dataService.userclaims.userId;
    templateSearchRequest.searchRequestData = request;
    this.dataService.getTemplate(templateSearchRequest, accessToken).subscribe((data: any) => {
      const rslt = JSON.parse(JSON.stringify(data));
      const srchrslt = rslt.body;
      if (srchrslt.length === 0) {
        this.dropDownListTemplate.push({ viewValue: "No Template found with given input", value: null });
      }
      if (this.dropDownListTemplate.length == 0) {
        for (let i = 0; i < srchrslt.length; i++) {
          this.searchResults.push(srchrslt[i]);
          if (srchrslt[i].ID == 'Multi_Property') {
            this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName + ' - ' + srchrslt[i].propertyID, value: srchrslt[i] });
          } else {
            this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName, value: srchrslt[i] });
          }
        }
      } else {
        for (let i = 0; i < srchrslt.length; i++) {
          this.searchResults.push(srchrslt[i]);
          if (this.dropDownListTemplate[0].value.templateid != srchrslt[i].templateid) {
            if (srchrslt[i].ID == 'Multi_Property') {
              this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName + ' - ' + srchrslt[i].propertyID, value: srchrslt[i] });
            } else {
              this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName, value: srchrslt[i] });
            }
          }
        }
      }
    }, (error) => {
      console.log("*** error" + error); //Error callback
      this.globalErrorHandlerService.handleError(error);
    })
  }

  updateGroupsToOkta(selectedGroupId, group_prefix) {
    const selReqBody = {
      "userId": this.editObj.id,
      "loginId": this.editObj.profile.login,
      "groupId": selectedGroupId,
      "groupPrefix": group_prefix,
      "submitted_by": this.dataService.userclaims.userId,
      "bearer_token": this.accessToken
    }
    this.dataService.changeGroupAccess(selReqBody, this.accessToken).subscribe(
      (response) => {
        console.log("Access group has been added to okta");
        let successMessage = "Menu access provided successfully.";
        this.openDialog(successMessage, "Success");
      },
      (error) => {
        let failMeassage = 'Menu Access Failed!';
        this.openDialog(failMeassage, "Warning")
        console.log(error)
      });
  }
}