export class GlobalConstants {
    public server_error: string = "Internal Server error";
    public global_error: string = "An error occurred, if this persists, please contact the Help Desk";
    public submit: string = "Submit";
    public cancel: string = "Cancel";
    /**************** header ****************/
    public idm: string = "User Management";
    public home: string = "Home";
    public manage_user: string = "Manage User";
    public create_user: string = "Create User";
    public profile: string = "Profile";
    public sign_out: string = "Sign out";
    /**************** IDM user groups ****************/
    public devAllIdmGroup: string = 'OH-IDM-Scope User Types - All';
    public qaAllIdmGroup: string = 'OH-IDM-Scope User Types - All';
    public prodAllIdmGroup: string = 'OH-IDM-Scope User Types - All';
    public devIdmGroup: string = 'OH-IDM-Scope User Types - All Property';
    public qaIdmGroup: string = 'OH-IDM-Scope User Types - All Property';
    public prodIdmGroup: string = 'OH-IDM-Scope User Types - All Property';
    /**************** create and edit user ****************/
    public personalInfo: string = "Personal Information";
    public userType: string = "User Type";
    public userType_err: string = "Please choose a User Type";
    public email_err1: string = "Email is required";
    public email_err2: string = "Please include Email address";
    public email_err3: string = "Email Address is not Valid. It can not contain bestwestern.com, autoclerk.com, worldhotels.com, bwhhotelgroup.com";
    public email_err4: string = "Email already exists.";
    public email_err5: string = "(Note: Email ID will be login ID)";
    public email_err6: string = "Please enter valid Email";
    public affiliate_ofc: string = "Affiliate Office";
    public vendor_cd: string = "Vendor Code";
    public vendor_err: string = "Please choose a Vendor Code";
    public affiliate_ofc_err1: string = "Please choose a Affiliate Office";
    public user_mgmt: string = "Allow User Management";
    public first_name_err1: string = "First Name is required";
    public first_last_name_err: string = "Allowed special characters are spaces, dots, apostrophes, underscore and dashes.Can't start with special character.";
    public property_number_err: string = "Allowed characters are only numbers and comma.Can't start with comma.";
    public last_name_err1: string = "Last Name is required";
    public initials_err1: string = "Initials is required";
    public initials_err2: string = "Initials is invalid";
    public user_status: string = "User Status";
    public active_date: string = "Active Date";
    public active_date_err: string = "Active Date is required";
    public inactive_date: string = "Inactive Date";
    public inactive_date_err: string = "Inactive Date cannot be before Active Date";
    public inactive_date_err1: string = "Inactive date cannot be past date";
    public clear: string = "clear";
    public user_info: string = "User Information";
    public props: string = "Properties";
    public props_err1: string = "Please choose atleast one property";
    public props_err2: string = "Note: Property IDs must be a five digit numbers separated by commas.";
    public user_groups: string = "User Groups";
    public doNotDelete: string = "Do not delete";
    public groups_info: string = "Groups Information";
    public assigned_groups: string = "Assigned User Groups";
    public select_user_groups: string = "click here to select user groups";
    public inactive_resorts: string = "Inactive Resort(s) : ";
    public invalid_resorts: string = "Invalid Resort(s) : ";
    public invalid_memberId: string = "Invalid Front Desk Incentive ID";
    public invalid_role: string = "User is not allowed to grant this role."
    /**************** search ****************/
    public search_user: string = "Search User";
    public quick_search: string = "Quick Search";
    public advanced_search: string = "Advanced Search";
    public search_hint1: string = "Please enter firstname/ lastname/ userID/ email";
    public search_hint2: string = "Enter * or leave blank to display all the results";
    public search_hint3: string = "Please enter one or more property IDs, affiliate office code or 'Multi_Property' separated by comma to fetch templates";
    public search_err1: string = "Please fill mandatory fields";
    public search_err2: string = "Enter * or leave blank to display all the results";
    public search_err3: string = "Please select the field";
    public search_err4: string = "Please select the operator";
    public search_err5: string = "Please enter the value";
    public search_results: string = "Search Results";
    public active: string = "Active";
    public inActive: string = "Deactivated";
    public edit: string = "Edit";
    public user_id: string = "User Id";
    public first_name: string = "First Name";
    public last_name: string = "Last Name";
    public search_user_status: string = "Status";
    public search_active_date: string = "Active Date";
    public search_inactive_date: string = "Inactive Date";
    public search_value_err1: string = "Search Value is required";
    public search_value_err2: string = "Search Value must be at least 3 characters";
    public search_value_err3: string = "Search Value must be at least 5 characters";
    public status_tooltip_active: string = "User is activated, and user is able to login to access applications.";
    public status_tooltip_active_pending: string = "User is in an active state, but the user has not completed the activation process.";
    public status_tooltip_recovery: string = "User requests a password reset or an Admin initiates one on their behalf.";
    public status_tooltip_password_expired: string = "The password has expired and the account requires an update to the password before a user is granted access to applications.";
    public status_tooltip_locked_out: string = "User exceeds the number of login attempts or ‘Forgot Password’ attempts defined by policy.";
    public status_tooltip_suspended: string = "User cannot login to access applications, but application assignments are unaffected. Password status also remains unchanged.";
    public status_tooltip_deprovisioned: string = "All application assignments are removed, and the password is permanently deleted.";
    public status_donotdelete: string = "\"Do Not Delete\" accounts will have an extended period of time before account is removed due to non-use.";
    /**************** Question & Answers ****************/
    public welcomeMessage: string = 'Please answer the below security questions';
    public warningMessage: string = 'Please answer at least two challenge questions correctly to change password';
    public req_field: string = "This Field is required";
    public challenge_ans1: string = "Challenge Answer1";
    public challenge_ans2: string = "Challenge Answer2";
    public challenge_ans3: string = "Challenge Answer3";
    /**************** Reset Password ****************/
    public old_psw: string = "Old Password";
    public old_psw_err: string = "Please enter your old password";
    public new_psw: string = "New Password";
    public new_psw_err: string = "Please enter your new password";
    public new_psw_err2: string = "Password should contain atleast 8 characters, one upper and lower case letter, number and special character";
    public cnfrm_psw: string = "Confirm Password";
    public cnfrm_psw_err: string = "Passwords do not match";
    public change_psw: string = "Change Password";
    /**************** Update Profile ****************/
    public update_profile: string = "Update Profile"
    public uid_error: string = "User Id is required";
    public user_type_error: string = "User Type is required";
    public update_challengeQA: string = "Update Challenge Q/A";
    public update_challengeQA_note: string = "All challenge answers must be unique";
    public challenge_anw_err1: string = "Answer must contain at least 3 characters.";
    public challenge_anw_err2: string = "Challenge Answers must be unique";
    /**************** Template ****************/
    public usr_info: string = "User Information";
    public search_template: string = "Search Template";
    public user_grp: string = "User Groups";
    public template_info: string = "Template Information";
    public search_frm_template: string = "Use Template";
    public manage_template: string = "Manage Template";
    public temp_err1: string = "The only characters allowed are letters, numbers or '_' in the value 'Multi_Property'"
    public temp_hint1: string = "Please enter only one value - either propertyID, affiliate code or 'Multi_Property'"

    /**app access page constants */
    public readonly default_side_nav_app_name: string = 'Member Web';
    public readonly member_web_app_code: string = 'MemberWeb';
    public readonly app_autoclerk: string = "Autoclerk Cloud";
    public readonly invalid_fdic: string = "Invalid Front Desk Incentive Code";
    public readonly date_time_format: string = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
    public readonly locale = 'en-US';
    public readonly app_member_web: string = "Member Web";
    public readonly idm_role_res: string = 'OH-IDM-Role User Management - Restricted';
    public readonly create_user_title = 'User Management';
    public readonly non_property_users: string [] = ['AFFILIATE_ADMIN', 'AFFILIATE_STAFF', 'REGIONAL_AFFILIATE_ADMIN', 'VENDOR_ADMIN', 'VENDOR_STAFF'];
    public readonly user_type_pa: string = "PROPERTY_ADMIN";
    public readonly user_type_ps: string = "PROPERTY_STAFF";
    public readonly menu_type_div: string = "div";
    public readonly menu_type_title: string = "title";
    public readonly menu_type_radio: string = "radio";
    public readonly menu_type_checkbox: string = "checkbox";
    public readonly no_access: string = "No Access";
    public readonly empty_str: string = "";
    public readonly front_desk_cd: string = "frontDeskIncentiveCd";
}