import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/data.service';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Affiliate } from 'src/app/model/affiliate';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CONFIG, OktaAuthService } from '@okta/okta-angular';
import { GlobalConstants } from '../global-constants';
import { formatDate } from '@angular/common';
import { TemplateServiceService } from 'src/app/template-service.service';
import { UserGroups } from 'src/app/model/userGroups';
import { UsergroupsSerivice } from 'src/app/usergroups.service';
import { callbackify } from 'util';
import { CreateTemplate } from 'src/app/model/createTemplate';
import { ResortSummary } from 'src/app/model/resortSummary';
import { environment } from 'src/environments/environment';


export interface Items {
  value: string;
  viewValue: string;
}

export interface Dropdownlist {
  item_id: String;
  item_text: String;
}

export interface TemplateRoleItems {
  value: string;
  viewValue: string;
  tooltip: string;
}

@Component({
  selector: 'app-create-template',
  templateUrl: './create-template.component.html',
  styleUrls: ['./create-template.component.css']
})
export class CreateTemplateComponent implements OnInit {
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  displayDateTimeFormat = "MM/dd/yyyy' 'HH:mm:ss a'";
  locale = 'en-US';
  timeZoneOffset = new Date().getTimezoneOffset().toString();
  userType = new FormControl('', [Validators.required]);
  /*  usertypes: Items[] = [
     { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
     { value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
     { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
     { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' },
     { value: 'VENDOR_STAFF', viewValue: 'Vendor Staff' },
     { value: 'VENDOR_ADMIN', viewValue: 'Vendor Admin' }
   ]; */

  usertypes: Items[] = [];
  selectedUserType: any;
  isUserMgmt: boolean = false;
  propertyselected: any;
  selectedproperties: any[] = [];
  isSinglePropUser: boolean = false;
  propControl = new FormControl('', [Validators.required]);
  propertyID = new FormControl('', [Validators.required, Validators.pattern("^[0-9]{5}([,][0-9]{5})*$")]);
  templateName = new FormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9@_^$&, ]{5,30}$")]);
  votingMember = new FormControl('', [Validators.required]);
  isPropertyUser: boolean = true;
  isAffiliateUser: boolean = false;
  votingMemb: boolean = false;
  userMgmt = new FormControl('', [Validators.required])
  propID: any;
  tempID: any;
  tempCreatedBy: string;
  tempCreatedDate: string;
  displayCreatedDate: string;
  profile: TemplateProfile = new TemplateProfile();
  userGroupsControl = new FormControl('');
  dropdownList: Dropdownlist[] = [];
  dropdownSettings!: IDropdownSettings;
  selectedItems = [];
  isPDRuser: boolean;
  loggedInUser: any;
  isSuperEmployee: boolean;
  sortedDropDownList: String[] = [];
  affiliateOffices: Items[] = [];
  affiliatePdrMap: Map<string, boolean> = new Map();
  affilateOfficeCode!: Affiliate;
  affiliateOfficeControl = new FormControl('', [Validators.required]);
  isaffiliatePDR: boolean = false;
  selectedAffiliate: string = '';
  email: any;
  invalidPropMessage: string;
  inactivePropMessage: string;
  invalidPropControl = new FormControl('', [Validators.required]);
  inactivePropControl = new FormControl('', [Validators.required]);
  accessToken: any;
  affiliateCodeControl = new FormControl('', [Validators.required]);
  affiliatecodeselected: string;
  hasError: boolean;
  isMultipleUsers: boolean = true;
  isSingleUser: boolean = false;
  worldhotelProps: any[];
  templateRoleControl = new FormControl('', [Validators.required]);
  selectedTemplateRole: string;
  templateRoles: TemplateRoleItems[] = [];

  constructor(public oktaAuth: OktaAuthService, public templateService: TemplateServiceService, public apiDataService: DataService, public usergroups: UsergroupsSerivice, public datePipe: DatePipe, public router: Router, public globalConstants: GlobalConstants) {

    this.isPDRuser = false;
    this.apiDataService.userclaims = JSON.parse(localStorage.getItem("userClaims")!);
    this.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';

    this.loggedInUser = this.apiDataService.userclaims.userRole;
    if (this.loggedInUser == 'PROPERTY_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
      this.isMultipleUsers = false;
      this.isSingleUser = true;
      this.selectedUserType = "PROPERTY_STAFF";
    }
    else if (this.loggedInUser == 'PROPERTY_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
      this.isMultipleUsers = false;
      this.isSingleUser = true;
      this.selectedUserType = "PROPERTY_STAFF";
    }
    else if (this.loggedInUser == 'AFFILIATE_STAFF') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }
    else if (this.loggedInUser == 'AFFILIATE_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' });
    }
    else if (this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }
    else if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == true) {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' },
        { value: 'AFFILIATE_STAFF', viewValue: 'Affiliate Staff' },
        { value: 'AFFILIATE_ADMIN', viewValue: 'Affiliate Admin' });
    }

    else if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == false) {
      this.usertypes.push({ value: 'PROPERTY_ADMIN', viewValue: 'Property Admin' },
        { value: 'PROPERTY_STAFF', viewValue: 'Property Staff' });
    }


    if (typeof this.apiDataService.userclaims.properties != 'undefined' && this.apiDataService.userclaims.properties != null)
      this.sortedDropDownList = this.apiDataService.userclaims.properties.sort();
    if (this.sortedDropDownList.length == 1) {
      this.selectedproperties = this.sortedDropDownList;
      this.isSinglePropUser = true;
    }

    if (this.loggedInUser == "EMPLOYEE") {
      this.apiDataService.getAffiliateOfficesforEmp(this.apiDataService.accessToken).then((data: String) => {
        let affilateOfficeCodes: Affiliate[] = JSON.parse(JSON.stringify(data));
        for (let i = 0; i < affilateOfficeCodes.length; i++) {
          this.affiliateOffices.push({ value: affilateOfficeCodes[i].code, viewValue: affilateOfficeCodes[i].code + "--" + affilateOfficeCodes[i].name })
          this.affiliatePdrMap.set(affilateOfficeCodes[i].code, affilateOfficeCodes[i].pdr);
        }
        this.sortAffiliates(this.affiliateOffices);
      },
        (error: any) => {
          console.log(error);
        });
    }
    else if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' ||
      this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.apiDataService.getAffiliateOffices(this.apiDataService.userclaims.affiliateOffice, this.apiDataService.accessToken).then((data: string) => {
        this.affilateOfficeCode = JSON.parse(JSON.stringify(data));
        if (this.affilateOfficeCode != null) {
          if (this.affilateOfficeCode.regionalAffiliate) {
            for (let i = 0; i < this.affilateOfficeCode.affiliates.length; i++) {
              this.affiliateOffices.push({ value: this.affilateOfficeCode.affiliates[i].code, viewValue: this.affilateOfficeCode.affiliates[i].code + "--" + this.affilateOfficeCode.affiliates[i].name })
            }
            this.sortAffiliates(this.affiliateOffices);
          } else {
            this.affiliateOffices.push({ value: this.affilateOfficeCode.code, viewValue: this.affilateOfficeCode.code + "--" + this.affilateOfficeCode.name })
          }
          if (typeof this.affilateOfficeCode.properties != 'undefined' && this.affilateOfficeCode.properties != null)
            this.sortedDropDownList = this.affilateOfficeCode.properties.sort();
          if (typeof this.affilateOfficeCode != 'undefined' && this.affilateOfficeCode.pdr == true) {
            this.isPDRuser = true;
          }
        }
      },
        (error: any) => {
          console.log(error);
        });
    }

    this.templateRoles.push({ value: environment.user_roles.admin_role, viewValue: 'Admin User', tooltip: 'Can create and edit templates. Also, can create or edit a user with or without a template' },
      { value: environment.user_roles.power_role, viewValue: 'Power User', tooltip: 'Cannot create or edit templates. But can create or edit a user with or without a template' },
      { value: environment.user_roles.restricted_role, viewValue: 'Restricted User', tooltip: 'Cannot create or edit templates. Also, can only create or edit a user using a template' },
      { value: environment.user_roles.no_user_mgnt, viewValue: 'No User Management', tooltip: 'Does not have user Management privilege. Cannot create or manage users or templates.' });
  }

  sortAffiliates(affiliateOffices: any[]) {
    affiliateOffices.sort((left, right): number => {
      if (left.value < right.value) return -1;
      if (left.value > right.value) return 1;
      return 0;
    });
  }

  onAffSelect() {
    this.isaffiliatePDR = this.affiliatePdrMap.get(this.selectedAffiliate)!;
    this.affiliatecodeselected = this.selectedAffiliate;
    this.affiliateCodeControl.setValue(this.selectedAffiliate);
  }


  onCategoryChange(event: any) {
    this.isAffiliateUser = false;
    this.isPropertyUser = false;
    // if (event.value == 'PROPERTY_ADMIN' || event.value == 'AFFILIATE_ADMIN' || event.value == 'REGIONAL_AFFILIATE_ADMIN') {
    //   this.isUserMgmt = true;
    // }
    // else {
    //   this.isUserMgmt = false;
    // }
    if (this.selectedUserType === "AFFILIATE_STAFF" || this.selectedUserType === "AFFILIATE_ADMIN" || this.selectedUserType === 'REGIONAL_AFFILIATE_ADMIN') {
      this.isAffiliateUser = true;
      this.isPropertyUser = false;
    }
    if (this.selectedUserType === "PROPERTY_STAFF" || this.selectedUserType === "PROPERTY_ADMIN") {
      this.isPropertyUser = true;
      this.isAffiliateUser = false;
    }
  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.tempCreatedBy = this.apiDataService.userclaims.userId;
    let date = new Date();
    let timezone = new Date().toLocaleDateString('en-US', {
      day: '2-digit',
      timeZoneName: 'short',
    }).slice(4);
    this.displayCreatedDate = formatDate(date, this.displayDateTimeFormat, this.locale) + " " + timezone;
    let tempCreatedDate = new Date(date).toLocaleString('en-US', {
      timeZone: "Africa/Accra" //GMT
    });
    this.tempCreatedDate = formatDate(tempCreatedDate, this.dateTimeFormat, this.locale);
    this.dropdownList = [
      { item_id: 'MPRD_GOV', item_text: 'Allow Governors content access' },
      { item_id: 'MPRD_IC', item_text: 'Allow International content access' },
      { item_id: 'MP_SOFT', item_text: 'BW Premier Collection' },
      { item_id: 'MPRD_ADMIN', item_text: 'Member portal Admin ( Developers) Administrator / Help Desk Admin' },
      { item_id: 'MPRD_HOTELPORTAL', item_text: 'Sure Stay Brand Group' }
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 30,
      allowSearchFilter: true
    };
    let isReturnFromMembTemp = localStorage.getItem('isReturnFromCreateMembTemp');
    if (isReturnFromMembTemp == 'Y') {
      if (this.apiDataService.user.templateProfile.templateName != null || this.apiDataService.user.templateProfile.templateName != undefined) {
        this.apiDataService.getInvokeEvent(this.apiDataService.user.templateProfile.templateName);
      } else {
        this.apiDataService.getInvokeEvent(this.globalConstants.idm);
      }

      this.setFromApiDataService();
    }
    this.templateService.saveCreateTemplate.subscribe((data) => {
      if (data == true) {
        this.saveCreateTemplateData();
      }
    })
  }

  async saveCreateTemplateData() {
    await this.ValidateMandatoryFields().then((data) => {
      this.hasError = data;
    }, (error) => {
      console.log(error);
    })
    this.profile.userType = this.userType.value;
    this.profile.userType = this.selectedUserType;
    // this.profile.userManagement = this.userMgmt.value;
    // this.profile.userManagement = this.isUserMgmt;
    let propid = '';
    if (this.selectedUserType == null || this.selectedUserType == undefined) {
      if (this.propertyselected != null || this.propertyselected != undefined) {
        propid = this.propertyselected;
        console.log(propid);
        const prop = propid.split(',');
        this.profile.propertyID = prop;
      } else {
        this.profile.propertyID = null;
      }
    }
    if (this.isPropertyUser && this.loggedInUser == 'EMPLOYEE') {
      if (this.propertyselected != null || this.propertyselected != undefined) {
        propid = this.propertyselected;
        console.log(propid);
        const prop = propid.split(',');
        this.profile.propertyID = prop;
        if (prop.length == 1) {
          this.profile.ID = propid;
        }
        if (prop.length > 1) {
          this.profile.ID = 'Multi_Property';
        }
      } else {
        this.profile.propertyID = null;
      }
    }
    if (this.isPropertyUser && this.loggedInUser != 'EMPLOYEE') {
      this.profile.propertyID = this.selectedproperties;
      if (this.profile.propertyID.length == 1) {
        this.profile.ID = this.selectedproperties[0];
      }
      if (this.profile.propertyID.length > 1) {
        this.profile.ID = 'Multi_Property';
      }
    }
    if (this.isAffiliateUser) {
      this.profile.ID = this.selectedAffiliate;
      this.profile.propertyID = null;
    }
    this.profile.templateName = this.templateName.value;
    this.profile.selectedItems = this.selectedItems;
    this.profile.affiliateOffice = this.selectedAffiliate;
    this.profile.createdBy = this.apiDataService.userEmail;
    let date = new Date();
    let tempCreatedDate = formatDate(date, this.dateTimeFormat, this.locale);
    this.profile.createdDate = tempCreatedDate;
    if (this.hasError == false) {
      this.profile.templateid = this.userType.value + '_' + this.generateUserId(10);
    } else {
      this.profile.templateid = null;
    }
    this.apiDataService.user = new CreateTemplate();
    this.apiDataService.user.templateProfile = this.profile;
    this.apiDataService.user.isDelete = false;
    this.apiDataService.user.delID = null;
    this.apiDataService.user.delTemplateID = null;
    this.templateService.getValueOfTabIndex(1);
  }

  setFromApiDataService() {
    this.templateName.setValue(this.apiDataService.user.templateProfile.templateName);
    this.userType.setValue(this.apiDataService.user.templateProfile.userType);
    this.selectedUserType = this.apiDataService.user.templateProfile.userType;
    // this.userMgmt.setValue(this.apiDataService.user.templateProfile.userManagement);
    // this.isUserMgmt = this.apiDataService.user.templateProfile.userManagement;
    if (this.selectedUserType == null || this.selectedUserType == undefined) {
      if (this.apiDataService.user.templateProfile.propertyID != null) {
        const prop = this.apiDataService.user.templateProfile.propertyID;
        for (let i = 0; i < prop.length; i++) {
          if (i == 0) {
            this.propertyselected = prop[i];
          } else {
            this.propertyselected = this.propertyselected + ',' + prop[i];
          }
        }
      } else {
        this.propertyselected = '';
      }
    }
    if ((this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'PROPERTY_STAFF') && this.loggedInUser == 'EMPLOYEE') {
      if (this.apiDataService.user.templateProfile.propertyID != null) {
        const prop = this.apiDataService.user.templateProfile.propertyID;
        for (let i = 0; i < prop.length; i++) {
          if (i == 0) {
            this.propertyselected = prop[i];
          } else {
            this.propertyselected = this.propertyselected + ',' + prop[i];
          }
        }
      } else {
        this.propertyselected = '';
      }
    }
    if ((this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'PROPERTY_STAFF') && this.loggedInUser != 'EMPLOYEE') {
      this.selectedproperties = this.apiDataService.user.templateProfile.propertyID;
    }
    console.log(this.propertyselected);
    this.selectedItems = this.apiDataService.user.templateProfile.selectedItems;
    this.selectedAffiliate = this.apiDataService.user.templateProfile.affiliateOffice;
    this.affiliatecodeselected = this.selectedAffiliate;
    this.affiliateOfficeControl.setValue(this.apiDataService.user.templateProfile.affiliateOffice);
    if (this.apiDataService.user.templateProfile.userType == 'AFFILIATE_ADMIN' || this.apiDataService.user.templateProfile.userType == 'AFFILIATE_STAFF') {
      this.isAffiliateUser = true;
      this.isPropertyUser = false;
    }
    if (this.apiDataService.user.templateProfile.userType == 'PROPERTY_STAFF' || this.apiDataService.user.templateProfile.userType == 'PROPERTY_ADMIN') {
      this.isPropertyUser = true;
      this.isAffiliateUser = false;
    }
    localStorage.setItem('isSwitchTabs', 'Y');
    this.selectedTemplateRole = this.apiDataService.user.templateProfile.templateRole;
    this.templateRoleControl.setValue(this.apiDataService.user.templateProfile.templateRole);
  }

  async ValidateMandatoryFields() {
    let hasErrors = false;
    if (this.userType.errors) {
      this.userType.markAsTouched();
      hasErrors = true;
    }
    if ((this.loggedInUser == 'PROPERTY_STAFF' || this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' || this.loggedInUser == 'AFFILIATE_ADMIN') && this.isPropertyUser) {
      if (this.propControl.errors) {
        this.propControl.markAsTouched();
        hasErrors = true;
      }
    }
    if (this.loggedInUser == 'EMPLOYEE' && this.isPropertyUser) {
      if (this.propertyID.errors) {
        this.propertyID.markAsTouched();
        hasErrors = true;
      }
    }
    if (this.templateName.errors) {
      this.templateName.markAsTouched();
      hasErrors = true;
    }

    if (this.selectedUserType == 'PROPERTY_STAFF' || this.selectedUserType == 'AFFILIATE_STAFF') {
      if (this.templateRoleControl.errors) {
        this.templateRoleControl.markAsTouched();
        hasErrors = true;
      }
    }

    if (this.isAffiliateUser) {
      if (this.affiliateOfficeControl.errors) {
        this.affiliateOfficeControl.markAsTouched();
        hasErrors = true;
      }
      if (this.affiliateCodeControl.errors) {
        this.affiliateCodeControl.markAsTouched();
        hasErrors = true;
      }
    }
    return hasErrors;
  }

  async submit() {
    if (await this.ValidateMandatoryFields()) {
      return;
    }
    this.templateService.getIsNextButtonClicked(true);
    this.profile = new TemplateProfile();
    this.profile.userType = this.userType.value;
    this.profile.userType = this.selectedUserType;
    if (this.selectedUserType == 'PROPERTY_ADMIN' || this.selectedUserType == 'AFFILIATE_ADMIN') {
      this.profile.templateRole = environment.user_roles.admin_role;
    } else {
      this.profile.templateRole = this.selectedTemplateRole;
    }
    // if (this.profile.templateRole == '00g18vgrq1wxUNGPK0h8') {
    //   this.profile.userManagement = false;
    // } else {
    //   this.profile.userManagement = true;
    // }
    let propid = '';
    console.log(this.propertyselected);
    if (this.isPropertyUser && this.loggedInUser == 'EMPLOYEE') {
      propid = this.propertyselected;
      const prop = propid.split(',');
      await this.validateProperties(prop, this.accessToken);
      if (this.invalidPropMessage != '' || this.inactivePropMessage != '') {
        return;
      }
      console.log(this.worldhotelProps);
      if (prop.length == this.worldhotelProps.length) {
        this.apiDataService.isWorldHotel = true;
      } else {
        this.apiDataService.isWorldHotel = false;
      }
      this.profile.propertyID = prop;
      if (prop.length == 1) {
        this.profile.ID = propid;
      }
      if (prop.length > 1) {
        this.profile.ID = 'Multi_Property';
      }
    }
    if (this.isPropertyUser && this.loggedInUser != 'EMPLOYEE') {
      this.profile.propertyID = this.selectedproperties;
      if (this.selectedproperties.length == 0 && (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF')) {
        this.profile.ID = this.apiDataService.userclaims.affiliateOffice + '_Property';
      }
      await this.validateProperties(this.selectedproperties, this.accessToken);
      if (this.selectedproperties.length == this.worldhotelProps.length && this.selectedproperties.length != 0) {
        this.apiDataService.isWorldHotel = true;
      } else {
        this.apiDataService.isWorldHotel = false;
      }
      if (this.profile.propertyID.length == 1) {
        this.profile.ID = this.selectedproperties[0];
      }
      if (this.profile.propertyID.length > 1) {
        this.profile.ID = 'Multi_Property';
      }
    }




    //  let userGroupdata: UserGroups = await this.usergroups.addGroups(this.selectedAffiliate, this.profile.propertyID, this.accessToken);
    //  console.log(userGroupdata.inactivePropMessage);
    //  if (userGroupdata.hasError)
    //    return;
    //  if (userGroupdata.invalidPropMessage || userGroupdata.inactivePropMessage) {
    //     this.invalidPropertyMessage = userGroupdata.errorMessage.replace("~~~", "\n");
    //    if (userGroupdata.invalidPropMessage) {
    //      this.invalidPropMessage = userGroupdata.invalidPropMessage;
    //      this.propertyID.setErrors({ invalidProp: true })
    //      this.propertyID.markAsTouched();
    //    }
    //    if (userGroupdata.inactivePropMessage) {
    //      this.inactivePropMessage = userGroupdata.inactivePropMessage;
    //      this.propertyID.setErrors({ inactiveProp: true })
    //      this.propertyID.markAsTouched();
    //    }
    //    return;
    //  }

    // this.apiDataService.getPropDetails(this.profile.propertyID, this.accessToken).then((data)=>{
    //   console.log(data);
    //   if( data.inactiveProperties.length > 0){
    //     this.inactivePropMessage = "Inactive Properties : " + data.inactiveProperties;
    //     return;
    //   }
    //   if( data.invalidProperties.length > 0){
    //     this.invalidPropMessage = "Invalid Properties : " + data.invalidProperties;
    //     return;
    //   }
    //   this.profile.propertyID = [];
    //   for(let i=0; i<data.activeProperties.length; i++){
    //     this.profile.propertyID.push(data.activeProperties[i]);
    //   }
    //   console.log('propID', this.profile.propertyID);

    // })



    if (this.isAffiliateUser) {
      this.profile.ID = this.selectedAffiliate;
      this.profile.propertyID = null;
    }
    console.log(this.profile.propertyID);
    this.profile.templateName = (this.templateName.value).toUpperCase();
    this.profile.templateid = this.userType.value + '_' + this.generateUserId(10);
    this.profile.createdBy = this.apiDataService.userEmail;
    this.profile.createdDate = this.tempCreatedDate;
    this.profile.selectedItems = this.selectedItems;
    console.log(this.selectedItems);
    this.profile.affiliateOffice = this.selectedAffiliate;
    this.apiDataService.user = new CreateTemplate();
    this.apiDataService.user.templateProfile = this.profile;
    this.apiDataService.user.isDelete = false;
    this.apiDataService.user.delID = null;
    this.apiDataService.user.delTemplateID = null;
    this.router.navigate(['pages/manageTemplate/createMemberwebTemplate']);
    this.templateService.getValueOfTabIndex(1);
    //const body = JSON.stringify(this.profile);

    //this.apiDataService.postTemplate(body).then((data: string) => {
    // console.log("Success");
    // }, (error) => {
    // console.log(error);
    // })
    //this.resetControls();
  }

  async validateProperties(selectedproperties: string[], accessToken: any) {
    let resortSummary: ResortSummary;
    let invalidProps: string[] = [];
    let inactiveProps: string[] = [];
    this.worldhotelProps = [];
    this.inactivePropMessage = "";
    this.invalidPropMessage = "";
    try {
      resortSummary = JSON.parse(JSON.stringify(await this.apiDataService.getPropDetails(selectedproperties, accessToken)));
      console.log('resortsummary', resortSummary);
      if (resortSummary.inactiveProperties.length > 0) {
        this.propertyID.setErrors({ inactiveProp: true });
        this.propertyID.markAsTouched();
        resortSummary.inactiveProperties.forEach((item) => {
          inactiveProps.push(item);
        });
        this.inactivePropMessage = this.globalConstants.inactive_resorts + inactiveProps;
      }
      if (resortSummary.invalidProperties.length > 0) {
        this.propertyID.setErrors({ invalidProp: true });
        this.propertyID.markAsTouched();
        resortSummary.invalidProperties.forEach((item) => {
          invalidProps.push(item);
        });
        this.invalidPropMessage = this.globalConstants.invalid_resorts + invalidProps;
      }
      if (resortSummary.activeProperties.length > 0) {
        for (let i = 0; i < resortSummary.activeProperties.length; i++) {
          if (resortSummary.activeProperties[i].chainCode! && 
            (resortSummary.activeProperties[i].chainCode == 'WW' || resortSummary.activeProperties[i].chainCode == 'DO')) {
            this.worldhotelProps.push(resortSummary.activeProperties[i]);
          }
        }
      }
    }
    catch (error) {
      // this.globalErrorHandlerService.handleError(error);
      return;
    }
  }

  public generateUserId(length: number) {
    var userId = '';
    var characters = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ987654321';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      userId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return userId;
  }


  resetControls() {
    if (this.isMultipleUsers) {
      this.userType.reset();
      this.selectedUserType = '';
    }
    this.templateName.reset();
    // this.userMgmt.reset();
    this.propertyID.reset();
    if (!this.isSinglePropUser) {
      this.propControl.reset();
    }
    this.votingMember.reset();
    this.affiliateOfficeControl.reset();
    this.userGroupsControl.reset();
    this.isAffiliateUser = false;
    this.isPropertyUser = true;
    this.apiDataService.getInvokeEvent(this.globalConstants.idm);
    this.templateRoleControl.reset();
  }

  displayTemplate(event: any) {
    this.apiDataService.getInvokeEvent(event.target.value);
    this.apiDataService.user.templateProfile.templateName = event.target.value;

  }

  onTemplateRoleChange(event) {
    this.selectedTemplateRole = event.value;
    this.templateRoleControl.setValue(event.value);
  }


}

