import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OktaAuthService } from '@okta/okta-angular';
import { DataService } from 'src/app/data.service';
import { SearchUserComponent } from '../search-user/search-user.component';

@Component({
  selector: 'app-bulk-apply-popup',
  templateUrl: './bulk-apply-popup.component.html',
  styleUrls: ['./bulk-apply-popup.component.css']
})
export class BulkApplyPopupComponent implements OnInit  {
  @Input() isConfirmed : boolean;

  constructor(public oktaAuth: OktaAuthService, public dataService: DataService, public dialog: MatDialog) {}

 ngOnInit() {
    
  }

  exit() {
    this.dialog.closeAll();
  }

  apply() {
    let id = this.generateRandomNumber(8);
    console.log(id);
    this.dataService.invokeBulkApply.emit(id);
    this.dialog.closeAll();
  }

  public generateRandomNumber(length) {
    var userId = '';
    var characters = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ987654321';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      userId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return userId;
  }

}
