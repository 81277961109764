import { Component, Inject, OnInit } from '@angular/core';
import { QuestionAnswersComponent } from '../question-answers/question-answers.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
  description: String,
  title: String,
  note: String
}

@Component({
  selector: 'app-three-button-popup',
  templateUrl: './three-button-popup.component.html',
  styleUrls: ['./three-button-popup.component.css']
})
export class ThreeButtonPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<QuestionAnswersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,  private router: Router) { }

  ngOnInit(): void {
  }

  onButtonClick(i){
    this.dialogRef.close(i);
  }
}
