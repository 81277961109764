import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppMenuItem, MultiAppData, SelectedMenuData } from 'src/app/model/multi-app-access.model';

@Injectable({
  providedIn: 'root'
})
export class MultiAppAccessDataService {
  radioSelectionChange = new Subject<AppMenuItem>();
  selectedMenuData = new BehaviorSubject<SelectedMenuData[]>([]);

  constructor() { }

  updateSelection(appDetails: MultiAppData[], appMenuItems: AppMenuItem[], selectedAppData: MultiAppData) {
    let selectedDataList: SelectedMenuData[] = [];
    let selectedData: SelectedMenuData;
    selectedData.appDetails = selectedAppData;
    appMenuItems.forEach(item => {
      console.log(item);
    })
    
  }
}
