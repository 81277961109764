import { PagesModule } from './pages/pages.module';
import { AppModule } from './app.module';
import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  { path: '', redirectTo: 'pages', pathMatch: 'full'},
  { path: '**', redirectTo: 'pages/home' }  
];


export const routing: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes);