<header class="header-container">
    <div class="header-logo">
        <img src="assets/bwh-hotel-group.png" height="60px">
        <h4 class="header-title">{{errorMessage | titleCase}}</h4>
    </div>
</header>
<div class="okta-error-msg">
    <p>{{ errorDescription}}</p>
</div>
<div class="okta-error-btn">
    Click <a (click)="reLogin()"> Sign In </a> to return to the Okta login page.
</div>