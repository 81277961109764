<div class="page-content">
    <div class="flex-container">
        <mat-form-field class="mat-field-col-4 mat-input-border">
            <mat-label>Enter Property Number </mat-label>
            <input matInput #retreiveBtn [formControl]="propNumber">
            <mat-error *ngIf="propNumber.invalid && propNumber.touched">
                <span *ngIf="propNumber.hasError('required')">{{globalConstants.network_prop_req_err}}</span>
                <span *ngIf="propNumber.hasError('minlength')">{{globalConstants.network_prop_len_err}}</span>
                <span *ngIf="propNumber.hasError('maxlength')">{{globalConstants.network_prop_len_err}}</span>
                <span *ngIf="propNumber.hasError('pattern')">{{globalConstants.network_prop_num_err}}</span>
                <span *ngIf="propNumber.hasError('invalidProp')">{{globalConstants.network_prop_invalid_err}}</span>
                <span *ngIf="propNumber.hasError('inactiveProp')">{{globalConstants.network_prop_inactive_err}}</span>
                <span *ngIf="propNumber.hasError('noaccessProp')">{{globalConstants.network_prop_noaccess_err}}</span>
            </mat-error>
        </mat-form-field>
        <div class="mat-field-col-4">
            <button [disabled]="propNumber.invalid" class="button button-medium" mat-raised-button
                (click)="searchIpAddress()">Retrieve</button>
        </div>
    </div>
    <div *ngIf="isContentEnabled" class="flex-container container-pad">
        <mat-form-field class="mat-field-col-3 mat-input-border">
            <mat-label>Enter IP Address*</mat-label>
            <input matInput [formControl]="ipAddress">
            <mat-error *ngIf="ipAddress.invalid && ipAddress.touched">
                <span *ngIf="ipAddress.hasError('required')">{{globalConstants.ip_req_err}}</span>
                <span *ngIf="ipAddress.hasError('invalidInput')">{{globalConstants.ip_invalid_input_err}}</span>
                <span *ngIf="ipAddress.hasError('invalidIp')">{{globalConstants.ip_invalid_err}}</span>
                <span *ngIf="ipAddress.hasError('tooManyIps')">{{globalConstants.ip_too_many_err}}</span>
                <span *ngIf="ipAddress.hasError('blockedIp')">{{globalConstants.ip_blocked_err}}</span>
            </mat-error>
        </mat-form-field>
        <mat-form-field class="mat-field-col-3 mat-input-border">
            <mat-label>Enter Comment </mat-label>
            <input matInput [formControl]="comment">
        </mat-form-field>
        <div class="mat-field-col-3">
            <button [disabled]="propNumber.invalid || ipAddress.invalid" class="button button-medium" mat-raised-button
                (click)="addIpAddress()">Add</button>
        </div>
    </div>
    <div *ngIf="isContentEnabled" class="flex-container container-pad">
        <table mat-table [dataSource]="ipAddressData" class="mat-elevation-z8">
            <!-- IP Address Column -->
            <ng-container matColumnDef="ip">
                <th mat-header-cell *matHeaderCellDef> IP Address </th>
                <td mat-cell *matCellDef="let element"> {{element.ip}} </td>
            </ng-container>

            <!-- Comment Column -->
            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef> Comment </th>
                <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <button class="button button-medium" mat-raised-button
                        (click)="deleteIpAddress(element)">Delete</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>