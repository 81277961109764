import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { User } from 'src/app/model/user';
import { UserAuthService } from 'src/app/user-auth.service';
import { DataService } from 'src/app/data.service';
import { Affiliate } from 'src/app/model/affiliate';
import { GlobalConstants } from '../global-constants';
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { environment } from '../.././../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  titleDisplay: string = this.globalConstants.idm;
  isAuthenticated: boolean = false;
  user: User;
  userName: string;
  isDataAvailable: boolean = false;
  isUserMgmtAllowed: boolean = false;
  userClaims: any;
  isSuperEmployee: boolean;
  showTemplateScreen: boolean = false;
  loggedInTemplatePermission: string = '';
  loggedInUser: string = '';
  isVendorUser: boolean = false;
  isMenuOpen: boolean = false;
  
  constructor(
    private oktaAuth: OktaAuthService,
    private myservice: UserAuthService,
    private router: Router,
    private dataService: DataService,
    public globalConstants: GlobalConstants,
    private eRef: ElementRef,
    private globalErrorHandlerService: GlobalErrorHandlerService) {
      this.myservice.userInfo().then((data: any) => {
        console.log(data)
      });
  }

  async ngOnInit() {
    this.isAuthenticated = this.myservice.isAuthenticated;
    if (this.isAuthenticated) {
      this.userClaims = await this.oktaAuth.getUser();
      localStorage.setItem('userClaims', JSON.stringify(this.userClaims));
      this.dataService.accessToken = await this.oktaAuth.getAccessToken()
      this.dataService.userclaims = this.userClaims;
      this.loggedInUser = this.userClaims.userRole;
      this.loggedInTemplatePermission = this.userClaims.idm_role[0];
      if (this.globalConstants.templatesAccessGroups.includes(this.loggedInTemplatePermission)) {
        this.showTemplateScreen = true;
      } else {
        this.showTemplateScreen = false;
      }
      this.userName = this.userClaims.firstName + ' ' + this.userClaims.lastName;
      this.showCreateModifyUser(this.userClaims.userRole, this.loggedInTemplatePermission);
      if (this.userName != null && this.userName != undefined) {
        this.isDataAvailable = true;
      }
    }
    if (this.dataService.userclaims.userRole == "VENDOR_ADMIN" || this.dataService.userclaims.userRole == "VENDOR_STAFF") {
      this.isVendorUser = true;
    }
    if (this.dataService.userclaims.userRole == "AFFILIATE_ADMIN" || this.dataService.userclaims.userRole == "REGIONAL_AFFILIATE_ADMIN") {
      this.dataService.getAffiliateOffices(this.dataService.userclaims.affiliateOffice, this.dataService.accessToken).then((data: string) => {
        let affilateOfficeCode: Affiliate = JSON.parse(JSON.stringify(data));
        if (affilateOfficeCode.regionalAffiliate == true) {
          this.dataService.userclaims.userRole = "REGIONAL_AFFILIATE_ADMIN";
        }
        this.dataService.userclaims.properties = affilateOfficeCode.properties;
        localStorage.setItem('userClaims', JSON.stringify(this.userClaims));
      },
        (error) => {
          console.log(error);
          this.globalErrorHandlerService.handleError(error);
        });
    }
    const editObject = JSON.parse(localStorage.getItem('editTempObject'));
    if (localStorage.getItem('isDispEditTemplate') == 'Y') {
      setTimeout(() => {
        this.titleDisplay = editObject.templateName;
      })
    }
    this.dataService.invokeEvent.subscribe((data: any) => {
      this.titleDisplay = data;
    }, (error: any) => {
      console.log(error);
    })
  }

  // Toggle the visibility of the mobile menu
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  toggleMenuIfOpen() {
    if (this.isMenuOpen) {
      this.isMenuOpen = false;
    }
  }

  redirectTohome() {
    this.toggleMenuIfOpen();
    this.titleDisplay = this.globalConstants.idm;
    this.router.navigate(['pages/home']);
    localStorage.removeItem('isReturnFromCreateMembTemp');
    this.dataService.isWorkInProgress = false;
    localStorage.removeItem('isReqFromCreateTemp');
    this.dataService.isSetFromTemplate = false;
    localStorage.removeItem('isRequestFromEditScreen');
    localStorage.removeItem('isRequestFromCreateScreen');
  }

  redirectToNetworking() {
    this.toggleMenuIfOpen();
    this.titleDisplay = this.globalConstants.idm;
    localStorage.removeItem('isReturnFromCreateMembTemp');
    this.dataService.isWorkInProgress = false;
    localStorage.removeItem('isReqFromCreateTemp');
    this.dataService.isSetFromTemplate = false;
    localStorage.removeItem('isRequestFromEditScreen');
    localStorage.removeItem('isRequestFromCreateScreen');
    this.router.navigate(['pages/networking']);
  }

  redirectToEdit() {
    this.toggleMenuIfOpen();
    this.titleDisplay = this.globalConstants.idm;
    this.router.navigate(['pages/search-user']);
    localStorage.removeItem('isReturnFromCreateMembTemp');
    this.dataService.isWorkInProgress = false;
    localStorage.removeItem('isReqFromCreateTemp');
    this.dataService.isSetFromTemplate = false;
    localStorage.removeItem('isRequestFromEditScreen');
    localStorage.removeItem('isRequestFromCreateScreen');
  }
  redirectTousers() {
    this.toggleMenuIfOpen();
    this.titleDisplay = this.globalConstants.idm;
    this.router.navigate(['pages/create-user']);
    localStorage.removeItem('isReturnFromCreateMembTemp');
    this.dataService.isWorkInProgress = false;
    localStorage.removeItem('isReqFromCreateTemp');
    this.dataService.isSetFromTemplate = false;
    localStorage.removeItem('isRequestFromEditScreen');
    localStorage.removeItem('isRequestFromCreateScreen');
    localStorage.removeItem('createTempUser');
    localStorage.setItem("isButtonClicked", "N");
  }
  redirectToProfile() {
    this.toggleMenuIfOpen();
    this.titleDisplay = this.globalConstants.idm;
    this.router.navigate(['pages/update-profile']);
    localStorage.removeItem('isReturnFromCreateMembTemp');
    this.dataService.isWorkInProgress = false;
    localStorage.removeItem('isReqFromCreateTemp');
    this.dataService.isSetFromTemplate = false;
    localStorage.removeItem('isRequestFromEditScreen');
    localStorage.removeItem('isRequestFromCreateScreen');
  }
  redirectToManageTemplate() {
    this.toggleMenuIfOpen();
    this.titleDisplay = this.globalConstants.idm;
    this.router.navigate(['pages/searchTemplate']);
    localStorage.removeItem('isReturnFromCreateMembTemp');
    this.dataService.isWorkInProgress = false;
    localStorage.removeItem('isReqFromCreateTemp');
    this.dataService.isSetFromTemplate = false;
    localStorage.removeItem('isRequestFromEditScreen');
    localStorage.removeItem('isRequestFromCreateScreen');
  }

  showCreateModifyUser(userRole: string, idm_role: string) {
    if (userRole === 'PROPERTY_ADMIN' || userRole === 'VENDOR_ADMIN' || userRole === 'AFFILIATE_ADMIN' || userRole === 'REGIONAL_AFFILIATE_ADMIN') {
      this.isUserMgmtAllowed = true;
    } else if ((userRole === 'PROPERTY_STAFF' || userRole === 'AFFILIATE_STAFF') && idm_role != 'OH-IDM-Role User Management - None') {
      this.isUserMgmtAllowed = true;
    }
    else if (userRole === 'EMPLOYEE') {
      if (this.userClaims.idm_scope != null) {
        let x: string[] = this.userClaims.idm_scope;
        for (let i = 0; i < x.length; i++) {
          if (x[i].toLowerCase() == this.globalConstants.devAllIdmGroup.toLowerCase() ||
            x[i].toLowerCase() == this.globalConstants.qaAllIdmGroup.toLowerCase() ||
            x[i].toLowerCase() == this.globalConstants.prodAllIdmGroup.toLowerCase()) {
            this.isSuperEmployee = true;
            break;
          }
          else if (x[i].toLowerCase() == this.globalConstants.devIdmGroup.toLowerCase() ||
            x[i].toLowerCase() == this.globalConstants.qaIdmGroup.toLowerCase() ||
            x[i].toLowerCase() == this.globalConstants.prodIdmGroup.toLowerCase()) {
            this.isSuperEmployee = false;
            break;
          }
        }
        if (this.loggedInTemplatePermission == environment.corp_groups.corp_autoclerk_none) {
          this.isUserMgmtAllowed = false;
        }
        else {
          this.isUserMgmtAllowed = true;
        }
        //  this.dataService.isSuperEmployee = this.isSuperEmployee;  
        localStorage.setItem('isSuperEmployee', String(this.isSuperEmployee));
      }
    }
  }

  logout() {
    // Terminates the session with Okta and removes current tokens.
    localStorage.removeItem('isDispEditTemplate');
    localStorage.removeItem('isReqFromCreateTemp');
    localStorage.removeItem('isRequestFromEditScreen');
    this.oktaAuth.logout();
    this.router.navigateByUrl(environment.navigateSignOut);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (this.isMenuOpen && !this.eRef.nativeElement.contains(event.target)) {
      this.isMenuOpen = false; // Close the menu if the click is outside the header
    }
  }
}
