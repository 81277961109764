import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { AppMenuItem, MultiAppData } from 'src/app/model/multi-app-access.model';
import { FormControl, Validators } from '@angular/forms';
import { GlobalConstants } from 'src/app/pages/global-constants';

@Component({
  selector: 'multi-app-sidenav-content',
  templateUrl: './multi-app-sidenav-content.component.html',
  styleUrls: ['./multi-app-sidenav-content.component.css']
})
export class MultiAppSidenavContentComponent implements OnInit {

  @Input() selectedAppData: MultiAppData;
  @Input() appMenuItems: AppMenuItem[];
  @Input() isMemberWeb: boolean;
  @Input() mwMenuItems: MemberwebMenuItems[];
  @Input() master_checked: boolean[];
  @Input() disableMemberWebMenus: boolean;
  @Input() incentiveCdFormCtrl: FormControl;

  @Output() masterChange = new EventEmitter<{menuName: string, index: number}>();
  @Output() listChange = new EventEmitter<{menuName: string, index: number}>();

  constructor(public globalConstants: GlobalConstants){

  }
  
  ngOnInit(): void {
    
  }

  onMasterChange(menuName: string, index: number) {
    this.masterChange.emit({menuName: menuName, index: index});
  }

  onListChange(menuName: string, index: number) {
    this.listChange.emit({menuName: menuName, index: index});
  }
} 
