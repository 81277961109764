import { RouterModule } from '@angular/router';
import { PagesModule } from './pages/pages.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AuthRoutingModule } from './auth-routing.module';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { MaterialModule } from './material.module';
import { UserAuthService } from './user-auth.service';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';

import {
  OKTA_CONFIG,
  OktaAuthModule
} from '@okta/okta-angular';
import { FormsModule } from '@angular/forms';
import { InterceptorService } from './interceptor.service';
import { MatMomentDateModule } from '@angular/material-moment-adapter';


@NgModule({
  declarations: [
    AppComponent
  ], 
  imports: [
    BrowserModule,
    RouterModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AuthRoutingModule,
    MaterialModule,
    PagesModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true
    }),
    routing,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatMomentDateModule
  ],
  exports: [],
  providers: [{ provide: APP_BASE_HREF, useValue: '' },UserAuthService, DatePipe,
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
