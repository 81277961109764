import { Component, Input, OnInit } from '@angular/core';
import { AppMenuItem, MultiAppData, MultiAppAccessReqParams, MemberWebUserMenu } from 'src/app/model/multi-app-access.model';
import { FormControl } from '@angular/forms';
import { GlobalConstants } from 'src/app/pages/global-constants';
import { OktaAuthService } from '@okta/okta-angular';
import { DataService } from 'src/app/data.service';
import { GlobalErrorHandlerService } from 'src/app/pages/GlobalErrorHandlerService';
import { AppAccessDataService } from '../app-access-data.service';
import { User } from 'src/app/model/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'multi-app-sidenav-content',
  templateUrl: './multi-app-sidenav-content.component.html',
  styleUrls: ['./multi-app-sidenav-content.component.css']
})
export class MultiAppSidenavContentComponent implements OnInit {

  @Input() currentUser: User;
  @Input() parentModule: string;
  @Input() incentiveCdFormCtrl: FormControl;

  selectedAppData: MultiAppData;
  appMenuItems: AppMenuItem[];
  accessToken: string;
  isCheckedAll: boolean = true;
  subscription: Subscription = new Subscription();

  constructor(
    private dataService: DataService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    public globalConstants: GlobalConstants,
    private appAccessDataService: AppAccessDataService,
    private oktaAuth: OktaAuthService) {

  }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.subscription.add(this.appAccessDataService.selectedAppDataObs$.subscribe(appData => {
      if (appData) {
        this.selectedAppData = appData;
        this.loadMultiAppMenus();
      }
    }));
    this.subscription.add(this.appAccessDataService.clearDataSubject$.subscribe(status => {
      if (status) {
        this.clearMenuData();
      }
    }));
  }

  private loadMultiAppMenus() {
    let reqParams: MultiAppAccessReqParams = {
      "appCode": this.selectedAppData.appCode,
      "token": this.accessToken,
      "submittedBy": this.dataService.userclaims.userId,
      "userType": this.currentUser.profile.employeeTypeIDM,
      "userId": this.appAccessDataService.loginIdByUserType(this.currentUser, this.dataService.isPdrUser)
    }

    this.appAccessDataService.getMultiAppMenus(reqParams).subscribe((response) => {
      if (response.statusCode === 200) {
        this.appMenuItems = response.body;
        this.appAccessDataService.defaultNoAccessOption(this.appMenuItems);
        let userAccessGroupsReqParams: MultiAppAccessReqParams = {
          "loginId": this.currentUser.profile.login,
          "token": this.accessToken,
          "groupPrefix": this.selectedAppData.groupPrefix,
          "appName": this.selectedAppData.appName,
          "userId": this.currentUser.profile.uid
        }
        this.appAccessDataService.getUserAccessGroups(userAccessGroupsReqParams).subscribe(response => {
          const userGroups = response;
          if (userGroups && userGroups.length > 0) {
            userGroups.forEach(userGroup => {
              this.assignSelectedMenu(this.appMenuItems, userGroup);
            });
          } else {
            if (this.selectedAppData.appName === this.globalConstants.app_member_web) {
              if (this.dataService.userclaims.idm_role[0] === this.globalConstants.idm_role_res) {
                this.disableMenus(this.appMenuItems);
              }
              this.userSpecificMwSelection();
            }
          }
          this.appAccessDataService.appMenusDataSubject$.next(this.appMenuItems);
        });
      } else {
        console.error('Error in get multi app menu API calls:');
        this.globalErrorHandlerService.handleError('Error in API calls:');
      }
    });
  }

  private disableMenus(menuItems: AppMenuItem[]) {
    menuItems.forEach(menu => {
      if (menu.menuType === this.globalConstants.menu_type_radio
        || menu.menuType === this.globalConstants.menu_type_checkbox) {
        menu.disabled = true;
      }
      if (menu.children && menu.children.length > 0) {
        this.disableMenus(menu.children);
      }
    })
  }

  private userSpecificMwSelection() {
    if (this.dataService.isSetFromTemplate) {
      this.setMenuFromTemplate();
    } else {
      if (this.parentModule === this.globalConstants.create_user && this.currentUser.profile.employeeTypeIDM !== 'PROPERTY_STAFF' && this.isCheckedAll) {
        this.selectAllCheckboxOption(this.appMenuItems);
        this.isCheckedAll = !this.isCheckedAll;
      } else {
        this.appAccessDataService.clearAllSelectedMenuData(this.appMenuItems);
      }
    }
  }

  private selectAllCheckboxOption(menuItems: AppMenuItem[]) {
    menuItems.forEach(menu => {
      if (menu.menuType === this.globalConstants.menu_type_checkbox) {
        menu.selectedOption = true;
      }
      if (menu.children && menu.children.length > 0) {
        this.selectAllCheckboxOption(menu.children);
      }
    })
  }

  private assignSelectedMenu(menuItems: AppMenuItem[], userGroup: any) {
    menuItems.forEach(menu => {
      if (menu.groupId === userGroup.id) {
        if (this.globalConstants.input_menu_types.includes(menu.menuType)) {
          if (menu.menuType == this.globalConstants.menu_type_radio) {
            menu.selectedOption = menu.name;
          } else {
            menu.selectedOption = true;
            this.onCheckboxSelectionChange(menu);
          }
        }
      }
      if (menu.children && menu.children.length > 0) {
        this.assignSelectedMenu(menu.children, userGroup);
      }
    })
  }

  private setMenuFromTemplate() {
    this.appAccessDataService.clearAllSelectedMenuData(this.appMenuItems);
    //console.log("template specific", localStorage.getItem("editTempObject"))
    const mwTemplateSelectionJson = JSON.parse(localStorage.getItem("editTempObject")).menuitems;
    mwTemplateSelectionJson.menus = mwTemplateSelectionJson.menus.filter(menu => menu !== null);
    const mwUserGroups = this.appAccessDataService.convertToUserGroupsFormat(new MemberWebUserMenu(mwTemplateSelectionJson));
    if (mwUserGroups && mwUserGroups.length > 0) {
      mwUserGroups.forEach(userGroup => {
        this.assignSelectedMenu(this.appMenuItems, userGroup);
      });
    }
  }

  onRadioSelectionChange(selectedItem: AppMenuItem) {
    this.markRadioSelected(selectedItem);
  }

  private markRadioSelected(selectedItem: AppMenuItem) {
    if (selectedItem) {
      let searchResult = this.searchSelectedNode(this.appMenuItems, selectedItem);
      if (searchResult) {
        searchResult.matchedNode.selectedOption = selectedItem.name;
        searchResult.otherSiblings.forEach(item => item.selectedOption = '');
      }
    }
  }

  private searchSelectedNode(data: AppMenuItem[], search: AppMenuItem) {
    for (const node of data) {
      if (node.children) {
        const result = this.searchSelectedNode(node.children, search);
        if (result) {
          return result;
        }
      }
      const parent = data.find(parentNode => parentNode.children && parentNode.children
        .some(child => (child.name === search.name && child.parent === search.parent)));
      if (parent) {
        const matchedNode = parent.children.find(child => child.name === search.name);
        const otherSiblings = parent.children.filter(child => child.name !== search.name);
        return { matchedNode, otherSiblings };
      }
    }
    return null;
  }

  onCheckboxSelectionChange(selectedItem: AppMenuItem) {
    if (selectedItem) {
      this.toggleSelect(selectedItem, selectedItem.selectedOption);
    }
  }

  private toggleSelect(selectedItem: AppMenuItem, isChecked: boolean | string) {
    this.toggleSelectChildren(selectedItem, isChecked);
    this.toggleSelectParents(this.appMenuItems, selectedItem.name);
  }

  private toggleSelectChildren(selectedItem: AppMenuItem, isChecked: boolean | string) {
    selectedItem.selectedOption = isChecked;
    if (selectedItem.children && selectedItem.children.length > 0) {
      selectedItem.children.forEach(child => this.toggleSelectChildren(child, isChecked));
    }
  }

  private toggleSelectParents(appMenuItems: AppMenuItem[], selectedItemName: string) {
    for (let menuItem of appMenuItems) {
      if (menuItem.children && menuItem.children.length > 0) {
        if (menuItem.children.some(child => child.name === selectedItemName)) {
          menuItem.selectedOption = this.areAllChildrenChecked(menuItem);  // Only check parent if all children are checked
          this.toggleSelectParents(this.appMenuItems, menuItem.name);  // Recursively check further up
        } else {
          this.toggleSelectParents(menuItem.children, selectedItemName);  // Traverse deeper if needed
        }
      }
    }
  }

  private areAllChildrenChecked(menuItem: AppMenuItem) {
    return menuItem.children.every(child => child.selectedOption);
  }

  clearMenuData() {
    this.appMenuItems = [];
  }

  ngOnDestroy() {
    this.clearMenuData();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

} 
