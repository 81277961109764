import { Component, OnInit, Inject } from '@angular/core';
import { QuestionAnswersComponent } from '../question-answers/question-answers.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
  description: String,
  title: String
}

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css']
})
export class ConfirmationPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<QuestionAnswersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,  private router: Router) { }

  ngOnInit(): void {
  }
  onContinue(){
    this.dialogRef.close(true);
  }

  onCancel(){
    this.dialogRef.close(false);
  }

}
