import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppMenuItem } from 'src/app/model/multi-app-access.model';
import { MultiAppAccessDataService } from '../multi-app-access-data.service';
import { GlobalConstants } from 'src/app/pages/global-constants';

@Component({
  selector: 'multi-app-menu',
  templateUrl: './multi-app-menu.component.html',
  styleUrls: ['./multi-app-menu.component.css']
})
export class MultiAppMenuComponent implements OnInit {

  @Input() menuItem: AppMenuItem;

  constructor(
    private menuDataService: MultiAppAccessDataService,
    public globalConstants: GlobalConstants){

  }

  ngOnInit(): void {
    console.log(this.menuItem);
  }

  onSelectionChange(selectedItem: AppMenuItem) {
    console.log(selectedItem);
    this.menuDataService.radioSelectionChange.next(selectedItem);
  }
  
}
