export const environment = {
  production: true,
  envName: 'production',
  navigateSignOut: 'https://hotelsso.bwhhotelgroup.com/login/signout',
  allowedOrigins: ['https://idm.bwhhotelgroup.com'],
  services: {
    userProfileURL: 'https://userprofile.idm.bwhhotelgroup.com/production/user',
    userURL: 'https://user.idm.bwhhotelgroup.com/production/user',
    getPropUsersURL: 'https://getprops.idm.bwhhotelgroup.com/production/getPropUsers',
    search_post_url: 'https://searchuser.idm.bwhhotelgroup.com/production/searchUser',
    password_change_url: 'https://changepwd.idm.bwhhotelgroup.com/production/changePassword',
    reset_user_password_url: 'https://changepwd.idm.bwhhotelgroup.com/production/resetUserPassword',
    affilate_office_url: 'https://affiliates.idm.bwhhotelgroup.com/production/affiliates',
    resort_summary_url:'https://resortsumm.idm.bwhhotelgroup.com/production/resortSummary',
    unlock_user_url: 'https://unlockuser.idm.bwhhotelgroup.com/production/unlockUser',
    memberweb_menu_url: 'https://menus.idm.bwhhotelgroup.com/production/menus/',
    suspend_user_url: 'https://suspenduser.idm.bwhhotelgroup.com/production/suspenduser',
    unsuspend_user_url: 'https://unsuspenduser.idm.bwhhotelgroup.com/production/unsuspenduser',
    activate_user_url: 'https://activateuser.idm.bwhhotelgroup.com/production/activateuser',
    vendor_codes_url: 'https://vendors.idm.bwhhotelgroup.com/production/vendors',
    postURL: 'https://templates.idm.bwhhotelgroup.com/production/post-templates',
    getURL: 'https://templates.idm.bwhhotelgroup.com/production/get-templates',
    updateURL: 'https://templates.idm.bwhhotelgroup.com/production/update-templates',
    multi_app_url: 'https://multiappaccess.idm.bwhhotelgroup.com/production/',
    deleteURL: 'https://templates.idm.bwhhotelgroup.com/production/delete-templates',
    templatesrolesURL: 'https://templates.idm.bwhhotelgroup.com/production/templates-roles'
  },
  okta:{
    issuer: 'https://hotelsso.bwhhotelgroup.com/oauth2/aus3g6v6mf7S3mjiV4x7',
    redirectUri: 'https://idm.bwhhotelgroup.com/callback',
    clientId: '0oa228g0c4W4YJbTy4x7',
    scopes: ['openid', 'profile']
  },
  vendor_menus: 'LG-MW Vendor',
  user_roles:{
    admin_role: '00gbp8iaeyrohemUr4x7',
    power_role: '00gbp8hac0EJeP67r4x7',
    restricted_role: '00gbp8k2zvYeBNU6W4x7',
    no_user_mgnt: '00gbp8hzjmR2qqacr4x7'
  },
  corp_groups:{
    corp_admin: 'LG-IDM-Prod All User Management - Admin',
    corp_power_user: 'LG-IDM-Prod All User Management - PowerUser',
    corp_autoclerk_none: 'LG-IDM-Prod Autoclerk User Management - None',
    corp_prop_admin: 'LG-IDM-Prod Property User Management - Admin',
    corp_prop_power_user: 'LG-IDM-Prod Property User Management - PowerUser'
  },
};