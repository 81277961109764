<body>
    <div class="content">
        <mat-tab-group (selectedIndexChange)="changeTabs($event)" [(selectedIndex)]="tabIndex">
            <mat-tab label="Create User">
                <div class="left-fields">
                    <div *ngIf="(!isVendorUser && (loggedInUser != 'EMPLOYEE'))">
                        <h1 class="top-italic">{{globalConstants.search_frm_template}}</h1>
                        <p>Target Template Group</p>
                        <div class="user-groups">
                            <ng-multiselect-dropdown [placeholder]="'click here to select template ID'"
                                [settings]="dropdownSettingsID" [(data)]="dropDownListProperties" [(ngModel)]="selectedID" (onSelect)="onIDChange($event)" (onDeSelect)="onIDUnselect()"
                                [formControl]="templateIDControl">
                            </ng-multiselect-dropdown>
                        </div>
                    </div> 
                    <div *ngIf="(!isVendorUser && (loggedInUser == 'EMPLOYEE'))">
                        <h1 class="top-italic">{{globalConstants.search_frm_template}}</h1>
                        <p>Target Template Group</p>
                        <div class="target-grp" >
                            <textarea [(ngModel)]="selectedID" class="txtareaTemplate" 
                                [formControl]="tempIDtxtboxControl"></textarea>
                            <button class="save-button fetch-more" (click)="onIDChange($event)">Fetch Templates</button>
                            <p *ngIf="tempIDtxtboxControl.hasError('pattern')"
                            class="prop-temp-error">
                            {{globalConstants.temp_err1}}</p>
                        </div>
                        <div class="tempHint">
                        <mat-hint >{{globalConstants.temp_hint1}}</mat-hint>
                        </div>
                    </div>
                    <br>
                        <mat-form-field class="mat-field-col-2" *ngIf="!isVendorUser">
                    <mat-select  placeholder="Select a template to choose from"  [formControl]="propertyTempControl" (selectionChange)="onTemplateChange($event)" [(value)]="selectedPropTemp">
                      <mat-option *ngFor="let template of dropDownListTemplate" [value]="template.value"> {{template.viewValue}}</mat-option>
                    </mat-select>
                    <!-- <mat-error *ngIf="propertyTempControl.hasError('required') && loggedInTemplatePermission =='OH-IDM-Role User Management - Restricted'">
                        Please select a template</mat-error> -->
                </mat-form-field>
                    <h1 class="top-italic">{{globalConstants.personalInfo}}</h1>
                    <mat-form-field class="mat-field-col-2"  *ngIf="isMultipleUsers">
                        <mat-label>{{globalConstants.userType}}</mat-label>
                        <mat-select [formControl]="userControl" disableOptionCentering required [(value)]="selectedUserType"
                            (selectionChange)="onCategoryChange($event)">
                            <mat-option *ngFor="let usertype of usertypes" [value]="usertype.value">
                                {{ usertype.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userControl.hasError('required')">{{globalConstants.userType_err}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mat-field-col-2" *ngIf="isSingleUser">
                        <mat-label>{{globalConstants.userType}}</mat-label>
                        <input matInput required [formControl]="userControl" [(ngModel)]="selectedUserType" readonly>
                        <mat-error *ngIf="userControl.hasError('required')">{{globalConstants.userType_err}}
                        </mat-error>
                    </mat-form-field>
                    <div *ngIf=isAffiliateUser>
                        <mat-form-field class="mat-field-col-2">
                            <mat-label>{{globalConstants.affiliate_ofc}}</mat-label>
                            <mat-select [formControl]="affiliateOfficeControl" disableOptionCentering [(value)]="selectedAffiliate"
                            (selectionChange)="onAffSelect()"
                                placeholder="Select" required>
                                <mat-option *ngFor="let affiliateOffice of affiliateOffices"
                                    [value]="affiliateOffice.value">
                                    {{ affiliateOffice.viewValue }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="affiliateOfficeControl.hasError('required')">
                                {{globalConstants.affiliate_ofc_err1}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="loggedInUser == 'EMPLOYEE' && isVendorUser">
                        <mat-form-field class="mat-field-col-2">
                            <mat-label>{{globalConstants.vendor_cd}}</mat-label>
                            <mat-select [formControl]="vendorcdControl" disableOptionCentering [(value)]="selectedVendor"
                                placeholder="Select" required>
                                <mat-option *ngFor="let vendor of vendors | keyvalue"
                                    [value]="vendor.key">
                                    {{vendor.key}} - {{vendor.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="vendorcdControl.hasError('required')">
                                {{globalConstants.vendor_err}}</mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div *ngIf="selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'AFFILIATE_STAFF'">
                        <mat-checkbox [formControl]="userMngmtControl" [(ngModel)]="isUserMgmt">{{globalConstants.user_mgmt}}
                        </mat-checkbox>
                        <br>
                    </div> -->
                    <br>
                    <mat-form-field class="mat-field-col-2" *ngIf="(selectedUserType =='PROPERTY_STAFF' || selectedUserType =='AFFILIATE_STAFF')">        
                            <mat-label>User Role</mat-label>
                            <mat-select [formControl]="templateRoleControl" disableOptionCentering [(value)]="templateRole" (selectionChange)="onTemplateRoleChange()" placeholder="Select a template permission to assign to the user" required >
                                <mat-option *ngFor="let templatePermission of templatePermissions" [value]="templatePermission.value" matTooltip={{templatePermission.tooltip}} matTooltipPosition="right">{{templatePermission.viewValue}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="templateRoleControl.hasError('required')">
                                Please choose a User Role</mat-error>
                        </mat-form-field>   
                    <div>
                    <mat-form-field class="mat-field-col-2" id="myemailbox" [formGroup]="form" *ngIf=isAffiliateUser>
                        <input #em id="emailbox" matInput required [(ngModel)]="email" formControlName="emailAddressControl"
                            (keyup)="emailValidator(email)"  placeholder="Email">
                        <mat-error *ngIf="form.get('emailAddressControl').hasError('required')">
                            {{globalConstants.email_err1}}
                        </mat-error>
                        <mat-error *ngIf="form.get('emailAddressControl').hasError('pattern')">
                            {{globalConstants.email_err2}}
                        </mat-error>
                        <mat-error
                            *ngIf="!isaffiliatePDR && !isPDRuser && isAffiliateUser == true && form.get('emailAddressControl').hasError('invalid')">
                            {{globalConstants.email_err3}}</mat-error>
                        <mat-error *ngIf="form.get('emailAddressControl').hasError('duplicate')">
                            {{globalConstants.email_err4}}
                        </mat-error>
                        <mat-hint *ngIf="!isaffiliatePDR && !isPDRuser && isAffiliateUser" class="select-user">{{globalConstants.email_err5}}</mat-hint>
                    </mat-form-field>
                    <div *ngIf="isAffiliateUser == true && form.get('emailAddressControl').hasError('invalid')" class = "pad-bottom2"></div>
                    </div>
                    <div [formGroup]="form">
                        <mat-form-field class="mat-field-col-2" *ngIf="isVendorUser">
                            <input matInput [required]="selectedUserType == 'VENDOR_STAFF' || selectedUserType == 'VENDOR_ADMIN'" [(ngModel)]="email"
                                formControlName="emailAddressControl" (keyup)="emailValidator(email)" placeholder="Email">
                            <mat-error
                                *ngIf="form.get('emailAddressControl').hasError('required') && (selectedUserType == 'VENDOR_STAFF' ||selectedUserType == 'VENDOR_ADMIN')">
                                {{globalConstants.email_err1}}
                            </mat-error>
                            <mat-error *ngIf="form.get('emailAddressControl').hasError('pattern')">
                                {{globalConstants.email_err2}}
                            </mat-error>
                            <mat-error *ngIf="isVendorUser == true && form.get('emailAddressControl').hasError('invalid')">
                            {{globalConstants.email_err3}}</mat-error>
                            <mat-error *ngIf="form.get('emailAddressControl').hasError('duplicate')">
                                {{globalConstants.email_err4}}
                            </mat-error>
                            <mat-hint class="select-user">{{globalConstants.email_err5}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div *ngIf="(isAffiliateUser == true || isVendorUser == true) && form.get('emailAddressControl').hasError('invalid')" class = "pad-bottom2"></div>
                    <mat-form-field class="mat-field-col-2">
                        <input matInput required [(ngModel)]="firstName" [formControl]="firstNameControl"
                            placeholder="First Name" name="firstNamebox"  id="firstNamebox"  maxlength="50">
                        <mat-error *ngIf="firstNameControl.hasError('required')">{{globalConstants.first_name_err1}}
                        </mat-error>
                        <mat-error
                            *ngIf="(!firstNameControl.hasError('required')) && firstNameControl.hasError('pattern')">
                            {{globalConstants.first_last_name_err}}
                        </mat-error>
                    </mat-form-field>
                    <br /> <div *ngIf="(!firstNameControl.hasError('required')) && firstNameControl.hasError('pattern')" class = "pad-bottom2"></div>
                    <mat-form-field class="mat-field-col-2">
                        <input matInput required [(ngModel)]="lastName" [formControl]="lastNameControl"
                            placeholder="Last Name" maxlength="50">
                        <mat-error *ngIf="lastNameControl.hasError('required')">{{globalConstants.last_name_err1}}
                        </mat-error>
                        <mat-error
                            *ngIf="(!lastNameControl.hasError('required')) && lastNameControl.hasError('pattern')">
                            {{globalConstants.first_last_name_err}}
                        </mat-error>
                    </mat-form-field>
                    <br /> <div *ngIf="(!lastNameControl.hasError('required')) && lastNameControl.hasError('pattern')" class = "pad-bottom2"></div>
                    <mat-form-field class="mat-field-col-2">
                        <input matInput maxlength="3" required [(ngModel)]="initials" [formControl]="initialsControl"
                            placeholder="Initials">
                        <mat-error *ngIf="initialsControl.hasError('required')">{{globalConstants.initials_err1}}
                        </mat-error>
                        <mat-error
                            *ngIf="(!initialsControl.hasError('required')) && initialsControl.hasError('pattern')">
                            {{globalConstants.initials_err2}}</mat-error>
                    </mat-form-field>
                    <div [formGroup]="form">
                        <mat-form-field class="mat-field-col-2" *ngIf=isPropertyUser>
                            <input matInput [required]="selectedUserType == 'PROPERTY_ADMIN'" [(ngModel)]="email"
                                formControlName="emailAddressControl" placeholder="Email">
                            <mat-error
                                *ngIf="form.get('emailAddressControl').hasError('required') && (selectedUserType == 'PROPERTY_ADMIN')">
                                {{globalConstants.email_err1}}
                            </mat-error>
                            <mat-error *ngIf="form.get('emailAddressControl').hasError('pattern')">
                                {{globalConstants.email_err2}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="right-fields">
                    <h1 class="top-italic">{{globalConstants.user_status}}</h1>
                    <mat-form-field class="mat-field-col-2" appearance="fill">
                        <mat-label>{{globalConstants.active_date}}</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="activeDateControl" [(ngModel)]="activeDate" required readonly
                            (dateInput)="addEvent(activeDate, $event)" (dateChange)="addEvent(activeDate, $event)"
                            (click)="picker.open()" [min]="todayDate" (dateChange)="validateInActiveDate($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="activeDateControl.hasError('required')">{{globalConstants.active_date_err}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="mat-field-col-2" appearance="fill">
                        <mat-label>{{globalConstants.inactive_date}}</mat-label>
                        <input matInput [matDatepicker]="picker1" [formControl]="inActiveDateControl"
                            (dateInput)="addEvent(inActiveDateControl, $event)"
                            (dateChange)="addEvent(inActiveDateControl, $event)" readonly (click)="picker1.open()"
                            [min]="inActiveDateMin">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker-toggle matSuffix *ngIf="inActiveDateControl.value" (click)="clearEndDate()">
                            <mat-icon matDatepickerToggleIcon>{{globalConstants.clear}}</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error>{{globalConstants.inactive_date_err}}</mat-error>
                    </mat-form-field>
                    <div *ngIf="((loggedInUser == 'EMPLOYEE' && !isVendorUser) || isPropertyUser)">
                        <h1 class="top-italic">{{globalConstants.user_info}}</h1>
                    </div>
                    
                    <div *ngIf="(loggedInUser != 'EMPLOYEE') && isPropertyUser" class="prop-multiselect-dropdown">
                        <mat-form-field class="mat-field-col-2">
                            <mat-label>{{globalConstants.props}}</mat-label>
                            <mat-select [formControl]="propControl" disableOptionCentering required [(ngModel)]="selectedproperties"
                            (selectionChange)="onPropertyChange($event)" multiple>
                            <mat-option *ngFor="let property of sortedDropDownList" [value]="property">
                                {{property}}
                            </mat-option>
                        </mat-select>
                            <mat-error *ngIf="userControl.hasError('required')">{{globalConstants.userType_err}}
                            </mat-error>
                        </mat-form-field>
                        <!-- <p>{{globalConstants.props}} *</p>
                        <ng-multiselect-dropdown class="font-15" [placeholder]="'Click here to select Properties'"
                            [settings]="dropdownSettings" [(data)]="sortedDropDownList" [formControl]="propControl"
                            [(ngModel)]="selectedproperties" required>
                        </ng-multiselect-dropdown> -->
                        <p *ngIf="propControl.hasError('required') && propControl.touched" class="prop-error">
                            {{globalConstants.props_err1}}
                        </p>
                        <p [innerText]="invalidPropertyMessage"
                            *ngIf="propControl.hasError('invalid') && propControl.touched" class="prop-error">
                        </p>
                        <p *ngIf="invalidPropControl.hasError('invalidProp') && invalidPropControl.touched"
                        class="prop-error">{{invalidPropMessage}}
                        </p>
                        <p *ngIf="inactivePropControl.hasError('inactiveProp') && inactivePropControl.touched"
                        class="prop-error">{{inactivePropMessage}}
                        </p>
                    </div>
                    <div *ngIf="(loggedInUser == 'EMPLOYEE') && isPropertyUser">
                        <span>{{globalConstants.props}} *</span>
                        <div class="pad-top">
                            <textarea [(ngModel)]="selectedproperties" class="txtarea"
                                [formControl]="propertyControl" (keyup)="onPropChange()"></textarea>
                        </div>
                        <p *ngIf="!propertyControl.hasError('required') && propertyControl.hasError('pattern') && propertyControl.touched"
                            class="prop-error">
                            {{globalConstants.props_err2}}</p>
                        <p *ngIf="propertyControl.hasError('required') && propertyControl.touched" class="prop-error">
                            {{globalConstants.props_err1}}</p>
                        <p [innerText]="invalidPropertyMessage"
                            *ngIf="propertyControl.hasError('invalid') && propertyControl.touched" class="prop-error">
                        </p>
                        <p *ngIf="invalidPropControl.hasError('invalidProp') && invalidPropControl.touched"
                        class="prop-error">{{invalidPropMessage}}
                        </p>
                        <p *ngIf="inactivePropControl.hasError('inactiveProp') && inactivePropControl.touched"
                        class="prop-error">{{inactivePropMessage}}
                        </p>
                        <br>
                    </div>
                    <div *ngIf="loggedInUser=='EMPLOYEE' && !isVendorUser">
                        <div>
                            <label for="UserType">{{globalConstants.user_groups}} &nbsp;</label>
                        </div>
                        <div class="user-groups">
                            <ng-multiselect-dropdown [placeholder]="'click here to select user groups'"
                                [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                                [formControl]="userGroupsControl">
                            </ng-multiselect-dropdown>
                        </div>
                    </div>
                    <br>
                </div>
                <div class="pad-top25">
                    <div class="pad-left55">
                        <button class="save-button bg-color-white" mat-raised-button [disabled]="isDisabled"
                            (click)="Clear()">Clear</button>
                        <button *ngIf="!isVendorUser && !isRestrictedUser" class="save-button" mat-raised-button (click)="submit()">Next</button>
                        <button *ngIf="isVendorUser || isRestrictedUser"class="save-button" mat-raised-button (click)="submit()">Save</button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>  
    </div>
</body>