import { Component, OnInit } from '@angular/core';
import { UserAuthService } from 'src/app/user-auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isAuthenticated:boolean;
  constructor(private myservice: UserAuthService) { }

  ngOnInit(): void {
  this.isAuthenticated = this.myservice.isAuthenticated;
  }

}
