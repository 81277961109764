import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SearchUserComponent } from './search-user/search-user.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { PagesComponent } from './pages.component';
import { routing } from './pages.routing';
import { AuthRoutingModule } from '../auth-routing.module';
import { MaterialModule } from '../material.module';
import { EditUserComponent } from './edit-user/edit-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { MemberwebPrivilagesComponent } from './memberweb-privilages/memberweb-privilages.component';
import { QuestionAnswersComponent } from './question-answers/question-answers.component';
import { PopupComponent } from './popup/popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GlobalErrorComponent } from './global-error-component/global-error-component.component';
import { GlobalErrorHandlerService } from './GlobalErrorHandlerService';
import {GlobalConstants} from './global-constants';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';
import { CreateTemplateComponent } from './create-template/create-template.component';
import { EditTemplateComponent } from './edit-template/edit-template.component';
import { SearchTemplateComponent } from './search-template/search-template.component';
import { CreateMemberwebTemplateComponent } from './create-memberweb-template/create-memberweb-template.component';
import { EditMemberwebTemplateComponent } from './edit-memberweb-template/edit-memberweb-template.component';
import { PopUpTemplateComponent } from './pop-up-template/pop-up-template.component';
import { ManageTemplateComponent } from './manage-template/manage-template.component';
import { ManageTemplateHeaderComponent } from './manage-template-header/manage-template-header.component';
import { ManageEditTemplateHeaderComponent } from './manage-edit-template-header/manage-edit-template-header.component';
import { ThreeButtonPopupComponent } from './three-button-popup/three-button-popup.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { BulkApplyPopupComponent } from './bulk-apply-popup/bulk-apply-popup.component';
import { DeletePopupComponent } from './delete-popup/delete-popup.component';
import { MultiAppMenuAccessComponent } from './shared/application-menu-access/multi-app-menu-access/multi-app-menu-access.component';
import { MultiAppMenuComponent } from './shared/application-menu-access/multi-app-menu/multi-app-menu.component';
import { MultiAppSidenavComponent } from './shared/application-menu-access/multi-app-sidenav/multi-app-sidenav.component';
import { MultiAppSidenavContentComponent } from './shared/application-menu-access/multi-app-sidenav-content/multi-app-sidenav-content.component';
import { AppAccessDataService } from './shared/application-menu-access/app-access-data.service';
import { CreateUserAppAccessComponent } from './create-user-app-access/create-user-app-access.component';

@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SearchUserComponent,
    UpdateProfileComponent,
    CreateUserComponent,
    EditUserComponent,
    ResetPasswordComponent,
    MemberwebPrivilagesComponent,
    QuestionAnswersComponent,
    PopupComponent,
    GlobalErrorComponent,
    ConfirmationPopupComponent,
    CreateTemplateComponent,
    EditTemplateComponent,
    SearchTemplateComponent,
    CreateMemberwebTemplateComponent,
    EditMemberwebTemplateComponent,
    PopUpTemplateComponent,
    ManageTemplateComponent,
    ManageTemplateHeaderComponent,
    ManageEditTemplateHeaderComponent,
    ThreeButtonPopupComponent,
    BulkApplyPopupComponent,
    DeletePopupComponent,
    MultiAppMenuAccessComponent,
    MultiAppMenuComponent,
    MultiAppSidenavComponent,
    MultiAppSidenavContentComponent,
    CreateUserAppAccessComponent
  ], 
  imports: [
    CommonModule,
    BrowserModule,
    routing,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    AuthRoutingModule,
    MaterialModule,
    MatDialogModule,
    FlexLayoutModule,
    MatTooltipModule
  ],
  exports: [],
  providers: [GlobalErrorHandlerService, GlobalConstants, AppAccessDataService]
})
export class PagesModule { }
