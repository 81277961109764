export class MultiAppMenus {
    OWNER: string;
    INSERTDATE: Date;
    ENDPOINT: string;
    SK: string;
    PK: string;
    GROUP: string;
    GROUPID: string;
    UPDATEDATE: Date;
    TYPE: string;
    NAME: string;
    PARENT: string;
    selected:boolean;
}

export class ReqParamsMultiApp {
    appCode?: string;
    menuType?: string;
    token: string;
    submittedBy: string;
    userType: string;
    userId: string;
}

export class MultiAppData {
    appCode: string;
    appName: string;
    menuType: string;
    appUrl: string
    description: string;
    groupPrefix: string;
}

export class AppMenuItem {
    name: string;
    parent: string;
    owner: string;
    pk: string;
    sk: string;
    menuType: string;
    group: string;
    groupId: string;
    selectedOption: string | boolean;
    children: AppMenuItem[]
}

export class SelectedMenuData {
    appDetails: MultiAppData;
    groupSelectedData: GroupSelectedData[];
}

export class GroupSelectedData {
    groupName: string;
    groupId: string
    selectedValues: string[];
}

export interface SelectedMenu {
    value: string;
    viewValue: string;
}