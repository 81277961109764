import { Component, Inject, OnInit } from '@angular/core';
import { memberwebMenus } from 'src/app/model/memberwebMenu';
import { Memberweb } from 'src/app/model/memeberweb';
import { MemberwebMenuItems } from 'src/app/model/memberwebMenuItems';
import { OktaAuthService } from '@okta/okta-angular';
import { DataService } from 'src/app/data.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { SubMenuList } from 'src/app/model/subMenuList';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { MatDialog } from '@angular/material/dialog';
import { PopUpTemplateComponent } from '../pop-up-template/pop-up-template.component';
import { TemplateServiceService } from 'src/app/template-service.service';
import { GlobalConstants } from '../global-constants';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-memberweb-template',
  templateUrl: './edit-memberweb-template.component.html',
  styleUrls: ['./edit-memberweb-template.component.css']
})
export class EditMemberwebTemplateComponent implements OnInit {
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  locale = 'en-US';
  master_checked: boolean[] = new Array();
  disableCheckboxes: boolean = false;
  memberwebMenuItems: MemberwebMenuItems[] = [];
  menuitems: Memberweb = new Memberweb();
  accessToken: any;
  headings: MemberwebMenuItems[] = [];
  profile: TemplateProfile = new TemplateProfile();
  menus: any;
  tempMenu: any;
  ediObj: any;


  constructor(public oktaAuth: OktaAuthService, public apiDataService: DataService, public templateService: TemplateServiceService, public router: Router, public globalConstants: GlobalConstants, public dialog: MatDialog, public toastr: ToastrService) { }

  async ngOnInit() {
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.apiDataService.getMemberMenus(this.apiDataService.accessToken).subscribe(
      (response) => {
        this.memberwebMenuItems = JSON.parse(JSON.stringify(response));
        this.getUserSpecificMenus();     
      },
      (error) => {
        console.log(error);

      }
    );
  }

  getUserSpecificMenus() {
    this.ediObj = localStorage.getItem("editTempObject");
    const editObject = JSON.parse(this.ediObj);
    console.log('editobject', editObject);
    this.menuitems = editObject.menuitems;
    for (let i = 0; i < this.memberwebMenuItems.length; i++) {
      for (let m = 0; m < this.menuitems.menus.length; m++) {
        if (this.menuitems.menus[m] !== null && this.menuitems.menus[m]['menu-id'] == this.memberwebMenuItems[i]["menu-id"]) {
          let k = 0;
          for (let j = 0; j < this.memberwebMenuItems[i]["sub-menu-list"].length; j++) {
            if (this.menuitems.menus[m]['sub-menu-id-list'].includes(this.memberwebMenuItems[i]["sub-menu-list"][j]["sub-menu-id"])) {
              this.memberwebMenuItems[i]["sub-menu-list"][j].checked = true;
              k++;
              if (k == this.memberwebMenuItems[i]["sub-menu-list"].length) {
                this.subMenuAllchecked(i);

              }
            }
          }
        }
      }
    }
  }

  subMenuAllchecked(val: number) {
    this.master_checked[val] = true;
  }

  subMenuAllunChecked(val: number) {
    this.master_checked[val] = false;
  }

  list_change(menu: any, order: any) {
    let value: SubMenuList;
    let allChange: boolean = true;
    let allNotChange: boolean = true;
    for (value of this.memberwebMenuItems[order]['sub-menu-list']) {
      if (value != null && value.checked) {
        allNotChange = false;
        if (this.menuitems.menus[order] == null) {
          this.menuitems.menus[order] = new memberwebMenus();
          this.menuitems.menus[order]['menu-id'] = this.memberwebMenuItems[order]['menu-id'];
          this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
        }
        else {
          if (!this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            this.menuitems.menus[order]['sub-menu-id-list'].push(value['sub-menu-id']);
          }
        }

      }
      else if (!value.checked) {
        allChange = false;
        if (this.menuitems.menus[order] != null) {
          if (this.menuitems.menus[order]['sub-menu-id-list'].includes(value['sub-menu-id'])) {
            const index: number = this.menuitems.menus[order]['sub-menu-id-list'].indexOf(value['sub-menu-id']);
            this.menuitems.menus[order]['sub-menu-id-list'].splice(index, 1);
          }
        }
      }
    }
    if (allChange) {
      this.subMenuAllchecked(order);
    }
    else {
      this.subMenuAllunChecked(order);
    }
    if (allNotChange) {
      this.menuitems.menus[order] = null;
    }
  }

  master_change(str: string, val: number) {
    for (let value of this.memberwebMenuItems[val]['sub-menu-list']) {
      value.checked = this.master_checked[val];
    }
    this.list_change(str, val);
  }

  async Clear() {
    for (var i = 0; i < Object.keys(this.memberwebMenuItems).length; i++) {
      this.subMenuAllunChecked(i)
      this.master_change(this.memberwebMenuItems[i].name, i)
    }
    this.getUserSpecificMenus();
  }

  cancel() {
    this.router.navigate(['pages/manageTemplate/editTemplate']);
    this.templateService.getValueOfTabIndex(0);
    localStorage.removeItem('editTempScreen');
    localStorage.setItem('editTempScreen', 'Y');
  }

  public generateUserId(length: number) {
    var userId = '';
    var characters = '123456789ABCDEFGHIJKLMNPQRSTUVWXYZ987654321';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      userId += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return userId;
  }

  openDialog(description: any, title: any): void {
    const dialogRef = this.dialog.open(PopUpTemplateComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  updateMemberwebPrivileges() {    
    if (this.apiDataService.editTempBody.isEdit == true) {
      this.apiDataService.editTempBody.submittedBy = this.apiDataService.userclaims.userId
      this.apiDataService.editTempBody.menuitems = this.menuitems;
      const body = JSON.stringify(this.apiDataService.editTempBody);
      this.apiDataService.updateTemplate(body, this.apiDataService.accessToken).subscribe({
        next: (v) => {
          v = "Template updated successfully.";
          localStorage.setItem("isRequestFromEditScreen", "Y");
          this.openDialog(v, "Message")
          this.apiDataService.isWorkInProgress = false;
          localStorage.removeItem('isDispEditTemplate');
          this.apiDataService.getInvokeEvent(this.globalConstants.idm);
        },
        error: (e) => {
          console.log(e);
        }
      })
    } else {
      this.apiDataService.user.isDelete = true;
      this.apiDataService.user.delID = this.apiDataService.editTempBody.ID;
      this.apiDataService.user.delTemplateID = this.apiDataService.editTempBody.tempID;
      this.apiDataService.user.templateProfile.menuitems = this.menuitems; 
      this.apiDataService.user.templateProfile.userType = this.apiDataService.editTempBody.userType;
      this.apiDataService.user.templateProfile.propertyID = this.apiDataService.editTempBody.propertyID;
      // this.apiDataService.user.templateProfile.userManagement = this.apiDataService.editTempBody.userManagement;
      this.apiDataService.user.templateProfile.createdBy = this.apiDataService.editTempBody.createdBy;
      this.apiDataService.user.templateProfile.createdDate = this.apiDataService.editTempBody.createdDate;
      this.apiDataService.user.templateProfile.lastModifBy = this.apiDataService.userEmail;
      this.apiDataService.user.templateProfile.templateRole = this.apiDataService.editTempBody.templateRole;
      let date = new Date();
      let tempCreatedDate = formatDate(date, this.dateTimeFormat, this.locale);
      this.apiDataService.user.templateProfile.lastModifDate = tempCreatedDate;
      this.apiDataService.user.templateProfile.selectedItems = this.apiDataService.editTempBody.selectedItems;
      this.apiDataService.user.templateProfile.templateName = (this.apiDataService.editTempBody.templateName).toUpperCase();
      this.apiDataService.user.templateProfile.templateid = this.apiDataService.editTempBody.userType + '_' + this.generateUserId(10);
      this.apiDataService.user.templateProfile.affiliateOffice = this.apiDataService.editTempBody.affiliateOffice;
      if (this.apiDataService.user.templateProfile.propertyID != null && this.apiDataService.user.templateProfile.propertyID.length == 1) {
        this.apiDataService.user.templateProfile.ID = this.apiDataService.user.templateProfile.propertyID[0];
      } else if (this.apiDataService.user.templateProfile.propertyID != null && this.apiDataService.user.templateProfile.propertyID.length > 1) {
        this.apiDataService.user.templateProfile.ID = 'Multi_Property';
      } else {
        this.apiDataService.user.templateProfile.ID = this.apiDataService.editTempBody.affiliateOffice;
      }
      this.apiDataService.user.templateProfile.submittedBy = this.apiDataService.userclaims.userId;
      const body = JSON.stringify(this.apiDataService.user);
      this.apiDataService.postTemplate(body, this.apiDataService.accessToken).then((data) =>{
          data = "Template updated successfully.";
          localStorage.setItem("isRequestFromEditScreen", "Y");
          this.openDialog(data, "Message")
          this.apiDataService.isWorkInProgress = false;
          localStorage.removeItem('isDispEditTemplate');
          this.apiDataService.getInvokeEvent(this.globalConstants.idm);
        }, (error) => {
          console.log(error);
        }
      )
    }
  }


}

