<div class="content">
    <div class="main">
        <div class="left-fields">
            <h3>{{globalConstants.update_profile}}</h3>
            <div *ngIf="(employeecontrol.value == 'PROPERTY_STAFF' || employeecontrol.value == 'PROPERTY_ADMIN')">
                <mat-form-field class="disabled">
                    <input matInput placeholder="User Id*" [formControl]="userIdcontrol">
                    <mat-error *ngIf="userIdcontrol.hasError('required')">{{globalConstants.uid_error}}</mat-error>
                </mat-form-field>
                <br />
            </div>
            <mat-form-field class="disabled">
                <input matInput placeholder="User Type*" [formControl]="employeecontrol">
                <mat-error *ngIf="employeecontrol.hasError('required')">{{globalConstants.user_type_error}}
                </mat-error>
            </mat-form-field>
            <br />
            <mat-form-field class="disabled">
                <input matInput placeholder="First Name" [formControl]="firstname">
            </mat-form-field>
            <mat-form-field class="disabled">
                <input matInput placeholder="Last Name" [formControl]="lastname">
            </mat-form-field>
            <mat-form-field class="disabled">
                <input matInput placeholder="Initials*" [formControl]="initials">
                <mat-error *ngIf="initials.hasError('required')">{{globalConstants.initials_err1}}</mat-error>
            </mat-form-field>
            <br />
            <mat-form-field class="disabled">
                <input matInput placeholder="Email Address" [formControl]="email">
            </mat-form-field>
            <br><br>
            <button class="save-button" mat-raised-button (click)="resetPassword()">Change Password</button>

        </div>
        <div class="right-fields">
            <h3>{{globalConstants.update_challengeQA}}</h3>
            <p>{{globalConstants.challenge_anw_err2}}</p>
            <mat-form-field class="mat-field">
                <mat-select [formControl]="challengeQuestion1" [(value)]="selectedQuestion1"
                    placeholder="Challenge Question1*">
                    <mat-option *ngFor="let challengeQuestion1 of challengeQuestionsList"
                        [style.display]="challengeQuestion1.value==challengeQuestion2.value || challengeQuestion1.value==challengeQuestion3.value ? 'none' : 'block'"
                        [value]="challengeQuestion1.value">
                        {{ challengeQuestion1.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-error class="font16" *ngIf="challengeQuestion1.hasError('required')">
                    {{globalConstants.req_field}}
                </mat-error>
            </mat-form-field>
            <div>
                <mat-form-field>
                    <input input type="{{varType1}}" (click)="changeVal()" (focus)="changeVal()" matInput
                        placeholder="Challenge Answer1" [formControl]="challengeAnswer1">
                    <mat-error class="font16" *ngIf="challengeAnswer1.hasError('required')">
                        {{globalConstants.req_field}}
                    </mat-error>
                    <mat-error class="font16" *ngIf="challengeAnswer1.hasError('pattern')">
                        {{globalConstants.challenge_anw_err1}}</mat-error>
                    <mat-error class="font16" *ngIf="challengeAnswer1.hasError('unique')">
                        {{globalConstants.challenge_anw_err2}}</mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="mat-field">
                <mat-select [formControl]="challengeQuestion2" [(value)]="selectedQuestion2" placeholder="Question*">
                    <mat-option *ngFor="let challengeQuestion2 of challengeQuestionsList"
                        [style.display]="challengeQuestion2.value==challengeQuestion1.value || challengeQuestion2.value==challengeQuestion3.value ? 'none' : 'block'"
                        [value]="challengeQuestion2.value">
                        {{ challengeQuestion2.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="challengeQuestion2.hasError('required')">{{globalConstants.req_field}}
                </mat-error>
            </mat-form-field>
            <div>
                <mat-form-field>
                    <input input type="{{varType2}}" (click)="changeVal()" (focus)="changeVal()" matInput
                        placeholder="Challenge Answer2" [formControl]="challengeAnswer2">
                    <mat-error class="font16" *ngIf="challengeAnswer2.hasError('required')">
                        {{globalConstants.req_field}}
                    </mat-error>
                    <mat-error class="font16" *ngIf="challengeAnswer2.hasError('pattern')">
                        {{globalConstants.challenge_anw_err1}}
                    </mat-error>
                    <mat-error class="font16" *ngIf="challengeAnswer2.hasError('unique')">
                        {{globalConstants.challenge_anw_err2}}
                    </mat-error>
                </mat-form-field>
            </div>
            <mat-form-field class="mat-field">
                <mat-select [formControl]="challengeQuestion3" [(value)]="selectedQuestion3" placeholder="Question*">
                    <mat-option *ngFor="let challengeQuestion3 of challengeQuestionsList"
                        [style.display]="challengeQuestion3.value==challengeQuestion2.value || challengeQuestion3.value==challengeQuestion1.value ? 'none' : 'block'"
                        [value]="challengeQuestion3.value">
                        {{ challengeQuestion3.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="challengeQuestion3.hasError('required')">{{globalConstants.req_field}}
                </mat-error>
            </mat-form-field>
            <div>
                <mat-form-field>
                    <input input type="{{varType3}}" (click)="changeVal()" (focus)="changeVal()" matInput
                        placeholder="Challenge Answer3" [formControl]="challengeAnswer3">
                    <mat-error class="font16" *ngIf="challengeAnswer3.hasError('required')">
                        {{globalConstants.req_field}}
                    </mat-error>
                    <mat-error class="font16" *ngIf="challengeAnswer3.hasError('pattern')">
                        {{globalConstants.challenge_anw_err1}}
                    </mat-error>
                    <mat-error class="font16" *ngIf="challengeAnswer3.hasError('unique')">
                        {{globalConstants.challenge_anw_err2}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="pad-buttons">
        <div class="two_col_right">
            <button class="save-button bg-color" mat-raised-button (click)="Cancel()">Cancel</button>
            <button class="save-button" mat-raised-button [ngClass]="(isValid ? 'grayout': null)" [disabled]="isValid" (click)="updateProfileInfo()">Save</button>
        </div>
    </div>
</div>