import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import * as AWS from "aws-sdk";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Route } from '@angular/compiler/src/core';
import { DataService } from 'src/app/data.service';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { OktaAuthService } from '@okta/okta-angular';
import { GlobalConstants } from '../global-constants';

class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  passwordForm: FormGroup;
  showError;
  params;
  hide1 = true;
  hide2 = true;
  hide3 = true;
  accessToken: string;
  errorMatcher = new CrossFieldErrorMatcher();
  constructor(public oktaAuth: OktaAuthService,private formBuilder: FormBuilder, private route: ActivatedRoute,private dataService: DataService, private toastr: ToastrService,private router: Router,public globalConstants:GlobalConstants) { }

  async ngOnInit() {
    // this.params = this.route.snapshot.queryParams;
    const userRole = JSON.parse(localStorage.getItem('userClaims')).userRole;
    if(userRole == 'EMPLOYEE'){
      this.router.navigate(['pages/home']);
    }
    this.params = JSON.parse(localStorage.getItem("userDetails"))
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.passwordForm = this.formBuilder.group({
    userId: '',
    oldPassword: ['',Validators.required],
    newPassword: ['',[Validators.required,
                      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$#!%^*?&])[A-Za-z\d$@$#^!%*?&].{7,}'),
                    ]],
    confirmPassword: ['',Validators.required]
    },{validator: this.passwordValidator});

    // this.passwordForm.get('newPassword').valueChanges.subscribe(data =>{
    //   if (data.includes(this.params.userName) || data.includes(this.params.firstName) || data.includes(this.params.lastName)) {
    //     this.showError=true;
    //   } else {
    //     this.showError=false;
    //   }
    //   console.log(this.showError)
    // })
  }
  
  passwordValidator(form: FormGroup) {
    const condition = form.get('newPassword').value !== form.get('confirmPassword').value;

    return condition ? { passwordsDoNotMatch: true} : null;
  }

  moreValidattion() {
    const password = this.passwordForm.get('newPassword').value;
    const condition = password.includes(this.params.userName) || password.includes(this.params.firstName) || password.includes(this.params.lastName)
    return condition ? { notValid: true } : null;
  }

  confirm() {
    this.passwordForm.controls['userId'].setValue(this.params.userName);
    const password = this.passwordForm.get('newPassword').value;
    const condition = password.includes(this.params.userName) || password.includes(this.params.firstName) || password.includes(this.params.lastName)
    if (condition)
      alert("Password should not contain firstname or lastname or username");

      let data = {
      "oldPassword": { "value": this.passwordForm.get('oldPassword').value },
      "newPassword": { "value": this.passwordForm.get('newPassword').value },
      "userId"     : this.passwordForm.get('userId').value,
      "bearer_token" : this.accessToken
    }
    this.dataService.postPasswordChangeRequest(this.accessToken, JSON.stringify(data)).subscribe(
      (response) => { 
        if (response.status === 403) {
          if (response.response.errorCauses[0].errorSummary.includes("invalid credentials")) {
            this.toastr.error('Invalid credentials',"Error",{
              timeOut: 5000,
              extendedTimeOut: 5000
            });
          } else {
            this.toastr.error(response.response.errorCauses[0].errorSummary,"Error",{
              timeOut: 5000,
              extendedTimeOut: 5000
            });
          }
        } else {
          this.toastr.success(response.description,"Success",{
            timeOut: 5000,
            extendedTimeOut: 5000
          });
          this.router.navigate(['pages/home']);
        }                        
      },
      (error) => {             
        var errorText = JSON.parse(error.error.errorMessage.replaceAll('\'','\"')); 
        this.toastr.error(errorText.response,"Error",{
          timeOut: 5000,
          extendedTimeOut: 5000
        })
        this.passwordForm.reset();
      }
    )
 }

 Cancel() {
  this.router.navigate(['pages/update-profile'])
 }
}


