<body>
  <div class="content">

    <div class="left-fields">

      <h1 class="userinfo-header">Template Information</h1>

      <mat-form-field class="example-full-width tempname">
        <mat-label>Template Name</mat-label>
        <textarea style="height:20px" [formControl]="templateName" matInput (keyup)="displayTemplate($event)"
          placeholder="00000"></textarea>
          <mat-hint>Please enter at least 5 characters but not more than 30 characters</mat-hint>
          <mat-error *ngIf="templateName.hasError('required')">Please enter a template name
          </mat-error>
          <mat-error *ngIf="templateName.hasError('pattern')">Allowed characters are a-z, A-Z, 0-9, '@', '$', '^', '&', '_', comma and space. Please enter at least 5 characters but not more than 30 characters.
          </mat-error>
      </mat-form-field> 
      <br><br><br>
      <mat-form-field class="example-full-width">
        <mat-label>User Type</mat-label>
        <mat-select [formControl]="userType" [(value)]="selectedUserType" disabled
          (selectionChange)="onCategoryChange($event)">
          <mat-option *ngFor="let usertype of usertypes" [value]="usertype.value">
            {{usertype.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field> 

      <div *ngIf=isAffiliateUser>
        <mat-form-field class="example-full-width">
          <mat-label>Affiliate Office</mat-label>
          <mat-select [formControl]="affiliateOfficeControl" disableOptionCentering [(value)]="selectedAffiliate"
            (selectionChange)="onAffSelect()" placeholder="Select" required>
            <mat-option *ngFor="let affiliateOffice of affiliateOffices" [value]="affiliateOffice.value">
              {{ affiliateOffice.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="affiliateOfficeControl.hasError('required')">
            Please choose an Affiliate Office.</mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="example-full-width" appearance="fill" *ngIf="selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'AFFILIATE_STAFF'">
        <mat-checkbox [(ngModel)]="isUserMgmt" [formControl]="userMgmt">Allow User Management
        </mat-checkbox>
        <br>
      </div><br> -->

      <mat-form-field *ngIf="selectedUserType == 'PROPERTY_STAFF' || selectedUserType == 'AFFILIATE_STAFF'" class="example-full-width">
        <mat-label>User Role</mat-label>
        <mat-select [formControl]="templateRoleControl" [(value)]="selectedTemplateRole"
          (selectionChange)="onTemplateRoleChange($event)">
          <mat-option *ngFor="let templateRole of templateRoles" [value]="templateRole.value" matTooltip={{templateRole.tooltip}} matTooltipPosition="right" >
            {{templateRole.viewValue}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="templateRoleControl.hasError('required')">Please select a User Role
        </mat-error>
      </mat-form-field>
      <br><br><br>
      
      <table class="example-full-width" >
        <tr>
          <th>Created By</th>
          <td>{{tempCreatedBy}}</td>
          
        </tr>
        <tr>
          <th>Creation Date</th>
          <td>{{displayCreatedDate}}</td>
          
        </tr>
        <tr>
          <th>Last Modified By</th>
          <td>{{tempLastModifBy}}</td>
          
        </tr>
        <tr>
          <th>Last Modified Date</th>
          <td>{{tempLastModifDate}}</td>
          
        </tr>
      </table>
      


    </div>


    <div class="right-fields">
      

      <div *ngIf="loggedInUser == 'EMPLOYEE' && isPropertyUser"  >
        
          <p>Properties:</p>
          <textarea class="txtarea" [formControl]="propertyID" matInput [(ngModel)]="propertyids"
            placeholder="xxxxx"></textarea>
            <p *ngIf="propertyID.hasError('required') && propertyID.touched" class="prop-error" >Please enter a property ID
            </p>
            <p *ngIf="propertyID.hasError('pattern') && propertyID.touched" class="prop-error" >Allowed characters are only numbers and comma. Please enter 5-digit numerical property IDs separated by comma
            </p>
            <p *ngIf="propertyID.hasError('invalidProp') && propertyID.touched"
            class="prop-error">{{invalidPropMessage}}
            </p>
            <p *ngIf="propertyID.hasError('inactiveProp') && propertyID.touched"
            class="prop-error">{{inactivePropMessage}}
            </p>
      </div><br>
  
      <div class="user-groups"  *ngIf="(loggedInUser != 'EMPLOYEE' && isPropertyUser)">
        <ng-multiselect-dropdown [placeholder]="'click here to select properties'" [settings]="dropdownSettings"
            [data]="sortedDropDownList" [(ngModel)]="propertyid" [formControl]="propDropDown">
          </ng-multiselect-dropdown>
          <p *ngIf="propDropDown.hasError('required') && propDropDown.touched" class="prop-error">
            Please enter a property ID
        </p>
        <p *ngIf="propDropDown.hasError('invalidProp') && propDropDown.touched"
        class="prop-error">{{invalidPropMessage}}
        </p>
        <p *ngIf="propDropDown.hasError('inactiveProp') && propDropDown.touched"
        class="prop-error">{{inactivePropMessage}}
        </p> 
      </div><br>

      <div class="user-groups" *ngIf="(selectedUserType != 'PROPERTY_STAFF' && loggedInUser == 'EMPLOYEE')">
        <h1 class="usergroup-header">User Groups</h1>
        <ng-multiselect-dropdown [placeholder]="'click here to select user groups'" [settings]="dropdownSettings"
          [data]="dropdownList" [(ngModel)]="selectedItems" [formControl]="userGroupsControl">
        </ng-multiselect-dropdown>

      </div>
      <div class="pad-top">
        <button class="save-button bg-color-white" mat-raised-button (click)="goSearchTemplate()">Back</button>
        <button class="save-button bg-color-white" mat-raised-button (click)="resetControls()">Reset</button>
        <button class="save-button" mat-raised-button (click)="submit()">Next</button>
  
      </div>

    </div>
  </div>

  
  

     
     
      
    
   
    

    <!-- <div class="right-fields audittable">
      <h1 class="top-italic">Template Audit Data</h1>
      <table>
        <tr>
      <th><mat-form-field class="example-less-height">
        <mat-label>Template Created By:</mat-label>
        <textarea style="height:18px" [formControl]="tempCreatedBy" matInput readonly placeholder="xxxxx"></textarea>
      </mat-form-field></th>
       

      <th>><mat-form-field class="example-less-height">
        <mat-label>Template Created Date:</mat-label>
        <textarea style="height: 18px;" [formControl]="tempCreatedDate" matInput readonly
          placeholder="xxxx-xx-xx"></textarea>
      </mat-form-field></th>
    </tr>
      <tr>
      <th><mat-form-field class="example-less-height">
        <mat-label>Template Last Modified By:</mat-label>
        <textarea style="height: 18px;" [formControl]="tempLastModifBy" matInput readonly
          placeholder="xxxxx"></textarea>
      </mat-form-field></th>

      <th><mat-form-field class="example-less-height">
        <mat-label>Template Last Modified Date:</mat-label>
        <textarea style="height: 18px;" [formControl]="tempLastModifDate" matInput readonly
          placeholder="xxxx-xx-xx"></textarea>
      </mat-form-field></th>
    </tr>
    </table>
    </div> -->








</body>