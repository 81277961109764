import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
    constructor(private router: Router) { }

    handleError(error: any) {
        console.log('Error Location ::' + this.router.url);
        if (error instanceof HttpErrorResponse) {
            //Backend returns unsuccessful response codes such as 404, 500 etc.				  
            console.error('Backend returned status code: ', error.status);
            console.error('Response body:', error);
        } else {
            //A client-side or network error occurred.	          
            console.error('An error occurred:', error);
        }
        this.router.navigate(['pages/error']);
    }
} 