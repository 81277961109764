import { Memberweb } from "./memeberweb"

export class EditTemplateBody{
    userType: any 
    userManagement: any
    propertyID: any[]
    lastModifBy: any
    lastModifDate: any
    menuitems: Memberweb = new Memberweb;
    selectedItems: any[];
    affiliateOffice: string = '';
    templateName: any;
    ID: any;
    tempID: any;
    isEdit: boolean;
    createdDate: any;
    createdBy: any;
    templateRole: any;
    submittedBy: string;
}