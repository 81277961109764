import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators, FormArray, FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OktaAuthService } from '@okta/okta-angular';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from 'src/app/data.service';
import { User } from 'src/app/model/user';
import { SearchModel } from 'src/app/model/searchModel';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from "@angular/common";
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
const moment = _moment;

const MY_FORMATS = {
  parse: {
    dateInput: 'MMM DD yyyy',
  },
  display: {
    dateInput: 'MMM DD yyyy',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import { SearchRequest } from 'src/app/model/searchRequest';
import { CompoundSearchRequest } from 'src/app/model/compoundSearchRequest';
import { GlobalConstants } from '../global-constants';
import { GlobalErrorHandlerService } from '../GlobalErrorHandlerService';
import { isNull } from 'util';
import { TemplateProfile } from 'src/app/model/templateProfile';
import { element } from 'protractor';
import { Profile } from 'src/app/model/profile';
import { CreateUpdateRequest } from 'src/app/model/createUpdateRequest';
import { Memberweb } from 'src/app/model/memeberweb';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BulkApplyPopupComponent } from '../bulk-apply-popup/bulk-apply-popup.component';
import { GroupsTemplate } from 'src/app/model/groupsTemplate';
import { CreateTemplateGroupRequest } from 'src/app/model/createTemplateGroupRequest';
import { ListAllUsersforGroup } from 'src/app/model/listAllUsersforGroup';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TemplateSearchRequest, UserSearchRequest } from 'src/app/model/idm-models';
export interface Items {
  value: string;
  viewValue: string;
}

export interface Report {
  userId: string;
  lastName: string;
  firstName: string;
  status: string;
  activeDate: any;
  inactiveDate: any;
  userType: string;
  userManagement: boolean;
}

export interface Templates {
  viewValue: string;
  value: TemplateProfile;

}

@Component({
  selector: 'app-search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.css'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class SearchUserComponent implements OnInit {
  isUserMgmt: boolean;
  searchRequest: SearchRequest;
  searchFormArray = new FormArray([]);
  dataSource: any;
  dataSource1: any;
  isDisplayAddFilter: boolean = false;
  isDisplayCompoundSearch: boolean = false;
  isActive: boolean = true;
  isInactive: boolean = false;
  isDisplayBorder: boolean = true;
  isDisplyResults: boolean = false;
  isDisplayProps: boolean = false;
  isVendorUser: boolean = false;
  loggedin_vendor: string = '';
  activeAndInactive: String
  searchValue
  CustomOrCompound: string = 'custom';
  searchCustomorCompound: string = '';
  isRequestFromEditScreen: string = "N";
  accessToken: string;
  dropdownList = [];
  selectedItems = [];
  selectedUserProp: string;
  sortedDropDownList = [];
  dropDownListTemplate: Templates[] = [];
  dropdownSettings: IDropdownSettings;
  searchResults: User[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userClaims: any;
  searchModelArray = [];
  searchTemplateArray = [];
  tempIDtxtboxControl = new FormControl('', [Validators.pattern("^[a-zA-Z0-9_]+$")]);
  templateDropDownControl = new FormControl('');
  selectedProperties = new FormControl('');
  bulkUsers: any[] = [];
  editBulkUsers: User[] = [];
  uid: string[] = [];
  isUserChecked: boolean = false;
  selectAll: boolean = false;
  menuitems: Memberweb = new Memberweb();
  isformArrayError: boolean = false;
  filterMap: Map<String, String[]> = new Map();
  dateFormat = 'yyyy-MM-dd';
  dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
  locale = 'en-US';
  displayedColumns = ['userId', 'lastName', 'firstName', 'activeOrInactive', 'beginDate', 'endDate', 'userType', 'idm_role'];
  headerList = ['userId', 'lastName', 'firstName', 'status', 'activeDate', 'inactiveDate', 'userType', 'userManagement'];
  reportItems: Report[] = [];
  isSearchResult: boolean = false;
  exportData: any;
  searchValuesTemp = new FormControl();
  selDate: string;
  selDay: string;
  selMonth: string;
  selYear: string;
  searchForm = this.fb.group({
    searchControl: ['', [Validators.required, Validators.minLength(3), Validators.pattern("^[a-zA-Z0-9 ][a-zA-Z0-9_' .@-]*$")]],
    selectedProperties: ['', []]
  });

  searchItems: Items[] = [
    { value: 'uid', viewValue: 'User ID' },
    { value: 'lastName', viewValue: 'Last Name' },
    { value: 'firstName', viewValue: 'First Name' },
    { value: 'affiliateOffice', viewValue: 'Affiliate Office' },
    { value: 'propID', viewValue: 'PropId' },
    { value: 'beginDate', viewValue: 'Active Date' },
    { value: 'endDate', viewValue: 'Inactive Date' },
    { value: 'email', viewValue: 'Email' },
    { value: 'doNotDelete', viewValue: 'Do Not Delete' },
    { value: 'created', viewValue: 'Created Date' },
    { value: 'employeeTypeIDM', viewValue: 'User Type' }
  ];
  operatorList: Items[] = [
    { value: 'eq', viewValue: '=' },
    { value: 'gt', viewValue: '>' },
    { value: 'ge', viewValue: '>=' },
    { value: 'lt', viewValue: '<' },
    { value: 'le', viewValue: '<=' },
    { value: 'sw', viewValue: 'starts with' }
  ];
  minChars: boolean = false;
  isSuperEmployee: boolean;
  loggedInUser: any;
  selectedTemplate: any;
  placeholderValue: any = '';
  indexRole: any = 0;
  listAllUsersforGroup: ListAllUsersforGroup = new ListAllUsersforGroup();
  templateAdmin: any[];
  templatePowerUser: any[];
  templateRestrictedUser: any[];
  templateNone: any[];
  affilateOfficeCode: any;
  affiliateOffices: any;
  idm_role: any;
  // listAllUsers: [templateAdmin: any[], templatePowerUser: any[], templateRestrictedUser: any[], templateNone: any[]];

  addCount() {
    if (this.searchFormArray.valid) {
      this.isDisplayCompoundSearch = true;
      this.isformArrayError = false;
      this.searchFormArray.push(this.fb.group({ selectedItem: new FormControl("", [Validators.required]), selectedOperator: new FormControl("", [Validators.required]), searchValues: new FormControl("", [Validators.required]) }, {
        validator: this.minChars
      }));
    }
    else {
      this.isformArrayError = true;
    }
  }

  redirectToEditUser(element: any) {
    this.searchRequest = new SearchRequest();
    this.searchRequest.searchType = this.CustomOrCompound;
    this.searchRequest.isActive = this.isActive ? true : false;
    this.searchRequest.isInActive = this.isInactive ? true : false;
    if (this.CustomOrCompound == 'custom') {
      this.searchRequest.searchValue = this.searchValue;
      this.searchRequest.selectedProperties = this.selectedItems;
    } else if (this.CustomOrCompound == 'compound') {
      this.searchRequest.compoundSearchRequest = [];
      for (let control of this.searchFormArray.controls) {
        let compoundSearchRequest: CompoundSearchRequest = new CompoundSearchRequest();
        compoundSearchRequest.operator = control.value['selectedOperator'];
        compoundSearchRequest.item = control.value['selectedItem'];
        compoundSearchRequest.value = control.value['searchValues'];
        this.searchRequest.compoundSearchRequest.push(compoundSearchRequest);
      }
    }
    localStorage.setItem("searchRequest", JSON.stringify(this.searchRequest));
    if (element.profile.employeeTypeIDM == 'PROPERTY_STAFF' || element.profile.employeeTypeIDM == 'PROPERTY_ADMIN' ||
      ((element.profile.employeeTypeIDM == 'AFFILIATE_STAFF' || element.profile.employeeTypeIDM == 'AFFILIATE_ADMIN') &&
        element.profile.email != element.profile.login)) {
      element.profile.login = element.profile.uid + "@bwhhotel.com";
    }
    localStorage.setItem("editObject", JSON.stringify(element));
    this.dataService.editServiceData = element;
    this.dataService.userclaims = this.userClaims;
    this.router.navigate(['pages/edit-user']);
  }

  validateSearchRequest() {
    if (!this.searchFormArray.valid) {
      this.isformArrayError = true;
    }
    else {
      this.isformArrayError = false;
    }
  }

  validateMandetoryFields() {
    if ("Y" == localStorage.getItem("isButtonClicked")) {
      localStorage.removeItem("isButtonClicked");
      return true;
    }
    else if (this.searchForm.valid) {
      return true;
    } else {
      return false;
    }
  }

  async getSearchUsers() {
    this.isSearchResult = false;
    if (this.CustomOrCompound == 'custom') {
      if (!this.validateMandetoryFields()) {
        return;
      }
      this.isDisplyResults = true;
      this.searchCustomorCompound = 'custom';
      let searchModel = this.searchRequestBuilder(true);
      if (typeof this.searchValue != 'undefined')
        searchModel.customSearchValue = this.searchValue.trim();
      this.searchModelArray.push(searchModel);
    }
    else if (this.CustomOrCompound == 'compound') {
      this.validateSearchRequest();
      if (!this.searchFormArray.valid) {
        return;
      }
      this.isDisplyResults = true;
      this.searchCustomorCompound = 'compound';
      for (let control of this.searchFormArray.controls) {
        if (control.value['selectedItem'] == 'created' && control.value['selectedOperator'] == 'eq') {
          this.searchModelArray.push(this.compundSearchRequestBuilder('le', control.value['selectedItem'], control.value['searchValues']));
          this.searchModelArray.push(this.compundSearchRequestBuilder('ge', control.value['selectedItem'], control.value['searchValues']));
        } else if (control.value['selectedItem'] == 'affiliateOffice') {
          this.searchModelArray.push(this.compundSearchRequestBuilder('eq', control.value['selectedItem'], control.value['searchValues']));
        } else {
          this.searchModelArray.push(this.compundSearchRequestBuilder(control.value['selectedOperator'], control.value['selectedItem'],
            control.value['searchValues']));
        }
      }
    }
    this.sendUserRequest(this.searchModelArray, this.accessToken);
    this.searchModelArray = [];
  }

  getSortFilterPagination(data: any) {
    setTimeout(() => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.filterPredicate = (data: User, filter: Map<String, String[]>) => {
        let statusFilter = filter.get("STATUS")[0];
        let propertyFilter = filter.get("PROPERTY");
        let filterByStatus = false;
        let propAvailable = false;
        if (propertyFilter && propertyFilter.length > 0) {
          propAvailable = data.profile.propID.length === filter.get("PROPERTY").length &&
            data.profile.propID.every(item => filter.get("PROPERTY").includes(item));
        } else {
          propAvailable = true;
        }
        if (statusFilter == "none") {
          return false;
        } else if (statusFilter == "all") {
          filterByStatus = propAvailable;
        } else if (statusFilter == "active") {
          filterByStatus = !(data.status.toLowerCase() == "deprovisioned" || data.status.toLowerCase() == "suspended");
        } else if (statusFilter == "inactive") {
          filterByStatus = (data.status.toLowerCase() == "deprovisioned" || data.status.toLowerCase() == "suspended");
        }
        return propAvailable && filterByStatus;
      }
      data.forEach(element => {
        if ((element.profile.employeeTypeIDM == 'PROPERTY_ADMIN' || element.profile.employeeTypeIDM == 'AFFILIATE_ADMIN') &&
          (isNull(element.profile.userManagement) || element.profile.userManagement == undefined)) {
          element.profile.userManagement = true;
        }
        if (element.profile.login) {
          element.profile.login = element.profile.login && element.profile.login.indexOf("@bwhhotel.com") >= 0 ?
            element.profile.login.substr(0, element.profile.login.indexOf("@")) : element.profile.login;
        }
      });
      this.applyFilter()
    });

    setTimeout(() => this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'lastName': return item.profile.lastName.toLowerCase();
        case 'firstName': return item.profile.firstName.toLowerCase();
        case 'userId': return item.profile.login.toLowerCase();
        case 'userType': return item.profile.employeeTypeIDM.toLowerCase();
        case 'beginDate': return item.created;
        case 'endDate': return item.endDate;
        case 'activeOrInactive': return item.status;
        case 'userManagement': return item.profile.userManagement.toLowerCase();
        default: return item[property];
      }
    });
    setTimeout(() => this.dataSource.sort = this.sort);
    const sortState: Sort = { active: 'lastName', direction: 'asc' };
    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
    setTimeout(() => this.dataSource.paginator = this.paginator);
  }

  clearConditions() {
    if (this.searchFormArray && this.CustomOrCompound == 'compound') {
      this.searchFormArray.reset();
      this.isformArrayError = false;
    }
    if (this.searchForm && this.CustomOrCompound == 'custom') {
      this.searchForm.reset();
    }

  }

  removeCondition(idCount) {
    this.searchFormArray.removeAt(idCount);
    if (this.searchFormArray.length == 0) {
      this.isDisplayCompoundSearch = false;
    }
  }

  applyFilter() {
    let condition: string;
    if (this.isActive && !this.isInactive) {
      condition = "active";
    }
    else if (!this.isActive && this.isInactive) {
      condition = "inactive";
    }
    else if (this.isActive && this.isInactive) {
      condition = "all";
    }
    else if (!this.isActive && !this.isInactive) {
      condition = "none";
    }
    this.filterMap.set("STATUS", [condition]);
    this.dataSource.filter = this.filterMap;
    this.exportData = this.dataSource.filteredData;
    console.log(this.dataSource);
  }

  onItemSelect(item: any) {
    this.filterMap.set("PROPERTY", this.selectedItems);
    this.dataSource.filter = this.filterMap;
  }

  onItemDeselect(item: any) {
    this.filterMap.set("PROPERTY", this.selectedItems);
    this.dataSource.filter = this.filterMap;
  }
  onSelectAll(items: any) {
    this.filterMap.set("PROPERTY", this.userClaims.properties);
    this.dataSource.filter = this.filterMap;
  }

  onDeselectAll(items: any) {
    this.filterMap.set("PROPERTY", []);
    this.dataSource.filter = this.filterMap;
  }

  selectAllUsers(event) {
    if (this.selectAll == true) {
      this.bulkUsers = [];
      this.uid = [];
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        this.bulkUsers.push(this.dataSource.filteredData[i]);
        this.uid.push(this.dataSource.filteredData[i].profile.uid);
        this.dataSource.filteredData[i].isUserChecked = true;
      }
    } else {
      this.bulkUsers = [];
      this.uid = [];
      for (let i = 0; i < this.dataSource.filteredData.length; i++) {
        this.dataSource.filteredData[i].isUserChecked = false;
      }
    }
    console.log(this.dataSource);
    console.log(this.bulkUsers);
    console.log(this.uid);
  }

  fetchTemplates() {
    console.log(this.selectedUserProp);
    console.log(this.selectedProperties.value);
    let searchModel: SearchModel = new SearchModel();
    searchModel.isCustomSearch = true;
    searchModel.userRole = this.loggedInUser;
    const slctID = this.selectedItems;
    if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == true) {
      if (this.tempIDtxtboxControl.value.toString() == 'Multi_Property') {
        searchModel.customSearchValue = [this.tempIDtxtboxControl.value.toString()]
      } else {
        searchModel.customSearchValue = [this.tempIDtxtboxControl.value.toUpperCase().toString()];
      }
    } else {
      searchModel.customSearchValue = slctID;
    }
    if (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == true) {
      searchModel.isCorpUser = true;
      searchModel.propUnderScope = [];
    } else {
      searchModel.isCorpUser = false;
    }
    if (this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'PROPERTY_STAFF' ||
      (this.loggedInUser == 'EMPLOYEE' && this.isSuperEmployee == false)) {
      searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
    }
    // if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' || this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
    //   searchModel.propUnderScope = this.affiliateUserPropList;
    // }
    this.searchTemplateArray = [];
    this.dropDownListTemplate = [];
    this.searchTemplateArray.push(searchModel);
    this.sendTemplateRequest(this.searchTemplateArray, this.dataService.accessToken);
  }

  setMyStyles() {
    let styles = {
      'padding-top': this.isDisplayAddFilter ? '2%' : ''
      , 'padding-left': this.isDisplayAddFilter ? '30%' : 'unset'
    };
    return styles;
  }
  radioChange() {
    this.isDisplayCompoundSearch = true;
    if (this.searchCustomorCompound != '' && this.CustomOrCompound != this.searchCustomorCompound) {
      this.isDisplyResults = true;
    }
    else {
      this.isDisplyResults = false;
    }
    if ("Y" == this.isRequestFromEditScreen) {
      this.isDisplyResults = true;
    }
  }
  mathOperatorforNumbers(str: any, str1: any): boolean {
    if (str == 'endDate' || str == 'beginDate') {
      return false;
    } else if (str == 'created') {
      return str1 == 'sw' ? true : false;
    } else if (str == 'affiliateOffice') {
      return (str1 == 'sw' || str1 == 'gt' || str1 == 'ge' || str1 == 'lt' || str1 == 'le') ? true : false;
    } else {
      return (str1 == 'gt' || str1 == 'ge' || str1 == 'lt' || str1 == 'le') ? true : false;
    }
  }

  onCategoryChange(index: any) {
    let searchcontrol = this.searchFormArray.controls[index] as FormArray;
    searchcontrol.controls['searchValues'].reset();
    searchcontrol.controls['selectedOperator'].reset();
  }

  validateSearchValue(selectedItem: any, index: any): boolean {
    let hasMinCharError = false;
    let searchcontrol = this.searchFormArray.controls[index] as FormArray;
    if (searchcontrol.controls['searchValues'].value != null && searchcontrol.controls['searchValues'].value != '') {
      if ((selectedItem == 'uid' || selectedItem == 'lastName' || selectedItem == 'firstName' || selectedItem == 'email')) {
        if (searchcontrol.controls['searchValues'].value.length < 3) {
          searchcontrol.controls['searchValues'].setErrors({ 'minChars': true });
          hasMinCharError = true;
        }
      }
      searchcontrol.controls['searchValues'].markAsTouched();
    }
    return hasMinCharError;
  }

  constructor(public oktaAuth: OktaAuthService, private router: Router, public dialog: MatDialog, private dataService: DataService, private fb: FormBuilder, private tostr: ToastrService, public globalConstants: GlobalConstants, private globalErrorHandlerService: GlobalErrorHandlerService) { }


  async ngOnInit() {
    const userClaims = JSON.parse(localStorage.getItem("userClaims"));
    this.accessToken = await this.oktaAuth.getAccessToken();
    this.loggedInUser = userClaims.userRole;
    this.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';
    this.userClaims = userClaims;
    if (userClaims.userRole === 'VENDOR_ADMIN' || userClaims.userRole === 'VENDOR_STAFF') {
      this.isVendorUser = true;
      this.loggedin_vendor = userClaims.vendorCode;
    }
    this.listAllUsersforGroup.groupId = [environment.user_roles.admin_role, environment.user_roles.power_role, environment.user_roles.restricted_role, environment.user_roles.no_user_mgnt];
    this.listAllUsersforGroup.listOfUsers = [];
    this.listAllUsersforGroup.listOfUsers.push(this.templateAdmin, this.templatePowerUser, this.templateRestrictedUser, this.templateNone);
    this.getListOfAllUsers();

    this.dataService.invokeBulkApply.pipe(take(1)).subscribe((data: any) => {
      console.log('data', data);
      if (data != this.placeholderValue) {
        this.applyTemplate(data);
      }
    }, (error: any) => {
      console.log(error);
    })
    console.log(this.editBulkUsers);
    console.log(this.bulkUsers);

    this.isRequestFromEditScreen = localStorage.getItem("isRequestFromEditScreen");
    localStorage.removeItem("isRequestFromEditScreen");
    if (userClaims.userRole === 'PROPERTY_ADMIN' || userClaims.userRole === 'PROPERTY_STAFF' ||
      userClaims.userRole === 'VENDOR_ADMIN' || userClaims.userRole === 'VENDOR_STAFF') {
      this.CustomOrCompound = 'custom';
      this.isDisplyResults = true;
      if (!this.isVendorUser)
        this.isDisplayProps = true;
      this.dropdownList = userClaims.properties;
      this.sortedDropDownList = this.dropdownList.sort();
      let searchRequest: SearchRequest = JSON.parse(localStorage.getItem("searchRequest"));
      let searchValue = isNull(searchRequest) ? null : searchRequest.searchValue;
      if (isNull(searchValue) || searchValue == undefined)
        this.isRequestFromEditScreen = "N";

      if ("Y" != this.isRequestFromEditScreen) {
        const body = { "userId": userClaims.login_ID, "propId": userClaims.properties, "userRole": userClaims.userRole, "vendorCode": this.loggedin_vendor }
        this.dataService.getPropUserDetails(body, this.accessToken).then((data: String) => {
          this.searchResults = JSON.parse(data.toString());
          if (this.searchResults.length === 0) {
            this.tostr.error("No records found with given search input", "Error", {
              timeOut: 5000,
              extendedTimeOut: 5000
            })
          }
          this.filterDeprovisioned(this.searchResults);

        }, (error) => {
          this.globalErrorHandlerService.handleError(error);
          console.log("*** error" + error);  //Error callback
        });
      }
    } else if (this.loggedInUser == 'AFFILIATE_ADMIN' || this.loggedInUser == 'AFFILIATE_STAFF' ||
      this.loggedInUser == 'REGIONAL_AFFILIATE_ADMIN') {
      this.isDisplayAddFilter = true;
      if ("Y" != this.isRequestFromEditScreen) {
        this.searchFormArray.push(this.fb.group({ selectedItem: new FormControl("", [Validators.required]), selectedOperator: new FormControl("", [Validators.required]), searchValues: new FormControl("", [Validators.required]) }, {
          validator: this.minChars
        }));
      }
      this.dataService.getAffiliateOffices(this.dataService.userclaims.affiliateOffice, this.dataService.accessToken).then((data: string) => {
        this.affilateOfficeCode = JSON.parse(JSON.stringify(data));
        if (this.affilateOfficeCode != null) {
          if (typeof this.affilateOfficeCode.properties != 'undefined' && this.affilateOfficeCode.properties != null) {
            this.sortedDropDownList = this.affilateOfficeCode.properties.sort();
          }
          if (this.affilateOfficeCode.regionalAffiliate) {
            for (let i = 0; i < this.affilateOfficeCode.affiliates.length; i++) {
              this.sortedDropDownList.push(this.affilateOfficeCode.affiliates[i].code);
            }
          } else {
            this.sortedDropDownList.push(this.affilateOfficeCode.code);
          }

        }
      },
        (error) => {
          this.globalErrorHandlerService.handleError(error);
        });
    } else {
      this.isDisplayAddFilter = true;
      if ("Y" != this.isRequestFromEditScreen) {
        this.searchFormArray.push(this.fb.group({ selectedItem: new FormControl("", [Validators.required]), selectedOperator: new FormControl("", [Validators.required]), searchValues: new FormControl("", [Validators.required]) }, {
          validator: this.minChars
        }));
      }
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 30,
      allowSearchFilter: true
    };
    if ("Y" == this.isRequestFromEditScreen) {
      let searchRequest: SearchRequest = JSON.parse(localStorage.getItem("searchRequest"));
      this.isActive = searchRequest.isActive
      this.isInactive = searchRequest.isInActive;
      this.CustomOrCompound = searchRequest.searchType;
      if (this.CustomOrCompound == 'custom') {
        this.searchValue = searchRequest.searchValue;
        this.selectedItems = searchRequest.selectedProperties;
      } else if (this.CustomOrCompound == 'compound') {
        let request: CompoundSearchRequest[] = searchRequest.compoundSearchRequest;
        request.forEach(item => {
          this.searchFormArray.push(this.fb.group({
            selectedItem: new FormControl(item.item, [Validators.required]),
            selectedOperator: new FormControl(item.operator, [Validators.required]),
            searchValues: new FormControl(item.value, [Validators.required])
          }, {
            validator: this.minChars
          }));

        });
        this.isDisplayCompoundSearch = true;
      }
      localStorage.removeItem("searchRequest");
      this.getSearchUsers();
    }
    if (userClaims.properties && userClaims.properties.length == 1) {
      this.selectedItems = userClaims.properties;
    }

    if ((this.loggedInUser == 'PROPERTY_ADMIN' || this.loggedInUser == 'PROPERTY_STAFF') && userClaims.properties.length == 1) {
      let searchModel: SearchModel = new SearchModel();
      searchModel.isCustomSearch = true;
      searchModel.userRole = this.loggedInUser;
      searchModel.customSearchValue = this.selectedItems;
      searchModel.isCorpUser = false;
      searchModel.propUnderScope = this.dataService.userclaims.properties.sort();
      this.searchTemplateArray = [];
      this.dropDownListTemplate = [];
      this.searchTemplateArray.push(searchModel);
      this.sendTemplateRequest(this.searchTemplateArray, this.dataService.accessToken);
    }

  }

  getListOfAllUsers() {
    for (let i = 0; i < this.listAllUsersforGroup.groupId.length; i++) {
      this.dataService.groupsTemplate = new GroupsTemplate();
      this.dataService.groupsTemplate.user = null;
      this.dataService.groupsTemplate.group = this.listAllUsersforGroup.groupId[i];
      this.dataService.groupsTemplate.delGroup = null;
      let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
      createTemplateGroupRequest.group = this.dataService.groupsTemplate;
      createTemplateGroupRequest.submitted_by = this.dataService.userclaims?.userId;
      createTemplateGroupRequest.bearer_token = this.accessToken;
      const body = {
        "Detail": JSON.stringify(createTemplateGroupRequest),
        "Source": "searchUserEvent"
      }
      console.log(this.listAllUsersforGroup.groupId[i]);
      this.dataService.templatesRoles(body, this.accessToken).then((data) => {
        this.listAllUsersforGroup.listOfUsers[i] = data;
      }, (error) => {
        console.log(error);
      })
    }
  }

  changeTemplateRole() {
    console.log('test');
    console.log(this.listAllUsersforGroup);
    for (let i = 0; i < this.bulkUsers.length; i++) {
      console.log(this.bulkUsers[i]);
      for (let j = 0; j < this.listAllUsersforGroup.listOfUsers.length; j++) {
        console.log(this.listAllUsersforGroup.listOfUsers[j]);
        for (let k = 0; k < this.listAllUsersforGroup.listOfUsers[j].length; k++) {
          if (this.bulkUsers[i].id == this.listAllUsersforGroup.listOfUsers[j][k].id) {
            console.log('matched', i, j, k);
            if (j == 0 && j != this.indexRole && (this.bulkUsers[i].profile.employeeTypeIDM == 'PROPERTY_STAFF' || this.bulkUsers[i].profile.employeeTypeIDM == 'AFFILIATE_STAFF')) {
              this.dataService.groupsTemplate = new GroupsTemplate();
              this.dataService.groupsTemplate.group = this.selectedTemplate.templateRole;
              this.dataService.groupsTemplate.user = this.bulkUsers[i].id;
              this.dataService.groupsTemplate.delGroup = environment.user_roles.admin_role;
              let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
              createTemplateGroupRequest.group = this.dataService.groupsTemplate;
              createTemplateGroupRequest.submitted_by = this.dataService.userclaims.userId;
              createTemplateGroupRequest.bearer_token = this.accessToken;
              const body = {
                "Detail": JSON.stringify(createTemplateGroupRequest),
                "Source": "searchUserEvent" + "/" + this.dataService.editServiceData.profile.login
              }
              this.dataService.templatesRoles(body, this.accessToken).then((data: String) => {
                console.log(data);
              }, (error) => {
                console.log(error);
              })
            }
            if (j == 1 && j != this.indexRole && (this.bulkUsers[i].profile.employeeTypeIDM == 'PROPERTY_STAFF' || this.bulkUsers[i].profile.employeeTypeIDM == 'AFFILIATE_STAFF')) {
              this.dataService.groupsTemplate = new GroupsTemplate();
              this.dataService.groupsTemplate.group = this.selectedTemplate.templateRole;
              this.dataService.groupsTemplate.user = this.bulkUsers[i].id;
              this.dataService.groupsTemplate.delGroup = '00g18vgojctBaWnFy0h8';
              let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
              createTemplateGroupRequest.group = this.dataService.groupsTemplate;
              createTemplateGroupRequest.submitted_by = this.dataService.userclaims.userId;
              createTemplateGroupRequest.bearer_token = this.accessToken;
              const body = {
                "Detail": JSON.stringify(createTemplateGroupRequest),
                "Source": "searchUserEvent" + "/" + this.dataService.editServiceData.profile.login
              }
              this.dataService.templatesRoles(body, this.accessToken).then((data: String) => {
                console.log(data);
              }, (error) => {
                console.log(error);
              })
            }
            if (j == 2 && j != this.indexRole && (this.bulkUsers[i].profile.employeeTypeIDM == 'PROPERTY_STAFF' || this.bulkUsers[i].profile.employeeTypeIDM == 'AFFILIATE_STAFF')) {
              this.dataService.groupsTemplate = new GroupsTemplate();
              this.dataService.groupsTemplate.group = this.selectedTemplate.templateRole;
              this.dataService.groupsTemplate.user = this.bulkUsers[i].id;
              this.dataService.groupsTemplate.delGroup = '00g18vgicefcsxFfY0h8';
              let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
              createTemplateGroupRequest.group = this.dataService.groupsTemplate;
              createTemplateGroupRequest.submitted_by = this.dataService.userclaims.userId;
              createTemplateGroupRequest.bearer_token = this.accessToken;
              const body = {
                "Detail": JSON.stringify(createTemplateGroupRequest),
                "Source": "searchUserEvent" + "/" + this.dataService.editServiceData.profile.login
              }
              this.dataService.templatesRoles(body, this.accessToken).then((data: String) => {
                console.log(data);
              }, (error) => {
                console.log(error);
              })
            }
            if (j == 3 && j != this.indexRole && (this.bulkUsers[i].profile.employeeTypeIDM == 'PROPERTY_STAFF' || this.bulkUsers[i].profile.employeeTypeIDM == 'AFFILIATE_STAFF')) {
              this.dataService.groupsTemplate = new GroupsTemplate();
              this.dataService.groupsTemplate.group = this.selectedTemplate.templateRole;
              this.dataService.groupsTemplate.user = this.bulkUsers[i].id;
              this.dataService.groupsTemplate.delGroup = '00g18vgrq1wxUNGPK0h8';
              let createTemplateGroupRequest: CreateTemplateGroupRequest = new CreateTemplateGroupRequest();
              createTemplateGroupRequest.group = this.dataService.groupsTemplate;
              createTemplateGroupRequest.submitted_by = this.dataService.userclaims.userId;
              createTemplateGroupRequest.bearer_token = this.accessToken;
              const body = {
                "Detail": JSON.stringify(createTemplateGroupRequest),
                "Source": "searchUserEvent" + "/" + this.dataService.editServiceData.profile.login
              }
              this.dataService.templatesRoles(body, this.accessToken).then((data: String) => {
                console.log(data);
              }, (error) => {
                console.log(error);
              })
            }
          }
        }

      }
    }
    console.log(this.dataSource.filteredData);
  }

  searchRequestBuilder(isCustomSearch: boolean) {
    let searchModel: SearchModel = new SearchModel();
    if (this.dataService.userclaims.userRole == 'EMPLOYEE')
      searchModel.isSuperEmployee = localStorage.getItem('isSuperEmployee') == 'true';
    searchModel.isCustomSearch = isCustomSearch;
    searchModel.userId = this.userClaims.login_ID
    searchModel.propertyId = this.userClaims.properties;
    searchModel.selectedItems = this.selectedItems
    searchModel.userRole = this.userClaims.userRole;
    searchModel.affiliateOffice = this.userClaims.affiliateOffice;
    return searchModel;
  }

  compundSearchRequestBuilder(selectedOperator: string, selectedItem: string, searchValues: string) {
    let searchModel = this.searchRequestBuilder(false);
    let searchString = searchValues;
    searchModel.operator = selectedOperator;
    searchModel.searchCriteria = selectedItem;

    /* For begin/End Date change if operator is eq change to sw and convert string to Date format
        For Created Date convert string to required date/time format. 
    */
    if (searchString == undefined || searchString == '' || searchString == ' ') {
      searchString = "*";
    } else {
      if (searchModel.searchCriteria == 'beginDate' || searchModel.searchCriteria == 'endDate') {
        if (searchModel.operator == 'eq') {
          searchModel.operator = 'sw';
        }
        searchString = formatDate(searchValues, this.dateFormat, this.locale);
      } else if (searchModel.searchCriteria == 'created') {
        if (searchModel.operator == 'gt' || searchModel.operator == 'le') {
          let endDate = new Date(searchValues).setHours(23, 59, 59, 999);
          searchString = formatDate(endDate, this.dateTimeFormat, this.locale);
        } else if (searchModel.operator == 'ge' || searchModel.operator == 'lt') {
          searchString = formatDate(searchValues, this.dateTimeFormat, this.locale);
        }
      } else {
        searchString = searchValues;
      }
    }
    searchModel.inputValue = '"' + searchString + '"';
    return searchModel;
  }

  sendUserRequest(request: any[], accessToken) {
    let userSearchRequest = new UserSearchRequest();
    userSearchRequest.searchRequestData = request;
    userSearchRequest.submittedBy = this.dataService.userclaims.userId;
    this.dataService.sendUserSearchRequest(userSearchRequest, accessToken).subscribe((data: String) => {
      this.searchResults = JSON.parse(data.toString());
      this.filterDeprovisioned(this.searchResults);
    }, (error) => {
      var errorText;
      if (error.error.message) {
        errorText = error.message;
        console.log("*** error" + error);  //Error callback
        this.globalErrorHandlerService.handleError(error);
      } else {
        var errorText = JSON.parse(error.error.errorMessage.replaceAll('\'', '\"'));
        if (errorText.statusCode == 400) {
          this.tostr.error(errorText.errorDesc, "Error", {
            timeOut: 5000,
            extendedTimeOut: 5000
          });
        }
      }
    });
  }

  async filterDeprovisioned(searchResult) {
    // for (var i = 0; i < searchResult.length; i = i + 1) {
    //   if (searchResult[i]['status'] === "DEPROVISIONED") {
    //     delete searchResult[i];
    //   }
    // }
    if (searchResult.length === 0) {
      this.isSearchResult = false;
      this.tostr.error("No records found with given search input", "Error", {
        timeOut: 5000,
        extendedTimeOut: 5000
      })
    } else {
      this.isSearchResult = true;
    }
    this.getSortFilterPagination(searchResult);
  }

  showStatusTooltip(status): string {
    let tooltipText = ""
    if (status === "ACTIVE") {
      tooltipText = this.globalConstants.status_tooltip_active;
    } else if (status === "PROVISIONED") {
      tooltipText = this.globalConstants.status_tooltip_active_pending;
    } else if (status === "RECOVERY") {
      tooltipText = this.globalConstants.status_tooltip_recovery;
    } else if (status === "PASSWORD_EXPIRED") {
      tooltipText = this.globalConstants.status_tooltip_password_expired;
    } else if (status === "LOCKED_OUT") {
      tooltipText = this.globalConstants.status_tooltip_locked_out;
    } else if (status === "SUSPENDED") {
      tooltipText = this.globalConstants.status_tooltip_suspended;
    } else if (status === "DEPROVISIONED") {
      tooltipText = this.globalConstants.status_tooltip_deprovisioned;
    }
    return tooltipText;
  }

  downloadFile(data, filename) {
    let csvData = this.ConvertToCSV(data, this.headerList);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  reportData(data) {
    this.reportItems = [];
    for (var i = 0; i < data.length; i = i + 1) {
      let trimUserID = data[i].profile.login && data[i].profile.login.indexOf("@bwhhotel.com") >= 0 ?
        data[i].profile.login.substr(0, data[i].profile.login.indexOf("@")) :
        data[i].profile.login;

      this.reportItems.push({
        userId: trimUserID,
        lastName: data[i].profile.lastName,
        firstName: data[i].profile.firstName,
        status: data[i].status == 'PROVISIONED' ? "ACTIVE (PENDING)" : (data[i].status == 'SUSPENDED') ? ("DEACTIVATED") : (data[i].status),
        activeDate: data[i].profile.beginDate ? data[i].profile.beginDate.split('T')[0] : "",
        inactiveDate: data[i].profile.endDate ? data[i].profile.endDate.split('T')[0] : "",
        userType: data[i].profile.employeeTypeIDM,
        userManagement: data[i].profile.userManagement
      })
    }
    return this.reportItems;
  }

  exportSearchResult() {
    this.downloadFile(this.reportData(this.exportData), 'SearchResult');
  }

  async sendTemplateRequest(request: any[], accessToken: any) {
    let templateSearchRequest = new TemplateSearchRequest();
    templateSearchRequest.submittedBy = this.dataService.userclaims.userId;
    templateSearchRequest.searchRequestData = request;
    this.dataService.getTemplate(templateSearchRequest, accessToken).subscribe((data: any) => {
      const rslt = JSON.parse(JSON.stringify(data));
      const srchrslt = rslt.body;
      if (srchrslt.length === 0) {
        // this.tostr.error("No template found with given search input", "Error", {
        //   timeOut: 5000,
        //   extendedTimeOut: 5000
        // })
        this.dropDownListTemplate.push({ viewValue: "No Template found with given input", value: null });
      }
      if (this.dropDownListTemplate.length == 0) {
        for (let i = 0; i < srchrslt.length; i++) {
          if (srchrslt[i].ID == 'Multi_Property') {
            this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName + ' - ' + srchrslt[i].propertyID, value: srchrslt[i] });
          } else {
            this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName, value: srchrslt[i] });
          }
        }
      } else {
        for (let i = 0; i < srchrslt.length; i++) {
          if (this.dropDownListTemplate[0].value.templateid != srchrslt[i].templateid) {
            if (srchrslt[i].ID == 'Multi_Property') {
              this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName + ' - ' + srchrslt[i].propertyID, value: srchrslt[i] });
            } else {
              this.dropDownListTemplate.push({ viewValue: srchrslt[i].templateName, value: srchrslt[i] });
            }
          }
        }
      }

    }, (error) => {
      console.log("*** error" + error); //Error callback
      this.globalErrorHandlerService.handleError(error);
    })
  }

  onTemplateChange(event) {
    if (event.value != null) {
      this.selectedTemplate = event.value;
      if (this.selectedTemplate.templateRole == environment.user_roles.admin_role) {
        this.indexRole = 0;
        this.idm_role = 'Admin';
      }
      if (this.selectedTemplate.templateRole == environment.user_roles.power_role) {
        this.indexRole = 1;
        this.idm_role = 'PowerUser';
      }
      if (this.selectedTemplate.templateRole == environment.user_roles.restricted_role) {
        this.indexRole = 2;
        this.idm_role = 'Restricted';
      }
      if (this.selectedTemplate.templateRole == environment.user_roles.no_user_mgnt) {
        this.indexRole = 3;
        this.idm_role = 'None';
      }
    }
  }

  addUserToBulkUpdate(event: MatCheckboxChange, element) {
    if (element.isUserChecked) {
      console.log(event);
      this.bulkUsers.push(element);
      console.log(this.bulkUsers);
      this.uid.push(element.profile.uid);
    } else {
      for (let i = 0; i < this.bulkUsers.length; i++) {
        if (element.profile.uid == this.bulkUsers[i].profile.uid) {
          this.bulkUsers.splice(i, 1);
        }
      }
      for (let j = 0; j < this.uid.length; j++) {
        if (element.profile.uid == this.uid[j]) {
          this.uid.splice(j, 1);
        }
      }
    }
    if (this.dataSource.filteredData.length == this.bulkUsers.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
    console.log(this.bulkUsers);
  }

  applyTemplate(data) {
    this.placeholderValue = data;
    console.log('bulk apply works', data);
    console.log(this.bulkUsers);
    console.log(this.editBulkUsers);
    this.editBulkUsers = [];
    for (let i = 0; i < this.bulkUsers.length; i++) {
      let editUser: User = new User();
      let profile: Profile = new Profile();
      editUser.profile = profile;
      profile.firstName = this.bulkUsers[i].profile.firstname;
      profile.lastName = this.bulkUsers[i].profile.lastname;
      profile.employeeTypeIDM = this.selectedTemplate.userType;
      profile.uid = this.bulkUsers[i].profile.userIdcontrol;
      // profile.userManagement = this.selectedTemplate.userManagement;
      profile.idm_role = this.idm_role;
      profile.doNotDelete = this.bulkUsers[i].profile.isDelete;
      profile.beginDate = this.bulkUsers[i].profile.beginDate;
      profile.endDate = this.bulkUsers[i].profile.endDate;
      if (profile.employeeTypeIDM == 'AFFILIATE_ADMIN' || profile.employeeTypeIDM == 'AFFILIATE_STAFF' || profile.employeeTypeIDM == 'REGIONAL_AFFILIATE_ADMIN') {
        profile.affiliateOffice = this.selectedTemplate.affiliateOffice;
      }
      profile.initials = this.bulkUsers[i].profile.initials;
      profile.propID = this.selectedTemplate.propertyID;
      profile.groupsAssigned = this.selectedTemplate.selectedItems;
      profile.email = this.bulkUsers[i].profile.email;
      profile.login = this.bulkUsers[i].profile.login;
      profile.secondEmail = this.bulkUsers[i].profile.secondEmail;
      profile.mobilePhone = this.bulkUsers[i].profile.mobilePhone;
      profile.profileType = this.bulkUsers[i].profile.profileType;
      this.editBulkUsers.push(editUser);
    }
    for (let i = 0; i < this.editBulkUsers.length; i++) {
      let createUpdateRequest: CreateUpdateRequest = new CreateUpdateRequest();
      createUpdateRequest.user = this.editBulkUsers[i];
      createUpdateRequest.submitted_by = this.dataService.userclaims.userId;
      createUpdateRequest.bearer_token = this.accessToken;
      if ((this.editBulkUsers[i].profile.employeeTypeIDM == 'AFFILIATE_ADMIN' 
        || this.editBulkUsers[i].profile.employeeTypeIDM == 'AFFILIATE_STAFF' 
        || this.editBulkUsers[i].profile.employeeTypeIDM == 'REGIONAL_AFFILIATE_ADMIN' 
        || this.editBulkUsers[i].profile.employeeTypeIDM == 'VENDOR_ADMIN' 
        || this.editBulkUsers[i].profile.employeeTypeIDM == 'VENDOR_STAFF')) {
        createUpdateRequest.searchUser = this.dataService.editServiceData.profile.email;
      } else {
        createUpdateRequest.searchUser = this.dataService.editServiceData.profile.login;
      }
      const body = {
        "Detail": JSON.stringify(createUpdateRequest),
        "Source": "updateUserEvent"
      }
      this.dataService.postUserDetails(body, this.accessToken).then((data: String) => {
        console.log(data);

      }, (error) => {
        console.log(error);
      })
    }
    for (let i = 0; i < this.selectedTemplate.menuitems.menus.length; i++) {
      if (this.selectedTemplate.menuitems.menus[i] != null) {
        this.menuitems.menus.push(this.selectedTemplate.menuitems.menus[i]);
      }
    }
    this.menuitems["updated-by"] = this.dataService.userclaims.userId;
    this.menuitems["bearer_token"] = this.accessToken;
    console.log(this.uid);
    for (let i = 0; i < this.uid.length; i++) {
      this.dataService.postMemberMenusAccess(this.uid[i], JSON.stringify(this.menuitems), this.accessToken).subscribe(
        (response) => {
          console.log(response);
          // this.successMessage = "Menu access has been updated."
          // localStorage.setItem("isRequestFromEditScreen", "Y");
          // this.openDialog(this.successMessage, "Success")
        },
        (error) => {
          console.log(error);
          // this.successMessage = 'Menus Update unsuccessfull! please contact Adminstrator.'
          // localStorage.setItem("isRequestFromEditScreen", "Y");
          // this.openDialog(this.successMessage, "Warning")
        }
      )
    }
    this.changeTemplateRole();
    setTimeout(() => {
      localStorage.setItem("isRequestFromEditScreen", "Y");
      this.openDialog('User account has been updated.', 'Success');
    }, 5000)
  }


  openDialog(description, title): void {
    const dialogRef = this.dialog.open(PopupComponent, {
      width: '500px',
      data: { description: description, title: title },
      disableClose: true
    });
  }

  bulkApplyConfirmation() {
    this.dialog.open(BulkApplyPopupComponent, {
      width: '500px',
      disableClose: true
    });
  }

  // ngOnDestroy() {
  //   this.dataService.invokeBulkApply.unsubscribe();
  // }
}