import { Component } from '@angular/core';
import { UserAuthService } from './user-auth.service';
import {User} from './model/user';
import { SpinnerService } from './spinner.service';
import '../../src/styles.css';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'custom-idm';
  flag:boolean = false;
  user:User;
   constructor(private myservice: UserAuthService,  public spinnerService: SpinnerService) {}
   ngOnInit() {
      this.myservice.userInfo().then((data: any)=>{
        if (data!=null) {
          this.flag = true;
        }
        });
   }
}
