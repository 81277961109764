import { Memberweb } from "./memeberweb"

export class TemplateProfile{
    userType: any 
    userManagement: any 
    propertyID: any[]
    createdBy: any
    createdDate: any
    lastModifBy: any
    lastModifDate: any
    menuitems: Memberweb = new Memberweb();
    selectedItems: any[] = [];
    affiliateOffice: string = '';
    templateName: any;
    templateid: any;
    ID: any;
    templateRole: any;
    submittedBy: string;
}