import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
  description: String,
  title: String
}

@Component({
  selector: 'app-pop-up-template',
  templateUrl: './pop-up-template.component.html',
  styleUrls: ['./pop-up-template.component.css']
})
export class PopUpTemplateComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onClick(){
    this.dialog.closeAll();
    /* if (localStorage.getItem("isRequestFromTemplateEditScreen") === "Y") {
      this.router.navigate(['pages/create-user'])
    } else if (localStorage.getItem("isRequestFromTemplateEditScreen") === "N") {
      this.router.navigate(['pages/create-user']) 
    }  */
    
    this.router.navigate(['pages/searchTemplate']);
  }

}
