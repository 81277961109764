import { User } from "./user";

export class CreateUpdateRequest{
    user: User;
    searchUser: string;
    submitted_by: string;
    bearer_token: string;    
    isPdrUser: boolean;
    networkRestricted: boolean
    updateGroups: boolean;
}