<div class ="basic">
    <p class="font18">{{globalConstants.welcomeMessage}}</p>
    <p class="warning">{{globalConstants.warningMessage}}</p>
    <p class="font14 paddingtop2-5">{{question1}}</p>
    <form class="example-form" [formGroup]="CAForm">
        <div class="flex-container">
            <mat-form-field>
                <input type="password" matInput  formControlName="userChallengeAnswer1">
                <mat-error class="font16" *ngIf="CAForm.get('userChallengeAnswer1').hasError('required')">{{globalConstants.req_field}}</mat-error>
                <mat-hint>{{globalConstants.challenge_ans1}}</mat-hint>            
            </mat-form-field>
            <div class="align" *ngIf="CAForm.controls.userChallengeAnswer1.value">
                <img *ngIf="tick1" class="imgsize" src="../../../assets/icons/new_green_tick.png">
                <img *ngIf="!tick1" class="imgsize" src="../../../assets/icons/red_cross.png">
            </div>
        </div>
        
        <p class="font14 paddingtop2-5">{{question2}}</p>
        <div class="flex-container">
            <mat-form-field>
                <input type="password" matInput  formControlName="userChallengeAnswer2">
                <mat-error class="font16" *ngIf="CAForm.get('userChallengeAnswer2').hasError('required')">{{globalConstants.req_field}}</mat-error>
                <mat-hint>{{globalConstants.challenge_ans2}}</mat-hint>
            </mat-form-field>
            <div class="align" *ngIf="CAForm.controls.userChallengeAnswer2.value">
                <img *ngIf="tick2" class="imgsize" src="../../../assets/icons/new_green_tick.png">
                <img *ngIf="!tick2" class="imgsize" src="../../../assets/icons/red_cross.png">
            </div>
        </div>

        <p class="font14 paddingtop2-5">{{question3}}</p>
        <div class="flex-container">
            <mat-form-field>
                <input  type="password" matInput  formControlName="userChallengeAnswer3">
                <mat-error class="font16" *ngIf="CAForm.get('userChallengeAnswer3').hasError('required')">{{globalConstants.req_field}}</mat-error>
                <mat-hint>{{globalConstants.challenge_ans3}}</mat-hint>
            </mat-form-field>
            <div class="align" *ngIf="CAForm.controls.userChallengeAnswer3.value">
                <img *ngIf="tick3" class="imgsize" src="../../../assets/icons/new_green_tick.png">
                <img *ngIf="!tick3" class="imgsize" src="../../../assets/icons/red_cross.png">
            </div>
        </div>
        <p *ngIf="errorTF" class="error">{{error}} </p>
        <div>
             <button class="save-button" mat-raised-button (click)="validateAnswer()">{{globalConstants.submit}}</button>
             <button class="save-button marginleft5" mat-raised-button (click)="Cancel()">{{globalConstants.cancel}}</button>
        </div>
    </form>
</div>