<body>
    <div class="content">
        <h1 class="search-lable"><i>{{globalConstants.search_user}}</i>
            <mat-radio-group *ngIf="isDisplayAddFilter" class="quick-search" [(ngModel)]="CustomOrCompound"
                layout="row">
                <mat-radio-button value="custom" (change)="radioChange()">{{globalConstants.quick_search}}
                </mat-radio-button>
                <mat-radio-button class="pad-left20" value="compound" (change)="radioChange()">
                    {{globalConstants.advanced_search}} </mat-radio-button>
                <br>
            </mat-radio-group>
            <form [formGroup]="searchForm" autocomplete="off">
                <mat-form-field *ngIf="!isDisplayAddFilter || (CustomOrCompound=='custom')" class="search-input"
                    [ngStyle]="setMyStyles()">
                    <input matInput placeholder="Search Value" formControlName="searchControl" 
                        [(ngModel)]="searchValue"  (keydown.enter)="getSearchUsers()">
                        <mat-error *ngIf="searchForm.get('searchControl').hasError('required')">
                            {{globalConstants.search_value_err1}}
                        </mat-error>
                        <mat-error *ngIf="searchForm.get('searchControl').hasError('minlength')">
                            {{globalConstants.search_value_err2}}
                        </mat-error>
                        <mat-error *ngIf="!searchForm.get('searchControl').hasError('minlength') && searchForm.get('searchControl').hasError('pattern')">
                            {{globalConstants.first_last_name_err}}
                        </mat-error>
                    <mat-hint class="mat-hint-text">{{globalConstants.search_hint1}}</mat-hint>
                </mat-form-field>
                <div *ngIf="isDisplayProps && (!isDisplayAddFilter||CustomOrCompound=='custom')" class="prop-dropdown">
                    <ng-multiselect-dropdown class="quick-search left-pad"
                        [placeholder]="'click here to select Properties'" [settings]="dropdownSettings"
                        (onDeSelect)="onItemDeselect($event)" [data]="sortedDropDownList" [(ngModel)]="selectedItems"
                        (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                        (onDeSelectAll)="onDeselectAll($event)" formControlName="selectedProperties">
                    </ng-multiselect-dropdown>
                </div>
            </form>
        </h1>
        <div *ngIf="isDisplayCompoundSearch && CustomOrCompound=='compound'" class="search-pad">
            <div class="border" [style.visibility]="isDisplayBorder ? 'visible' : 'hidden'">
                <div *ngIf="isformArrayError" class="search-error">{{globalConstants.search_err1}}</div>
                <form [formGroup]="searchFormArray" autocomplete="off">
                    <div *ngFor="let searchArray of searchFormArray.controls; let idCount=index;">
                        <div [formGroup]="searchArray">
                            <p class="search-txt">where
                                <mat-form-field class="search-item">
                                    <mat-select formControlName="selectedItem" placeholder="Field" required (selectionChange)="onCategoryChange(idCount)">
                                        <mat-option *ngFor="let searchItem of searchItems" [value]="searchItem.value" >
                                            {{ searchItem.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="searchArray.get('selectedItem').hasError('required')">
                                        {{globalConstants.search_err3}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="search-op">
                                    <mat-select formControlName="selectedOperator" placeholder="Operator" required>
                                        <mat-option *ngFor="let operator of operatorList" [value]="operator.value"
                                            [style.display]="mathOperatorforNumbers(searchArray.get('selectedItem').value, operator.value )?'none':'block'">
                                            {{ operator.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="searchArray.get('selectedOperator').hasError('required')">
                                        {{globalConstants.search_err4}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="search-val" *ngIf="!(searchArray.get('selectedItem').value == 'beginDate' || searchArray.get('selectedItem').value == 'endDate' 
                                    || searchArray.get('selectedItem').value == 'created')">
                                    <input formControlName="searchValues" matInput placeholder="Value" required (keydown.enter)="getSearchUsers()">
                                    <mat-error *ngIf="searchArray.get('searchValues').hasError('required')">
                                        {{globalConstants.search_err5}}
                                    </mat-error>
                                    <mat-error *ngIf="validateSearchValue(searchArray.get('selectedItem').value,idCount) && searchArray.get('searchValues').hasError('minChars')">
                                        {{globalConstants.search_value_err2}}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="search-val"
                                    *ngIf="(searchArray.get('selectedItem').value == 'beginDate' || 
                                    searchArray.get('selectedItem').value == 'endDate' || searchArray.get('selectedItem').value == 'created')">
                                    <input matInput [matDatepicker]="picker" formControlName="searchValues"
                                        (click)="picker.open()" placeholder="Choose a date" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <i class="material-icons font18 pad-left" (click)="removeCondition(idCount)">clear
                                    icon</i>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <h1>
            <div *ngIf="isDisplayAddFilter && CustomOrCompound=='compound' " class="left-pad10">
                <button class="save-button addnew" mat-raised-button (click)="addCount()">+ADD NEW CONDITION</button>
            </div>
        </h1>
        <form>
            <div class="pad-top25">
                <div class="search-button">
                    <button class="save-button bg-color" mat-raised-button (click)="clearConditions()">Clear</button>
                    <button class="save-button" mat-raised-button (click) ="getSearchUsers()">Search</button>
                    <button *ngIf="isSearchResult" class="export-button" mat-raised-button (click) ="exportSearchResult()">Export</button>
                </div>
            </div>
        </form>
        <div *ngIf="isDisplyResults">
            <!-- <h1 class="pad-btm20 font18"><i>Use Template</i>
                <div *ngIf="(loggedInUser == 'EMPLOYEE' && isDisplyResults)" class="template-input">
                    <input class="txtareaTemplate" [formControl]="tempIDtxtboxControl" placeholder="Search Value"><br>
                        <mat-hint class="tempHint">Enter a property ID, affiliate office code or 'Multi_Property'</mat-hint>
                    </div>
                    <div *ngIf="(loggedInUser != 'EMPLOYEE' && isDisplyResults)" class="prop-dropdown">
                        <ng-multiselect-dropdown class="quick-search"
                            [placeholder]="'click here to select Properties'" [settings]="dropdownSettings"
                             [data]="sortedDropDownList" (onSelect)="fetchTemplates()" [(ngModel)]="selectedItems">
                        </ng-multiselect-dropdown>
                    </div>
                <button *ngIf="loggedInUser == 'EMPLOYEE'" class="template-button-employeeuser" mat-raised-button (click) ="fetchTemplates()">Fetch Template</button>
                <div class="template-dropdown">
                    <mat-form-field>
                        <mat-select [formControl]="templateDropDownControl" disableOptionCentring [(value)]="selectedTemplate" (selectionChange)="onTemplateChange($event)" placeholder="Select a template to apply to the user/s">
                            <mat-option *ngFor="let template of dropDownListTemplate" [value]="template.value">{{template.viewValue}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button class="template-button-employeeuser" mat-raised-button (click) ="bulkApplyConfirmation()">Bulk Apply</button>
            </h1> -->
            <h1 class="pad-btm20 font18"><i>{{globalConstants.search_results}}</i>
                <mat-label class="status">{{globalConstants.user_status}}</mat-label>
                <mat-checkbox class="font-checkbox" [(ngModel)]="isActive" (change)="applyFilter()">
                    {{globalConstants.active}}
                </mat-checkbox>
                <mat-checkbox class="font-checkbox" [(ngModel)]="isInactive" (change)="applyFilter()">
                    {{globalConstants.inActive}}
                </mat-checkbox>
            </h1>
            <mat-card-content>
                <table mat-table matSort #table [dataSource]="dataSource" class="mat-elevation-z8"
                    matSortActive="lastName" matSortStart="asc" matSortDisableClear>
                    <ng-container matColumnDef="edit">
                        <th mat-header-cell class="edit-col" *matHeaderCellDef mat-sort-header="edit">
                            {{globalConstants.edit}}</th>
                        <td mat-cell *matCellDef="let element" data-label="edit">
                            <button class="edit-btn"
                                (click)="redirectToEditUser(element)">{{globalConstants.edit}}</button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="userId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="userId">{{globalConstants.user_id}}</th>
                        <td mat-cell *matCellDef="let element" data-label="userId" [ngClass]="'monospacestyle'" class="text-overflow"
                        matTooltip={{element.profile.login}} matTooltipPosition="below" (click)="redirectToEditUser(element)"> <a>
                            {{(element.profile.login && element.profile.login.indexOf("@bwhhotel.com") >=0) ?
                            element.profile.login.substr(0,element.profile.login.indexOf("@")): element.profile.login}}</a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="lastName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="lastName">{{globalConstants.last_name}}
                        </th>
                        <td mat-cell *matCellDef="let element" data-label="lastName" [ngClass]="'monospacestyle'" class="text-overflow"
                        matTooltip={{element.profile.lastName}} matTooltipPosition="below" (click)="redirectToEditUser(element)"><a>{{element.profile.lastName}}</a></td>
                    </ng-container>
                    <ng-container matColumnDef="firstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="firstName">{{globalConstants.first_name}}
                        </th>
                        <td mat-cell *matCellDef="let element" data-label="firstName" [ngClass]="'monospacestyle'" class="text-overflow"
                        matTooltip={{element.profile.firstName}} matTooltipPosition="below" (click)="redirectToEditUser(element)"><a>{{element.profile.firstName}}</a>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="idm_role">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="idm_role">User Role
                        </th>
                        <td mat-cell *matCellDef="let element" data-label="idm_role"> {{element.profile.idm_role}}
                        </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="isApplyTemplate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="isApplyTemplate"><mat-checkbox class="font-checkbox" [(ngModel)]="selectAll" (change)="selectAllUsers($event)">
                            Select
                        </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let element" data-label="isApplyTemplate"><mat-checkbox [(ngModel)]="element.isUserChecked" (change)="addUserToBulkUpdate($event, element)"></mat-checkbox>
                        </td>
                    </ng-container> -->
                    <ng-container matColumnDef="activeOrInactive">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="activeOrInactive">
                            {{globalConstants.search_user_status}}</th>
                        <td mat-cell *matCellDef="let element" data-label="activeOrInactive" class="status-data" matTooltip={{showStatusTooltip(element.status)}} matTooltipPosition="below">
                            {{(element.status == 'PROVISIONED') ? ("ACTIVE (PENDING)") : ((element.status == 'SUSPENDED') ? ("DEACTIVATED") : (element.status))}}</td>
                    </ng-container>
                    <ng-container matColumnDef="beginDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="beginDate">{{globalConstants.search_active_date}}
                        </th>
                        <td mat-cell *matCellDef="let element" data-label="beignDate">
                            {{element.profile.beginDate ? (element.profile.beginDate | date:'mediumDate' : 'UTC'): "" }}</td>
                    </ng-container>
                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="endDate">{{globalConstants.search_inactive_date}}
                        </th>
                        <td mat-cell *matCellDef="let element" data-label="endDate">
                            {{element.profile.endDate ? (element.profile.endDate | date:'mediumDate' : 'UTC'): "" 
                            }}</td>
                    </ng-container>
                    <ng-container matColumnDef="userType">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="userType">{{globalConstants.userType}}
                        </th>
                        <td mat-cell *matCellDef="let element" data-label="userType">
                            {{element.profile.employeeTypeIDM}}</td>
                    </ng-container>
                    <ng-container matColumnDef="userManagement">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header="userManagement">{{globalConstants.idm}}
                        </th>
                        <td mat-cell *matCellDef="let element" class="text-center" data-label="userManagement">
                            {{element.profile.userManagement}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-card-content>
        </div>
    </div>
</body>